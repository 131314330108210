
import React from 'react';


const ViewItems = () => {
    return (
        <i className="fa fa-sitemap" style={{fontSize:"25px"}}></i>
    );
};

export default ViewItems;
