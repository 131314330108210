import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import Columnchart from './columnloadingdashboard.js';
import redirectURL from "../redirectURL";

var googleAnalytics = require("../common/googleanalytics");

var moment = require('moment');

export default class LoadingDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '',
            endDate: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            dept_code: "SNDG",
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
            sndtab: 'show-n',
            prttab: 'show-n',
            tnptab: 'show-n',
            sndtabactive: '',
            prttabactive: '',
            tnptabactive: '',
            //loadingurl:""
        }
    }
    componentDidMount() {
        //console.log("window.location.pathname ", this.props)
        // var userdpts = JSON.parse(localStorage.getItem("dept_code"));
        // var splitdept = userdpts.split(",")
        //console.log("splitdept ",userdpts)
        var dept_code = '';
        var loadingurl = "";
        if (window.location.pathname == "/loadingdashboard") {
            dept_code = "SNDG";
        }
        if (window.location.pathname == "/sndloadingdashboard") {
            dept_code = "SNDG";

        }

        if (window.location.pathname == "/tnploadingdashboard") {
            dept_code = "LOG-TNP";
        }

        if (window.location.pathname == "/prtloadingdashboard") {
            dept_code = "LOG-PRT";
        }

        // if(userdpts.length > 0)
        // {
        // if(userdpts.includes("SA") == true || userdpts.includes("AD") == true)
        // {
        //     this.setState({
        //         sndtab:'show-m',
        //         sndtabactive:'active',
        //         prttab:'show-m',
        //         prttabactive:'',
        //         tnptab:'show-m',
        //         tnptabactive:''
        //     })
        // }
        // else{
        if (dept_code == "SNDG") {
            this.setState({
                sndtab: 'show-m',
                sndtabactive: 'active',
                prttab: 'show-n',
                prttabactive: '',
                tnptab: 'show-n',
                tnptabactive: ''
            })
        }
        else if (dept_code == "LOG-PRT") {
            this.setState({
                sndtab: 'show-n',
                sndtabactive: '',
                prttab: 'show-m',
                prttabactive: 'active',
                tnptab: 'show-n',
                tnptabactive: ''
            })
        }
        else if (dept_code == "LOG-TNP") {
            this.setState({
                sndtab: 'show-n',
                sndtabactive: '',
                prttab: 'show-n',
                prttabactive: '',
                tnptab: 'show-m',
                tnptabactive: 'active'
            })
        }
        else {
            this.setState({
                sndtab: 'show-m',
                sndtabactive: 'active',
                prttab: 'show-m',
                prttabactive: '',
                tnptab: 'show-m',
                tnptabactive: ''
            })
        }
        // }
        //}

        var edate = moment.parseZone().format('YYYY-MM-DD');
        var sdate = moment.parseZone().subtract(90, 'days').format('YYYY-MM-DD');
        //var sdate = "2020-05-01"
        var preqparams = {
            dept_code: dept_code
        }
        if (dept_code == 'all') {
            this.setState({
                dept_code: "SNDG",
                startDate: sdate,
                endDate: edate,
                // loadingurl:loadingurl
            })
        }
        else {
            this.setState({
                dept_code: "SNDG",
                startDate: sdate,
                endDate: edate,
                // loadingurl:loadingurl
            })
        }
    }

    // componentWillReceiveProps(newprops){
    //     //.log("window.location.pathname ", newprops.dept_code)
    //     var userdpts = JSON.parse(localStorage.getItem("dept_code"));
    //    // var splitdept = userdpts.split(",")
    //     //console.log("splitdept ",userdpts)
    //     if(newprops.dept_code == "SNDG")
    //     {
    //         this.setState({
    //             sndtab:'show-m',
    //             sndtabactive:'active',
    //             prttab:'show-n',
    //             prttabactive:'',
    //             tnptab:'show-n',
    //             tnptabactive:''
    //         })
    //     }
    //     else if(newprops.dept_code == "LOG-PRT")
    //     {
    //         this.setState({
    //             sndtab:'show-n',
    //             sndtabactive:'',
    //             prttab:'show-m',
    //             prttabactive:'active',
    //             tnptab:'show-n',
    //             tnptabactive:''
    //         })
    //     }
    //    else if(newprops.dept_code == "LOG-TNP")
    //     {
    //         this.setState({
    //             sndtab:'show-n',
    //             sndtabactive:'',
    //             prttab:'show-n',
    //             prttabactive:'',
    //             tnptab:'show-m',
    //             tnptabactive:'active'
    //         })
    //     }
    //     else
    //     {
    //         this.setState({
    //             sndtab:'show-m',
    //             sndtabactive:'active',
    //             prttab:'show-m',
    //             prttabactive:'',
    //             tnptab:'show-m',
    //             tnptabactive:''
    //         })
    //     }
    //     // if(userdpts.length > 0)
    //     // {
    //     //     if(userdpts.includes("SA") == true || userdpts.includes("AD") == true)
    //     //     {
    //     //         this.setState({
    //     //             sndtab:'show-m',
    //     //             sndtabactive:'active',
    //     //             prttab:'show-m',
    //     //             prttabactive:'',
    //     //             tnptab:'show-m',
    //     //             tnptabactive:''
    //     //         })
    //     //     }
    //     //     else{
    //     //         if(userdpts.includes("SNDG") == true){
    //     //             this.setState({
    //     //                 sndtab:'show-m',
    //     //                 sndtabactive:'active'
    //     //             })
    //     //         }

    //     //         if(userdpts.includes("LOG-PRT") == true){
    //     //             this.setState({
    //     //                 prttab:'show-m',
    //     //                 prttabactive:'active'
    //     //             })
    //     //         }

    //     //         if(userdpts.includes("LOG-TNP") == true){
    //     //             this.setState({
    //     //                 tnptab:'show-m',
    //     //                 tnptabactive:'active'
    //     //             })
    //     //         }
    //     //     }
    //     // }
    //     var edate = moment.parseZone().format('YYYY-MM-DD');
    // 	//var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
    //     var sdate = "2020-05-01";
    //     var dept_code='SNDG';
    //     var preqparams = {
    //         dept_code:dept_code
    //     }
    //     this.setState({
    //         dept_code:dept_code,
    //         startDate:sdate,
    //         endDate:edate
    //     })

    // }

    onClickLoadDept(deptcode) {
        this.setState({
            dept_code: deptcode
        })
    }

    render() {
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl = '';
        var transiturl = '';
        var transporturl = '';
        if (this.state.dept_code == "SNDG") {
            taburl = "/snddashboardtemplate";
            loadingurl = "/sndloadingdashboard";
            transiturl = "/sndtransitanalytics";
            transporturl = "/sndtransportdashboard";
        }
        if (this.state.dept_code == "LOG-TNP") {
            taburl = "/tnpdashboardtemplate";
            loadingurl = "/tnploadingdashboard";
            transiturl = "/tnptransitanalytics";
            transporturl = "/tnptransportdashboard";
        }
        if (this.state.dept_code == "LOG-PRT") {
            taburl = "/prtdashboardtemplate";
            loadingurl = "/prtloadingdashboard";
            transiturl = "/prttransitanalytics";
            transporturl = "/prttransportdashboard";
        }
        if (this.state.dept_code == "LOG-SC") {
            taburl = "/scdashboardtemplate";
        }

        if (this.state.dept_code == "LOG-PRT") {
            var dailyurl = "/prtdailyrunningdashboard";
        }
        else {
            var dailyurl = "/dailyrunningdashboard";
        }
        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                        {/* <li>
                            {(window.location.pathname == "/loadingdashboard") ?
                                // <a href={"/dashboardsummary"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                //     <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                // </a>

                                : ""}
                            {(this.state.dept_code == "SNDG" && window.location.pathname != "/loadingdashboard") ?
                                <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-PRT" && window.location.pathname != "/loadingdashboard") ?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-TNP" && window.location.pathname != "/loadingdashboard") ?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}

                        </li> */}
                        {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
                       
                      
                    </ul>
                </div>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>Loading Dashboard</span>

                        </h5>
                    </div>

                    {/* <ul className="tabc-sm">
                        <li className={this.state.sndtab}>
                            <a href="javascript:;" className={"loadclick " + (this.state.sndtabactive)} id="SNDG" onClick={this.onClickLoadDept.bind(this, 'SNDG')}>Sales and Dispatch</a>
                        </li>
                        <li className={this.state.prttab}>
                            <a href="javascript:;" className={"loadclick " + (this.state.prttabactive)} id="LOG-PRT" onClick={this.onClickLoadDept.bind(this, 'LOG-PRT')}>Spare Parts</a>
                        </li>
                        <li className={this.state.tnptab}>
                            <a href="javascript:;" className={"loadclick " + (this.state.tnptabactive)} id="LOG-TNP" onClick={this.onClickLoadDept.bind(this, 'LOG-TNP')}>Train and Production</a>
                        </li>
                    </ul> */}
                    <div className=" col-xl-12 col-lg-12 mt-35p whiteRow">
                        <Columnchart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            //locations={this.state.locations}
                            //transporters={this.state.transporters}
                            dept_code={this.state.dept_code}
                        />
                    </div>
                </div>
            </div>
        );
    }
}



$(document).ready(function () {
    $(".loadclickm").on("mouseover", function () {
        var id = $(this).attr("id");
        // $(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide()
        if (id == "load") {
            $("#" + id).addClass("active");
            $(".dashboard-sub").show()
        }

    })

    $(".loadclickm").on("mouseout", function () {
        var id = $(this).attr("id");
        //$(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide()
        // $("#"+id).addClass("active");
        // $("#"+id+">dashboard-sub").show()

    })
});









