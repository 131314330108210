// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**=====================
     61. Print CSS Start
==========================**/
@media print {
  .page-sidebar {
    display: none; }

  .page-wrapper .page-main-header {
    display: none; }
  .page-wrapper .page-body-wrapper .page-header {
    display: none; }
  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
    margin-left: 0;
    padding: 0; } }
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px; }

.invoice .text-right input {
  margin-top: 6px; }

/**=====================
    61. Print CSS Start
==========================**/
`, "",{"version":3,"sources":["webpack://./src/assets/css/print.css"],"names":[],"mappings":"AAAA;;4BAE4B;AAC5B;EACE;IACE,aAAa,EAAE;;EAEjB;IACE,aAAa,EAAE;EACjB;IACE,aAAa,EAAE;EACjB;IACE,cAAc;IACd,UAAU,EAAE,EAAE;AAClB;EACE,gBAAgB;EAChB,mBAAmB,EAAE;;AAEvB;EACE,eAAe,EAAE;;AAEnB;;4BAE4B","sourcesContent":["/**=====================\n     61. Print CSS Start\n==========================**/\n@media print {\n  .page-sidebar {\n    display: none; }\n\n  .page-wrapper .page-main-header {\n    display: none; }\n  .page-wrapper .page-body-wrapper .page-header {\n    display: none; }\n  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {\n    margin-left: 0;\n    padding: 0; } }\n.invoice-table {\n  margin-top: 16px;\n  margin-bottom: 16px; }\n\n.invoice .text-right input {\n  margin-top: 6px; }\n\n/**=====================\n    61. Print CSS Start\n==========================**/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
