import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DailyRunningTransporter from './dailyrunningtransporter';

var moment = require('moment');

export default class DailyRunningTransporterDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '',
            endDate: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            dept_code: "SNDG",
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }]
        }
    }
    componentDidMount() {
        var edate = moment.parseZone().format('YYYY-MM-DD');
        //var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
        var sdate = "2020-05-01";
        var dept_code = 'SNDG';
        if (window.location.pathname == "/sndtransportdashboard") {
            dept_code = "SNDG";

        }

        if (window.location.pathname == "/tnptransportdashboard") {
            dept_code = "LOG-TNP";
        }

        if (window.location.pathname == "/prttransportdashboard") {
            dept_code = "LOG-PRT";
        }
        var preqparams = {
            dept_code: dept_code
        }
        this.setState({
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate
        })

    }


    onClickLoadDept(deptcode) {
        this.setState({
            dept_code: deptcode
        })
    }

    render() {
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl = '';
        var transiturl = '';
        var transporturl = '';
        if (this.state.dept_code == "SNDG") {
            taburl = "/snddashboardtemplate";
            loadingurl = "/sndloadingdashboard";
            transiturl = "/sndtransitanalytics";
            transporturl = "/sndtransportdashboard";
        }
        if (this.state.dept_code == "LOG-TNP") {
            taburl = "/tnpdashboardtemplate";
            loadingurl = "/tnploadingdashboard";
            transiturl = "/tnptransitanalytics";
            transporturl = "/tnptransportdashboard";
        }
        if (this.state.dept_code == "LOG-PRT") {
            taburl = "/prtdashboardtemplate";
            loadingurl = "/prtloadingdashboard";
            transiturl = "/prttransitanalytics";
            transporturl = "/prttransportdashboard";
        }
        if (this.state.dept_code == "LOG-SC") {
            taburl = "/scdashboardtemplate";
        }
        if (this.state.dept_code == "LOG-PRT") {
            var dailyurl = "/prtdailyrunningdashboard";
        }
        else {
            var dailyurl = "/dailyrunningdashboard";
        }
        return (
            <div className="col-xl-12 col-lg-12">
                {/* <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                        <li>
                            {(this.state.dept_code == "SNDG") ?
                                <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-PRT") ?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-TNP") ?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                        </li>
                        {(this.state.dept_code != 'LOG-SC') ?
                            <li className="btn-group active">
                                <a href="javascript:;" className="loadclickm active" id="load" onClick={this.onClickLoadDept.bind(this, 'load')}>
                                    <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />

                                    <ul className="dashboard-sub" style={{ "display": "none" }}>

                                        {(this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT') ?
                                            <li>
                                                <a href={dailyurl}>
                                                    Daily Running
                                                </a>
                                            </li>
                                            : ""}
                                        {(this.state.dept_code == "SNDG") ?
                                            <li>
                                                <a href="/sndloadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-PRT") ?
                                            <li>
                                                <a href="/prtloadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-TNP") ?
                                            <li>
                                                <a href="/tnploadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        <li>
                                            {(this.state.dept_code == "SNDG") ?
                                                <a href="/sndunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}
                                            {(this.state.dept_code == "LOG-PRT") ?
                                                <a href="/prtunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}
                                            {(this.state.dept_code == "LOG-TNP") ?
                                                <a href="/tnpunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}
                                        </li>
                                        {(this.state.dept_code == "SNDG") ?
                                            <li>
                                                <a href="/sndtransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-PRT") ?
                                            <li>
                                                <a href="/prttransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-TNP") ?
                                            <li>
                                                <a href="/tnptransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}


                                        {(this.state.dept_code == 'SNDG') ?
                                            <li className="active">
                                                <a href="/sndtransportdashboard">
                                                    Transporter Daily
                                                </a>
                                            </li>
                                            : ""}
                                        {(localStorage.getItem('roles') != '') ?
                                            <li>
                                                <a href="/sndgpsdashboard">
                                                    GPS Dashboard
                                                </a>
                                            </li>
                                            // : ""
                                            : ""}

                                        {(localStorage.getItem('roles') != '') ?
                                            <li>
                                                <a href="/prtgpsdashboard">
                                                    GPS Dashboard
                                                </a>
                                            </li>
                                            // : ""
                                            : ""}

                                        {(localStorage.getItem('roles') != '') ?
                                            <li>
                                                <a href="/tnpgpsdashboard">
                                                    GPS Dashboard
                                                </a>
                                            </li>
                                            : ""}
                                        {(this.state.dept_code == 'SNDG' && window.location.pathname != "/dailyrunningmanagement") ?
                                            <li>
                                                <a href="/ontimedashboard">
                                                    On TIme Performance
                                                </a>
                                            </li>
                                            : ""}
                                    </ul>
                                </a>
                            </li>
                            : ""}
                    </ul>
                </div> */}
                <h5 className="col-xl-12 col-lg-12">
                    <i className="icofont icofont-dashboard-web cus-i"></i> <span style={{ fontWeight: "600" }}>Transporter Daily Running</span>
                </h5>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className="card-body n-p-0 mt-10p">
                        {/*<ul className="tabc-sm">
                            <li>
                                <a href="javascript:;" className="loadclick active" id="SNDG" onClick={this.onClickLoadDept.bind(this, 'SNDG')}>Sales and Dispatch</a>
                            </li>
                             <li>
                                    <a href="javascript:;" className="loadclick" id="LOG-PRT" onClick={this.onClickLoadDept.bind(this,'LOG-PRT')}>Spare Parts</a>
                                </li>
                                <li>
                                    <a href="javascript:;" className="loadclick" id="LOG-TNP" onClick={this.onClickLoadDept.bind(this,'LOG-TNP')}>Train and Production</a>
                                </li> 
                        </ul>*/}
                        <div className=" col-xl-12 col-lg-12">
                            <DailyRunningTransporter
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                //locations={this.state.locations}
                                //transporters={this.state.transporters}
                                dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}












