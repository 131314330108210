
import React, { Component } from 'react';

 
const ColorPicker = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        route_color = e.target.value;
        props.context.componentParent.onChangeRouteColor(e, props.data);
    };

    var route_color = props.route_color

    return (
        <div>
			{props.route_no} &nbsp; &nbsp; &nbsp;<input type="color" value={route_color} onChange={handleRouteClick}/>
        </div>
    );
};

export default ColorPicker;
