 
const ButtonAction = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        // console.log("Button props ", props)
        props.context.onClickButtonAction(props);
    };

    return (
        <button type="button" onClick={handleClick} className="btn btn-warning c_btn f12">{props.button_title}</button>
    );
};

export default ButtonAction;
