import React from 'react';

const EditButtonRenderer = (props) => {
    const handleEditClick = () => {
        props.onClickEdit(props.data);
    };

    return (
        <div onClick={handleEditClick} title="Edit">
            <i className="fa fa-edit"></i> Edit
        </div>

    );
};

export default EditButtonRenderer;



// import React from 'react';

// const EditButtonRenderer = (props) => {
//     const handleEditClick = () => {
//         props.context.componentParent.showEditBlock(props.data);
//     };

//     return (
//         <button onClick={handleEditClick} className="btn btn-primary">
//             Edit
//         </button>
//     );
// };

// export default EditButtonRenderer;
