const columnwithDefs = [
    {
        headerName:"",
        field:"_id",
        type:"actions",
        actionlist:[
            {
                headerName:"",
                field:"",
                type:"icon",
                isPinned:"left",
                actionfor:"edit",
                viewmode:"show",
                width:50
            }
        ]
    },
    {
        headerName:"Vehicle No",
        field:"vehicle_no",
        type:"string",
        isPinned:"no",
        width:200
    },
    {
        headerName:"Dealer Code",
        field:"consignee_code",
        type:"string",
        isPinned:"no",
        width:150
    },
    {
        headerName:"Dealer Name",
        field:"consignee_name",
        type:"string",
        isPinned:"no",
        width:200
    },
    {
        headerName: "Address",
        field: "consignee_address",
        type:"string",
        isPinned:"no",
        width:300
    }
];
export {columnwithDefs};