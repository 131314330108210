import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from "moment";
import EbillWayInfo from './ewaybillinfo';
import CancelButton from './cancelButton';
import UpdateVehicle from './updateVehicle';
import ExtendValidity from './extendValidity';
import ViewButton from './ViewButton';
import CountUp from 'react-countup';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SweetAlert from "react-bootstrap-sweetalert";
import redirectURL from "../redirectURL";
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYYHHMMSS } from '../common/utils';
import ViewConsignmentsLegs from '../tracktrace/viewConsignmentsLegs';

const EwayBillConsolidatedReports = () => {

    const columnwithDefs = [
        // {
        //     headerName: "Cancel",
        //     field: "cancel",
        //     width: 120,
        //     resizable: true,
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: CancelButton
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        // },
        // {
        //     headerName: "Update Vehicle",
        //     field: "partb",
        //     width: 130,
        //     resizable: true,
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: UpdateVehicle
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        // },
        // {
        //     headerName: "Extend Validity",
        //     field: "extend",
        //     width: 130,
        //     resizable: true,
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: ExtendValidity
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        // },
        {
            headerName: "Vehicle Number",
            field: "vehicle_number",
            colId: "vehicle_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true,
            valueGetter: function (params) {
                if (params.data.VehiclListDetails != undefined && params.data.VehiclListDetails != "") {
                    var VehiclListDetails = params.data.VehiclListDetails;
                    var vehicleNumber = [];
                    VehiclListDetails.map(function (v) {
                        if (v.vehicle_number != undefined && v.vehicle_number != "") {
                            vehicleNumber.push(v.vehicle_number);
                        }
                    })
                    if (vehicleNumber.length > 0) {
                        return vehicleNumber.join(",");
                    }
                }
                else {
                    return params.data.vehicle_number
                }
            }
        },
        {
            headerName: "E-Way Bill No",
            field: "eway_bill_number",
            colId: "eway_bill_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true,
        },
        {
            headerName: "E-Way Bill Date",
            field: "eway_bill_date",
            colId: "eway_bill_date",
            filter: true,
            width: 150,
            resizable: true,
            hide: false
        },
        {
            headerName: "E-Way Bill Validity",
            field: "eway_bill_valid_date",
            colId: "eway_bill_valid_date",
            filter: true,
            width: 150,
            resizable: true,
            hide: false
        },
        {
            headerName: "Entered Date",
            field: "entered_date",
            colId: "entered_date",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "E-Way Bill Status",
            field: "eway_bill_status",
            colId: "eway_bill_status",
            width: 130,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true,
            valueGetter: function (params) {
                if (params.data.eway_bill_status == "Cancelled") {
                    return params.data.eway_bill_status;
                }
                else {
                    if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
                        var validityDate = params.data.eway_bill_valid_date;
                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var difference = parseFloat(d.asDays());
                        if (difference > 0 && difference <= 1) {
                            return "Expiring Today";
                        }
                        else if (difference > 1 && difference <= 2) {
                            return "Expiring Tomorrow";
                        }
                        else if (difference <= 0) {
                            return "Expired";
                        }
                        else {
                            return "Active";
                        }
                    }
                    else {
                        return "N/A";
                    }
                }
            }
        },
        {
            headerName: "Total Invoice Value",
            field: 'total_invoice_value',
            colId: 'total_invoice_value',
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Number",
            field: "document_number",
            colId: "document_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Date",
            field: "document_date",
            colId: "document_date",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Consignee Name",
            field: "legal_name_of_consignee",
            colId: "legal_name_of_consignee",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Consignee GSTIN",
            field: "gstin_of_consignee",
            colId: "gstin_of_consignee",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Consignor Name",
            field: "legal_name_of_consignor",
            colId: "legal_name_of_consignor",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Consignor State",
            field: "state_of_consignor",
            colId: "state_of_consignor",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Transportation Mode",
            field: "transportation_mode",
            colId: "transportation_mode",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
    ]

    const columnwithDefs2 = [
        {
            headerName: "",
            field: "ewaybillLegs",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ViewConsignmentsLegs,
                            params: {
                                onClickShowConsignmentsLegsData: () => { }
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        {
            headerName: "Vehicle Number",
            field: "vehicle_number",
            colId: "vehicle_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true,
            valueGetter: function (params) {
                if (params.data.VehiclListDetails != undefined && params.data.VehiclListDetails != "") {
                    var VehiclListDetails = params.data.VehiclListDetails;
                    var vehicleNumber = [];
                    VehiclListDetails.map(function (v) {
                        if (v.vehicle_number != undefined && v.vehicle_number != "") {
                            vehicleNumber.push(v.vehicle_number);
                        }
                    })
                    if (vehicleNumber.length > 0) {
                        return vehicleNumber.join(",");
                    }
                }
                else {
                    return params.data.vehicle_number
                }
            }
        },
        {
            headerName: "Consolidated E-Way Bill No",
            field: "trip_sheet_number",
            colId: "trip_sheet_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true,
            cellStyle: { 'font-weight': 'bold' },
        },
        {
            headerName: "GST Of Generator",
            field: "userGstin",
            colId: "userGstin",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Number",
            field: "document_number",
            colId: "document_number",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Date",
            field: "document_date",
            colId: "document_date",
            width: 150,
            hide: false,
            filter: "agSetColumnFilter",
            cellRenderer: '', resizable: true
        },
    ]
    var columnwithDefsForvehicle = [
        {
            headerName: "Vehicle No",
            field: "vehicle_number",
            colId: "vehicle_number",
            width: 110,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Updated On",
            field: "vehicle_number_update_date",
            colId: "vehicle_number_update_date",
            width: 160,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Transportation Mode",
            field: "transportation_mode",
            colId: "transportation_mode",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Number",
            field: "transporter_document_number",
            colId: "transporter_document_number",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Date",
            field: "transporter_document_date",
            colId: "transporter_document_date",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
    ];

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        pivot: false,
        flex: 1
    }
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [rowData, setRowData] = useState([])
    const [responseData, setResponseData] = useState([])
    const [overly, setOverlay] = useState("show-n")
    const [loadshow, setLoadShow] = useState("show-n")
    const [sliderType, setSliderType] = useState('')
    const [inputDetails, setInputDetails] = useState('')
    const [activeData, setActiveData] = useState([])
    const [expiringtomorrowData, setExpiringtomorrowData] = useState([])
    const [detailCellRendererParams, setDetailCellRendererParams] = useState(null);
    const [expiringtodayData, setExpiringtodayData] = useState([])
    const [expiredData, setExpiredData] = useState([])
    const [cancelledData, setCancelledData] = useState([])

    useEffect(() => {
        setLoadShow("show-m")
        setOverlay("show-m")
        redirectURL.post("/ewaybill/getewaybillconsolidatedreports")
            .then(async (response) => {
                var gridData = response.data;
                console.log("gridData", gridData)
                var lastSyncedOn = "";
                if (gridData.length > 0) {
                    if (gridData[0].last_synced_on != undefined && gridData[0].last_synced_on != "") {
                        lastSyncedOn = getHyphenDDMMYYYYHHMMSS(gridData[0].last_synced_on);
                    }
                }
                let group_data = []
                gridData.map(each => {
                    let temp = group_data.findIndex(e => e.trip_sheet_number == each.trip_sheet_number)
                    if (temp >= 0) {
                        group_data[temp]["legsData"].push(each)
                    }
                    else {
                        each["legsData"] = [each]
                        group_data.push(each)
                    }
                })
                setRowData(group_data)
                setResponseData(group_data)
                var activeData = [];
                var expiringtomorrowData = [];
                var expiringtodayData = [];
                var expiredData = [];
                var cancelledData = [];
                await group_data.map(function (e) {
                    if (e.eway_bill_status == "Cancelled") {
                        cancelledData.push(e);
                    }
                    else {
                        if (e.eway_bill_extended_validity_date != undefined && e.eway_bill_extended_validity_date != '') {
                            var validityDate = e.eway_bill_extended_validity_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if (difference > 0 && difference <= 1) {
                                expiringtodayData.push(e);
                            }
                            else if (difference > 1 && difference <= 2) {
                                expiringtomorrowData.push(e);
                            }
                            else if (difference <= 0) {
                                expiredData.push(e);
                            }
                            else {
                                activeData.push(e);
                            }
                        }
                        else {
                            if (e.eway_bill_valid_date != undefined && e.eway_bill_valid_date != '') {
                                var validityDate = e.eway_bill_valid_date;
                                var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                                var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                                var d = moment.duration(ms);
                                var difference = parseFloat(d.asDays());
                                if (difference > 0 && difference <= 1) {
                                    expiringtodayData.push(e);
                                }
                                else if (difference > 1 && difference <= 2) {
                                    expiringtomorrowData.push(e);
                                }
                                else if (difference <= 0) {
                                    expiredData.push(e);
                                }
                                else {
                                    activeData.push(e);
                                }
                            }
                        }
                    }
                });
                setActiveData(activeData)
                setExpiringtomorrowData(expiringtomorrowData)
                setExpiringtodayData(expiringtodayData)
                setExpiredData(expiredData)
                setCancelledData(cancelledData)
                setOverlay("show-n")
                setLoadShow("show-n")
            })
    }, [])

    const onCellClicked = (params) => {
        if (params.colDef.field == "ewaybillLegs") {
            params.node.setExpanded(!params.node.expanded)
            setDetailCellRendererParams({
                detailGridOptions: {
                    columnDefs: columnwithDefs,
                    defaultColDef: {
                    },
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.legsData ?? []);
                },
            })
        }
    }

    const onclickCounter = (counterType) => {
        var rowData = [];
        if (counterType == "1") {
            setRowData(activeData)
        }
        else if (counterType == "2") {
            setRowData(expiringtomorrowData)
        }
        else if (counterType == "3") {
            setRowData(expiringtodayData)
        }
        else if (counterType == "4") {
            setRowData(expiredData)
        }
        else if (counterType == "5") {
            setRowData(responseData)
        }
        else {
            setRowData(cancelledData)
        }
    }
    const onConfirm = () => {
        setShow(false);
        setTitle('')
        setType('default')
        setOverlay("show-n")
        setLoadShow("show-n")
    };

    return (
        <div class="container-fluid">
            <SweetAlert
                show={show}
                title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                onConfirm={onConfirm}
                confirmBtnBsStyle={type}
                confirmBtnCssClass="sweetButton"
                customClass='sweetWidth'
                type={type}>
            </SweetAlert>
            <h5 className="col-xl-12 col-lg-12">
                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontWeight: "600" }}>E-Way Bills Consolidate Reports</span>
            </h5>
            <div className="row col-xl-12 col-lg-12">
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{ padding: "0" }}>
                    <div className="card">
                        <div className="card-body">
                            <div className="crm-numbers pb-0">
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>All</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "5", marginTop: "5%" }} onClick={e => onclickCounter("5")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#4099ff" }}>
                                                <span className="counter">
                                                    <CountUp end={(responseData.length) ? responseData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Active</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={e => onclickCounter("1")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#008000" }}>
                                                <span className="counter">
                                                    <CountUp end={(activeData.length) ? activeData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expiring Tomorrow </span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={e => onclickCounter("2")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FFFF00" }}>
                                                <span className="counter">
                                                    <CountUp end={(expiringtomorrowData.length) ? expiringtomorrowData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expiring Today</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={e => onclickCounter("3")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FFA500" }}>
                                                <span className="counter">
                                                    <CountUp end={(expiringtodayData.length) ? expiringtodayData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expired</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={e => onclickCounter("4")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FF0000" }}>
                                                <span className="counter">
                                                    <CountUp end={(expiredData.length) ? expiredData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Cancelled</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "5", marginTop: "5%" }} onClick={e => onclickCounter("6")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "red" }}>
                                                <span className="counter">
                                                    <CountUp end={(cancelledData.length) ? cancelledData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card col-xl-12 col-lg-12">
                    <div className="card-body">
                        <div id="myGrid" style={{ width: "100%", height: "478px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                columnDefs={columnwithDefs2}
                                defaultColDef={defaultColDef}
                                rowData={rowData}
                                enableCharts={false}
                                masterDetail={true}
                                detailCellRendererParams={detailCellRendererParams}
                                sideBar={{
                                    toolPanels: [
                                        {
                                            id: "columns",
                                            labelDefault: "Columns",
                                            labelKey: "columns",
                                            iconKey: "columns",
                                            toolPanel: "agColumnsToolPanel",
                                            toolPanelParams: {
                                                suppressPivotMode: true,
                                                suppressRowGroups: true,
                                                suppressValues: true,
                                            }
                                        },
                                        {
                                            id: "filters",
                                            labelDefault: "Filters",
                                            labelKey: "filters",
                                            iconKey: "filter",
                                            toolPanel: "agFiltersToolPanel"
                                        }
                                    ]
                                }}
                                statusBar={{
                                    statusPanels: [
                                        {
                                            statusPanel: "agTotalAndFilteredRowCountComponent",
                                            align: "left"
                                        },
                                        {
                                            statusPanel: "agTotalRowCountComponent",
                                            align: "center"
                                        },
                                        { statusPanel: "agFilteredRowCountComponent" },
                                        { statusPanel: "agSelectedRowCountComponent" },
                                        { statusPanel: "agAggregationComponent" }
                                    ]
                                }}
                                paginationPageSize={50}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                enableRangeSelection={true}
                                onCellClicked={onCellClicked}
                            // onCellEditingStopped={this.onCellUpdateData}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="form-theme col-xl-12 col-lg-12 row">
                    <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "auto" }}>
                        <h3 className="subH">
                            {this.state.sidebarSubHeader}
                            <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={onConfirm}>x</span>
                        </h3>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="container-fluid">
                                {(this.state.sliderType == 1) ?
                                    <form method="POST" className="theme-form upform" onSubmit={this.cancelEwayBill.bind(this)}>

                                        <div className="form-group mt-20p">
                                            <label>Reason Of Cancel</label>
                                            <select name="reason_of_cancel" id="reason_of_cancel" placeholder="" className="form-control" required>
                                                <option value="">Select Reason</option>
                                                <option value="Duplicate">Duplicate</option>
                                                <option value="Order Cancelled">Order Cancelled</option>
                                                <option value="Data Entry Mistake">Data Entry Mistake</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                    : ""}
                                {(sliderType == 2) ?
                                    <EbillWayInfo ebillInfo={inputDetails} />
                                    : ""}
                                {(this.state.sliderType == 3) ?
                                    <form method="POST" id="partbform" className="theme-form upform" onSubmit={this.updatePartBDetails}>
                                        <div className="form-group">
                                            <label>Mode</label>
                                            <select class="form-control" name="mode_of_transport" id="mode_of_transport" onChange={this.changeHandler}  >
                                                <option value="">-- Select Mode --</option>
                                                <option value="road">Road</option>
                                                <option value="rail">Rail</option>
                                                <option value="air">air</option>
                                                <option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-20p">
                                            <label>Vehicle Type</label>
                                            <select class="form-control" name="vehicle_type" id="vehicle_type">
                                                <option value="">-- Select Vehicle Type --</option>
                                                <option value="regular">Regular</option>
                                                <option value="over dimensional cargo">Over Dimensional Cargo</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-20p">
                                            <label>Vehicle No : </label>
                                            <input type="text" name="vehicle_number" id="vehicle_number" className="form-control" />
                                        </div>
                                        <div className="form-group mt-20p">
                                            <label>Reason</label>
                                            <select class="form-control" name="reason_for_vehicle_updation" id="reason_for_vehicle_updation">
                                                <option value="">-- Reason --</option>
                                                <option value="First Time">First Time</option>
                                                <option value="Due to Break Down">Due to Break Down</option>
                                                <option value="Due to Transhipment">Due to Transhipment</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-20p">
                                            <label>Transporter Doc. No : </label>
                                            <input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields" />
                                        </div>
                                        <div className={"form-group mt-20p"}>
                                            <label>Transporter Doc. Date : </label><br />
                                            <DatePicker
                                                selected={this.state.transporter_document_date}
                                                onChange={e => this.setState({ transporter_document_date: e })}
                                                name="transporter_document_date"
                                                id="transporter_document_date"
                                                className="datepicker-custom"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                        </div>
                                    </form>
                                    : ""}
                                {(this.state.sliderType == 4) ?
                                    <div className='row col-sm-12'>
                                        <div id="myGrid" style={{ width: "100%", height: "300px" }} className={"ag-theme-balham"}>
                                            <AgGridReact
                                                columnDefs={columnwithDefsForvehicle}
                                                defaultColDef={defaultColDef}
                                                rowData={rowData}
                                                enableCharts={false}
                                                sideBar={{
                                                    toolPanels: [
                                                        {
                                                            id: "columns",
                                                            labelDefault: "Columns",
                                                            labelKey: "columns",
                                                            iconKey: "columns",
                                                            toolPanel: "agColumnsToolPanel",
                                                            toolPanelParams: {
                                                                suppressPivotMode: true,
                                                                suppressRowGroups: true,
                                                                suppressValues: true,
                                                            }
                                                        },
                                                        {
                                                            id: "filters",
                                                            labelDefault: "Filters",
                                                            labelKey: "filters",
                                                            iconKey: "filter",
                                                            toolPanel: "agFiltersToolPanel"
                                                        }
                                                    ]
                                                }}
                                                statusBar={{
                                                    statusPanels: [
                                                        {
                                                            statusPanel: "agTotalAndFilteredRowCountComponent",
                                                            align: "left"
                                                        },
                                                        {
                                                            statusPanel: "agTotalRowCountComponent",
                                                            align: "center"
                                                        },
                                                        { statusPanel: "agFilteredRowCountComponent" },
                                                        { statusPanel: "agSelectedRowCountComponent" },
                                                        { statusPanel: "agAggregationComponent" }
                                                    ]
                                                }}
                                                paginationPageSize={50}
                                                pagination={false}
                                            />
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className={"dataLoadpage " + loadshow}></div>
            <div className={"dataLoadpageimg " + loadshow}>
                <div className="loader-box">
                    <div className="loader-box">
                        <div className="rotate dashed colored">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EwayBillConsolidatedReports