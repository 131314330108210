
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import redirectURL from "../redirectURL";


const ApprovalDeviationRoute = (props) => {
   
    const handleClickRevert = (e) => {
        e.stopPropagation();
       // console.log("eee ", e.target.value)
        props.context.componentParent.onClickRouteApproval(props, e.target.value);
    };
  //  console.log("props.data  ",props)
  // console.log("props.data.route_effective_from ",props.node.rowModel.rowsToDisplay[10].data[props.colDef.field])
   var effectiv = ''; 
//    var effectiv = props.node.rowModel.rowsToDisplay[10].data[props.colDef.field]; //tmsssssssssssss
   
   return (
        <div>
            <span>
                {(props.value != "undef")?
                    ((effectiv != "" || effectiv != " -- ")?
                        (parseInt(props.data[props.colDef.field]) == 1)?"Implement":
                            <select onChange={handleClickRevert}>
                                <option value="r" selected={(props.value == "")?"selected":false}>Select</option>
                                <option value={1} selected={(parseInt(props.data[props.colDef.field]) == 1)?"selected":false} >Implement</option>
                                <option value={0} selected={(parseInt(props.data[props.colDef.field]) == 0)?"selected":false} >Reject</option>
                                <option value={2} selected={(parseInt(props.data[props.colDef.field]) == 2)?"selected":false} >Hold</option>
                            </select>
                        :
                    <select onChange={handleClickRevert}>
                        <option value="r" selected={(props.value == "")?"selected":false}>Select</option>
                        <option value={1} selected={(parseInt(props.data[props.colDef.field]) == 1)?"selected":false} >Implement</option>
                        <option value={0} selected={(parseInt(props.data[props.colDef.field]) == 0)?"selected":false} >Reject</option>
                    </select>
                    )
                :""}
                
            </span>
        </div>
    );
};

export default ApprovalDeviationRoute;
