import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils.js';

import Map from '../common/drawmap.js';

import redirectURL from '../redirectURL.js';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
// import Consignmentforceclose from '../manage/consignmentforceclose';
var moment = require('moment');
export default class TruckSidebarComponent extends Component {

    constructor(props) {
        super(props);
        this.state={
            // truckData:this.props.truckInfo,
            consignmentlists:this.props.consignments,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            truckData:this.props.truckData,
            rowData: this.props.consignment,
            truckLocation:"",
            route_details:"",
            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
        };
        this.onClickShowMap = this.onClickShowMap.bind(this);
    //    console.log("Contxt", this.props.consignments);
    // console.log(typeof(this.state.truckData));

       // this.showRelatedData()
    }
    componentWillReceiveProps (newprops){
        // console.log("this.state.consignments",this.state.consignments);
        // console.log("newprops.consignments",newprops.consignments);
        if(this.state.truckData !== newprops.truckData){
            this.setState({
                truckData:newprops.truckData,
                consignment_details:newprops.consignment_details
            })
        }
    }
    
    // componentDidMount(){
    //     console.log(this.props);
    // }
    handleClick = (e) => {
        e.stopPropagation();
        this.props.context.onCloseUploadDiv();
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel(); 
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
     
     onClickShowMap(){
         console.log(this.state.truckData);
        //  let truck_Data = this.state.truckData
        // console.log("Step1", truck_Data[0].invoice_time)
        if(this.state.truckData[0].invoice_time)
        {
            // console.log("came into invoice time", consignment_details.invoice_time)
            
            var gateouttime = moment.parseZone(this.state.truckData[0].invoice_time).format('YYYY-MM-DD HH:mm:ss');
            console.log(gateouttime);
        }
        else{
            //console.log("Step2")
            if(this.state.truckData[0].gate_in_time)
            {
                // console.log("came into gate in time", consignment_details.gate_in_time)
                //console.log("Step3")
                var gateouttime = moment.parseZone(this.state.truckData[0].gate_in_time).format('YYYY-MM-DD HH:mm:ss');
            }
            else{
                //console.log("Step4")
                if(this.state.truckData[0].gate_out_time){
                    //console.log("Step5")
                    // console.log("came into gate out time", consignment_details.gate_out_time)
                    var gateouttime = moment.parseZone(this.state.truckData[0].gate_out_time).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        }
        
        // if (e.gate_out_time) {
        // 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        // }
        
        var edate ='';
        var end_date;
        if(this.state.truckData[0].status <= 4)
        {
            //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
            //var edatesecond = new Date(ms);
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            
            edate = getDDMMYYYYHHMMSS(end_date);
        }
        else if(this.state.truckData[0].status == 5)
        {
            if (this.state.truckData[0].left_dealer_marked) {
                end_date = this.state.truckData[0].left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getDDMMYYYYHHMMSS(this.state.truckData[0].left_dealer_marked);
            }
            
        }
        else{
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            
            edate = getDDMMYYYYHHMMSS(end_date);
            
        }
        
        //console.log("end date",end_date);
        //console.log("checking e",e)

        
        if (!end_date) {
            // When end date is unavailable then current date is considered as end date.
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            //console.log("Defining end_date ", end_date);
        }
        var erparmas = {
            truck_no:this.props.truckNo,
            start_time:gateouttime,
            end_time:edate,
        }   
        // console.log(erparmas);
        redirectURL.post("/consignments/activeConsignmentSideMap", erparmas)
        .then((response) => {
            // console.log("resp map", response.data)
            this.setState({
                consignment_details : response.data,
            })
             
            // console.log("consignmentResponse",response.data);
            
            this.setState({
                truckLocation : this.state.consignment_details,
                route_details:{"start_time":gateouttime,"end_time":edate},

            });
            // console.log(this.state.truckLocation);
        })        
    }
    render(){
    const consigneecols = [
        {
            headerName: "Consignment Code",
            field: "consignment_code",
            width: 150,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width: 150,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Consignee Code",
            field: "consignee_code",
            width: 150,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Cluster",
            field: "cluster",
            width: 150,
            filter: "agTextColumnFilter"
        },
        {
            headerName: "Gate In time",
            field: "gate_in_time",
            width: 150,
            filter: "agTextColumnFilter",
            valueGetter:function(params){
                return getDDMMYYYYHHMMSS(params.data.gate_in_time)
            }
        },
        {
            headerName: "Gate Out time",
            field: "gate_out_time",
            width: 150,
            filter: "agTextColumnFilter",
            valueGetter:function(params){
                return getDDMMYYYYHHMMSS(params.data.gate_out_time)
            }
        },
    ];
    const consigneecols1 = [
        {
            headerName: "",
            field: "transit_delay",
            colId: "transit_delay",
            width: 50,
            pinned: 'left',
            pivot: false,
            cellRenderer:transitDelay,
            filter: true,resizable: true
        },
        {
            headerName: "",
            field: "no_gps_data",
            colId: "no_gps_data",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:noGpsData,
        },
        {
            headerName: "Overspeed",
            field: "overspeeding_exception",
            colId: "overspeeding_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:OverspeedException,
        },
        {
            headerName: "Night Drive",
            field: "nightdriving_exception",
            colId: "nightdriving_exception",
            width: 40,
            pinned: 'left',					
            pivot: true,
            cellRenderer:nightDrive,
            // hide:hideonlyPRT,
            
        },
        {
            headerName: "Loading Delay",
            field: "loading_delay_exception",
            colId: "loading_delay_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:loadingDelayExcept,
        },

        {
            headerName: "Enroute Stoppages",
            field: "enroute_exception",
            width: 40,
            pinned: 'left',
            pivot: false,
            cellRenderer:enrouteExcept,
        },
    ];
    // console.log(this.props.truckInfo);
        return ( 
                <div className="col-xl-12 xol-lg-12">
                    <div className="row">
                        <div className="slide-r-title col-xl-12 col-lg-12" style={{padding:"0px"}}>
                            <h4>
                                Truck No #{this.props.truckNo}
                            </h4>
                        </div>
                        <div className="slide-r-body col-xl-12 col-lg-12" style={{position:"relative"}}>
                            <div className="col-xl-12 col-lg-12">
                            {/* <Accordion preExpanded='info'>
                                <AccordionItem uuid='info'>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Basic Information:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={this.props.truckInfo}>
                                        
                                         <table className="table table-border">
                                             <tbody>
                                             {this.props.truckInfo.map(item=>{
                                            return [<tr>
                                                <th>Transporter Name</th>
                                                <td>{item.transporter_name}</td>
                                            </tr>,
                                            <tr>
                                                <th>Transporter Code</th>
                                                <td>{item.transporter_code}</td>
                                            </tr>,
                                            <tr>
                                                <th>Department Code</th>
                                                <td>{item.dept_code}</td>
                                            </tr>,
                                            <tr>
                                                <th>Last Data Received On</th>
                                                <td>{item.last_gps_date}</td>
                                            </tr>]
                                             })
                                             }
                                            </tbody>
                                        </table>
                                           
                                        
                                    </AccordionItemPanel>
                                </AccordionItem>
                                
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                              Recent Consignments:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={this.state.consignmentlists}>
                                         
                                        <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                columnDefs={consigneecols}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.props.consignments}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                            
                                            />
                                        </div>
                                           
                                    </AccordionItemPanel>
                                </AccordionItem>
                                
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                              Exceptions
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={this.state.consignmentlists}>
                                         
                                    <div id="myGrid1" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                columnDefs={consigneecols1}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.truckData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                            
                                            />
                                        </div>
                                           
                                    </AccordionItemPanel>
                                </AccordionItem>



                                <AccordionItem>
                                    <AccordionItemHeading  onClick={this.onClickShowMap}>
                                        <AccordionItemButton>
                                         Map Location:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    {(this.state.truckLocation != '')?
                                        <Map width={"92%"} mapFor={'activeTrucksSidebarMap'} route_details={this.state.route_details} mapinfo={this.state.truckLocation} marginLeft={"15px"} />
                                    :""}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion> */}

                            </div>
                            <div className="col-xl-12 col-lg-12 mt-30p">
                                <button onClick={this.handleClick.bind(this)} className="btn btn-default">Close</button>
                            </div>
                        </div>
                     </div>
                </div>
			);
		}
}

function transitDelay(params){
    // console.log("PP ", params.data.transit_delay)
    if(params.data.transit_delay == 1)
    {
        return '<i class="f22 icofont icofont-clock-time" style="color:#ff0000"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-clock-time" style="color:#ccc"></i>';
    }
}

function noGpsData(params){
    // console.log(params.data.no_gps_data, typeof params.data.no_gps_data)
    if(params.data.no_gps_data == 1)
    {
        return '<i class="f22 icofont icofont-social-google-map" style="color:#26c6da"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-social-google-map" style="color:#ccc"></i>';
    }
}


function OverspeedException(params){
    // console.log("params.data.overspeeding_exception",params.data.overspeeding_exception);
    if(params.data.overspeeding_exception == 1)
    {
        return '<i class="fa fa-tachometer fa-2x" style="color:#00c292"></i>';
    }
    else{
        return '<i class="fa fa-tachometer fa-2x" style="color:#ccc"></i>';
    
    }
}

function nightDrive(params){
    if(params.data.nightdriving_exception == 1)
    {
        return '<i class="f22 icofont icofont-full-night txt-primary" style="color:#ab8ce4"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-full-night" style="color:#ccc"></i>';
    
    }  
}

function loadingDelayExcept(params){
    if(params.data.loading_delay_exception){
        return '<i class="f22 icofont icofont-truck-loaded darkorange" style="color:#d3a337"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-truck-loaded" style="color:#ccc"></i>';
    
    }
    
}

function enrouteExcept(params){
    if (params.data.enroute_exception)
    {
        return '<i class="f22 icofont icofont-pause maroon" style="color:#a34518"></i>';
    }
    else{
        return '<i class="f22 icofont icofont-pause maroon" style="color:#ccc"></i>';
    
    }
}










