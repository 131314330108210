import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
// import { AgGridReact } from '@ag-grid-community/react';
// import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import CSVFileValidator from 'csv-file-validator'

import $ from 'jquery';
import Select from "react-select";
import { getDecoder, getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYY, getHyphenDDMMYYYYHHMMSS, getHyphenYYYYMMDD } from '../common/utils';
// import 'jquery-ui/ui/core';
// import 'jquery-ui/ui/widgets/datepicker';
// import ViewItems from "./customerviewitems";
import WithOutDefects from "./viewitemswithoutdefects";
import WithDefects from "./withdefects";
// import ViewDoc from "./viewdocbtn";
// import ViewDoc2Btn  from "./viewdoc2";
import CountUp from 'react-countup';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
// import NavLinkBtn from "./navigationurl";
// import PrintItems from "./printitems";
// import Rating from "./ratingpod";
// import ExportPDF from "./exportPDF";
// import * as Datetime from 'react-datetime';
// import 'react-datetime/css/react-datetime.css';
// $.datetimepicker.setLocale('en');
// var infoBox = require('../common/google-infowindow');
// var Constant = require('../common/Constant');
import redirectURL from "../redirectURL";
import SubmitEPOD from './SubmitEPOD';
import ViewItems from './viewitems';
import PrintItems from './printItems';
import Rating from './ratingpod';
import DownloadItem from './downloadItem';
import moment from 'moment';
var googleAnalytics = require("../common/googleanalytics");
var cdt = 0;
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    // return encoded => encoded.match(/.{1,2}/g)
    // 	.map(hex => parseInt(hex, 16))
    // 	.map(applySaltToChar)
    // 	.map(charCode => String.fromCharCode(charCode))
    // 	.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    catch (e) {

    }

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class EPODScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deptcode: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            // modules: AllModules,
            pivotmodeis: false,
            submitEPODType: '',
            plants: [],
            flCity: { value: "", label: "All" },
            flState: { value: "", label: "All" },
            flCluster: { value: "", label: "All" },
            flZone: { value: "", label: "All" },
            flDealer: { value: "", label: "All" },
            consigner: [{ value: "all", label: "All" }],
            startDate: "",
            endDate: "",
            date_type: { value: "all", label: "All" },
            filterCities: [],
            filterStates: [],
            filterDealers: [],
            filterClusters: [],
            filterZones: [],
            filterTransporters: [],
            flTransporter: { value: "", label: "All" },
            user: getDecoder(),
            pivotcols: [],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                // NavLinkBtn:NavLinkBtn,
                // ViewItems:ViewItems,
                // ViewDoc:ViewDoc,
                // ViewDoc2Btn:ViewDoc2Btn,
                WithOutDefects: WithOutDefects,
                WithDefects: WithDefects,
                // PrintItems:PrintItems,
                // ExportPDF:ExportPDF,
                // Rating:Rating
            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            show: false,
            basicTitle: "",
            basicType: "default",
            show1: false,
            basicTitle1: "",
            basicType1: "default",
            loadshow: "show-n",
            overly: "show-n",
            overly2: "show-n",
            sliderAddTranslate: '',
            sliderWithoutDefectAddTranslate: "",
            sliderWithDefectAddTranslate: "",
            sliderSubmitEPODTranslate: "",
            sliderFeedback: "",
            sliderViewDownloads: '',
            downloadItems: [],
            customer_pod_date: "",
            customer_lr_doc: "",
            customer_pod_remarks: "",
            item_code: { "value": "", "label": "Select Item" },
            item_description: "",
            defect_description: "",
            defect_code: { "value": "", "label": "Select Defect" },
            category: { "value": "", "label": "Select Category" },
            quantity: "",
            itemslist: [],
            itemcode: "",
            defectlist: [],
            defectcode: '',
            categorylist: [],
            categorycode: "",
            customer_code: "",
            grn_no: "",
            alldata: [],
            podsubmitdata: [],
            nonpoddata: [],
            withdefects: [],
            withoutdefects: [],
            withdefectspod: [],
            withoutdefectspod: [],
            sidebarrowData: [],
            srownode: "",
            invoice_no: "",
            supply_plant_desc: "",
            feedrowid: "",
            consignment_code: "",
            plantlist: [],
            plant_code: '',
            feed_rownode: "",
            rating1: { "value": "10", "label": "10" },
            rating2: { "value": "10", "label": "10" },
            rating3: { "value": "10", "label": "10" },
            comment1: "",
            comment2: "",
            comment3: "",
            material_description: "",
            material_no: "",
            customer_document_1: "",
            customer_document_2: "",
            admincode: "",
            allcustomers: [],
            scustomer: { "value": "", "label": "All Customers" },
            podsDocs: [],
            driver_mobile: "",
            driver_mobile_with_defects: "",
            driver_without_defect_otp: "",
            driver_with_defect_otp: "",
            is_driver_without_defect_verified: 0,
            is_driver_with_defect_verified: 0,
            is_driver_without_defect_otp_trigger: 0,
            is_driver_with_defect_otp_trigger: 0,
            mobile_number: "",
            gps_device_vehicle: { value: "", label: "Select" },
            device_no: ""


        }

        this.changeHandler = this.changeHandler.bind(this);
        this.changeMobileHandler = this.changeMobileHandler.bind(this);
        this.changeDeviceHandler = this.changeDeviceHandler.bind(this);
        this.changeMobileDefectsHandler = this.changeMobileDefectsHandler.bind(this);
        this.changeQtyHandler = this.changeQtyHandler.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.changeFileHandlerDoc = this.changeFileHandlerDoc.bind(this);
        this.saveItemsFormData = this.saveItemsFormData.bind(this);
        this.saveWithOutItemsFormData = this.saveWithOutItemsFormData.bind(this);
        this.onClickViewPrintItems = this.onClickViewPrintItems.bind(this);
        this.onClickShowFeedbackForm = this.onClickShowFeedbackForm.bind(this);
        this.savePODFeedback = this.savePODFeedback.bind(this);
        this.sendDriverWithOutDefectOTP = this.sendDriverWithOutDefectOTP.bind(this);
    }

    checkNotifyHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        const user = getDecoder()
        this.setState({
            user: user
        })
        if (localStorage.getItem("pr") == "" || localStorage.getItem("pr") == undefined) {
            this.setState({
                loadshow: "show-n"
            })
            localStorage.setItem("pr", 1)
            setTimeout(() => {
                window.location.reload();
            }, 100)
        }
        this.filtersData.bind(this);
        var edate = moment.parseZone().format('YYYY-MM-DD');
        var sdate = moment.parseZone().subtract(1, 'year').format('YYYY-MM-DD');
        let query = {}
        if (user.role[0] == "DEALER")
            query["user_code"] = user.dealer_code ?? "EMPTY"
        redirectURL.post("consignments/allPlantCodes", query)
            .then(res => {
                console.log(res, "resss");
                if (res.data?.length > 0) {
                    let temp = res.data.map(e => { return { label: (e.consigner_name + " (" + e.consigner_code + ")"), value: e.consigner_code } })
                    this.setState({
                        plantlist: temp
                    })
                }
            })
            .catch(err => console.log(err))

        // var loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        var loginplant = "";
        // try {
        // 	// var luser = JSON.parse(loguser);
        // 	loginplant = luser.is_plant_code;
        // 	// console.log("logplant", loginplant)
        // 	if (loginplant !== "" && loginplant !== undefined) {
        // 		this.setState({
        // 			loginplant: loginplant,
        // 			consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

        // 		})
        if (loginplant != "" && loginplant != undefined) {
            var consigners = [{ "value": loginplant, "label": loginplant }]
        }
        else {
            var consigners = this.state.consigner;
        }
        if (user?.role?.[0] === "TRANSPORTER") {
            var transportavalue = { value: user?.transportercode?.[0] };
        }
        else {
            var transportavalue = this.state.flTransporter;
        }

        this.setState({
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
            flTransporter: transportavalue
        })
        if (user.role[0] == "DEALER")
            query["user_code"] = user.dealer_code ?? "EMPTY"
        redirectURL.post("/podcodes", query)
            .then((response) => {
                //  console.log("response.data ", response.data)
                var itemslist = response.data.items;
                var defectlist = response.data.defects;
                var categories = response.data.categories;
                this.setState({
                    itemslist: itemslist,
                    defectlist: defectlist,
                    categorylist: categories
                })
            })
        if (this.state.user?.customer_code != "" && this.state.user?.customer_code != undefined && this.state.user?.customer_code != "undefined") {
            if (this.state.user?.customer_code != "adminpod") {
                var customer_code = this.state.user?.customer_code;
            }
            else {
                this.setState({
                    admincode: "adminpod"
                })
                var customer_code = "";
            }

        }
        else {
            var customer_code = "";
        }
        this.setState({
            customer_code: customer_code,
            startDate: sdate,
            endDate: edate,
        })
        if (this.state.user?.role?.[0] === "REGIONAL_MANAGER" || this.state.user?.role?.[0] === "AREA_MANAGER") {
            var mp = {
                email: user?.email
            }
            redirectURL.post("/managerdealers", mp)
                .then((response) => {
                    var records = response.data.records;
                    var managerDealers = []
                    if (records.length > 0) {
                        records.map((item) => {
                            managerDealers.push(item.consignee_code)
                        })
                    }
                    // console.log('managerDealers ', managerDealers)
                    this.setState({
                        managerDealers: managerDealers
                    }, () => {

                        var parameters = {
                            // dealer_code: customer_code,
                            startDate: sdate,
                            endDate: edate,
                        }
                        if (user.role[0] == "DEALER")
                            parameters["user_code"] = user.dealer_code ?? "EMPTY"
                        this.onLoadGetShipments(parameters);

                    })
                })
        }
        else {
            var parameters = {
                // dealer_code: customer_code,
                startDate: sdate,
                endDate: edate,
            }
            if (user.role[0] == "DEALER")
                parameters["user_code"] = user.dealer_code ?? "EMPTY"
            this.onLoadGetShipments(parameters);

        }
    }
    onLoadGetShipments(parameters) {
        const user = getDecoder()
        if (user?.role === "TRANSPORTER") {
            parameters.transporter_code = user?.transportercode?.[0]
        }
        if (this.state.user?.role?.[0] === "REGIONAL_MANAGER" || this.state.user?.role?.[0] === "AREA_MANAGER") {
            parameters.managerDealers = this.state.managerDealers
        }
        parameters.date_type = this.state.date_type.value;
        this.setState({
            loadshow: "show-n"
        })
        if (this.state.user?.role?.[0] === "APP_ADMIN" || this.state.user?.role?.[0] === "ADMIN") {
            var urlpath = "/customerconsignments"
        }
        else {
            if (this.state.user?.role?.[0] === "PARENT_DEALER") {
                var urlpath = "/customerconsignments"
            }
            else {
                var urlpath = "/customerconsignments"
            }
        }
        console.log(urlpath, parameters)
        redirectURL.post(urlpath, parameters)
            .then((response) => {
                if (response.data.status == "success") {
                    var records = response.data.records;
                    console.log("records_425 ", records)
                    var rowData = [];
                    // if(records.length > 0)
                    // {
                    //     try{
                    //         var pods = records[0].pods;
                    //         if(pods.length > 0)
                    //         {
                    //             pods.map((itm) => {
                    //                 itm.customer_pod_date = moment.parseZone(itm.customer_pod_date).format("DD-MM-YYYY HH:mm");
                    //                 itm.truck_no = records[0].truck_no;
                    //                 rowData.push(itm);
                    //             })
                    //         }
                    //     }
                    //     catch(e){

                    //     }

                    // }

                    var recordsarr = [];
                    var podsubmitdata = []
                    var nonpoddata = [];
                    var withoutdefects = [];
                    var withdefects = [];
                    var withoutdefectspod = [];
                    var withdefectspod = [];
                    var approvedpods = [];
                    var allcustomers = [];
                    try {
                        // console.log("records ", records)
                        if (records.length > 0) {
                            records.map((item) => {
                                // console.log(item.is_pod_submit,"item.is_pod_submit")
                                if (item.is_pod_submit != "" && item.is_pod_submit != undefined) {
                                    if (item.pods != "" && item.pods != undefined) {
                                        if (item.pods.length > 0) {
                                            // console.log("item.pods", item.pods);
                                            // item.truck_no = item.pods[0].truck_no;
                                            var isdefec = 0;
                                            item.pods.map((jt) => {
                                                jt.truck_no = item.truck_no;
                                                jt.invoice_date_frt = getHyphenDDMMMYYYYHHMM(jt.invoice_date);
                                                jt.customer_pod_date_frt = getHyphenDDMMMYYYYHHMM(jt.customer_pod_date);
                                                jt.tpt_code = getHyphenDDMMMYYYYHHMM(item.transporter_code);
                                                jt.tpt_name = getHyphenDDMMMYYYYHHMM(item.transporter_name);
                                                if (jt.is_defect_item == 1) {
                                                    isdefec = 1;
                                                    withdefects.push(jt);
                                                }
                                                else {
                                                    withoutdefects.push(jt);
                                                }
                                            })
                                            if (isdefec == 1) {
                                                withdefectspod.push(item)
                                            }
                                            else {
                                                withoutdefectspod.push(item)
                                            }

                                        }
                                    }

                                    podsubmitdata.push(item);
                                    if (item.is_approve_pod == 1) {
                                        approvedpods.push(item);
                                    }
                                }
                                else {
                                    item.invoice_document_1 = "";
                                    item.invoice_document_2 = "";
                                    item.is_pod_submit = "";
                                    nonpoddata.push(item)
                                }
                                item.inv_date = getHyphenDDMMMYYYYHHMM(item.invoice_date);
                                item.rec_dealer_reported = getHyphenDDMMMYYYYHHMM(item.recent_dealer_reported);
                                allcustomers.push({
                                    value: item.consignee_code,
                                    label: item.consignee_name + " - " + item.consignee_code
                                })
                                recordsarr.push(item);
                            })
                        }
                    }
                    catch (e) {

                    }
                    // console.log("allcustomers ", allcustomers)
                    var allcustomersarr = [];
                    if (allcustomers.length > 0) {

                        // Declare an empty object
                        let totuniqueObject = {};
                        var totobjTitle;
                        // Loop for the array elements
                        for (let i in allcustomers) {

                            // Extract the title
                            totobjTitle = allcustomers[i]['value'];

                            // Use the title as the index
                            totuniqueObject[totobjTitle] = allcustomers[i];
                        }

                        // Loop to push unique object into array
                        for (var a in totuniqueObject) {
                            allcustomersarr.push(totuniqueObject[a]);
                        }
                    }
                    // console.log("rowData ", allcustomersarr)

                    allcustomersarr = allcustomersarr.sort(GetSortASCOrder("label"));
                    this.setState({
                        rownode: recordsarr,
                        alldata: recordsarr,
                        rowData: recordsarr,
                        podsubmitdata: podsubmitdata,
                        // withoutdefects:withoutdefects,
                        // withdefects:withdefects,
                        // withoutdefectspod:withoutdefectspod,
                        // withdefectspod:withdefectspod,
                        nonpoddata: nonpoddata,
                        loadshow: "show-n",
                        overly: "show-n",
                        allcustomers: allcustomersarr
                    })
                }

            });

    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
    }
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
    }
    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

        this.gridApi.addEventListener('paginationChanged', (e) => {
            //Reset rows selection based on current page
            // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode((node) => {
            // console.log("node here ",node)
            node.setSelected(true);

        });

    };


    onGridState = () => {
        console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);




        //
    }

    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderRouteTranslate: "",
            sliderCommentTranslate: "",
            sliderSegmentTranslate: "",
            sliderFeedback: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
            overly: 'show-n'
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            overly2: 'show-n',
            slideuploadeway: '',
            sliderAddTranslate: '',
            sliderWithoutDefectAddTranslate: "",
            sliderWithDefectAddTranslate: "",
            sliderSubmitEPODTranslate: "",
            submitEPODType: '',
            sliderFeedback: "",
            sliderViewDownloads: '',
            downloadItems: [],
            material_no: '',
            material_description: "",
            supply_plant_desc: "",
            customer_pod_date: "",
            itemcode: "",
            defectcode: "",
            category: "",
            quantity: "",
            gps_device_vehicle: "",
            device_no: "",
            customer_document_1: "",
            customer_pod_remarks: "",
            // driver_mobile_with_defects:"",
            // driver_with_defect_otp:"",
            // driver_without_defect_otp:"",
            // is_driver_with_defect_otp_trigger:"",
            // is_driver_without_defect_verified:'',
            // is_driver_with_defect_verified:"",
            grn_no: "",
            customer_pod_date_without: "",
            gps_device_vehicle: "",
            device_no: '',
            // is_driver_without_defect_otp_trigger:"",
            driver_mobile: '',
            driver_mobile_no: ""
        });
        cdt = 0

    }

    onClicCloseCounterBlock() {
        this.setState({
            showmys: "show-n",
            showdsc: "show-n",
            showcrm: "show-n",
            showplatemill: "show-n",
            showpipemill: "show-n",
        })
    }
    onClickUpdateItem(rownode) {
        console.log("rpownodessss ", rownode)
        // console.log("rownode.data.invoice_no ", rownode.data.invoice_no)
        this.setState({
            sliderAddTranslate: "slider-translate",
            overly: 'show-m',
            rowid: rownode.data._id,
            invoice_no: rownode.data.invoice_no,
            srownode: rownode.data
        })

        // cdt = getHyphenDDMMYYYYHHMMSS(rownode.data.recent_dealer_reported)
        try {

            if (rownode.data.recent_dealer_reported != "" && rownode.data.recent_dealer_reported != undefined) {
                cdt = moment.parseZone(rownode.data.recent_dealer_reported).add(1, "days").format("YYYY-MM-DD")
            }

        } catch (error) {

        }
    }

    onShowSubmitEPOD = async (rownode) => {
        this.setState({
            sliderSubmitEPODTranslate: "slider-translate-40p",
            overly: 'show-m',
            rowid: rownode.data._id,
            invoice_no: rownode.data.invoice_no,
            srownode: rownode.data,
            rownode: [rownode.data]
        })
    }

    onCLickWithOutDefectsLoadInvoicItems(rownode) {
        console.log("without rownode ", rownode);

        var c = moment.parseZone().format("DD-MM-YYYY HH:mm");

        let g = rownode.data.rec_dealer_reported

        $("#rec_dest_at_without").val(g);

        $("#customer_pod_date_without").val(c);
        this.setState({
            sliderWithoutDefectAddTranslate: "slider-translate",
            overly: 'show-m',
            rowid: rownode.data._id,
            invoice_no: rownode.data.invoice_no,
            srownode: rownode.data,
            rownode: [rownode.data]
        }, () => {
        })
        try {

            if (rownode.data.recent_dealer_reported != "" && rownode.data.recent_dealer_reported != undefined) {
                cdt = moment.parseZone(rownode.data.recent_dealer_reported).add(1, "days").format("YYYY-MM-DD")
            }

        } catch (error) {

        }
        // cdt = getHyphenYYYYMMDD(rownode.data.recent_dealer_reported)
    }

    onCLickWithDefectsLoadInvoicItems(rownode) {
        console.log("with rownode ", rownode);
        var supplyplant_code = rownode.data.consigner_code;
        var supply_name = "Daimler India Commercial Vehicles - " + rownode.data.consigner_name;

        var c = moment.parseZone().format("DD-MM-YYYY HH:mm");
        $("#customer_pod_date").val(c);
        let g = rownode.data.rec_dealer_reported

        $("#rec_dest_at").val(g);

        // cdt = getHyphenYYYYMMDD(rownode.data.recent_dealer_reported)

        try {

            if (rownode.data.recent_dealer_reported != "" && rownode.data.recent_dealer_reported != undefined) {
                cdt = moment.parseZone(rownode.data.recent_dealer_reported).add(1, "days").format("YYYY-MM-DD")
            }

        } catch (error) {

        }

        this.setState({
            sliderWithDefectAddTranslate: "slider-translate",
            overly: 'show-m',
            rowid: rownode.data._id,
            invoice_no: rownode.data.invoice_no,
            srownode: rownode.data,
            supply_plant_desc: { value: supplyplant_code, label: supply_name }
        }, () => {
            console.log(rownode.data, "LLLLLLLLL")
        })


    }

    changeHandler(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }
    changeMobileHandler(event) {
        var input = event.target.value;
        // console.log("input",input)
        var mobileValue = input.replace(/\D/g, '');
        // console.log("mobileValue",mobileValue)
        // console.log("cleanedValue",cleanedValue) // Allow only digits

        // Update the state with the cleaned value
        this.setState({ driver_mobile_with_defects: mobileValue });
        // console.log("driver_mobile",driver_mobile)
    }
    changeDeviceHandler(event) {
        let input = event.target.value;
        let device_no = input.replace(/\D/g, '');
        if (device_no != "")
            this.setState({ device_no: device_no });
    }
    changeMobileDefectsHandler(event) {
        var input = event.target.value;
        // console.log("input2",input)
        var mobileDefectsValue = input.replace(/\D/g, '');
        // console.log("mobileDefectsValue2",mobileDefectsValue)
        // console.log("cleanedValue",cleanedValue) // Allow only digits

        // Update the state with the cleaned value
        this.setState({ driver_mobile: mobileDefectsValue });
    }
    changeQtyHandler(event) {
        var name = event.target.name;
        var value = event.target.value;
        if (isNaN(value) == false) {
            if (value[0] != "0" && value[0] != 0 && value[0] != " " && value[0] != null) {
                this.setState({
                    [name]: value
                })
            }
            else {
                this.setState({
                    [name]: ""
                })
            }
        }
        else {

            this.setState({
                [name]: ""
            })
        }
    }
    itemslist() {
        var items = [];
        var itemcodes = this.state.itemslist;
        if (itemcodes?.length > 0) {
            itemcodes.map((item) => {
                items.push({
                    value: item.item_code,
                    label: item.item_description
                })
            })
        }
        return items;
    }

    handleDateChange = (date) => {
        this.setState({
            customer_pod_date: date
        });
    }

    changeItemCode(itemcode) {
        this.setState({ itemcode })
    }
    // changeSupplyPlant(supply_plant_desc) {
    //     this.setState({ supply_plant_desc })
    // }
    onChangePlantCode = async supply_plant_desc => {
        //console.log("Select", event.target.value);
        await this.setState(
            { supply_plant_desc },
            () => console.log(`Option selected:`, this.state.supply_plant_desc)
        );

    }

    defectlist() {
        var items = [];
        var defectcodes = this.state.defectlist;
        if (defectcodes?.length > 0) {
            defectcodes.map((item) => {
                items.push({
                    value: item.defect_code,
                    label: item.defect_description
                })
            })
        }
        //   console.log("items",items)
        return items;
    }
    changeDefectCode(defectcode) {
        //  console.log("defectcode",defectcode)
        this.setState({ defectcode }, () => {
            //    console.log('category ', this.state.category)

        })
    }
    categorylist() {
        var items = [];
        var categorylist = this.state.categorylist;

        if (categorylist?.length > 0) {
            categorylist.map((item) => {
                items.push({
                    value: item.category_code,
                    label: item.category_description
                })
            })
        }
        // console.log("items",items)
        return items;
    }
    changeCategoryCode(category) {
        this.setState({ category }, () => {
            //  console.log('category ', this.state.category)
        })
    }
    changeGPSDeviceVehicle(gps_device_vehicle) {
        this.setState({ gps_device_vehicle })
    }

    changeFileHandler(event) {
        // console.log("event.target.files[0] ", event.target.files)
        try {
            // if(event.target.files[0].size <= 102400){
            // this.setState({
            //     customer_document_1: event.target.files
            // })
            // }else {
            //     this.setState({
            //         show: true,
            //         basicTitle: "File Size exceeds 100kb",
            //         basicType: "info",
            //         customer_lr_doc: ""
            //     })
            //     $("#customer_lr_doc").val("")
            // }
            if (event.target.files.length <= 5)
                this.setState({
                    customer_document_1: event.target.files
                })
            else {
                this.setState({
                    show1: true,
                    basicTitle1: "Max of 5 files are allowed",
                    basicType1: "info",
                    customer_document_1: ""
                })
                event.target.value = ''
                $("#customer_document_1").val("")
            }
        }
        catch (e) {
            this.setState({
                customer_document_1: ""
            })
            $("#customer_document_1").val("")
        }

    }

    changeFileHandlerDoc(event) {
        // console.log("event.target.files[0] ", event.target.files)
        try {
            // if(event.target.files[0].size <= 102400){
            this.setState({
                customer_document_2: event.target.files[0]
            })
            // }else {
            //     this.setState({
            //         show: true,
            //         basicTitle: "File Size exceeds 100kb",
            //         basicType: "info",
            //         customer_lr_doc: ""
            //     })
            //     $("#customer_lr_doc").val("")
            // }


        }
        catch (e) {
            this.setState({
                customer_document_2: ""
            })
            $("#customer_document_2").val("")
        }
    }
    viewDownload = (params) => {
        console.log(params.data.invoice_document_1, "paramsssssssss");
        this.setState({
            sliderViewDownloads: "slider-translate-30p",
            overly: 'show-m',
            downloadItems: params.data.invoice_document_1
        })
    }
    downloadFile = (fileName) => {
        let params = {
            fileName: fileName
        }
        redirectURL.post("/getDownloadLink", params)
            .then((res) => {
                if (res.status === 200) {
                    const downloadUrl = res.data.url;
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute("download", fileName); // Optional: specify download filename
                    link.setAttribute("target", "_blank");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch((err) => {
                console.log("error", err);
                this.setState({
                    show: true,
                    basicTitle: "Failed to retrieve file",
                    basicType: "danger",
                })
            });
    }
    onCLickLoadInvoicItems = async (rownode) => {
        console.log("rpownode ", rownode)
        if (rownode.colDef.field == "view_legs") {
            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "",
                                field: "file_download",
                                width: 80,
                                cellRenderer: DownloadItem,
                                cellRendererParams: {
                                    viewDownload: this.viewDownload
                                },
                                filter: false,
                                resizable: true
                            },
                            {
                                headerName: "POD No",
                                field: "pod_no",
                                width: 140,
                                resizable: true
                            },
                            {
                                headerName: "Unique Code",
                                field: "item_uniquecode",
                                width: 120,
                                resizable: true
                            },
                            // {
                            //     headerName: "Invoice No",
                            //     field: "invoice_no",
                            //     width: 120,
                            //     resizable: true
                            // },
                            {
                                headerName: "Material No",
                                field: "material_no",
                                width: 120,
                                resizable: true
                            },
                            {
                                headerName: "Material Description",
                                field: "material_desc",
                                width: 180,
                                resizable: true
                            },

                            {
                                headerName: "Supplying Plant Description",
                                field: "supply_plant_desc",
                                width: 200,
                                resizable: true
                            },
                            {
                                headerName: "Invoice Date",
                                field: "invoice_date",
                                width: 120,
                                resizable: true,
                                valueGetter: function (params) {
                                    try {
                                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                                            return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                                        }
                                        else {
                                            return "";
                                        }
                                    }
                                    catch (e) {

                                    }
                                }
                            },
                            {
                                headerName: "Transporter Code",
                                field: "transporter_code",
                                width: 120,
                                resizable: true,
                                valueGetter: function (params) {
                                    try {
                                        return rownode.data.transporter_code;
                                    }
                                    catch (e) { }
                                }
                            },
                            {
                                headerName: "Transporter Name",
                                field: "transporter_name",
                                width: 120,
                                resizable: true,
                                valueGetter: function (params) {
                                    try {
                                        return rownode.data.transporter_name;
                                    }
                                    catch (e) { }
                                }
                            },
                            {
                                headerName: "POD Date",
                                field: "customer_pod_date",
                                width: 150,
                                resizable: true,
                                valueGetter: function (params) {
                                    try {
                                        if (params.data.customer_pod_date != "" && params.data.customer_pod_date != undefined) {
                                            return getHyphenDDMMMYYYYHHMM(params.data.customer_pod_date);
                                        }
                                        else {
                                            return "";
                                        }
                                    }
                                    catch (e) { }
                                }
                            },

                            // {
                            //     headerName: "Item Code",
                            //     field: "item_code",
                            //     width: 120,
                            //     resizable: true
                            // },

                            // {
                            //     headerName: "Item Description",
                            //     field: "item_description",
                            //     width: 120,
                            //     resizable: true
                            // },

                            {
                                headerName: "Defect Code",
                                field: "defect_code",
                                width: 120,
                                resizable: true
                            },

                            {
                                headerName: "Defect Description",
                                field: "defect_description",
                                width: 120,
                                resizable: true
                            },

                            // {
                            //     headerName: "Category",
                            //     field: "category",
                            //     width: 120,
                            //     resizable: true
                            // },

                            {
                                headerName: "Quantity",
                                field: "quantity",
                                width: 120,
                                resizable: true
                            },
                            {
                                headerName: "Remarks",
                                field: "customer_pod_remarks",
                                width: 120,
                                resizable: true
                            },
                        ],
                        overlayNoRowsTemplate: 'No rows to show',

                    },
                    // getDetailRowData: async function (param) {
                    //     param.successCallback([]);
                    //     try {

                    //         if (this.state.user?.role?.[0] === "APP_ADMIN" || this.state.user?.role?.[0] === "ADMIN") {
                    //             var rr = {
                    //                 dealer_code: param.data.parent_dealer_code,
                    //                 invoice_no: param.data.invoice_no
                    //             }
                    //             var urlpath = "/dashboard/parentcustomerconsignments"
                    //         }
                    //         else {
                    //             if (this.state.user?.role?.[0] === "PARENT_DEALER") {
                    //                 var rr = {
                    //                     dealer_code: param.data.parent_dealer_code,
                    //                     invoice_no: param.data.invoice_no
                    //                 }

                    //                 var urlpath = "/dashboard/parentcustomerconsignments"
                    //             }
                    //             else {
                    //                 var rr = {
                    //                     dealer_code: param.data.consignee_code,
                    //                     invoice_no: param.data.invoice_no
                    //                 }

                    //                 var urlpath = "/customerconsignments"
                    //             }
                    //         }
                    //         redirectURL.post(urlpath, rr)
                    //             .then((resp) => {
                    //                 var records = resp.data.records;
                    //                 var recordsarr = []
                    //                 if (records?.length > 0) {
                    //                     try {
                    //                         var pods = records[0].pods;
                    //                         pods.map((item) => {
                    //                             item.truck_no = param.data.truck_no;
                    //                             recordsarr.push(item);
                    //                         })
                    //                     }
                    //                     catch (e) { }
                    //                 }
                    //                 param.successCallback(recordsarr);
                    //             })

                    //     }
                    //     catch (e) {

                    //     }

                    // },
                    getDetailRowData: async function (param) {
                        param.successCallback([]);
                        console.log(rownode?.pods, "sssssssssssssssssssssssssssssssssssss");

                        try {
                            let recordsarr = rownode.data?.pods
                            param.successCallback(recordsarr);
                        }
                        catch (e) {

                        }

                    },
                    masterDetail: true
                }
            });


            rownode.node.setExpanded(!rownode.node.expanded);
        }
    }

    saveItemsFormData = async (event) => {
        event.preventDefault();
        // var rownodes=[];
        // this.gridApi.forEachNode(function(node) { 
        //     rownodes.push(node.data);
        // });
        // console.log("rownodes ", rownodes);
        this.setState({
            overly2: "show-m",
        })

        var customer_pod_date = moment(this.state.customer_pod_date).format("YYYY-MM-DD HH:mm");
        var customer_pod_remarks = this.state.customer_pod_remarks;
        var gps_device_vehicle = this.state.gps_device_vehicle.value;
        var device_no = this.state.device_no;
        var material_no = this.state.material_no;
        var material_description = this.state.material_description;
        var supplyplantdesc = this.state.supply_plant_desc;
        var driver_mobile_with_defects = this.state.driver_mobile_with_defects;
        if (this.state.itemcode.value != "") {
            // var itemcode = this.state.itemcode.value;
            // var itemdesc = this.state.itemcode.label;
            var itemcode = this.state.itemcode.value;
            // console.log("itemcode",itemcode)
            var itemdesc = this.state.itemcode.label;
            // console.log("itemdesc",itemdesc)
        }
        else {
            var itemcode = "";
            var itemdesc = "";
        }
        if (this.state.defectcode != "") {
            var defectcode = this.state.defectcode;
            var defectdesc = this.state.defectcode;
        }
        else {
            var defectcode = "";
            var defectdesc = "";
        }
        if (this.state.category.value != "") {
            var category = this.state.category.value;
        }
        else {
            var category = "";
        }
        var quantity = this.state.quantity;
        if (defectcode != "" && customer_pod_date != "" && quantity != "" && supplyplantdesc.value != undefined && supplyplantdesc.value != "") {
            if (this.state.driver_mobile_with_defects.length == 10) {
                if (supplyplantdesc.value != "") {
                    var supply_plant_desc = supplyplantdesc.label ?? "" + "(" + supplyplantdesc.value ?? "0000" + ")"
                }
                else {
                    var supply_plant_desc = "";
                }
                var newrow = {
                    material_no: material_no,
                    material_description: material_description,
                    supply_plant_desc: supply_plant_desc,
                    customer_pod_date: customer_pod_date,
                    // item_code: itemcode,
                    // item_description: itemdesc,
                    defect_code: defectcode,
                    // defect_description: defectdesc,
                    // category: category,
                    quantity: quantity,
                    customer_pod_remarks: customer_pod_remarks,
                    is_defect_item: 1,
                    driver_mobile_no: driver_mobile_with_defects
                }
                //  console.log("this.state.customer_lr_doc ", newrow)
                var customer_document_1 = this.state.customer_document_1;
                // console.log("customer_document_1 ", customer_document_1)
                // console.log("customer_document_1.length ", customer_document_1.length)

                if (this.state.srownode !== "") {
                    var ship_to_party_code = this.state.srownode.consignee_code;
                    var truck_no = this.state.srownode.truck_no;
                    var consignment_code = this.state.srownode.consignment_code;
                }
                else {
                    var ship_to_party_code = ""
                    var truck_no = '';
                    var consignment_code = '';
                }
                if (customer_document_1?.length > 0) {
                    if (customer_document_1.length <= 5) {
                        let formData = new FormData()
                        for (let i = 0; i < customer_document_1.length; i++) {
                            formData.append('customer_document_1', customer_document_1[i]);
                        }
                        await redirectURL.post("/saveInvoiceDocumentUploadFile", formData, {
                            headers: {
                                'content-type': 'multipart/form-data',
                                'Access-Control-Allow-Origin': "*",
                                'Access-Control-Allow-Methods': 'POST',
                                'Access-Control-Allow-Headers': 'Origin, Content-Type'
                            }
                        })
                            .then((response) => {
                                let docs = response.data.document
                                this.setState({
                                    podsDocs: docs
                                })
                                if (response.data.document?.length > 0) {
                                    var oParams = {
                                        podDocs: docs,
                                        customer_code: this.state.user?.customer_code,
                                        user_type: this.state.user?.role[0],
                                        consignment_code: consignment_code,
                                        truck_no: truck_no,
                                        ship_to_party_code: ship_to_party_code,
                                        rownodes: JSON.stringify([newrow]),
                                        srownode: this.state.srownode,
                                        consignee_code: ship_to_party_code,
                                        defect_description: "WITH DEFECTS"
                                    }
                                    console.log("oParams ", oParams)
                                    redirectURL.post("/saveInvoiceDocumentForm", oParams)
                                        .then((response) => {
                                            if (response.data.status == "success") {
                                                this.setState({
                                                    show: true,
                                                    basicTitle: "Successfully data updated",
                                                    basicType: "success",
                                                    customer_pod_date: "",
                                                    customer_lr_doc: "",
                                                    customer_pod_remarks: "",
                                                    itemcode: { "value": "", "label": "Select Item" },
                                                    gps_device_vehicle: { value: "", label: "Select" },
                                                    device_no: '',
                                                    item_description: "",
                                                    defect_description: "",
                                                    defectcode: '',
                                                    category: { "value": "", "label": "Select Category" },
                                                    quantity: "",
                                                    customer_document_1: "",
                                                    customer_document_2: "",
                                                    sliderAddTranslate: "",
                                                    overly: 'show-n',
                                                    overly2: "show-n",
                                                    material_no: "",
                                                    material_description: "",
                                                    supply_plant_desc: ""
                                                })
                                                // window.location.reload();
                                            }
                                            else {
                                                this.setState({
                                                    show: true,
                                                    basicTitle: response.data.message,
                                                    basicType: "danger",
                                                    overly2: "show-n",
                                                })
                                            }
                                        })
                                        .catch(error => {
                                            this.setState({
                                                show1: true,
                                                basicTitle1: "File size is too large.",
                                                basicType1: "warning",
                                                overly2: "show-n",
                                            })
                                        })
                                }
                                else {
                                    this.setState({
                                        show1: true,
                                        basicTitle1: "Please upload atleast one document",
                                        basicType1: "danger",
                                        overly2: "show-n",
                                    })
                                }
                            })
                    }
                    else {

                    }
                }
            }
            else {
                this.setState({
                    show1: true,
                    basicTitle1: "Driver Mobile Number needs to be 10 digits",
                    basicType1: "danger",
                    overly2: "show-n",
                })
            }
        }
        else {
            this.setState({
                show1: true,
                basicTitle1: "All * fields are mandatory",
                basicType1: "danger",
                overly2: "show-n",
            })
        }
    }
    saveWithOutItemsFormData(event) {
        event.preventDefault();
        // var rownodes=[];
        // this.gridApi.forEachNode(function(node) { 
        //     rownodes.push(node.data);
        // });
        // console.log("rownodes ", rownodes);

        this.setState({
            overly2: "show-m",
        })
        var customer_pod_date = moment(this.state.customer_pod_date).format("YYYY-MM-DD HH:mm");
        var customer_pod_remarks = this.state.customer_pod_remarks;
        var gps_device_vehicle = this.state.gps_device_vehicle.value;
        var device_no = this.state.device_no;
        var grn_no = this.state.grn_no;
        var supplyplantdesc = this.state.supply_plant_desc;
        // var material_no = this.state.material_no;
        // var material_description = this.state.material_description;
        // var supply_plant_desc = this.state.supply_plant_desc;
        if (this.state.itemcode.value != "") {
            var itemcode = this.state.itemcode.value;
            var itemdesc = this.state.itemcode.label;
        }
        else {
            var itemcode = "";
            var itemdesc = "";
        }
        if (this.state.defectcode != "") {
            var defectcode = this.state.defectcode;
            var defectdesc = this.state.defectcode;
        }
        else {
            var defectcode = "";
            var defectdesc = "";
        }
        if (this.state.category.value != "") {
            var category = this.state.category.value;
        }
        else {
            var category = "";
        }

        if (this.state.srownode !== "") {
            var ship_to_party_code = this.state.srownode.consignee_code;
            var truck_no = this.state.srownode.truck_no;
            var consignment_code = this.state.srownode.consignment_code;
        }
        else {
            var ship_to_party_code = ""
            var truck_no = "";
            var consignment_code = ""
        }

        var quantity = this.state.quantity;
        if (customer_pod_date != "" && customer_pod_date != undefined && customer_pod_date != "" && supplyplantdesc.value != undefined && supplyplantdesc.value != null) {
            if (this.state.driver_mobile.length == 10) {
                if (supplyplantdesc.value != "") {
                    var supply_plant_desc = supplyplantdesc.label ?? "" + "(" + supplyplantdesc.value ?? "0000" + ")"
                }
                else {
                    var supply_plant_desc = "";
                }
                var newrow = {
                    // material_no:material_no,
                    grn_no: grn_no,
                    // material_description:material_description,
                    supply_plant_desc: supply_plant_desc,
                    customer_pod_date: customer_pod_date,
                    // item_code:itemcode,
                    // item_description:itemdesc,
                    // defect_code:defectcode,
                    // defect_description:defectdesc,
                    category: category,
                    quantity: quantity,
                    customer_pod_remarks: customer_pod_remarks,
                    gps_device_vehicle: gps_device_vehicle,
                    device_no: device_no,
                    is_defect_item: 0,
                    consignee_code: ship_to_party_code,
                    driver_mobile: this.state.driver_mobile
                }
                var supply_plant_desc = supplyplantdesc.value
                // console.log("this.state.customer_lr_doc ", this.state.customer_lr_doc)
                var customer_document_1 = this.state.customer_document_1;
                var customer_document_2 = this.state.customer_document_2;

                const formData = new FormData();
                // formData.append("customer_document_1", customer_document_1);
                // formData.append("customer_document_2", customer_document_2);
                formData.append("customer_code", this.state.user?.customer_code);
                formData.append("consignment_code", consignment_code);
                formData.append("truck_no", truck_no);
                formData.append("ship_to_party_code", ship_to_party_code);
                formData.append("rownodes", JSON.stringify([newrow]));

                console.log("formData ", formData)

                redirectURL.post("/saveInvoiceDocumentWithoutDefectForm", formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': "*",
                        'Access-Control-Allow-Methods': 'POST, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type'
                    }
                })
                    .then((response) => {
                        // console.log("response ", response.data)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: "Successfully data updated",
                                basicType: "success",
                                customer_pod_date: "",
                                customer_lr_doc: "",
                                grn_no: "",
                                customer_pod_remarks: "",
                                itemcode: { "value": "", "label": "Select Item" },
                                gps_device_vehicle: { "value": "", "label": "Select" },
                                device_no: "",
                                item_description: "",
                                defect_description: "",
                                defectcode: '',
                                category: { "value": "", "label": "Select Category" },
                                quantity: "",
                                customer_document_1: "",
                                customer_document_2: "",
                                sliderAddTranslate: "",
                                overly: 'show-n',
                                material_no: "",
                                material_description: "",
                                supply_plant_desc: "",
                                overly2: "show-n",
                            })
                            // window.location.reload();
                        }
                        else {
                            this.setState({
                                show: true,
                                basicTitle: response.data.message,
                                basicType: "danger",
                                overly2: "show-n",
                            })
                        }
                    })
                    .catch(error => {
                        this.setState({
                            show: true,
                            basicTitle: "File size is too large",
                            basicType: "warning",
                            overly2: "show-n",
                        })
                    })
            }
            else {
                this.setState({
                    show1: true,
                    basicTitle1: "Driver Mobile Number needs to be 10 digits",
                    basicType1: "danger",
                    overly2: "show-n",
                })
            }
        }
        else {
            this.setState({
                show1: true,
                basicTitle1: "All * fields are mandatory",
                basicType1: "danger",
                overly2: "show-n",
            })
        }
    }
    addItemData(event) {
        event.preventDefault();
        var customer_pod_date = this.state.customer_pod_date
        var customer_pod_remarks = this.state.customer_pod_remarks;

        if (this.state.itemcode.value != "") {
            var itemcode = this.state.itemcode.value;
            var itemdesc = this.state.itemcode.label;
        }
        else {
            var itemcode = "";
            var itemdesc = "";
        }
        if (this.state.defectcode != "") {
            var defectcode = this.state.defectcode;
            var defectdesc = this.state.defectcode;
        }
        else {
            var defectcode = "";
            var defectdesc = "";
        }
        if (this.state.category.value != "") {
            var category = this.state.category.value;
        }
        else {
            var category = "";
        }
        var quantity = this.state.quantity;
        if (itemcode != "" && defectcode != "" && customer_pod_date != "" && customer_pod_date != "" && category != "" && quantity != "") {
            var newrow = {
                customer_pod_date: customer_pod_date,
                item_code: itemcode,
                item_description: itemdesc,
                defect_code: defectcode,
                defect_description: defectdesc,
                category: category,
                quantity: quantity,
                customer_pod_remarks: customer_pod_remarks,
            }
            console.log("updateRowData ", newrow);

            // this.gridApi.updateRowData({add: [newrow]})
            // this.setState({
            //     customer_pod_date:"",
            //     customer_lr_doc:"",
            //     customer_pod_remarks:"",
            //     itemcode:{"value":"","label":"Select Item"},
            //     item_description:"",
            //     defect_description:"",
            //     defectcode:{"value":"","label":"Select Defect"},
            //     category:{"value":"","label":"Select Category"},
            //     quantity:"",            
            //     // sliderAddTranslate:"",
            //     // overly:'show-n'
            // })
            // $("#customer_pod_date").val("");
        }
        else {
            this.setState({
                show: true,
                basicTitle: "All * fields are mandatory",
                basicType: "danger"
            })
        }
    }

    onClickCounter(params) {
        if (params == "1") {
            this.setState({
                rowData: this.state.alldata
            })
        }

        if (params == "2") {
            this.setState({
                rowData: this.state.podsubmitdata
            })
        }

        if (params == "3") {
            this.setState({
                rowData: this.state.nonpoddata
            })
        }
        if (params == "4") {
            this.setState({
                rowData: this.state.approvedpods
            })
        }

        if (params == "withdefect") {
            this.setState({
                rowData: this.state.withdefectspod
            })
        }
        if (params == "withoutdefect") {
            this.setState({
                rowData: this.state.withoutdefectspod
            })
        }
    }
    onClickViewPrintItems(rownode) {
        console.log("Roenode ", rownode);
    }
    onClickShowFeedbackForm(rownode) {
        var params = {
            consignment_code: rownode.data.consignment_code
        }
        redirectURL.post("/customerfeedbackdata", params)
            .then((response) => {
                var records = response.data.records;
                if (records?.length > 0) {
                    var data = records[0];
                    if (data.based_on_delivery_time != undefined) {
                        var rating1 = { value: data.based_on_delivery_time, label: data.based_on_delivery_time };
                    }
                    else {
                        var rating1 = { "value": "10", "label": "10" };
                    }

                    if (data.based_on_delivery_time_comment != undefined) {
                        var comment1 = data.based_on_delivery_time_comment;
                    }
                    else {
                        var comment1 = "";
                    }

                    if (data.based_on_vehicle_delivery_condition != undefined) {
                        var rating2 = { value: data.based_on_vehicle_delivery_condition, label: data.based_on_vehicle_delivery_condition };
                    }
                    else {
                        var rating2 = { "value": "10", "label": "10" };
                    }

                    if (data.based_on_vehicle_delivery_condition_comment != undefined) {
                        var comment2 = data.based_on_vehicle_delivery_condition_comment;
                    }
                    else {
                        var comment2 = "";
                    }

                    if (data.overall_experience != undefined) {
                        var rating3 = { value: data.overall_experience, label: data.overall_experience };
                    }
                    else {
                        var rating3 = { "value": "10", "label": "10" };
                    }

                    if (data.overall_experience_comment != undefined) {
                        var comment3 = data.overall_experience_comment;
                    }
                    else {
                        var comment3 = "";
                    }
                    this.setState({
                        sliderFeedback: "slider-translate-30p",
                        overly: 'show-m',
                        feedrowid: rownode.data._id,
                        consignment_code: rownode.data.consignment_code,
                        feed_rownode: rownode,
                        rating1: rating1,
                        rating2: rating2,
                        rating3: rating3,
                        comment1: comment1,
                        comment2: comment2,
                        comment3: comment3,
                    })
                }
                else {
                    this.setState({
                        sliderFeedback: "slider-translate-30p",
                        overly: 'show-m',
                        feedrowid: rownode.data._id,
                        consignment_code: rownode.data.consignment_code,
                        feed_rownode: rownode,
                        rating1: { "value": "10", "label": "10" },
                        rating2: { "value": "10", "label": "10" },
                        rating3: { "value": "10", "label": "10" },
                        comment1: "",
                        comment2: "",
                        comment3: "",
                    })
                }
            })

    }
    savePODFeedback(event) {
        event.preventDefault();
        var rating1 = this.state.rating1.value;
        var rating2 = this.state.rating2.value;
        var rating3 = this.state.rating3.value;
        var comment1 = this.state.comment1;
        var comment2 = this.state.comment2;
        var comment3 = this.state.comment3;
        var params = {
            rating1: rating1,
            rating2: rating2,
            rating3: rating3,
            comment1: comment1,
            comment2: comment2,
            comment3: comment3,
            feedrowid: this.state.feedrowid,
            consignment_code: this.state.consignment_code,
            feed_rownode: this.state.feed_rownode.data
        }

        redirectURL.post("/savepodfeedback", params)
            .then((response) => {
                // console.log(response.data,"ffff")
                if (response.data.status == "success") {
                    this.setState({
                        sliderFeedback: "",
                        overly: 'show-n',
                        feedrowid: "",
                        consignment_code: "",
                        feed_rownode: "",
                        rating1: { "value": "10", "label": "10" },
                        rating2: { "value": "10", "label": "10" },
                        rating3: { "value": "10", "label": "10" },
                        comment1: "",
                        comment2: "",
                        comment3: "",
                        show: true,
                        basicTitle: "Successfully submitted feedback",
                        basicType: "success"
                    })
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed to submit feedback",
                        basicType: "danger"
                    })
                }
            })
    }
    ratingpoints() {
        var items = [];
        for (var i = 1; i <= 10; i++) {
            items.push({
                value: i,
                label: i
            })
        }
        return items;
    }
    changeRatingOne(rating1) {
        this.setState({ rating1 })
    }

    changeRatingTwo(rating2) {
        this.setState({ rating2 })
    }

    changeRatingThree(rating3) {
        this.setState({ rating3 })
    }
    changeCustomer(scustomer) {
        this.setState({ scustomer }, async () => {
            // console.log("scustomer ", this.state.scustomer)
            var customer = this.state.scustomer.value;
            // console.log("customer ", customer)
            if (customer != "") {
                var rowData = this.state.alldata;
                if (rowData?.length > 0) {
                    var data = rowData.filter(item => {
                        if (item.consignee_code == customer) {
                            return item;
                        }
                    })
                    // console.log("data ", data)
                    this.setState({
                        rowData: data
                    })
                }
            }
            else {
                this.setState({
                    rowData: this.state.alldata
                })
            }
        })
    }

    allcustomerslist() {
        var items = [{ value: "", label: "All Customers" }]
        var allcustomers = this.state.allcustomers
        if (allcustomers?.length > 0) {
            allcustomers.map((item) => {
                items.push(item)
            })
        }
        return items;
    }

    sendDriverWithOutDefectOTP() {
        this.setState({
            loadshow: "show-m"
        });
        var mobileNumber = this.state.driver_mobile;
        // console.log("mobileNumber",mobileNumber)
        // console.log("1822",mobileNumber)
        const mobileValue = mobileNumber.replace(/\D/g, '');
        // console.log("mobileValue",mobileValue) 

        // Check if there are at least 10 digits
        if (mobileValue?.length >= 10) {
            var params = {
                driver_mobile: mobileValue
            };
            console.log("params", params)
            redirectURL.post("/processOtpGenerate", params)
                .then((response) => {
                    console.log("response", response.data)
                    if (response.data.status === "fail") {
                        this.setState({
                            show1: true,
                            basicTitle1: "Please enter a valid 10-digit driver mobile number",
                            basicType1: "danger",
                            loadshow: "show-n"
                        });
                    } else {
                        if (response.data.status === "Failure") {
                            this.setState({
                                show1: true,
                                basicTitle1: response.data.message,
                                basicType1: "danger",
                                loadshow: "show-n"
                            });
                        } else {
                            this.setState({
                                show1: true,
                                basicTitle1: "Successfully sent OTP to the mobile number",
                                basicType1: "success",
                                is_driver_without_defect_otp_trigger: 1,
                                loadshow: "show-n"
                            });
                        }
                    }
                });
        } else {
            this.setState({
                show1: true,
                basicTitle1: "Please enter a valid 10-digit driver mobile number",
                basicType1: "danger",
                loadshow: "show-n"
            });
        }
    }

    verifyDriverWithOutDefectOTP() {

        this.setState({
            loadshow: "show-m"
        })
        if (this.state.driver_mobile !== "" && this.state.driver_without_defect_otp !== "") {

            var params = {
                driver_mobile: this.state.driver_mobile,
                otp: this.state.driver_without_defect_otp
            }

            this.setState({
                is_driver_without_defect_verified: 1,
                loadshow: "show-n"
            })
        }
        else {
            this.setState({
                show1: true,
                basicTitle1: "Please enter driver mobile number",
                basicType1: "danger",
                loadshow: "show-n"
            })
        }
    }


    sendDriverWithDefectOTP() {

        this.setState({
            loadshow: "show-m"
        })
        var mobileNumber = this.state.driver_mobile_with_defects;
        // console.log("mobileNumber",mobileNumber)
        const mobileDefectsValue = mobileNumber.replace(/\D/g, '');

        // Check if there are at least 10 digits
        if (mobileDefectsValue?.length >= 10) {
            var params = {
                driver_mobile: mobileDefectsValue
            };
            // console.log("params",params)
            redirectURL.post("/processOtpGenerate", params)
                .then((response) => {
                    console.log("response ", response.data)
                    if (response.data.status === "fail") {
                        this.setState({
                            show1: true,
                            basicTitle1: "Please enter a valid 10-digit driver mobile number",
                            basicType1: "danger",
                            loadshow: "show-n"
                        })
                    }
                    else {
                        if (response.data.status === "Failure") {
                            this.setState({
                                show1: true,
                                basicTitle1: response.data.message,
                                basicType1: "danger",
                                loadshow: "show-n"
                            })
                        }
                        else {
                            this.setState({
                                show1: true,
                                basicTitle1: "Successfully sent OTP to mobile",
                                basicType1: "success",
                                is_driver_with_defect_otp_trigger: 1,
                                loadshow: "show-n"
                            })
                        }

                    }
                })
        }
        else {
            this.setState({
                show1: true,
                basicTitle1: "Please enter a valid 10-digit driver mobile number",
                basicType1: "danger",
                loadshow: "show-n"
            })
        }
    }
    verifyDriverWithDefectOTP() {
        // console.log("1099")

        this.setState({
            loadshow: "show-m"
        })
        if (this.state.driver_mobile_with_defects !== "" && this.state.driver_with_defect_otp !== "") {

            var params = {
                driver_mobile: this.state.driver_mobile_with_defects,
                otp: this.state.driver_with_defect_otp
            }
            // console.log("params",params)
            redirectURL.post("/otpVerify", params)
                .then((response) => {
                    // console.log("response ", response.data)
                    if (response.data.status === "fail" || response.data.result === "Failure") {
                        this.setState({
                            show1: true,
                            basicTitle1: "Please enter valid OTP",
                            basicType1: "danger",
                            loadshow: "show-n"
                        })
                    }
                    else {
                        if (response.data.status === "Failure") {
                            this.setState({
                                show1: true,
                                basicTitle1: response.data.message,
                                basicType1: "danger",
                                loadshow: "show-n"
                            })
                        }
                        else {
                            this.setState({
                                show1: true,
                                basicTitle1: "Successfully verified OTP",
                                basicType1: "success",
                                is_driver_with_defect_verified: 1,
                                loadshow: "show-n"
                            })
                        }
                    }
                })
        }
        else {
            this.setState({
                show1: true,
                basicTitle1: "Please enter driver mobile number",
                basicType1: "danger",
                loadshow: "show-n"
            })
        }
    }

    changeConsignerArrayHandler = consigner => {
        this.setState(
            { consigner },
            // () => console.log(`Consigner Option selected:`, this.state.consigner)
        );

        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }


    ClearNewFilter = () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        this.setState({
            flCity: "",
            flState: "",
            flCluster: "",
            flZone: "",
            flTransporter: "",
            flDealer: ""
        });
    }

    newFilter = (filterType, selOption) => {
        // console.log(filterType, selOption)
        this.setState({ [filterType]: selOption });
    }
    selectConsignerOptionsItems() {
        let items = [];

        var plants = this.state.plants;

        items.push({ value: 'all', label: 'All' });
        if (plants?.length > 0) {
            plants.forEach(item => {
                if (this.state.loginplant != "" && this.state.loginplant != undefined) {
                    if (this.state.loginplant == item.plant_code) {
                        items.push({ value: item.plant_code, label: item.plant_name });
                    }

                }
                else {
                    items.push({ value: item.plant_code, label: item.plant_name });
                }

            });
        }


        return items;
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        if (this.state.endDate != undefined && this.state.endDate != "") {
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            if (startTime > endTime) {
                this.setState({
                    startDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    startDate: startdate
                });
            }
        }
        else {
            this.setState({
                startDate: startdate
            });
        }

    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            if (startTime > endTime) {
                this.setState({
                    endDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    endDate: edate
                });
            }
        }
        else {
            this.setState({
                endDate: edate
            });
        }
    }
    onChangeTransporter(flTransporter) {
        this.setState({ flTransporter }, () => {
            // this.onGetConsigners();
        })
    }
    onChangeDateType(date_type) {
        this.setState({ date_type })
    }
    onChangeZone(flZone) {
        this.setState({ flZone }, () => {
            // if(this.state.flZone.value !== "" && this.state.flZone.value !== undefined)
            // {
            this.filterZoneParams();
            // }
        })
    }
    onChangeDealerState(flState) {
        this.setState({ flState }, () => {
            // if(this.state.flState.value !== "" && this.state.flState.value !== undefined)
            // {
            this.filterZoneParams();
            // }
        })
    }
    onChangeDealerCity(flCity) {
        this.setState({ flCity }, () => {
            // if(this.state.flCity.value !== "" && this.state.flCity.value !== undefined)
            // {
            this.filterZoneParams();
            // }
        })
    }
    filterZoneParams() {
        var params = {
            dept_code: encode("SNDG")
        }
        if (this.state.flZone.value !== "" && this.state.flZone.value !== undefined) {
            params.zone = this.state.flZone.value;
        }
        if (this.state.flCity.value !== "" && this.state.flCity.value !== undefined) {
            params.dealer_city = this.state.flCity.value;
        }

        if (this.state.flState.value !== "" && this.state.flState.value !== undefined) {
            params.dealer_state = this.state.flState.value;
        }
        // console.log("flCityArray ", this.state.flCityArray)
        if (this.state.flStateArray?.length > 0) {
            var stlist = []
            this.state.flStateArray.map((it) => {
                stlist.push(it.value)
            })
            if (stlist?.length > 0) {
                params.region_states = stlist;
            }

        }

        if (this.state.flCityArray?.length > 0) {
            var astlist = []
            this.state.flCityArray.map((it) => {
                astlist.push(it.value)
            })
            if (astlist?.length > 0) {
                params.areas = astlist;
            }

        }
        if (this.state.user.role[0] == "DEALER")
            params["user_code"] = this.state.user.dealer_code ?? "EMPTY"
        redirectURL.post("/getConsignmentsNewFilterKeys", params)
            .then((resp) => {
                var dealers = resp.data.consigneeName;
                var filterDealers = [{ value: "", label: "All" }];
                if (dealers?.length > 0) {
                    dealers.map(function (c) {
                        filterDealers.push({
                            label: c.consignee_name + "-" + c.consignee_code,
                            value: c.consignee_code,
                        });
                    });
                }

                var cities = resp.data.cities;
                var filterCities = [{ value: "", label: "All" }];
                if (cities.length > 0) {
                    cities.map(function (c) {
                        filterCities.push({
                            label: c,
                            value: c,
                        });
                    });
                }
                var states = resp.data.states;
                var filterStates = [{ value: "", label: "All" }];
                if (states.length > 0) {
                    states.map(function (s) {
                        filterStates.push({
                            label: s,
                            value: s,
                        });
                    });
                }
                var clusters = resp.data.clusters;
                var filterClusters = [{ value: "", label: "All" }];
                if (clusters.length > 0) {
                    clusters.map(function (cl) {
                        filterClusters.push({
                            label: cl,
                            value: cl,
                        });
                    });
                }

                var transporters = resp.data.transporters;
                var filterTransporters = [{ value: "", label: "All" }];
                if (transporters.length > 0) {
                    transporters.map(function (t) {
                        filterTransporters.push({
                            label: t,
                            value: t,
                        });
                    });
                }
                this.setState({
                    filterCities: filterCities,
                    filterStates: filterStates,
                    filterClusters: filterClusters,
                    filterDealers: filterDealers
                });
            })
    }
    filtersData() {
        let qu = { dept_code: encode("SNDG") }
        if (this.state.user.role[0] == "DEALER")
            qu["user_code"] = this.state.user.customer_code ?? "EMPTY"
        redirectURL.post("/getConsignmentsNewFilterKeys", qu)
            .then((resp) => {
                var dealers = resp.data.consigneeName;

                var filterDealers = [{ value: "", label: "All" }];

                if (dealers?.length > 0) {
                    dealers.map(function (c) {
                        if (this.state.user?.role?.[0] === "DEALER") {
                            if (c.consignee_code == this.state.user?.customer_code) {
                                filterDealers.push({
                                    label: c.consignee_name + "-" + c.consignee_code,
                                    value: c.consignee_code,
                                });
                            }

                        }
                        else if (this.state.user?.role?.[0] === "PARENT_DEALER") {
                            if (c.parent_dealer_code == this.state.user?.customer_code) {
                                filterDealers.push({
                                    label: c.consignee_name + "-" + c.consignee_code,
                                    value: c.consignee_code,
                                });
                            }
                        }
                        else {
                            filterDealers.push({
                                label: c.consignee_name + "-" + c.consignee_code,
                                value: c.consignee_code,
                            });
                        }
                    });
                }

                var cities = resp.data.cities;
                var filterCities = [{ value: "", label: "All" }];
                if (cities.length > 0) {
                    cities.map(function (c) {
                        if (this.state.user?.role?.[0] !== "" && this.state.user?.role?.[0] !== undefined) {
                            if (this.state.user?.role?.[0] === "AREA_MANAGER") {
                                if (localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined) {
                                    var rr = localStorage.getItem("areas").split(",");
                                    if (rr.length > 0) {
                                        rr.map((i) => {
                                            if (i == c) {
                                                filterCities.push({
                                                    label: c,
                                                    value: c,
                                                });
                                            }
                                        })
                                    }
                                }
                                else {

                                    filterCities.push({
                                        label: c,
                                        value: c,
                                    });
                                }

                            }
                            else {
                                filterCities.push({
                                    label: c,
                                    value: c,
                                });
                            }
                        }
                        else {
                            filterCities.push({
                                label: c,
                                value: c,
                            });
                        }

                    });
                }
                var states = resp.data.states;
                var filterStates = [{ value: "", label: "All" }];
                if (states.length > 0) {
                    states.map(function (s) {
                        if (this.state.user?.role?.[0] !== "" && this.state.user?.role?.[0] !== undefined) {
                            if (this.state.user?.role?.[0] === "REGIONAL_MANAGER" || this.state.user?.role?.[0] === "AREA_MANAGER") {
                                if (localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined) {
                                    var rr = localStorage.getItem("region").split(",");
                                    if (rr.length > 0) {
                                        rr.map((i) => {
                                            if (i == s) {
                                                filterStates.push({
                                                    label: s,
                                                    value: s,
                                                });
                                            }
                                        })
                                    }
                                }
                                else {

                                    filterStates.push({
                                        label: s,
                                        value: s,
                                    });
                                }

                            }
                            else {
                                filterStates.push({
                                    label: s,
                                    value: s,
                                });
                            }
                        }
                        else {
                            filterStates.push({
                                label: s,
                                value: s,
                            });
                        }
                    });

                }
                var clusters = resp.data.clusters;
                var filterClusters = [{ value: "", label: "All" }];
                if (clusters.length > 0) {
                    clusters.map(function (cl) {
                        filterClusters.push({
                            label: cl,
                            value: cl,
                        });
                    });
                }
                var zones = resp.data.zones;
                var filterZones = [{ value: "", label: "All" }];
                if (zones.length > 0) {
                    zones.map(function (cl) {
                        if (this.state.user?.role?.[0] !== "" && this.state.user?.role?.[0] !== undefined) {
                            if (this.state.user?.role?.[0] === "ZONE_MANAGER" || this.state.user?.role?.[0] === "REGIONAL_MANAGER" || this.state.user?.role?.[0] === "AREA_MANAGER") {
                                // console.log("zone ", localStorage.getItem("zone"))
                                if (localStorage.getItem("zone").includes(cl) === true) {
                                    filterZones.push({
                                        label: cl,
                                        value: cl,
                                    });
                                }
                            }
                            else {
                                filterZones.push({
                                    label: cl,
                                    value: cl,
                                });
                            }
                        }
                        else {
                            filterZones.push({
                                label: cl,
                                value: cl,
                            });
                        }
                    });
                }
                var transporters = resp.data.alltransporters;
                var filterTransporters = [{ value: "", label: "All" }];
                if (transporters.length > 0) {
                    transporters.map(function (t) {
                        filterTransporters.push({
                            label: t.transporter_name,
                            value: t.transporter_code,
                        });
                    });
                }

                // if(this.state.user?.role?.[0] === "DEALER")
                // {
                // 	this.setState({
                // 		flDealer:filterDealers[0]
                // 	})
                // }
                this.setState({
                    filterCities: filterCities,
                    filterStates: filterStates,
                    filterClusters: filterClusters,
                    filterZones: filterZones,
                    filterTransporters: filterTransporters,
                    filterDealers: filterDealers
                });
            })
    }

    onGetConsigners = async (event) => {


        if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var sDate = this.state.defaultsdate;
        }
        else {
            var sDate = this.state.startDate;
        }
        if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
            var eDate = this.state.defaultedate;
        }
        else {
            var eDate = this.state.endDate;
        }


        if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
            var transporter_code = this.state.flTransporter.value;
        }
        else {
            var transporter_code = "";
        }

        if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
            var zone = this.state.flZone.value;
        }
        else {
            var zone = "";
        }


        if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
            var state = this.state.flState.value;
        }
        else {
            var state = "";
        }


        if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
            var city = this.state.flCity.value;
        }
        else {
            var city = "";
        }

        if (this.state.flDealer != "" && this.state.flDealer != undefined && this.state.flDealer != null) {
            var consignee_code = this.state.flDealer.value;
        }
        else {
            var consignee_code = "";
        }

        var selectconsigners = []
        // console.log('selectconsigners',this.state.consigner)
        if (this.state.consigner == null) {
            selectconsigners = [{ "value": 'all', "label": 'All' }]
        }
        else {
            selectconsigners = this.state.consigner
        }

        var mvmtype = []
        if (this.state.movementtype == null) {
            mvmtype = [{ "value": 'all', "label": 'All' }]
        }
        else {
            mvmtype = [this.state.movementtype]
        }

        //console.log("this.state.movementtype ", this.state.movementtype)
        //console.log("mvmtype ", mvmtype)
        var selecttrip = []
        // if(this.state.triptype == null)
        // {
        // 	selecttrip = [{"value":'all', "label":'All'}]
        // }
        // else{
        selecttrip = [this.state.triptype]

        var requestparams = {
            consigner: selectconsigners,
            dept_code: encode(this.state.deptcode),
            startDate: sDate,
            endDate: eDate,
            transporter_code: transporter_code,
            zone: zone,
            state: state,
            city: city,
            dealer_code: consignee_code
        }
        if (this.state.user.role[0] == "DEALER")
            requestparams["user_code"] = this.state.user.dealer_code ?? "EMPTY"

        if (this.state.flTransporter.value != "" && this.state.flTransporter.value != undefined && this.state.flTransporter.value != null) {
            requestparams.transporter_code = this.state.flTransporter.value;
        }
        if (this.state.user?.role?.[0] === "DEALER") {
            requestparams.dealer_code = this.state.user?.customer_code;
        }
        if (this.state.user?.role?.[0] === "PARENT_DEALER") {
            requestparams.parent_dealer_code = this.state.user?.customer_code;
        }




        //console.log("requestparams ", requestparams)

        var urlpath = '/consignments/deliveredConsignments';

        if (this.state.user?.role?.[0] !== "" && this.state.user?.role?.[0] !== undefined) {
            if (this.state.user?.role?.[0] === "ZONE_MANAGER") {
                requestparams.zone = localStorage.getItem("zone");
            }
            if (this.state.user?.role?.[0] === "REGIONAL_MANAGER") {
                if (localStorage.getItem("region") !== "" && localStorage.getItem("region") !== undefined) {
                    var rr = localStorage.getItem("region").split(",");
                    if (rr.length > 0) {
                        requestparams.region_states = rr;
                    }
                }

            }

            if (this.state.user?.role?.[0] === "AREA_MANAGER") {
                if (localStorage.getItem("areas") !== "" && localStorage.getItem("areas") !== undefined) {
                    var rr = localStorage.getItem("areas").split(",");
                    if (rr.length > 0) {
                        requestparams.areas = rr;
                    }
                }

            }
        }
        if (this.state.user?.customer_code != "" && this.state.user?.customer_code != undefined && this.state.user?.customer_code != "undefined") {
            if (this.state.user?.customer_code != "adminpod") {
                var customer_code = this.state.user?.customer_code;
            }
            else {
                this.setState({
                    admincode: "adminpod"
                })
                var customer_code = "";
            }

        }
        else {
            var customer_code = "";
        }
        this.setState({
            customer_code: customer_code
        })
        // var parameters = {
        //     dealer_code:customer_code
        // }
        this.onLoadGetShipments(requestparams);
    }

    render() {
        var maptransporter = '';
        // console.log("this.state.admincode ", this.state.admincode)
        if (this.state.admincode != "") {
            if (this.state.admincode == "adminpod") {
                var chide = false;
                var chiden = true;
            }
            else {
                var chide = true;
                var chiden = false;
            }
        }
        else {
            var chide = true;
            var chiden = false;
        }



        var columnwithDefs = [
            {
                headerName: "",
                field: "view_legs",
                width: 50,
                pinned: "left",
                cellRenderer: ViewItems,
                filter: false,
                resizable: true
            },
            {
                headerName: "Submit e-POD",
                field: "is_pod_submit",
                width: 150,
                pinned: "left",
                cellRenderer: SubmitEPOD,
                cellRendererParams: {
                    onShowSubmitEPOD: this.onShowSubmitEPOD,
                },
            },
            {
                headerName: "Print",
                field: "is_pod_submit",
                width: 100,
                cellRenderer: PrintItems,
            },
            // {
            //     headerName: "Feedback",
            //     field: "consigner_code",
            //     width: 100,
            //     cellRenderer: Rating,
            //     cellRendererParams: {
            //         onClickShowFeedbackForm: this.onClickShowFeedbackForm,
            //     },

            // },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 100,
            },
            {
                headerName: "FO No",
                field: "fo_number",
                width: 100,
            },
            // {
            //     headerName: "Order No",
            //     field: "order_number",
            //     width: 100,
            // },
            {
                headerName: "Dealer Code",
                field: "consignee_code"
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name"
            },
            {
                headerName: "Reached Destination At",
                field: "recent_dealer_reported",
                width: 140,
                valueGetter: function (params) {
                    try {
                        if (params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined) {
                            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                }
            },
            {
                headerName: "Pod Status",
                field: "_id",
                width: 130,
                valueGetter: function (params) {
                    try {
                        if (params.data.pods != "" && params.data.pods != undefined) {
                            if (params.data.pods.length > 0) {
                                return "Submitted";
                            }
                            else {
                                return "Pending";
                            }
                        }
                        else {
                            return "Pending";
                        }
                    }
                    catch (e) {

                    }
                }
            },
            {
                headerName: "Pod Submit Date",
                field: "_id",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.pods != "" && params.data.pods != undefined) {
                            if (params.data.pods.length > 0) {
                                var dd = params.data.pods[params.data.pods.length - 1].pod_create_date;
                                return getHyphenDDMMMYYYYHHMM(dd);
                            }
                            else {
                                return "Pending";
                            }
                        }
                        else {
                            return "Pending";
                        }
                    }
                    catch (e) {

                    }
                }
            },
        ]
        // columnwithDefs.push(
        //     {
        //         headerName: "",
        //         field: "_id",
        //         width: 150,
        //         cellRenderer: WithOutDefects,
        //         filter: false,
        //         resizable: true,
        //         hide: chiden
        //     },
        //     {
        //         headerName: "",
        //         field: "_id",
        //         width: 150,
        //         cellRenderer: WithDefects,
        //         filter: false,
        //         resizable: true,
        //         hide: chiden
        //     },
        // )

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}>
                </SweetAlert>
                <h5 className="col-xl-12 col-lg-12">
                    <i className="icon-receipt cus-i"></i> <span style={{ fontWeight: "600" }}>e-POD</span>
                </h5>
                {(this.state.user?.role?.[0] === "APP_ADMIN") ?
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <form id="filterForm" method="POST" className="row">
                                        <div className="row col-xl-12 col-lg-12" >
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Select Consigner </label>
                                                <Select
                                                    placeholder={"All"}
                                                    closeMenuOnSelect={false}
                                                    value={this.state.consigner}
                                                    isMulti="true"
                                                    className="border-radius-0"
                                                    onChange={this.changeConsignerArrayHandler.bind(this)}
                                                    style={{ borderRadius: "09px" }}
                                                    options={this.selectConsignerOptionsItems()}
                                                    required
                                                />

                                            </div>
                                            <div className="col-xl-2 col-lg-2 form-group">
                                                <label>From Date</label>
                                                <DatePicker
                                                    value={this.state.startDate}
                                                    disableCloseOnClickOutside={false}
                                                    closeOnSelect={true}
                                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                                    dateFormat="YYYY-MM-DD"
                                                    name="startDate"
                                                    onChange={this.handlerStartDateTime.bind(this)} />
                                            </div>

                                            <div className="col-xl-2 col-lg-2 form-group">
                                                <label>To Date</label>
                                                <DatePicker
                                                    value={this.state.endDate}
                                                    disableCloseOnClickOutside={false}
                                                    closeOnSelect={true}
                                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
                                                    dateFormat="YYYY-MM-DD"
                                                    onChange={this.handlerEndDateTime.bind(this)} />
                                            </div>
                                            {(this.state.user?.role?.[0] !== "TRANSPORTER") ?
                                                <div className="form-group col-xl-2 col-lg-3">
                                                    <label>Transporter </label>
                                                    <Select
                                                        className="border-radius-0"
                                                        placeholder="All"
                                                        isMulti={false}
                                                        id="selectedTransporter"
                                                        style={{ borderRadius: "0px" }}
                                                        value={this.state.flTransporter}
                                                        onChange={this.onChangeTransporter.bind(this)}
                                                        options={this.state.filterTransporters}
                                                    />
                                                </div>
                                                : ""}
                                            <div className="form-group col-xl-2 col-lg-3">
                                                <label>Date Type </label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.date_type}
                                                    onChange={this.onChangeDateType.bind(this)}
                                                    options={[{ value: "all", label: "All" }, { value: "Delivered", label: "Delivered Date" }, { value: "Dispatch", label: "Dispatch Date" }]}
                                                />
                                            </div>
                                        </div>

                                        <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Dealer Zone</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="selectedCluster"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.flZone}
                                                    onChange={this.onChangeZone.bind(this)}
                                                    options={this.state.filterZones}
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Dealer State</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="selectedState"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.flState}
                                                    onChange={this.onChangeDealerState.bind(this)}
                                                    options={this.state.filterStates}
                                                />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Dealer City</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="selectedCity"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.flCity}
                                                    onChange={this.onChangeDealerCity.bind(this)}
                                                    options={this.state.filterCities}
                                                />
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Dealer</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="selectedDealer"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.flDealer}
                                                    onChange={this.newFilter.bind(this, "flDealer")}
                                                    options={this.state.filterDealers}
                                                />
                                            </div>

                                        </div>

                                        <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                            <button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
                                            {/* <button type="button" onClick={this.reloadPageData.bind(this)} className="f12" style={{ color:"#000", textDecoration:"underline", border:"0px",background:"transparent",cursor:"pointer"}}>Reset Filters</button> */}

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "1")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total PODs</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.alldata.length > 0) ? this.state.alldata.length : 0} /></span></h4>

                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "2")}>
                                            <span style={{ pointer: "cursor" }}>
                                                <span className="f13"><i className="icofont icofont-clock-time f22 txt-success"></i><br /> POD Submitted</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.podsubmitdata.length > 0) ? this.state.podsubmitdata.length : 0} /></span></h4>
                                            </span>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "3")}>
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-info"></i><br /> POD Pending</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.nonpoddata.length > 0) ? this.state.nonpoddata.length : 0} /></span></h4>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <h5>
                                    <span> EPOD </span>
                                    {(this.state.admincode == "adminpod") ?
                                        <span className="float-right" style={{ width: "350px" }}>
                                            <Select
                                                placeholder={"Select Customer"}
                                                closeMenuOnSelect={true}
                                                isMulti={false}
                                                value={this.state.scustomer}
                                                onChange={this.changeCustomer.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.allcustomerslist()}
                                            />
                                        </span>
                                        : ""}

                                </h5>
                            </div> */}
                            <div className="card-body pt-15px">

                                <div className="row">
                                    <div id="myGrid" style={{ marginTop: "20px", height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            // modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            onCellClicked={this.onCLickLoadInvoicItems.bind(this)}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}

                                        />


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                <div className={"slide-r " + (this.state.sliderWithDefectAddTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Add With Defect
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.overly2)}>
                            <div style={{ position: "fixed", left: "50%", top: '50%' }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.saveItemsFormData}>

                                <div className={"form-group mt-20p "}>
                                    <label className="">Material No </label>
                                    <input type="text" name="material_no" id="material_no" value={this.state.material_no} onChange={this.changeHandler} className="form-control" />
                                </div>
                                <div className={"form-group mt-20p "}>
                                    <label className="">Material Description</label>
                                    <textarea name="material_description" id="material_description" rows="3" value={this.state.material_description} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Supplying Plant </label>
                                    {/* <textarea name="supply_plant_desc" id="supply_plant_desc" rows="3"  value={this.state.supply_plant_desc} onChange={this.changeHandler} className="form-control" ></textarea>
                                     */}
                                    <span className="form-control">
                                        {
                                            (this.state.srownode.hasOwnProperty("consigner_name") && this.state.srownode.consigner_name != undefined && this.state.srownode.consigner_name != "") ?
                                                this.state.srownode.consigner_name
                                                : "N/A"
                                        }
                                    </span>
                                    {/* <Select
                                        closeMenuOnSelect={true}
                                        placeholder="Select Plant"
                                        value={this.state.supply_plant_desc}
                                        onChange={this.onChangePlantCode}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.plantlist}
                                    /> */}
                                </div>
                                <div className={"form-group mt-20p"}>
                                    <label className="">Reached Destination At</label> <br />
                                    <span className="form-control">
                                        {
                                            (this.state.srownode.hasOwnProperty("truck_no") !== "") ?
                                                moment.utc(this.state.srownode.rec_dealer_reported).format('DD-MMM-YYYY HH:mm')
                                                : ""
                                        }
                                    </span>
                                    <input type="text" name="rec_dest_at" id="rec_dest_at" className="form-control" required />
                                </div>

                                <div className={"form-group mt-40p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;POD Date </label>
                                    <input type="text" name="customer_pod_date" id="customer_pod_date" className="form-control" required />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Item </label>
                                    <Select
                                        placeholder={"Select Item"}
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        value={this.state.itemcode}
                                        onChange={this.changeItemCode.bind(this)}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={this.itemslist()}
                                    />
                                    {/* <input type="text" name="itemcode" id="itemcode" value={this.state.itemcode} className="form-control"  onChange={this.changeHandler}   /> */}
                                </div>
                                {/*                                 
                                <div className="form-group mt-20p">
                                    <label className="">Item Description</label> 
                                    <input type="text" name="item_description" id="item_description" value={this.state.item_description} className="form-control" readOnly  />
                                </div> */}

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Defect </label>
                                    <Select
                                        placeholder={"Select Defect"}
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        value={this.state.defectcode}
                                        onChange={this.changeDefectCode.bind(this)}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={this.defectlist()}
                                    />
                                </div>

                                {/* <div className="form-group mt-20p">
                                    <label className="">Defect Description</label> 
                                    <input type="text" name="defect_description" id="defect_description" value={this.state.defect_description} className="form-control" readOnly  />
                                </div>                         */}

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Category </label>
                                    <Select
                                        placeholder={"Select Category"}
                                        closeMenuOnSelect={true}
                                        value={this.state.category}
                                        isMulti={false}
                                        onChange={this.changeCategoryCode.bind(this)}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={this.categorylist()}
                                    />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Quantity </label>
                                    <input type="text" name="quantity" id="quantity" value={this.state.quantity} onChange={this.changeQtyHandler} className="form-control" />
                                </div>
                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;GPS Device From Vehicle </label>
                                    <Select
                                        placeholder={"Select"}
                                        closeMenuOnSelect={true}
                                        value={this.state.gps_device_vehicle}
                                        isMulti={false}
                                        onChange={this.changeGPSDeviceVehicle.bind(this)}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ value: "", label: "Select" }, { value: "Yes", label: "Yes" }, { value: "No", label: "No" }]}
                                    />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Device No </label>
                                    <input type="text" name="device_no" id="device_no" value={this.state.device_no} onChange={this.changeDeviceHandler} className="form-control" />
                                </div>
                                <div className={"form-group mt-20p "}>
                                    <label className="">Remarks</label>
                                    <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3" value={this.state.customer_pod_remarks} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Upload Documents </label>
                                    <input type="file" name="customer_doc_1" id="customer_doc_1" multiple="multiple" onChange={this.changeFileHandler} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Driver Mobile Number </label>
                                    <input type="text" name="driver_mobile_with_defects" id="driver_mobile_with_defects" minLength="10" maxLength="10" value={this.state.driver_mobile_with_defects} onChange={this.changeMobileHandler} className="form-control" />
                                </div>



                                {/* {(this.state.is_driver_with_defect_verified === 1)? */}
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                {/* :""} */}

                            </form>
                        </div>
                    </div>

                    {/* <div className={"dataLoadpageimg"} style={{display:(this.state.overly2=="loader"?"block":"none")}}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div> */}

                </div>
                <div className={"slide-r " + (this.state.sliderWithoutDefectAddTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Add WithOut Defect Items
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.overly2)}>
                            <div style={{ position: "fixed", left: "50%", top: '50%' }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.saveWithOutItemsFormData}>

                                <div className={"form-group mt-20p "}>
                                    <label className="">Reached Destination At</label>
                                    {/* <span className="form-control">
                                        {
                                            (this.state.srownode != "")?
                                                getHyphenDDMMMYYYYHHMM(this.state.srownode.recent_dealer_reported)
                                            :""
                                        }
                                    </span> */}
                                    <input type="text" name="rec_dest_at_without" id="rec_dest_at_without" className="form-control" required />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;POD Date </label>
                                    <input type="text" name="customer_pod_date_without" id="customer_pod_date_without" className="form-control" required />
                                </div>



                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;GPS Device From Vehicle </label>
                                    <Select
                                        placeholder={"Select"}
                                        closeMenuOnSelect={true}
                                        value={this.state.gps_device_vehicle}
                                        isMulti={false}
                                        onChange={this.changeGPSDeviceVehicle.bind(this)}
                                        className={"border-radius-0"}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ value: "", label: "Select" }, { value: "Yes", label: "Yes" }, { value: "No", label: "No" }]}
                                    />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Device No </label>
                                    <input type="text" name="device_no" id="device_no" value={this.state.device_no} onChange={this.changeDeviceHandler} className="form-control" required />
                                </div>

                                <div className={"form-group mt-20p "}>
                                    <label className="">Remarks</label>
                                    <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3" value={this.state.customer_pod_remarks} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className={"form-group mt-20p "}>
                                    <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Driver Mobile Number </label>
                                    <input type="text" name="driver_mobile" id="driver_mobile" value={this.state.driver_mobile} minLength="10" maxLength="10" onChange={this.changeMobileDefectsHandler} className="form-control" required />
                                </div>

                                {/* {(this.state.is_driver_without_defect_otp_trigger === 1 && this.state.is_driver_without_defect_verified === 0)?
                                <div className="form-group mt-20p">
                                    <label className="">Enter OTP </label> 
                                    <input type="text" name="driver_without_defect_otp" id="driver_without_defect_otp" value={this.state.driver_without_defect_otp} onChange={this.changeQtyHandler} className="form-control" required  />
                                </div>        
                                :""}
                             
                                  {(this.state.is_driver_without_defect_otp_trigger === 0 && this.state.is_driver_without_defect_verified === 0)?   
                                    <div className="form-group  mt-20p">
                                        <button type="button" className="btn btn-success" onClick={this.sendDriverWithOutDefectOTP}>Send OTP</button>
                                    </div>
                               :""}   
                                
                                 {(this.state.is_driver_without_defect_otp_trigger === 1 && this.state.is_driver_without_defect_verified === 0)? 
                                    <div className="form-group">
                                        <button type="button" className="btn btn-info" onClick={this.verifyDriverWithOutDefectOTP.bind(this)}>Verify OTP</button>
                                    </div>
                                 :""}  */}

                                {/* {(this.state.is_driver_without_defect_verified === 1)? */}
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                {/* :""} */}

                            </form>
                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderSubmitEPODTranslate)} >
                    <div className="slide-r-title">
                        <h4>
                            Submit e-POD
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.overly2)}>
                            <div style={{ position: "fixed", left: "50%", top: '50%' }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="form-group">
                                <label className="c-lbl"><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Submit e-POD</label>
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="radio radio-primary col col-xl-6 col-lg-6">
                                        <input id="radio44" onChange={this.checkNotifyHandler} name="submitEPODType" type="radio" className="form-check-input" value="withdefects" checked={this.state.submitEPODType === 'withdefects'} />
                                        <label htmlFor="radio44" className="">With Defects</label>
                                    </div>
                                    <div className="radio radio-info col col-xl-6 col-lg-6">
                                        <input id="radio55" onChange={this.checkNotifyHandler} name="submitEPODType" type="radio" className="form-check-input" value="withoutdefects" checked={this.state.submitEPODType === 'withoutdefects'} />
                                        <label htmlFor="radio55" className="">Without Defects</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.submitEPODType == "withdefects" ?
                                <div className="col-xl-12 col-lg-12">
                                    <form id="upform" className="theme-form" onSubmit={this.saveItemsFormData}>

                                        <div className={"form-group mt-20p "}>
                                            <label className="">Material No </label>
                                            <input type="text" name="material_no" id="material_no" value={this.state.material_no} onChange={this.changeHandler} className="form-control" />
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className="">Material Description</label>
                                            <textarea name="material_description" id="material_description" rows="3" value={this.state.material_description} onChange={this.changeHandler} className="form-control" ></textarea>
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Supplying Plant </label>
                                            <span className="form-control">
                                                {
                                                    (this.state.srownode.hasOwnProperty("consigner_name") && this.state.srownode.consigner_name != undefined && this.state.srownode.consigner_name != "") ?
                                                        this.state.srownode.consigner_name
                                                        : "N/A"
                                                }
                                            </span>
                                            {/* <Select
                                                // placeholder={"Select Plant"}
                                                closeMenuOnSelect={true}
                                                isMulti={false}
                                                // id="supply_plant_desc"
                                                placeholder="Select Plant"
                                                value={this.state.supply_plant_desc}
                                                onChange={this.onChangePlantCode}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                required
                                                // options={[
                                                // {
                                                //     value: "Chennai",
                                                //     label: "Plants - Chennai"
                                                // },
                                                // {
                                                //     value: "Jaipur",
                                                //     label: "Dealers - Jaipur"
                                                // }
                                                // ]}
                                                options={this.state.plantlist}
                                            /> */}
                                        </div>
                                        <div className={"form-group mt-20p"}>
                                            <label className="">Reached Destination At</label> <br />
                                            <span className="form-control">
                                                {
                                                    (this.state.srownode.hasOwnProperty("recent_dealer_reported") && this.state.srownode.recent_dealer_reported != undefined && this.state.srownode.recent_dealer_reported != "") ?
                                                        moment.utc(this.state.srownode.rec_dealer_reported).format('DD-MMM-YYYY HH:mm')
                                                        : "N/A"
                                                }
                                            </span>
                                            {/* <input type="text" name="rec_dest_at" id="rec_dest_at" className="form-control" required /> */}
                                        </div>
                                        <div className={"form-group mt-40p"}>
                                            <label className="">
                                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;POD Date
                                            </label>
                                            <DatePicker
                                                selected={this.state.customer_pod_date}
                                                onChange={this.handleDateChange}
                                                name="customer_pod_date"
                                                closeOnSelect='true'
                                                id="customer_pod_date"
                                                className="form-control"
                                                autoComplete='off'
                                                // dateFormat="MMMM d, yyyy h:mm aa"
                                                dateFormat="dd-MMM-YYYY HH:mm"
                                                showTimeSelect
                                                placeholderText="Select a date"
                                                required
                                            />
                                        </div>
                                        {/* <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Item </label>
                                            <Select
                                                placeholder={"Select Item"}
                                                closeMenuOnSelect={true}
                                                isMulti={false}
                                                value={this.state.itemcode}
                                                onChange={this.changeItemCode.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.itemslist()}
                                            />
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Defect </label>
                                            <Select
                                                placeholder={"Select Defect"}
                                                closeMenuOnSelect={true}
                                                isMulti={false}
                                                value={this.state.defectcode}
                                                onChange={this.changeDefectCode.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.defectlist()}
                                            />
                                        </div> */}

                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Defect Code: </label>
                                            <input type="text" name="defectcode" id="defectcode" value={this.state.defectcode} onChange={e => this.setState({ defectcode: e.target.value })} className="form-control" required />
                                        </div>
                                        {/* <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Category </label>
                                            <Select
                                                placeholder={"Select Category"}
                                                closeMenuOnSelect={true}
                                                value={this.state.category}
                                                isMulti={false}
                                                onChange={this.changeCategoryCode.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.categorylist()}
                                            />
                                        </div> */}

                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Quantity </label>
                                            <input type="text" name="quantity" id="quantity" value={this.state.quantity} onChange={this.changeQtyHandler} className="form-control" />
                                        </div>
                                        {/* <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;GPS Device From Vehicle </label>
                                            <Select
                                                placeholder={"Select"}
                                                closeMenuOnSelect={true}
                                                value={this.state.gps_device_vehicle}
                                                isMulti={false}
                                                onChange={this.changeGPSDeviceVehicle.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ value: "", label: "Select" }, { value: "Yes", label: "Yes" }, { value: "No", label: "No" }]}
                                            />
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Device No </label>
                                            <input type="text" name="device_no" id="device_no" value={this.state.device_no} onChange={this.changeDeviceHandler} className="form-control" />
                                        </div> */}
                                        <div className={"form-group mt-20p "}>
                                            <label className="">Remarks</label>
                                            <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3" value={this.state.customer_pod_remarks} onChange={this.changeHandler} className="form-control" ></textarea>
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Upload Documents </label>
                                            <input type="file" name="customer_doc_1" id="customer_doc_1" multiple="multiple" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group mt-20p">
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Driver Mobile Number</label>
                                            <input type="text" name="driver_mobile_with_defects" id="driver_mobile_with_defects" minLength="10" maxLength="10" required value={this.state.driver_mobile_with_defects} onChange={this.changeMobileHandler} className="form-control" />
                                        </div>
                                        {/* {(this.state.is_driver_with_defect_verified === 1)? */}
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                        {/* :""} */}
                                    </form>
                                </div>
                                : ""
                        }
                        {
                            this.state.submitEPODType == "withoutdefects" ?
                                <div className="col-xl-12 col-lg-12">
                                    <form id="upform" className="theme-form" onSubmit={this.saveWithOutItemsFormData}>

                                        <div className={"form-group mt-20p "}>
                                            <label className="">Reached Destination At</label>
                                            <span className="form-control">
                                                {
                                                    (this.state.srownode.hasOwnProperty("recent_dealer_reported") && this.state.srownode.recent_dealer_reported != undefined && this.state.srownode.recent_dealer_reported != "") ?
                                                        moment.utc(this.state.srownode.rec_dealer_reported).format('DD-MMM-YYYY HH:mm')
                                                        : "N/A"
                                                }
                                            </span>
                                        </div>
                                        <div className={"form-group mt-40p"}>
                                            <label className="">
                                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;POD Date
                                            </label>
                                            <DatePicker
                                                selected={this.state.customer_pod_date}
                                                onChange={this.handleDateChange}
                                                name="customer_pod_date"
                                                closeOnSelect='true'
                                                id="customer_pod_date"
                                                className="form-control"
                                                autoComplete='off'
                                                // dateFormat="MMMM d, yyyy h:mm aa"
                                                dateFormat="dd-MMM-YYYY HH:mm"
                                                showTimeSelect
                                                placeholderText="Select a date"
                                                required
                                            />
                                        </div>

                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Supplying Plant </label>
                                            <span className="form-control">
                                                {
                                                    (this.state.srownode.hasOwnProperty("consigner_name") && this.state.srownode.consigner_name != undefined && this.state.srownode.consigner_name != "") ?
                                                        this.state.srownode.consigner_name
                                                        : "N/A"
                                                }
                                            </span>
                                            {/* <Select
                                                closeMenuOnSelect={true}
                                                placeholder="Select Plant"
                                                value={this.state.supply_plant_desc}
                                                onChange={this.onChangePlantCode}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.plantlist}
                                            /> */}
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className="">Remarks</label>
                                            <textarea name="customer_pod_remarks" id="customer_pod_remarks" rows="3" value={this.state.customer_pod_remarks} onChange={this.changeHandler} className="form-control" ></textarea>
                                        </div>
                                        <div className={"form-group mt-20p "}>
                                            <label className=""><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Driver Mobile Number </label>
                                            <input type="text" name="driver_mobile" id="driver_mobile" value={this.state.driver_mobile} minLength="10" maxLength="10" onChange={this.changeMobileDefectsHandler} className="form-control" required />
                                        </div>

                                        {/* {(this.state.is_driver_without_defect_otp_trigger === 1 && this.state.is_driver_without_defect_verified === 0)?
                                    <div className="form-group mt-20p">
                                        <label className="">Enter OTP </label> 
                                        <input type="text" name="driver_without_defect_otp" id="driver_without_defect_otp" value={this.state.driver_without_defect_otp} onChange={this.changeQtyHandler} className="form-control" required  />
                                    </div>        
                                    :""}
                                 
                                      {(this.state.is_driver_without_defect_otp_trigger === 0 && this.state.is_driver_without_defect_verified === 0)?   
                                        <div className="form-group  mt-20p">
                                            <button type="button" className="btn btn-success" onClick={this.sendDriverWithOutDefectOTP}>Send OTP</button>
                                        </div>
                                   :""}   
                                    
                                     {(this.state.is_driver_without_defect_otp_trigger === 1 && this.state.is_driver_without_defect_verified === 0)? 
                                        <div className="form-group">
                                            <button type="button" className="btn btn-info" onClick={this.verifyDriverWithOutDefectOTP.bind(this)}>Verify OTP</button>
                                        </div>
                                     :""}  */}

                                        {/* {(this.state.is_driver_without_defect_verified === 1)? */}
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>

                                    </form>
                                </div> : ""
                        }
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderFeedback)} >

                    <div className="slide-r-title">
                        <h4>
                            FeedBack
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <form id="upform" className="theme-form" onSubmit={this.savePODFeedback}>

                                <div className="form-group mt-20p">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Based on delivery time : </label>
                                        </div>
                                        <div className="col-sm-4">
                                            <Select
                                                placeholder={"10"}
                                                closeMenuOnSelect={true}
                                                value={this.state.rating1}
                                                isMulti={false}
                                                onChange={this.changeRatingOne.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.ratingpoints()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="">Comments:</label>
                                    <textarea name="comment1" id="comment1" rows="3" value={this.state.comment1} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-40p">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Based on goods delivery condition: </label>
                                        </div>
                                        <div className="col-sm-4">
                                            <Select
                                                placeholder={"10"}
                                                closeMenuOnSelect={true}
                                                value={this.state.rating2}
                                                isMulti={false}
                                                onChange={this.changeRatingTwo.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.ratingpoints()}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label className="">Comments:</label>
                                    <textarea name="comment2" id="comment2" rows="3" value={this.state.comment2} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>

                                <div className="form-group mt-40p">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <label className="">Overall Experience: </label>
                                        </div>
                                        <div className="col-sm-4">
                                            <Select
                                                placeholder={"10"}
                                                closeMenuOnSelect={true}
                                                value={this.state.rating3}
                                                isMulti={false}
                                                onChange={this.changeRatingThree.bind(this)}
                                                className={"border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                options={this.ratingpoints()}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label className="">Comments:</label>
                                    <textarea name="comment3" id="comment3" rows="3" value={this.state.comment3} onChange={this.changeHandler} className="form-control" ></textarea>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderViewDownloads)} >
                    <div className="slide-r-title">
                        <h4>
                            Uploaded Documents
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            {
                                Array.isArray(this.state.downloadItems) ?
                                    this.state.downloadItems.map((each, i) => {
                                        return (
                                            <div style={{ paddingBottom: "10px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <button className='download-button' type='button' onClick={(e) => this.downloadFile(each)} style={{ cursor: "pointer" }}><i className="fa fa-download" />&nbsp;&nbsp;File {i + 1}</button>
                                            </div>
                                        )
                                    })
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    // $('#customer_pod_date').datetimepicker({
    // 	mask:'39-19-9999 29:59',
    // 	format:'d-m-Y H:i',
    //     // maxDate:0
    //     minDate:cdt,
    // });
    // $('#customer_pod_date_without').datetimepicker({
    // 	mask:'39-19-9999 29:59',
    // 	format:'d-m-Y H:i',
    //     // maxDate:0,
    //     minDate:cdt
    // });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};



function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('#customer_pod_date').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        // maxDate:0
        minDate: cdt,
    });
    $('#customer_pod_date_without').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        // maxDate:0,
        minDate: cdt
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
