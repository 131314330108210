module.exports = {
    DEALERS:{
        "PAGE_TITLE" : "Dealers",
        "BTN_ADD": 0,
        "BTN_ADD_TITLE":"Add Dealer",
        "BTN_UPLOAD_BULK":0,
        "BTN_UPLOAD_TITLE":"Bulk Upload",
        "FETCH_URL":"/masters/dealerlist"
    },
    VEHICLES:{
        "PAGE_TITLE" : "Vehicles Information",
        "BTN_ADD": 1,
        "BTN_ADD_TITLE":"Add Vehicle",
        "BTN_UPLOAD_BULK":1,
        "BTN_UPLOAD_TITLE":"Bulk Upload",
        "FETCH_URL":"/masters/vehiclelist"
    }
}