import React, { Component } from 'react';
import CountUp from 'react-countup';


export default class DashboardCountersComponent extends Component {
    constructor(props){
    	super(props);
    	this.state={
            records:[],
            totalTrucksCount:0,
            trucksWithGpsDataCount:0,
            trucksWithNoTransportersCount:0,
            gpsDataNotReportedTodayCount:0,
            totalActiveConsignmentsCount:0,
            gpsNotTodayActiveConsignmentsCount:0,
            totalInactiveConsignmentsCount:0,
            ontripwithgps:0,
            ontripwithnogps24hrs:0,
            israil:0,
            ontripgpsna:0,
            railtotlaontrip:0,
            atdealertruckcnt:0,
            atdealernogps:0,
            primaryontrips:0,
            secondaryontrips:0,
            deptcode:''
    	}
    
    }
    componentWillReceiveProps(newprops){
        let gpsDataNotReportedToday = newprops.tottrucks - newprops.truckgps;
        let gpsDataNotReportedTodayCount = (gpsDataNotReportedToday) ? gpsDataNotReportedToday : 0;
        let totalInactiveConsignmentsCount = newprops.tottrucks - newprops.totalActiveConsignmentsCount;
        totalInactiveConsignmentsCount = (totalInactiveConsignmentsCount >= 0) ? totalInactiveConsignmentsCount : 0;
        var ontripwithnogps24hrs = (newprops.totalActiveConsignmentsCount-newprops.ontripwithgps) 
        //console.log("israil ", newprops.israil)
        if(newprops.israil == 1)
        {
            var ontripgpsna=newprops.ontripgpsna
            var railtotlaontrip = parseInt(newprops.ontripwithgps)+parseInt(newprops.ontripgpsna)
        }
        else
        {
            var ontripgpsna=0
            var railtotlaontrip = 0
        }
        this.setState({
            totalTrucksCount:newprops.tottrucks,
            trucksWithGpsDataCount:newprops.truckgps,
            gpsDataNotReportedTodayCount:gpsDataNotReportedTodayCount,
            trucksWithNoTransportersCount:newprops.notruckgps,
            totalActiveConsignmentsCount:newprops.totalActiveConsignmentsCount,
            gpsNotTodayActiveConsignmentsCount:newprops.gpsNotTodayActiveConsignmentsCount,
            totalInactiveConsignmentsCount:totalInactiveConsignmentsCount,
            ontripwithgps:newprops.ontripwithgps,
            ontripwithnogps24hrs:ontripwithnogps24hrs,
            ontripgpsna:ontripgpsna,
            railtotlaontrip:railtotlaontrip,
            israil:newprops.israil,
            atdealertruckcnt:newprops.atdealertruckcnt,
            atdealernogps:newprops.atdealernogps,
            primaryontrips:newprops.primaryontrips,
            secondaryontrips:newprops.secondaryontrips,
            deptcode:newprops.deptcode
        })
    }
    onClickCounter(data){
        //this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="dashboardCounterCardBody">
                        <div className="crm-numbers pb-0">
                            {(this.state.israil == 0)?
                            <div className="row">
                               
                                <div className="col cirlce-d " onClick={this.onClickCounter.bind(this,0)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                        <br /> Total Trucks
                                    </span>
                                    <h4 className="txt-info f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.totalTrucksCount != '')?this.state.totalTrucksCount:0}/>
                                        </span>
                                    </h4>
                                     <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col " onClick={this.onClickCounter.bind(this,1)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i>
                                        <br /> Trucks with GPS Data in 24hrs
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.ontripwithgps != '')?this.state.ontripwithgps:0}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col " onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13">
                                        <i className="icofont icofont-social-google-map  f24 txt-pink-light"></i>
                                        <br /> GPS Data Not Reported in 24hrs
                                    </span>
                                    <h4 className="txt-pink-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.gpsDataNotReportedTodayCount) ? this.state.gpsDataNotReportedTodayCount : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            {/* <CountUp end={(this.state.gpsNotTodayActiveConsignmentsCount != '')?this.state.gpsNotTodayActiveConsignmentsCount:0}/> */}
                                            
                                            <CountUp end={(this.state.ontripwithnogps24hrs != '')?this.state.ontripwithnogps24hrs:0}/>
                                            
                                        </div>
                                        {/* <div className="counter txt-danger-medium">
                                            Inactive: &nbsp;
                                            <CountUp end={(this.state.totalInactiveConsignmentsCount != '')?this.state.totalInactiveConsignmentsCount:0}/>
                                        </div> */}
                                    </div>
                                    {/* {(this.state.deptcode != '' && this.state.deptcode == "SNDG")?
                                    <div className=" f16">
                                        <span className="counter txt-secondary-medium" style={{color:"#0077ff"}}>
                                        Primary: &nbsp;
                                            <CountUp end={(this.state.primaryontrips != '')?this.state.primaryontrips:0}/>
                                        </span>&nbsp;&nbsp;
                                        <span className="counter txt-danger-medium" style={{color:"#fd2f52"}}>
                                            Secondary: &nbsp;
                                            <CountUp end={(this.state.secondaryontrips != '')?this.state.secondaryontrips:0}/>
                                        </span>
                                    </div>
                                    :""} */}
                                    
                                </div>


                                <div className="col " onClick={this.onClickCounter.bind(this,3)}>
                                    <span className="f13">
                                        <i className="icofont icofont-ui-home  f24 txt-warning-light"></i>
                                        <br /> At Dealer
                                    </span>
                                    <h4 className="txt-warning-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.atdealertruckcnt) ? this.state.atdealertruckcnt : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f18">
                                        <div className="counter txt-success-medium">
                                        GPS No Data in 24hrs: &nbsp;
                                            {/* <CountUp end={(this.state.gpsNotTodayActiveConsignmentsCount != '')?this.state.gpsNotTodayActiveConsignmentsCount:0}/> */}
                                            
                                            <CountUp end={(this.state.atdealernogps != '')?this.state.atdealernogps:0}/>
                                            
                                        </div>
                                        {/* <div className="counter txt-danger-medium">
                                            Inactive: &nbsp;
                                            <CountUp end={(this.state.totalInactiveConsignmentsCount != '')?this.state.totalInactiveConsignmentsCount:0}/>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="col " onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt f22 txt-info-medium"></i>
                                        <br />Trucks with No Transporters
                                    </span>
                                    <h4 className="txt-info-dark f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.trucksWithNoTransportersCount) ? this.state.trucksWithNoTransportersCount : 0}/>
                                        </span>
                                    </h4>
                                </div>


                            </div>

                            :
                            <div className="row">
                               
                               <div className="col cirlce-d " onClick={this.onClickCounter.bind(this,0)}>
                                   <span className="f13">
                                       <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                       <br /> Total Trucks
                                   </span>
                                   <h4 className="txt-info f50">
                                       <span className="counter">
                                           <CountUp end={(this.state.totalTrucksCount != '')?this.state.totalTrucksCount:0}/>
                                       </span>
                                   </h4>
                                    <div className=" f20">
                                       <div className="counter txt-success-medium">
                                       On Trip Trucks: &nbsp;
                                           <CountUp end={(this.state.railtotlaontrip != '')?this.state.railtotlaontrip:0}/>
                                       </div>
                                   </div>
                               </div> 
                               <div className="col " onClick={this.onClickCounter.bind(this,1)}>
                                   <span className="f13">
                                       <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i>
                                       <br /> Trucks with GPS Data in 24hrs
                                   </span>
                                   <h4 className="txt-primary f50">
                                       <span className="counter">
                                           <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                       </span>
                                   </h4>
                                   <div className=" f20">
                                       <div className="counter txt-success-medium">
                                       On Trip Trucks: &nbsp;
                                           <CountUp end={(this.state.ontripwithgps != '')?this.state.ontripwithgps:0}/>
                                       </div>
                                   </div>
                               </div>

                               <div className="col " onClick={this.onClickCounter.bind(this,3)}>
                                   <span className="f13">
                                       <i className="icofont icofont-ui-home f24 txt-pink-light"></i>
                                       <br /> GPS Data Not Reported in 24hrs
                                   </span>
                                   <h4 className="txt-pink-medium f50">
                                       <span className="counter">
                                           <CountUp end={(this.state.gpsDataNotReportedTodayCount) ? this.state.gpsDataNotReportedTodayCount : 0}/>
                                       </span>
                                   </h4>
                                   <div className=" f20">
                                       <div className="counter txt-success-medium">
                                       On Trip Trucks: &nbsp;
                                           {/* <CountUp end={(this.state.gpsNotTodayActiveConsignmentsCount != '')?this.state.gpsNotTodayActiveConsignmentsCount:0}/> */}
                                           
                                           <CountUp end={(this.state.ontripgpsna != '')?this.state.ontripgpsna:0}/>
                                           
                                       </div>
                                       {/* <div className="counter txt-danger-medium">
                                           Inactive: &nbsp;
                                           <CountUp end={(this.state.totalInactiveConsignmentsCount != '')?this.state.totalInactiveConsignmentsCount:0}/>
                                       </div> */}
                                   </div>
                               </div>

                               <div className="col " onClick={this.onClickCounter.bind(this,4)}>
                                   <span className="f13">
                                       <i className="icofont icofont-truck-alt f22 txt-info-medium"></i>
                                       <br />Trucks with No Transporters
                                   </span>
                                   <h4 className="txt-info-dark f50">
                                       <span className="counter">
                                           <CountUp end={(this.state.trucksWithNoTransportersCount) ? this.state.trucksWithNoTransportersCount : 0}/>
                                       </span>
                                   </h4>
                               </div>


                           </div>

                            }
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






