const NightDriving=(props)=>{
    const handleClick = (params) => {
        props.onShowNightDrivingData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#ab8ce4"
    }
    return(
        <a onClick={handleClick}  title="Night Driving"><i className="icofont icofont-full-night f20" style={{ color: color }}></i> </a>
    )
}
export default NightDriving