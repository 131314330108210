 
const ConsignmentActions = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log("MAP Icon ", props)
        // props.context.componentParent.onCloseUploadDiv(e);
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.onShowTripDeviationData(props);
    };

    return (
            <a onClick={handleRouteClick}  title="Trip Deviation" style={{color:"#ff0000"}}><i className="fa fa-random f20"></i> </a>
        
    );
};

export default ConsignmentActions;
