import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import TransitData from './transitdashboard.js';
import moment from 'moment'

export default class TransitDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '',
            endDate: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            dept_code: "SNDG",
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
        }
    }
    componentDidMount() {
        var edate = new Date(moment.parseZone())
        var sdate = new Date(moment.parseZone().subtract(6, "month"))
        //var sdate = "2020-05-01";
        var dept_code = 'SNDG';
        if (window.location.pathname == "/sndtransitanalytics") {
            dept_code = "SNDG";

        }

        if (window.location.pathname == "/tnptransitanalytics") {
            dept_code = "LOG-TNP";
        }

        if (window.location.pathname == "/prttransitanalytics") {
            dept_code = "LOG-PRT";
        }
        var preqparams = {
            dept_code: dept_code
        }
        this.setState({
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate
        })

    }
    // componentWillReceiveProps(newprops){
    //     //console.log("newprops.dept_code ", newprops)
    //     if(newprops.dept_code != this.state.dept_code)
    //     {

    //             this.setState({
    //                 dept_code:this.props.dept_code
    //             })


    //     }
    // }

    onClickLoadDept(deptcode) {
        this.setState({
            dept_code: deptcode
        })
    }

    render() {
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl = '';
        var transiturl = '';
        var transporturl = '';
        if (this.state.dept_code == "SNDG") {
            taburl = "/snddashboardtemplate";
            loadingurl = "/sndloadingdashboard";
            transiturl = "/sndtransitanalytics";
            transporturl = "/sndtransportdashboard";
        }
        if (this.state.dept_code == "LOG-TNP") {
            taburl = "/tnpdashboardtemplate";
            loadingurl = "/tnploadingdashboard";
            transiturl = "/tnptransitanalytics";
            transporturl = "/tnptransportdashboard";
        }
        if (this.state.dept_code == "LOG-PRT") {
            taburl = "/prtdashboardtemplate";
            loadingurl = "/prtloadingdashboard";
            transiturl = "/prttransitanalytics";
            transporturl = "/prttransportdashboard";
        }
        if (this.state.dept_code == "LOG-SC") {
            taburl = "/scdashboardtemplate";
        }


        if (this.state.dept_code == "LOG-PRT") {
            var dailyurl = "/prtdailyrunningdashboard";
        }
        else {
            var dailyurl = "/dailyrunningdashboard";
        }
        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                        {/* <li>
                            {(this.state.dept_code == "SNDG") ?
                                <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-PRT") ?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-TNP") ?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}

                        </li> */}
                        {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
                       
                      
                    </ul>
                </div>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>Transit Analytics</span>

                        </h5>
                    </div>

                    <div className="card-body n-p-0 mt-10p">

                        {(this.props.dept_code == 'all') ?
                            <ul className="tabc-sm">
                                <li>
                                    <a href="javascript:;" className="loadclick active SNDG" id="SNDG" onClick={this.onClickLoadDept.bind(this, 'SNDG')}>Sales and Dispatch</a>
                                </li>
                                <li>
                                    <a href="javascript:;" className="loadclick LOG-PRT" id="LOG-PRT" onClick={this.onClickLoadDept.bind(this, 'LOG-PRT')}>Spare Parts</a>
                                </li>
                                <li>
                                    <a href="javascript:;" className="loadclick LOG-TNP" id="LOG-TNP" onClick={this.onClickLoadDept.bind(this, 'LOG-TNP')}>Train and Production</a>
                                </li>
                            </ul>
                            : ""}

                        {(this.props.dept_code == 'SNDG') ?
                            <ul className="tabc-sm">
                                <li>
                                    <a href="javascript:;" className="loadclick active SNDG" id="SNDG" onClick={this.onClickLoadDept.bind(this, 'SNDG')}>Sales and Dispatch</a>
                                </li>
                            </ul>
                            : ""}
                        {(this.props.dept_code == 'LOG-PRT') ?
                            <ul className="tabc-sm">
                                <li>
                                    <a href="javascript:;" className="loadclick active LOG-PRT" id="LOG-PRT" onClick={this.onClickLoadDept.bind(this, 'LOG-PRT')}>Spare Parts</a>
                                </li>
                            </ul>
                            : ""}
                        {(this.props.dept_code == 'LOG-TNP') ?
                            <ul className="tabc-sm">
                                <li>
                                    <a href="javascript:;" className="loadclick active LOG-TNP" id="LOG-TNP" onClick={this.onClickLoadDept.bind(this, 'LOG-TNP')}>Train and Production</a>
                                </li>
                            </ul>
                            : ""}

                        <div className=" col-xl-12 col-lg-12 mt-30p">
                            <TransitData
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                //locations={this.state.locations}
                                //transporters={this.state.transporters}
                                dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}




$(document).ready(function () {

    $(".loadclick").click(function () {
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("." + id).addClass("active");
    })
})