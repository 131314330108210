import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import EditButtonRenderer from './EditButtonRenderer';
import * as XLSX from 'xlsx';
import CSVFileValidator from 'csv-file-validator';


class Masters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            defaultColDef: {
                resizable: true,
                filter: true,
                sortable: true,
                wrapText: true
            },
            columnDefs: [],
            // frameworkComponents: {
            //     EditButtonRenderer: EditButtonRenderer,
            // },
            showloader: "show-m",
            showAddSlider: "",
            showBulkUploadSlider: "", // State for Bulk Upload Slider
            overlay: "show-n",
            sliderText: "",
            hideAll: "",
            resetForm: "",
            csvContent: [],
            file: '',
            isEditMode: false,
            showEditSlider: "",
            transporter_name: "",
            transporter_code: "",
            truck_no: "",
            vehicle_type: "",
            location_name: "",
            location_code: "",
            show: false,
            basicType: "",
            basicTitle: "",
        };
    }



    componentDidMount() {
        setTimeout(() => {
            this.setState({ showloader: "show-n" });
        }, 2000);
        this.loadData();
    }



    loadData = async () => {
        const parameters = {};
        await redirectURL.post('/getVehicleDetails', parameters)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({ rowData: response.data.records });
                }
                this.setState({ showloader: "show-n" });
            })
            .catch(error => console.error('Error fetching data:', error));
    }

    showAddBlock = () => {
        this.setState({
            showAddSlider: "slider-translate-30p",
            overlay: "show-m",
            sliderText: "Add Vehicle",
            isEditMode: false,
            transporter_name: "",
            transporter_code: "",
            truck_no: "",
            vehicle_type: "",
            location_name: "",
            location_code: "",
        });
    }

    showBulkUploadBlock = () => {
        this.setState({
            showBulkUploadSlider: "slider-translate-30p",
            overlay: "show-m",
            sliderText: "Bulk Upload Vehicles"
        });
    }

    hideAll = () => {

        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }
        this.setState({
            overlay: "show-n",
            showEditSlider: "",
            showAddSlider: "",
            showBulkUploadSlider: "", // Hide Bulk Upload Slider
        });
    }

    showEditBlock = (data) => {
        this.setState({
            showEditSlider: "slider-translate-30p",
            overlay: "show-m",
            sliderText: "Edit Vehicle",
            isEditMode: true,
            currentVehicleId: data._id,
            transporter_name: data.transporter_name,
            transporter_code: data.transporter_code,
            truck_no: data.truck_no,
            vehicle_type: data.vehicle_type,
            location_name: data.location_name,
            location_code: data.location_code
        });
    };

    saveMasterForm = async (e) => {
        e.preventDefault();
        const user_email = localStorage.getItem('email');
        this.setState({ showloader: "show-m" });
        const params = {
            transporter_name: this.state.transporter_name,
            transporter_code: this.state.transporter_code,
            truck_no: this.state.truck_no,
            vehicle_type: this.state.vehicle_type,
            location_name: this.state.location_name,
            location_code: this.state.location_code
        };

        if (this.state.isEditMode) {
            params._id = this.state.currentVehicleId;
            await redirectURL.post('/updateVehicleMaster', params).then((resp) => {
                if (resp.data.status === "success") {
                    this.hideAll();
                    this.loadData();
                    this.setState({
                        transporter_code: "",
                        transporter_name: "",
                        truck_no: "",
                        vehicle_type: "",
                        location_name: "",
                        location_code: "",
                        isEditMode: false,
                        show: true,
                        basicTitle: "Successfully Updated data",
                        basicType: "success",
                    });
                }
            });
        } else {
            await redirectURL.post('/addVehicle', params).then((resp) => {
                if (resp.data.status === "success") {
                    this.hideAll();
                    this.loadData();
                    this.setState({
                        transporter_code: "",
                        transporter_name: "",
                        truck_no: "",
                        vehicle_type: "",
                        location_name: "",
                        location_code: "",
                        isEditMode: false,
                        show: true,
                        basicTitle: "Successfully Added data",
                        basicType: "success",
                    });
                } else if (resp.data.status === "failure") {
                    this.hideAll();
                    this.setState({
                        showloader: "show-n",
                        isEditMode: false,
                        transporter_code: "",
                        transporter_name: "",
                        show: true,
                        basicTitle: resp.data.message,
                        basicType: "danger",
                    });
                }
            }).catch(err => { });
        }
    };

    resetUpload = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }
    };

    handleBulkUpload = async (e) => {
        if (e.target.files.length !== 0) {
            const config = {
                headers: [
                    {
                        name: 'truck_no',
                        inputName: 'truck_no',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'transporter_code',
                        inputName: 'transporter_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'transporter_name',
                        inputName: 'transporter_name',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'vehicle_type',
                        inputName: 'vehicle_type',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'location_name',
                        inputName: 'location_name',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'location_code',
                        inputName: 'location_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },

                ],
            };

            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- _]*$/); // Allowed characters

            try {
                const csvData = await CSVFileValidator(e.target.files[0], config);
                console.log('csvData', csvData);

                let keys = Object.keys(csvData.data[0] || {});
                let items = ['truck_no', 'transporter_code', 'transporter_name', 'vehicle_type', 'location_name', 'location_code'];
                let check = csvData.inValidData.length === 0 && items.every(item => keys.includes(item));

                console.log(check, "check");

                if (!check) {
                    e.target.value = ""; // Clear file input
                    this.setState({
                        show: true,
                        basicTitle: 'Dont upload other fields or special characters',
                        basicType: 'danger',
                    });
                }

                else {
                    this.setState({ csvContent: csvData.data });
                }

            } catch (err) {
                console.error('Error validating CSV:', err);
            }

            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = e.target.result;
                console.log(contents);
            };
            reader.readAsText(e.target.files[0]);

            this.setState({
                file: e.target.files[0],
            });

            if (e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel') {
                e.target.value = null;
                this.setState({
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
    }



    handleBulkUploadSubmit = async (e) => {
        e.preventDefault();

        const { csvContent } = this.state;

        if (csvContent.length > 0) {
            try {
                const createdDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

                const updatedCsvContent = csvContent.map(item => ({
                    ...item,
                    created_date: createdDate,
                }));

                const response = await redirectURL.post('/bulkUploadVehicle', updatedCsvContent, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('userid'),
                    },
                });

                if (response.data.message === 'success') {
                    document.getElementById('bulkUploadInput').value = '';
                    this.hideAll();
                    // this.resetForm();
                    this.setState({
                        basicTitle: "Bulk Upload Successful",
                        basicType: "success",
                        csvContent: [],

                        file: '',
                        show: true
                    });


                    setTimeout(() => {
                        this.loadData();
                    }, 1000);
                }
                if (response.data.status === 'failure' || response.data.status === 'partial') {
                    document.getElementById('bulkUploadInput').value = '';
                    this.hideAll();
                    // this.resetForm(); // Uncomment this if you need to reset the form
                    this.setState({
                        basicTitle: `${response.data.uploadedCount} Records uploaded out of ${response.data.totalRecords}`,
                        basicType: "warning",
                        csvContent: [],
                        file: '',
                        show: true
                    });




                    setTimeout(() => {
                        this.loadData();
                    }, 1000);
                }
            } catch (error) {
                console.error('Error during bulk upload:', error);
                this.setState({
                    show: true,
                    basicTitle: 'Bulk Upload Error',
                    basicType: 'danger'
                });
            }
        } else {
            this.setState({
                show: true,
                basicTitle: 'Cannot enter empty data',
                basicType: 'danger'
            });
        }
    };


    render() {
        let columnDefs = [

            {
                headerName: "Vehicle Number",
                field: "truck_no",
                width: 220,
                filter: true,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 220,
                filter: true,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 240,
                filter: true,
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 220,
                filter: true,
            },
            {
                headerName: "Location Name",
                field: "location_name",
                width: 220,
                filter: true,
            },
            {
                headerName: "Plant Code",
                field: "location_code",
                width: 220,
                filter: true,
            },
            {
                headerName: "",
                field: "_id",
                width: 90,
                cellRenderer: EditButtonRenderer,
                cellRendererParams: {
                    onClickEdit: (data) => this.showEditBlock(data),
                },
                cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
            }
        ]

        return (
            <div>
                <div className="container-fluid">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={() => {
                            this.setState({ show: false });
                        }}>
                    </SweetAlert>

                </div>
                <div className="row">

                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                                <h4 className="screen-name">Vehicle Master</h4>
                                <div>
                                    {Constants.VEHICLES.BTN_ADD === 1 && (
                                        <>
                                            <button
                                                className="btn btn-warning"
                                                // style={{ marginRight: "10px" }} 
                                                onClick={this.showAddBlock}>
                                                <i className="icon-plus"></i>&nbsp;Add Vehicle
                                            </button>
                                            <button
                                                className="btn btn-success ml-2"
                                                onClick={this.showBulkUploadBlock}>
                                                <i className="icon-upload"></i>&nbsp;Bulk Upload
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="card-body">
                                <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                    <AgGridReact
                                        columnDefs={columnDefs}
                                        rowData={this.state.rowData}
                                        defaultColDef={this.state.defaultColDef}
                                        pagination={true}
                                        paginationPageSize={50}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableRangeSelection={true}
                                        enableCellContextMenu={true}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel",
                                                    toolPanelParams: {
                                                        suppressPivotMode: true,
                                                        suppressRowGroups: true,
                                                        suppressValues: true,
                                                    }
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                {
                                                    statusPanel: "agTotalAndFilteredRowCountComponent",
                                                    align: "left"
                                                },
                                                {
                                                    statusPanel: "agTotalRowCountComponent",
                                                    align: "center"
                                                },
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"overlay-part " + this.state.overlay} onClick={this.hideAll}></div>

                {/* Add Vehicle Slider */}
                <div className={"slide-r " + this.state.showAddSlider}>
                    <div className="slide-r-title">
                        <h4 className="">{this.state.sliderText}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideAll}>X</span>
                        </h4>
                    </div>

                    <div className="slide-r-body">
                        <form className="" style={{ padding: "20px" }} onSubmit={this.saveMasterForm}>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Vehicle Number
                                </label>
                                <input
                                    type="text"
                                    name="truck_no"
                                    autoComplete="off"
                                    value={this.state.truck_no}
                                    style={{
                                        width: "100%", padding: "10px", border: "1px solid #ccc", borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ truck_no: e.target.value.toUpperCase() })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Code
                                </label>
                                <input
                                    type="text"
                                    name="transporter_code"
                                    autoComplete='off'
                                    value={this.state.transporter_code}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ transporter_code: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                    disabled={this.state.isEditMode}
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Name
                                </label>
                                <input
                                    type="text"
                                    name="transporter_name"
                                    autoComplete='off'
                                    value={this.state.transporter_name}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ transporter_name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Vehicle Type
                                </label>
                                <input
                                    type="text"
                                    name="vehicle_type"
                                    autoComplete='off'
                                    value={this.state.vehicle_type}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ vehicle_type: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Location Name
                                </label>
                                <input
                                    type="text"
                                    name="location_name"
                                    autoComplete='off'
                                    value={this.state.location_name}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ location_name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>


                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Plant Code
                                </label>
                                <input
                                    type="text"
                                    name="location_code"
                                    autoComplete='off'
                                    value={this.state.location_code}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ location_code: e.target.value })}
                                    required
                                />
                            </div>


                            <div className="form-group" style={{ textAlign: "center" }}>
                                <button type="submit" className="btn btn-success"
                                    style={{
                                        padding: "10px 20px",
                                        border: "none",
                                        borderRadius: "4px",
                                        backgroundColor: "#28a745",
                                        color: "white",
                                    }}
                                >
                                    Add Vehicle
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={"slide-r " + this.state.showEditSlider}>
                    <div className="slide-r-title">
                        <h4 className="">{this.state.sliderText}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideAll}>X</span>
                        </h4>
                    </div>

                    <div className="slide-r-body">
                        <form className="" style={{ padding: "20px" }} onSubmit={this.saveMasterForm}>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Vehicle Number
                                </label>
                                <input
                                    type="text"
                                    name="truck_no"
                                    autoComplete="off"
                                    value={this.state.truck_no}
                                    style={{
                                        width: "100%", padding: "10px", border: "1px solid #ccc", borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ truck_no: e.target.value.toUpperCase() })}
                                    required
                                    disabled={this.state.isEditMode}
                                />
                                {this.state.isEditMode && (
                                    <p style={{ color: "#EB1D54", fontSize: "11px", marginTop: "5px" }}>
                                        *Vehicle Number cannot be changed
                                    </p>
                                )}
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Code
                                </label>
                                <input
                                    type="text"
                                    name="transporter_code"
                                    autoComplete='off'
                                    value={this.state.transporter_code}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ transporter_code: e.target.value.toUpperCase() })}
                                    required
                                // disabled={this.state.isEditMode}
                                />

                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Name
                                </label>
                                <input
                                    type="text"
                                    name="transporter_name"
                                    autoComplete='off'
                                    value={this.state.transporter_name}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ transporter_name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Vehicle Type
                                </label>
                                <input
                                    type="text"
                                    name="vehicle_type"
                                    autoComplete='off'
                                    value={this.state.vehicle_type}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ vehicle_type: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Location Name
                                </label>
                                <input
                                    type="text"
                                    name="location_name"
                                    autoComplete='off'
                                    value={this.state.location_name}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ location_name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label style={{ display: "block", marginBottom: "5px" }}>
                                    <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Plant Code
                                </label>
                                <input
                                    type="text"
                                    name="location_code"
                                    autoComplete='off'
                                    value={this.state.location_code}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px"
                                    }}
                                    onChange={(e) => this.setState({ location_code: e.target.value })}
                                    required
                                />
                            </div>



                            <div className="form-group" style={{ textAlign: "center" }}>
                                <button type="submit" className="btn btn-success"
                                    style={{
                                        padding: "10px 20px",
                                        border: "none",
                                        borderRadius: "4px",
                                        backgroundColor: "#28a745",
                                        color: "white",
                                    }}
                                >
                                    {this.state.isEditMode ? 'Update Vehicle' : 'Add Vehicle'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>



                {/* Bulk Upload Slider */}
                <div className={"slide-r " + this.state.showBulkUploadSlider}>
                    <div className="slide-r-title">
                        <h4 className="">{this.state.sliderText}
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.hideAll}>X</span>
                        </h4>
                    </div>

                    <div className="slide-r-body">
                        <div style={{ padding: "20px" }}>
                            <form method="POST" id="upform" className="theme-form" onSubmit={this.handleBulkUploadSubmit}>
                                <div className="form-group">
                                    <label className="">Upload File</label>
                                    <input type="file" id="bulkUploadInput" onChange={this.handleBulkUpload} className="form-control" required />
                                    <div style={{ color: "#ff0000", marginTop: "10px" }}>
                                        {/* ** Allowed Space and Special Characters are <span style={{ fontWeight: "700", fontSize: "18px", fontFamily: "arial" }}>-._,&#</span> */}
                                    </div>

                                </div>

                                <div className="form-group">
                                    <button className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>

                            </form>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../assets/json/bulk_master_vehicle.csv')} target="_blank">Sample Template</a>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Masters;
