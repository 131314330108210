import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDrilldown from 'highcharts-drilldown';

// Initialize drilldown module
HighchartsDrilldown(Highcharts);

const DonutChartWithDrilldown = (props) => {
  const options = {
    chart: {
      type: 'pie',
      events: {
        render() {
          const chart = this;
          const total = chart.series[0].data.reduce((sum, point) => sum + point.y, 0);

          if (!chart.customLabel) {
            chart.customLabel = chart.renderer.text(
              `Total Force Closure Summary: ${total}`,
              chart.plotWidth / 2 + chart.plotLeft, // Center horizontally
              chart.plotHeight + chart.plotTop + 8 // Position below the chart
            )
              .css({
                fontSize: '18px',
                color: '#000',
                textAlign: 'center',
              })
              .attr({
                align: 'center',
                zIndex: 10,
              })
              .add();
          } else {
            chart.customLabel.attr({ text: `Total Force Closure Summary: ${total}` });
          }
        },
      },
    },
    title: {
      text: props.title,
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        depth: 45,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    // series: [
    //   {
    //     name: 'Browsers',
    //     data: [
    //       {
    //         name: 'Chrome',
    //         y: 63.24,
    //       },
    //       {
    //         name: 'Edge',
    //         y: 12.24,
    //       },
    //       {
    //         name: 'Firefox',
    //         y: 9.36,
    //       },
    //       {
    //         name: 'Safari',
    //         y: 8.56,
    //       },
    //       {
    //         name: 'Other',
    //         y: 6.60,
    //       },
    //     ],
    //   },
    // ],
    series: props.series
  };


  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DonutChartWithDrilldown;
