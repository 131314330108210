const TollActions = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log("MAP Icon ", props)
        // props.context.componentParent.onCloseUploadDiv(e);
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.onShowTollsData(props);
    };

    return (
            <a onClick={handleRouteClick}  title="Tolls"><i className="icofont icofont-help-robot f20"></i>&nbsp;</a>
        
    );
};

export default TollActions;
