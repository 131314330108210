import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
// import thunkMiddleware from 'redux-thunk'
// import logger from 'redux-logger'

// Import custom components
import reducers from '../reducers';


/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(reducers, compose(
    // applyMiddleware(thunkMiddleware, logger),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    // window.devToolsExtension ? window.devToolsExtension() : function (f) {
    //     return f;
    // }
));
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
});

export default store;