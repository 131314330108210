import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Info from '../../assets/icons/info.png'

export default ({ displayName }) => {
    let text = useMemo(() => (displayName == "Nestlé ETA" ? "This represents the Nestlé Expected Time of Arrival (ETA)." : (displayName == "Original ETA (Enmovil)" ? "This ETA is calculated once the truck departs from the plant for delivery." : (displayName == "Revised ETA (Enmovil)" ? "The ETA is adjusted if the vehicle fails to reach the destination by the Original ETA." : ""))), [displayName]);
    return (
        <div className="custom-header">
            <span>{displayName}</span>&nbsp;&nbsp;
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">{text}</Tooltip>}>
                <img src={Info} width="16px" style={{ position: "absolute" }} />
            </OverlayTrigger>
        </div>
    );
};