import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MENUITEMS2 } from './menu22'
import redirectURL from '../../redirectURL'
import { getDecoder } from '../utils';
//import {MENUITEMS} from '../../../constants/menu'

// Import custom components
//import UserPanel from './user-panel.component';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPath: "1",
            mainmenu: null,
        };
    }
    // state = {  };
    onItemSelection = (arg, e) => {
        this.setState({ selectedPath: arg.path });
    };

    componentWillMount() {

    }
    componentDidMount() {
        const user = getDecoder()
        var currentUrl = window.location.pathname;
        if (localStorage.getItem("usermenus") == undefined || localStorage.getItem("usermenus") == null) {
            if (!this.state.mainmenu) {
                //console.log("IN componentDidMount, this.state.mainmenu ", this.state.mainmenu);
                let menuslist = null;
                //console.log("IN componentDidMount, menuslist = ", menuslist);
                let body = {
                    role: user.role[0]
                }
                redirectURL.post('/getMenus', body)
                    .then(res => {
                        menuslist = res.data.menus
                        localStorage.setItem('usermenus', JSON.stringify(res.data.menus))
                    })
                    .catch(err => console.log(err))
                if (menuslist) {
                    const MENUITEMS = menuslist;
                    this.setState({
                        mainmenu: MENUITEMS
                    })
                }
            }
            if (this.state.mainmenu) {
                this.state.mainmenu.filter(items => {
                    if (!items.children) {
                        if (items.path === currentUrl)
                            this.setNavActive(items)
                        return false
                    }
                    items.children.filter(subItems => {
                        if (subItems.path === currentUrl)
                            this.setNavActive(subItems)
                        if (!subItems.children) return false
                        subItems.children.filter(subSubItems => {
                            if (subSubItems.path === currentUrl)
                                this.setNavActive(subSubItems)
                        })
                    })
                })
            }
        }

    }

    setNavActive(item) {
        let MENUITEMS = [...this.state.mainmenu];
        if (this.state.mainmenu) {

            MENUITEMS.filter(menuItem => {
                if (menuItem != item)
                    menuItem.active = false
                if (menuItem.children && menuItem.children.includes(item))
                    menuItem.active = true
                if (menuItem.children) {
                    // menuItem.children.filter(submenuItems => {
                    //     if(submenuItems.children && submenuItems.children.includes(item))
                    //         menuItem.active = true
                    //     if(submenuItems != item)
                    //         submenuItems.active = false
                    // })

                    menuItem.children.filter(submenuItems => {
                        if (submenuItems.children && submenuItems.children.includes(item)) {
                            //console.log("Step1")
                            menuItem.active = true
                        }

                        if (submenuItems != item) {
                            //console.log("Step2")
                            submenuItems.active = false
                            //this.props.context.openCloseSidebar();
                        }
                        if (submenuItems.children && submenuItems.children.length > 0) {
                            //console.log("Step3")
                            if (submenuItems.children.includes(item) == true) {
                                //console.log("Step4")
                                this.onHideSidebar();
                            }
                        }
                        else {
                            // console.log("Step5")
                            if (item.is_link == true) {
                                // console.log("Step6")
                                if (menuItem.children.includes(item) == true) {
                                    //console.log("Step7")
                                    this.onHideSidebar();
                                }
                            }

                        }
                        //console.log("submenuItems ", submenuItems)              
                    })
                }
            })
        }
        item.active = !item.active

        this.setState({
            mainmenu: MENUITEMS
        })


    }

    onCloseSidebar() {
        document.querySelector(".page-body-wrapper").classList.add('sidebar-close');

        //document.querySelector(".sidebaricon").classList.remove('show-n');
        //document.querySelector(".sidebaricon").classList.add('show-m');
        document.querySelector(".siconbg").classList.remove('show-m');
        document.querySelector(".siconbg").classList.add('show-n');
        document.querySelector(".sidemenu").classList.remove('l20n');
        document.querySelector(".sidemenu").classList.add('whgb');
        document.querySelector(".sidebrclose").classList.remove('show-n');
        document.querySelector(".sidebrclose").classList.add('show-m');
        //console.log(document.querySelector(".sidebrclose").classList);
        var sidebaricon = document.querySelector(".sidebaricon");
        // if(sidebaricon.classList.contains('show-n'))
        // {
        //console.log("Yr")
        document.querySelector(".sidebaricon").classList.remove('show-n');
        document.querySelector(".sidebaricon").classList.add('show-m');
        // }
        // if(sidebaricon.classList.contains('show-m'))
        // {
        //     document.querySelector(".sidebaricon").classList.remove('show-m');
        //     document.querySelector(".sidebaricon").classList.add('show-n');    
        // }
        // if(document.querySelector(".sidebrclose").hasClass('show-m'))
        // {            
        //     document.querySelector(".sidebrclose").classList.remove('show-m');
        //     document.querySelector(".sidebrclose").classList.add('show-n');
        // }
    }
    onHideSidebar() {
        document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        document.querySelector(".sidebaricon").classList.remove('show-n');
        document.querySelector(".sidebaricon").classList.add('show-m');
        document.querySelector(".siconbg").classList.remove('show-m');
        document.querySelector(".siconbg").classList.add('show-n');
        document.querySelector(".sidemenu").classList.remove('l20n');
        document.querySelector(".sidemenu").classList.add('whgb');
        document.querySelector(".sidebrclose").classList.remove('show-n');
        document.querySelector(".sidebrclose").classList.add('show-m');
    }
    render() {
        const theme = {
            selectionColor: "#C51162"
        };
        //console.log("IN render, 1 this.state.mainmenu ", this.state.mainmenu);
        let mainmenu = null;
        if (!this.state.mainmenu) {
            // console.log("IN Render, 2 this.state.mainmenu ", this.state.mainmenu);
            let menuslist = localStorage.getItem('usermenus');
            if (menuslist) {
                const MENUITEMS = JSON.parse(menuslist);
                this.setState({
                    mainmenu: MENUITEMS
                })
            }
        }
        // console.log("this.state.mainmenu ", this.state.mainmenu);
        var sideTitlecls = "";
        var sideHeadercls = "";
        if (this.state.mainmenu) {
            mainmenu = this.state.mainmenu.map((menuItem, i) =>
                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                    {(menuItem.group_heading) ?
                        <div className={"sidebar-title " + sideTitlecls}>{menuItem.group_heading}</div>
                        : ''}
                    {(menuItem.is_link === false) ?
                        <a className={"sidebar-header " + sideHeadercls} href="javascript:void(0)" onClick={() => this.setNavActive(menuItem)}>
                            <i className={`${menuItem.icon}`}></i>
                            <span style={{ textTransform: "none" }}>{menuItem.name}</span>
                            <i className="fa fa-angle-right pull-right"></i>
                        </a>
                        : ''}
                    {(menuItem.is_link === true) ?
                        <Link
                            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                            className={`sidebar-header ${menuItem.active ? 'Active ' : ' '}` + sideHeadercls}
                            onClick={() => this.setNavActive(menuItem)}
                        >
                            <i className={`${menuItem.icon}`}></i><span>{menuItem.name}</span>
                            {menuItem.children ?
                                <i className="fa fa-angle-right pull-right"></i> : ''}
                        </Link>
                        : ''}
                    {menuItem.children ?
                        <ul
                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                            style={menuItem.active ? { opacity: 1, paddingLeft: "14px", transition: 'opacity 500ms ease-in' } : {}}
                        >
                            {menuItem.children.map((childrenItem, index) =>
                                <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                    {(childrenItem.is_link === false) ?
                                        <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenItem)} >
                                            <i className="fa fa-angle-right"></i>{childrenItem.name}</a>
                                        : ''}

                                    {(childrenItem.is_link === true) ?
                                        <Link
                                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                            className={childrenItem.active ? 'active' : ''}
                                            onClick={() => this.setNavActive(childrenItem)}
                                        >
                                            <i className="fa fa-angle-right"></i>{childrenItem.name} </Link>
                                        : ''}
                                    {childrenItem.children ?
                                        <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : ''}`}>
                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                    {/* { (childrenSubItem.is_link === true )?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active?'active':''}
                                                    >
                                                        <i className="fa fa-angle-right"></i>{childrenSubItem.name}</Link>
                                                :''} */}

                                                    {(childrenSubItem.is_link === false) ?
                                                        <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenSubItem)} >
                                                            <i className="fa fa-angle-right"></i>{childrenSubItem.name}</a>
                                                        : ''}
                                                    {(childrenSubItem.is_link === true) ?
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                            className={childrenSubItem.active ? 'active' : ''}
                                                            onClick={() => this.setNavActive(childrenSubItem)}
                                                        >
                                                            <i className="fa fa-angle-right"></i>{childrenSubItem.name}</Link>
                                                        : ''}

                                                </li>
                                            )}
                                        </ul>
                                        : ''}
                                </li>
                            )}
                        </ul>
                        : ''}
                </li>
            )
        }
        var sideMenuCls = "";
        if (localStorage.getItem("is_enmovil_transporter") == 1) {
            var sideMenuCls = "sideMenuCls";
        }
        return (
            <div className={"page-sidebar custom-scrollbar page-sidebar-open " + sideMenuCls}>

                <ul className="sidebar-menu">
                    {mainmenu}
                </ul>
                <a href="javascript:void(0)" className="sidebar-blueicon" onClick={this.onCloseSidebar.bind(this)}>
                    <span id="ssinnerOpen" className={"show-m"} onClick={this.onCloseSidebar.bind(this)}></span>
                    <img src={require('../../../assets/icons/SIDE-NAV.png')} className="sidebaropen-icon" onClick={this.onCloseSidebar.bind(this)} />
                </a>
            </div>
        );
    }
}

export default Sidebar