import React, { Component } from 'react';

// Import authService

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
const decode = decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr")
var user = ''
try {
    user = JSON.parse(decode(localStorage.getItem("k")))
}
catch (err) {

}
class UserMenu extends Component {

    logOut(e) {
        localStorage.clear()
        window.location.href = '/'
    }


    render() {

        return (
            <li className="onhover-dropdown">
                <div className="media  align-items-center">
                    <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/user.png")} alt="header-user" />
                    <div className="media-body">
                        <h6 className="m-0 txt-dark f-16">
                            {this.props.username}
                            <i className="fa fa-angle-down pull-right ml-2"></i>
                        </h6>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20">
                    <li>
                        <a href="#" style={{ textDecoration: 'none' }}>
                            <i class="icofont icofont-user-male"></i>
                            {user?.firstname ? user?.firstname : "Welcome User"}
                        </a>
                    </li>
                    <li>
                        <a href="/change-password" style={{ textDecoration: 'none' }}>
                            <i className="fa fa-key"></i>
                            Change Password
                        </a>
                    </li>
                    {/* <li>
                        <a href="#">
                            <i className="icon-email"></i>
                            Inbox
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="icon-check-box"></i>
                            Task
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="icon-comments"></i>
                            Chat
                        </a>
                    </li> */}
                    <li>
                        <a href="#" onClick={this.logOut.bind(this)} style={{ textDecoration: 'none' }}>
                            <i className="icon-power-off"></i>
                            Logout
                        </a>
                    </li>
                </ul>
            </li>
        );
    }
}


export default UserMenu