
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
 
const EditSimTracking = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickEditBlock(props);
    };
 
    return (
        <div>
            <button onClick={handleOverspeedClick} style={{padding: "0px", width: "46px", height: "21px"}} className={"custom-btn f12 label label-success "} title="Edit" ><i className="icofont icofont-edit"></i> Edit</button>
         
        </div>
    );
};
 
export default EditSimTracking;