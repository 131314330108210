import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import { fetchGridLayout, getDecoder, getHyphenDDMMMYYYYHHMM, saveGridLayout } from '../common/utils';
import RoutemapTransitAction from './RoutemapTransitAction.js'
import RoutemapenrouteAction from './routemapenroute.js';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from '../../assets/icons/filters.svg'
import redirectURL from "../redirectURL";
import MainCounters from './mainCounters.js'
import ViewMap from './ViewMap';
import TransitDelay from './TransitDelay';
import EnrouteException from './EnrouteException';
import Overspeeding from './Overspeeding';
import NightDriving from './NightDriving';
import LoadingException from './LoadingException';
import UnloadingException from './UnloadingException';
import DrawMap from '../common/drawmap';
import RoutemapAction from './routemapActionComponent.js';
import RouteNightDrivemap from './routenightdrivemap.js';
import NoGPS from './NoGPS.js';
import NoGPSMap from './NoGPSMap.js';
import ForceClose from './forceClose.jsx';
import ViewConsignmentsLegs from './viewConsignmentsLegs.js';
import { useNavigate } from 'react-router-dom';
import GroupIconRenderer from './GroupIconRenderer.js';
import ModelForceClose from './modelForceClose.js';
import Filters from './homePageFilter.jsx';
import DeviationMap from './DeviationMap.jsx';
import DeviationRouteMap from './mapRouteDeviation.jsx';

var map = ''
function Consignments() {

    const user = useMemo(() => getDecoder(), []);
    // const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0, display: "none" });
    // const [style, setStyle] = useState({
    //     transit_delay: "#cccc",
    //     no_gps_data: "#cccc",
    //     enroute_exception: "#cccc",
    //     overspeeding_exception: "#cccc",
    //     nightdriving_exception: "#cccc",
    //     loading_delay_exception: "#cccc",
    //     unloading_delay_exception: "#cccc",
    //     consignment: "#cccc",
    // })
    // const style = {
    //     transit_delay: "#cccc",
    //     no_gps_data: "#cccc",
    //     enroute_exception: "#cccc",
    //     overspeeding_exception: "#cccc",
    //     nightdriving_exception: "#cccc",
    //     loading_delay_exception: "#cccc",
    //     unloading_delay_exception: "#cccc",
    //     consignment: "#cccc",
    // }
    const navigate = useNavigate();
    const [visibleGroupID, setVisibleGroupID] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const gridApi = useRef();
    const gridApiConsignments = useRef();
    const columnApiConsignments = useRef(null);
    const reloadPage = useRef(false);
    var [rowData, setRowData] = useState([])
    var [filterToggle, setFilterToggle] = useState(false)
    var [key, setKey] = useState('')
    const [gridColumnState, setGridColumnState] = useState([]);
    const [overly, setOverlay] = useState("show-n")
    const [loadshow, setLoadShow] = useState("show-n")
    const [forcecloseModal, setForcecloseModal] = useState(false);
    const [reasonForceClose, setReasonForceClose] = useState("");
    const [filterCities, setFilterCities] = useState([]);
    const [filterStates, setFilterStates] = useState([]);
    const [filterTransporters, setFilterTransporters] = useState([]);
    const [searchCity, setSearchCity] = useState({ label: '', value: '' });
    const [searchState, setSearchStates] = useState({ label: '', value: '' });
    const [searchTransporters, setSearchTransporters] = useState({ label: '', value: '' });
    const [searchConsigner, setSearchConsigner] = useState([{ label: 'All', value: 'all' }]);
    const [consignersList, setConsignersList] = useState([]);
    const [searchConsignee, setSearchConsignee] = useState([{ label: 'All', value: 'all' }]);
    const [movementtype, setMovementtype] = useState({ label: '', value: '' });
    const [searchValue, setSeachValue] = useState('');
    const [searchType, setSearchType] = useState({ label: "Vehicle", value: "truck_no" });
    const [startDate, setStartDate] = useState(new Date(moment.parseZone().subtract(10, "days")));
    const [endDate, setEndDate] = useState(new Date(moment.parseZone()));
    const [tabName, setTabName] = useState({ label: "Active", value: "active" });
    const [counter, setCounter] = useState({ label: "Consignments", value: "consignments" });
    var [detailCellRendererParams, setdetailCellRendererParams] = useState(null);
    const [tabOptions, setTabOptions] = useState([
        { label: "Active", value: "active" },
        { label: "Delivered", value: "completed" },
        { label: "All", value: "all" }
    ])
    const [counterOptions, setCounterOptions] = useState([
        { label: "Consignments", value: "consignments" },
        { label: "Transit Delays", value: "transit_delays" },
        { label: "GPS Not Available", value: "no_gps_data" },
        { label: "GPS Data Not Adequate", value: "no_gps_data_lifetime" },
        { label: "Overspeeding", value: "overspeeding" },
        { label: "Night Driving", value: "night_driving" },
        { label: "Stoppages", value: "stoppages" },
        { label: "Accidents", value: "accidents" },
        { label: "Trip Deviations", value: "deviation_identified" },
        { label: "Loading Delay", value: "loading_delay" },
        { label: "Unloading Delay", value: "unloading_delay" }
    ]);

    var filterCardStyle1 = { minHeight: "244px" };
    var filterCardStyle2 = { padding: '10px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    const [rownode, setRownode] = useState([]);
    var [mapDetails, setMapDetails] = useState([]);
    const [activeTrips, setActiveTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        deviation_identified: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    const [completedTrips, setCompletedTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        deviation_identified: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    const [allTrips, setAllTrips] = useState({
        all: [],
        transit_delay: [],
        overspeeding: [],
        night_driving: [],
        stoppages: [],
        accidents: [],
        deviation_identified: [],
        loading_delay: [],
        unloading_delay: [],
        no_gps_data: [],
        no_gps_data_lifetime: []
    })
    const [modalloadData, setModalLoadData] = useState([])
    const [plantData, setPlantData] = useState([])
    const [transporters, setTransporters] = useState([])
    const [dealers, setDealers] = useState([])
    // const [previousData, setPreviousData] = useState([])
    const fileRef = useRef()
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [loader, setLoader] = useState(false)
    const [overly2, setOverly2] = useState("show-n")
    const [bulkUploadModal, setBulkUploadModal] = useState({
        overly: "show-n",
        bulkPrioritySlide: ""
    })
    const [openMap, setOpenMap] = useState({
        sliderRouteTranslate: "",
        showDiv: 'show-n',
        mapinfo: [],
        dealer: "",
        consignment_code: "",
        maptruckno: "",
        geofence_coordinates: [],
        parking_coordinates: [],
        routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
        loadshow: 'show-n',
        sidebarSubHeader: "",
        overly: 'show-m',
        rownode: {},
        leg_no: 0,
        tolls: []
    })
    const [openMap2, setOpenMap2] = useState({
        open: false,
        rownode: []
    })
    const [countersActive, setCountersActive] = useState({
        consignments: 0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        deviation_identified: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const [countersCompleted, setCountersCompleted] = useState({
        consignments: 0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        deviation_identified: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const [countersAll, setCountersAll] = useState({
        consignments: 0,
        transit_delays: 0,
        overspeeding: 0,
        night_driving: 0,
        stoppages: 0,
        accidents: 0,
        deviation_identified: 0,
        loading_delay: 0,
        unloading_delay: 0,
        no_gps_data: 0,
        no_gps_data_lifetime: 0
    })
    const transitdelaycols = [
        {
            headerName: "", field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapTransitAction,
                            params: {
                                onShowTransitRouteMap: onShowTransitRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Vehicle No.", field: "truck_no", width: 120, resizable: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }
            }
        },
        {
            headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Leg End Time", field: "leg_end_eta",
            width: 120, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }
            }
        },
        {
            headerName: "Exp. Leg Distance (KM)",
            field: "leg_expected_distance", width: 140, resizable: true,
            valueGetter: function (params) {
                return Math.round(params.data.leg_expected_distance);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Actual Leg Distance Covered (KM)",
            field: "actual_leg_distance", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
                    return Math.round(params.data.actual_leg_distance);
                }
                else {
                    return 0;
                }
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Exp. Distance from Trip Start(KM)",
            field: "expected_distance_start_to_leg", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
                    return Math.round(params.data.expected_distance_start_to_leg);
                }
                else {
                    return 0;
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }

        },
        {
            headerName: "Actual Distance from Trip Start (KM)",
            field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
            valueGetter: function (params) {
                if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
                    return Math.round(params.data.actual_distance_traveled_start_to_leg);
                }
                else {
                    return 0;
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }


        },
        // {
        //     headerName: "Google Distance from Start (KM)",
        //     field: "actual_start_to_leg_google_distance",
        //     width: 140,
        //     resizable: true,
        //     cellClass: function (params) {
        //         if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
        //             return 'green';
        //         }
        //         else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
        //             return 'lightred';
        //         }
        //         else {
        //             return '';
        //         }

        //     }


        // },
        {
            headerName: "GPS Data Available",
            field: "no_gps_data",
            width: 140,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.no_gps_data == 1) {
                    return "Not Available";
                }
                if (params.data.no_gps_data == 0) {
                    return "Available";
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }


        },


        {
            headerName: "Trip Completed",
            field: "trip_completed",
            width: 140,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.trip_completed == 1) {
                    return "Yes";
                }
                if (params.data.trip_completed == 2) {
                    return "";
                }
                if (params.data.trip_completed == 0) {
                    return "";
                }

            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        }
    ]
    const stoppagescols = [
        {
            headerName: "",
            field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapenrouteAction,
                            params: {
                                onShowEnrouteRouteMap: onShowEnrouteRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true,
        },
        {
            headerName: "Break Start",
            field: "stoppage_start_time",
            width: 150,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.stoppage_start_time != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.stoppage_start_time)
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Break End",
            field: "reset_datetime",
            width: 150,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.reset_datetime != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.reset_datetime)
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Exception Closed On",
            field: "modified_date",
            width: 220,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.exception_closed == 1) {

                    if (params.data.modified_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Break Time",
            field: "elaped_time_from_last_moved_seconds",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.exception_closed == 1) {
                    var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
                    if (typeof params.data.reset_datetime == 'undefined') {
                        var edate = moment.parseZone().format("x");
                    }
                    else {
                        var edate = moment.parseZone(params.data.reset_datetime).format("x");
                    }
                    var diff = (edate - sdate) / 1000;
                    return secondsToString(diff);
                }
                else {
                    return '';
                }
            }
        }
    ]
    const overspeedcols = [
        {
            headerName: "", field: "_id",
            width: 50,
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RoutemapAction,
                            params: {
                                onShowRouteMap: onShowRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            resizable: true
        },
        { headerName: "Vehicle No.", field: "truck_no", filter: true, resizable: true },
        { headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
        {
            headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
            }
        },
    ]
    const nightdrivingcols = [
        {
            headerName: "", field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: RouteNightDrivemap,
                            params: {
                                onShowNightDriveRouteMap: onShowNightDriveRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true
        },
        { headerName: "Vehicle No.", field: "truck_no", width: 122, resizable: true },
        {
            headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
            }
        },
        {
            headerName: "Traveled Until", field: "recent_instance", resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.recent_instance);
            }
        },
        {
            headerName: "Distance Traveled (km)", field: "distance_from_first_instance", width: 180,
            resizable: true,
            valueGetter: function (params) {
                return Math.round(params.data.distance_from_first_instance)
            }
        }
    ]
    const deviationlogscols = [
        {
            headerName: "", field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: DeviationMap,
                            params: {
                                onShowDeviationRouteMap: onShowDeviationRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true
        },
        { headerName: "Vehicle No.", field: "truck_no", width: 122, resizable: true },
        {
            headerName: "Deviation On", field: "deviation_timestamp", width: 180, resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.deviation_timestamp);
            }
        },
        {
            headerName: "Deviation Address", field: "deviation_address", resizable: true
        },
        {
            headerName: "Lattitude", field: "deviation_lat", resizable: true
        },
        {
            headerName: "Longtitude", field: "deviation_lng", resizable: true
        }
    ]
    const unloadingcols = [
        // {
        //     headerName: "Consignment Code",
        //     field: "consignment_code",
        //     width: 140,
        //     resizable: true
        // },
        {
            headerName: "From Date",
            field: "from_date",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                // console.log("child params ", params)
                if (params.data.from_date != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.from_date);
                }
                else {
                    return "N/A"
                }

            }
        },
        {
            headerName: "To Date",
            field: "to_date",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                if (params.data.to_date != undefined) {
                    return getHyphenDDMMMYYYYHHMM(params.data.to_date);
                }
                else {
                    return "N/A"
                }
            }
        },
        {
            headerName: "Unloading Time",
            field: "unloading_time",
            width: 180,
        }
    ]
    const defaultColDef = {
        sortable: true,
        filter: true,
        autoSizeColumns: true,
        resizable: true,
        pivot: true,
    }
    let gridLegColumns = [];
    const columnDef = [
        {
            headerName: "Plant Code",
            field: "plant_code",
            width: 120,
            editable: true,
            cellStyle: params => {
                if (params.data.plant_code == undefined || params.data.plant_code == "" || !plantData.some(e => e.consigner_code == params.data.plant_code)) {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "FO Number",
            field: "fo_number",
            editable: true,
            width: 128,
            cellStyle: params => {
                if (params.data.fo_number == undefined || params.data.fo_number == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
                if (params.data.hasOwnProperty("exists") && params.data.exists == 1) {
                    return {
                        "backgroundColor": '#ffcc00'
                    };
                }
            }
        },
        {
            headerName: "Invoice Date",
            field: "invoice_date",
            width: 128,
            editable: true,
            cellStyle: params => {
                if (params.data.invoice_date == undefined || params.data.invoice_date == "" || (!moment(params.data.invoice_date, "DD/MM/YYYY HH:mm", true).isValid())) {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Vehicle No.",
            field: "vehicle_no",
            width: 122,
            editable: true,
            cellStyle: params => {
                if (params.data.vehicle_no == undefined || params.data.vehicle_no == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Vehicle Type",
            field: "vehicle_type",
            editable: true,
            width: 128,
        },
        {
            headerName: "Driver Name",
            field: "driver_name",
            width: 128,
            editable: true,
            cellStyle: params => {
                if (params.data.driver_name == undefined || params.data.driver_name == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Driver Phone No.",
            field: "driver_number",
            width: 128,
            editable: true,
            cellStyle: params => {
                if (params.data.driver_number == undefined || params.data.driver_number == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
                else if (!(/^[6-9]\d{9}$/.test(params.data.driver_number))) {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            editable: true,
            width: 158,
            cellStyle: params => {
                if (params.data.transporter_code == undefined || params.data.transporter_code == "" || !transporters.some(e => e.transporter_code == params.data.transporter_code)) {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            editable: true
        },
        {
            headerName: "Dealer Code",
            field: "dealer_code",
            editable: true,
            cellStyle: params => {
                if (params.data.dealer_code == undefined || params.data.dealer_code == "" || !dealers.some(e => e.consignee_code == params.data.dealer_code)) {

                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Dealer Name",
            field: "dealer_name",
            editable: true
        },
        {
            headerName: "Dealer City",
            field: "dealer_city",
            editable: true
        },
        // {
        //     headerName: "Quantity",
        //     field: "quantity",
        //     editable: true,
        //     cellStyle: params => {
        //         if (params.data.quantity == undefined || params.data.quantity == "" || isNaN(params.data.quantity)) {
        //             return {
        //                 "backgroundColor": '#c85f62'
        //             };
        //         }
        //     }
        // },
        // {
        //     headerName: "Quantity Unit",
        //     field: "quantity_unit",
        //     editable: true
        // },
        // {
        //     headerName: "Gross Weight (VU)",
        //     field: "gross_weight",
        //     editable: true
        // },
        // {
        //     headerName: "Gross Volume (VU)",
        //     field: "gross_volume",
        //     editable: true
        // },
        // {
        //     headerName: "Execution Date",
        //     field: "execution_date",
        //     editable: true,
        //     cellStyle: params => {
        //         if (params.data.execution_date == undefined || params.data.execution_date == "" || (!moment(params.data.execution_date, "DD/MM/YYYY HH:mm", true).isValid())) {
        //             return {
        //                 "backgroundColor": '#c85f62'
        //             };
        //         }
        //     }
        // },
        {
            headerName: "Expected Delivery Date",
            field: "expected_date",
            editable: true,
            cellStyle: params => {
                if (params.data.expected_date == undefined || params.data.expected_date == "" || (!moment(params.data.expected_date, "DD/MM/YYYY HH:mm", true).isValid())) {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        // {
        //     headerName: "Expected Return To Plant Date",
        //     field: "expected_return_to_plant_date",
        //     editable: true,
        //     cellStyle: params => {
        //         if (params.data.expected_return_to_plant_date == undefined || params.data.expected_return_to_plant_date == "" || (!moment(params.data.expected_return_to_plant_date, "DD/MM/YYYY HH:mm", true).isValid())) {
        //             return {
        //                 "backgroundColor": '#c85f62'
        //             };
        //         }
        //     }
        // }
    ]
    const nogpscols = [
        {
            headerName: "",
            field: "_id",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: NoGPSMap,
                            params: {
                                onShowNoGPSRouteMap: onShowNoGPSRouteMap
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            width: 50,
            suppressSizeToFit: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Vehicle No.", field: "truck_no", width: 122, resizable: true,
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Last Data Receieved",
            field: "last_packet_datetime",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.last_packet_datetime);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        },
        {
            headerName: "Data Received on",
            field: "packet_received_on",
            width: 180,
            resizable: true,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.packet_received_on);
            },
            cellClass: function (params) {
                if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                    return 'green';
                }
                else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                    return 'lightred';
                }
                else {
                    return '';
                }

            }
        }
    ]
    const columnDefs2 = [
        {
            headerName: "",
            field: "_id",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ViewMap,
                            params: {
                                onClickShowRoute: onClickShowRouteLegs
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        // {
        //     headerName: "",
        //     field: "transit_delay",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: TransitDelay,
        //                     params: {
        //                         onShowTransitData: onShowTransitData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "no_gps_data",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: NoGPS,
        //                     params: {
        //                         onShowNoGPSData: onShowNoGPSData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "enroute_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: EnrouteException,
        //                     params: {
        //                         onShowStoppagesData: onShowStoppagesData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "overspeeding_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: Overspeeding,
        //                     params: {
        //                         onShowOverspeedingData: onShowOverspeedingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "nightdriving_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: NightDriving,
        //                     params: {
        //                         onShowNightDrivingData: onShowNightDrivingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "loading_delay_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: LoadingException,
        //                     params: {
        //                         onShowLoadingData: onShowLoadingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        // {
        //     headerName: "",
        //     field: "unloading_delay_exception",
        //     width: 50,
        //     pinned: "left",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: UnloadingException,
        //                     params: {
        //                         onShowUnloadingData: onShowUnloadingData
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // },
        {
            headerName: "FO Number",
            field: "fo_number",
            width: 128,
        },
        {
            headerName: "Status",
            field: "status",
            width: 98,
            valueGetter: function (params) {
                if (params.data.status == 1) {
                    if (params.data.gate_in_time == undefined || params.data.gate_in_time == null || params.data.gate_in_time == "")
                        return "Consignment Created"
                    else
                        return "Inside Plant";
                }
                else if (params.data.status == 2) {
                    return "InTransit";
                }
                else if (params.data.status == 3) {
                    return "Near Dealer";
                }
                else if (params.data.status == 4) {
                    return "Reached Dealer";
                }
                else if (params.data.status == 5) {
                    return "Left Dealer";
                }
                else if (params.data.status == 6) {
                    return "Force Closed";
                }
                else if (params.data.status == 7) {
                    return "Proximity Trip Closure";
                }
                else if (params.data.status == 8) {
                    return "Cancelled";
                }
                else if (params.data.status == 9) {
                    return "Trip Closed";
                }
                else {
                    return "N/A";
                }
            }
        },
        {
            headerName: "Original ETA",
            field: "expected_trip_end",
            valueGetter: function (params) {
                if (params.data.expected_trip_end != undefined && params.data.expected_trip_end != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
                }
            }
        },
        {
            headerName: "Revised ETA",
            field: "revised_trip_end",
            valueGetter: function (params) {
                if (params.data.revised_trip_end != undefined && params.data.revised_trip_end != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
                }
            },
            cellStyle: function (params) {
                let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
                let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
                console.log(revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'), "hourDifference");
                try {
                    let hourDifference = 0;
                    if (params.data.revised_trip_end && params.data.expected_trip_end) {
                        let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
                        let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
                        hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
                    } else {
                    }
                    if (hourDifference >= 1) {
                        return { backgroundColor: "#ff90a3" };
                    }
                    if (hourDifference <= -1) {
                        return { backgroundColor: "#00dea7" };
                    }
                    else {
                        return ''
                    }
                } catch (error) {
                }
            }
        },
        {
            headerName: "Plant Code",
            field: "consigner_code",
            width: 120,
        },
        // {
        //     headerName: "Consignment Code",
        //     field: "consignment_code"
        // },
        // {
        //     headerName: "Quantity",
        //     field: "quantity"
        // },
        // {
        //     headerName: "Quantity Unit",
        //     field: "quantity_unit"
        // },
        // {
        //     headerName: "Gross Weight (VU)",
        //     field: "gross_weight"
        // },
        // {
        //     headerName: "Gross Volume (VU)",
        //     field: "gross_volume"
        // },
        {
            headerName: "Invoice Date",
            field: "invoice_time",
            width: 128,
            valueGetter: function (params) {
                if (params.data.invoice_time != undefined && params.data.invoice_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                }
            }
        },
        {
            headerName: "Vehicle No.",
            field: "truck_no",
            width: 122,
        },
        {
            headerName: "Vehicle Type",
            field: "truck_type",
            width: 128,
        },
        {
            headerName: "Gate In Time",
            field: "gate_in_time",
            width: 130,
            valueGetter: function (params) {
                if (params.data.gate_in_time != undefined && params.data.gate_in_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                }
            }
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width: 144,
            valueGetter: function (params) {
                if (params.data.gate_out_time != undefined && params.data.gate_out_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
                }
            }
        },
        {
            headerName: "Reached Dealer",
            field: "recent_dealer_reported",
            width: 144,
            valueGetter: function (params) {
                if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
                }
            }
        },
        {
            headerName: "Left Dealer",
            field: "left_dealer_marked",
            width: 144,
            valueGetter: function (params) {
                if (params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
                }
            }
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width: 158,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name"
        },
        // {
        //     headerName: "Consigner Code",
        //     field: "consigner_code"
        // },
        {
            headerName: "Consignee Code",
            field: "consignee_code"
        },
        {
            headerName: "Consignee Name",
            field: "consignee_name"
        },
        // {
        //     headerName: "Consignee Zone",
        //     field: "consignee_zone"
        // },
        // {
        //     headerName: "Consignee Area",
        //     field: "consignee_area"
        // },
        {
            headerName: "Consignee City",
            field: "consignee_city"
        },
        {
            headerName: "Consignee State",
            field: "consignee_state"
        },
        {
            headerName: "Dealer Distance from Plant",
            field: "dealer_distance_from_plant"
        },
        // {
        //     headerName: "Execution Date",
        //     field: "execution_date",
        //     valueGetter: function (params) {
        //         if (params.data.execution_date != undefined && params.data.execution_date != '') {
        //             return getHyphenDDMMMYYYYHHMM(params.data.execution_date)
        //         }
        //     }
        // },
        {
            headerName: "Expected Delivery Date",
            field: "expected_eta",
            valueGetter: function (params) {
                if (params.data.expected_eta != undefined && params.data.expected_eta != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.expected_eta)
                }
            }
        },
        // {
        //     headerName: "Expected Return To Plant Date",
        //     field: "expected_return_to_plant_date",
        //     valueGetter: function (params) {
        //         if (params.data.expected_return_to_plant_date != undefined && params.data.expected_return_to_plant_date != '') {
        //             return getHyphenDDMMMYYYYHHMM(params.data.expected_return_to_plant_date)
        //         }
        //     }
        // },
        {
            headerName: "Created Date",
            field: "created_date",
            valueGetter: function (params) {
                if (params.data.created_date != undefined && params.data.created_date != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.created_date)
                }
            }
        },
        // {
        //     headerName: "Force Close",
        //     field: "force_close",
        //     cellRendererSelector: function (param) {
        //         try {
        //             if (param.data._id != null && param.data._id != undefined) {
        //                 return {
        //                     component: ForceClose,
        //                     params: {
        //                         onShowForceCloseModal: onShowForceCloseModal
        //                     }
        //                 }
        //             }
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     },
        //     cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        // }
    ];
    const columnDefs3 = [
        {
            headerName: "",
            field: "",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ViewMap,
                            params: {
                                onClickShowRoute: onClickShowRoute
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        {
            headerName: "",
            field: "",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: ViewConsignmentsLegs,
                            params: {
                                onClickShowConsignmentsLegsData: onClickShowConsignmentsLegsData
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        {
            headerName: "",
            field: "kpi_configrations",
            width: 50,
            pinned: "left",
            cellRendererSelector: function (param) {
                try {
                    if (param.data._id != null && param.data._id != undefined) {
                        return {
                            component: GroupIconRenderer,
                            params: {
                                onShowTransitData: onShowTransitData,
                                onShowNoGPSData: onShowNoGPSData,
                                onShowStoppagesData: onShowStoppagesData,
                                onShowOverspeedingData: onShowOverspeedingData,
                                onShowNightDrivingData: onShowNightDrivingData,
                                onShowLoadingData: onShowLoadingData,
                                onShowUnloadingData: onShowUnloadingData,
                                onShowTripDeviation: onShowTripDeviation,
                                setVisibleGroupID: setVisibleGroupID,
                                setTooltipPosition: setTooltipPosition,
                                visibleGroupID: visibleGroupID,
                                tooltipPosition: tooltipPosition
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
        {
            headerName: "Plant Code",
            field: "consigner_code",
            width: 120
        },
        {
            headerName: "FO Number",
            field: "fo_number",
            width: 128,
        },
        {
            headerName: "Status",
            field: "status",
            width: 98,
            valueGetter: function (params) {
                if (params.data.status == 1) {
                    if (params.data.gate_in_time == undefined || params.data.gate_in_time == null || params.data.gate_in_time == "")
                        return "Consignment Created"
                    else
                        return "Inside Plant";
                }
                else if (params.data.status == 2) {
                    return "InTransit";
                }
                else if (params.data.status == 3) {
                    return "Near Dealer";
                }
                else if (params.data.status == 4) {
                    return "Reached Dealer";
                }
                else if (params.data.status == 5) {
                    return "Left Dealer";
                }
                else if (params.data.status == 6) {
                    return "Force Closed";
                }
                else if (params.data.status == 7) {
                    return "Proximity Trip Closure";
                }
                else if (params.data.status == 8) {
                    return "Cancelled";
                }
                else if (params.data.status == 9) {
                    return "Trip Closed";
                }
                else {
                    return "N/A";
                }
            }
        },
        // {
        //     headerName: "Original ETA",
        //     field: "expected_trip_end",
        //     valueGetter: function (params) {
        //         if (params.data.expected_trip_end != undefined && params.data.expected_trip_end != '') {
        //             return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
        //         }
        //     }
        // },
        // {
        //     headerName: "Revised ETA",
        //     field: "revised_trip_end",
        //     valueGetter: function (params) {
        //         if (params.data.revised_trip_end != undefined && params.data.revised_trip_end != '') {
        //             return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
        //         }
        //     },
        //     cellStyle: function (params) {
        //         try {
        //             let hourDifference = 0;
        //             if (params.data.revised_trip_end && params.data.expected_trip_end) {
        //                 let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
        //                 let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
        //                 hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
        //             } else {
        //             }
        //             if (hourDifference >= 1) {
        //                 return { backgroundColor: "#ff90a3" };
        //             }
        //             if (hourDifference <= -1) {
        //                 return { backgroundColor: "#00dea7" };
        //             }
        //             else {
        //                 return ''
        //             }
        //         } catch (error) {
        //         }
        //     }
        // },
        {
            headerName: "Invoice Date",
            field: "invoice_time",
            width: 128,
            valueGetter: function (params) {
                if (params.data.invoice_time != undefined && params.data.invoice_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                }
            }
        },
        {
            headerName: "Vehicle No.",
            field: "truck_no",
            width: 122,
        },
        {
            headerName: "Vehicle Type",
            field: "truck_type",
            width: 128,
        },
        {
            headerName: "Vehicle Description",
            field: "truck_description"
        },
        {
            headerName: "Driver Name",
            field: "driver_name",
            width: 128,
        },
        {
            headerName: "Driver Phone No.",
            field: "driver_mobile_no",
            width: 128,
        },
        {
            headerName: "Gate In Time",
            field: "gate_in_time",
            width: 130,
            valueGetter: function (params) {
                if (params.data.gate_in_time != undefined && params.data.gate_in_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                }
            }
        },
        {
            headerName: "Gate Out Time",
            field: "gate_out_time",
            width: 144,
            valueGetter: function (params) {
                if (params.data.gate_out_time != undefined && params.data.gate_out_time != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
                }
            }
        },
        {
            headerName: "Reached Dealer",
            field: "recent_dealer_reported",
            width: 144,
            valueGetter: function (params) {
                if (params.data.recent_dealer_reported != undefined && params.data.recent_dealer_reported != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
                }
            }
        },
        {
            headerName: "Left Dealer",
            field: "left_dealer_marked",
            width: 144,
            valueGetter: function (params) {
                if (params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked)
                }
            }
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width: 158,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name"
        },
        // {
        //     headerName: "Dealer Distance from Plant",
        //     field: "dealer_distance_from_plant"
        // },
        {
            headerName: "Created Date",
            field: "created_date",
            valueGetter: function (params) {
                if (params.data.created_date != undefined && params.data.created_date != '') {
                    return getHyphenDDMMMYYYYHHMM(params.data.created_date)
                }
            }
        },
    ];

    useEffect(() => {
        if (window.location.pathname == "/activeconsignments") {
            setRowData(activeTrips.all)
            setTabName(tabOptions[0])
            if (columnApiConsignments.current)
                columnApiConsignments.current.setColumnsVisible(["force_close"], true);
        }
        else if (window.location.pathname == "/deliveredconsignments") {
            setRowData(completedTrips.all)
            setTabName(tabOptions[1])
            if (columnApiConsignments.current)
                columnApiConsignments.current.setColumnsVisible(["force_close"], false);
        }
        else if (window.location.pathname == "/allconsignments") {
            setRowData(allTrips.all)
            setTabName(tabOptions[2])
            if (columnApiConsignments.current)
                columnApiConsignments.current.setColumnsVisible(["force_close"], true);
        }
    }, [window.location.pathname])

    const onClickHideAll = () => {
        fileRef.current.value = ''
        setOpenMap2({
            open: false,
            rownode: {}
        })
        setOpenMap({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            mapinfo: [],
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            geofence_coordinates: [],
            parking_coordinates: [],
            routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
            loadshow: 'show-n',
            sidebarSubHeader: "",
            overly: 'show-m',
            rownode: {},
            leg_no: 0,
            tolls: []
        })
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        setModalLoadData([])
        setOverlay("show-n")
        setLoadShow("show-n")
    }

    const closeForceCloseModal = (e) => {
        setForcecloseModal(false)
        setReasonForceClose('')
        setRownode([])
    }

    const onShowForceCloseModal = (e) => {
        setForcecloseModal(true)
        setRownode(e)
        setReasonForceClose('')
    }

    const saveGrid = async (e) => {
        let coloumnState = columnApiConsignments.current.getColumnState()
        let temp = await saveGridLayout(user.email, "consignments", coloumnState)
        reloadPage.current = 4;
        setGridColumnState(coloumnState)
        if (temp.data.status == "success") {
            setShow(true)
            setTitle("Grid successfully updated")
            setType("success")
        }
        else {
            setShow(true)
            setTitle("Failed to update grid")
            setType("danger")
        }
    }

    const resetGrid = async (e) => {
        let coloumnState = []
        let temp = await saveGridLayout(user.email, "consignments", coloumnState)
        if (temp.data.status == "success") {
            setShow(true)
            setTitle("Grid successfully reset")
            setType("success")
            columnApiConsignments.current.resetColumnState()
        }
        else {
            setShow(true)
            setTitle("Failed to reset grid")
            setType("danger")
        }
    }

    const handleStartDateChange = (date) => {
        if (endDate && date > endDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        if (startDate && date < startDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setEndDate(date)
        }
    };


    const onShowTransitRouteMap = (rownode) => {
        // var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        // var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        setOverlay("show-m")
        setLoadShow("show-m")
        var fintance = moment.parseZone(rownode.leg_start).format('YYYY-MM-DD HH:mm:ss')
        var eintance = moment.parseZone(rownode.leg_end_eta).format('YYYY-MM-DD HH:mm:ss')
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment",
			user_email: user.email
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                if (response.data.coords.length == 0) {
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    var sdate = '';
                    var edate = '';

                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "FO Number : " + rownode.fo_number,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                    setLoadShow("show-n")
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoadShow("show-n")
            });

    }
    const onShowEnrouteRouteMap = (rownode) => {
        var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        if (rownode.reset_datetime) {
            var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        }
        else {
            var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
        }
        var params = {
            truck_no: rownode.truck_no,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment",
			user_email: user.email
        }
        setOverlay("show-m")
        setLoadShow("show-m")
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                let records = response.data
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                    setOverlay("show-n")
                    setLoadShow("show-n")
                }
                else {
                    var sdate = '';
                    var edate = '';
                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    if (response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined) {
                        sdate = records.route_details.start_time;
                    }
                    if (response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined) {
                        edate = records.route_details.end_time;
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "FO Number : " + rownode.fo_number,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                    setLoadShow("show-n")
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoadShow("show-n")
            });
    }
    const onShowNightDriveRouteMap = (rownode) => {
        var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment",
            consignment_code: rownode?.consignment_codes?.[0],
			user_email: user.email
        }
        setOverlay("show-m")
        setLoadShow("show-m")
        redirectURL.post('/consignments/nightdriveroutes2', params)
            .then((response) => {
                let apiData = {}
                try {
                    apiData = JSON.parse(response.data.apiData)
                } catch (e) { }
                if (apiData.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    setLoadShow("show-n")
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: apiData,
                        consignment_code: "FO Number: " + response.data?.fo_number?.[0]?.["fo_number"],
                        defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
                        maptruckno: rownode.truck_no,
                        routeTruck: {
                            "truck_no": rownode.truck_no, "startTime": apiData['route_details']['start_time'],
                            "endTime": apiData['route_details']['end_time']
                        },
                        loadshow: 'show-n',
                        tolls: apiData.tolls
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const onShowDeviationRouteMap = (rownode) => {
        setOpenMap2({
            open: true,
            rownode: rownode
        })
    }
    const onShowRouteMap = (rownode) => {
        // var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        // var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

        var finstance = moment.parseZone(rownode.first_instance).format('YYYY-MM-DD HH:mm:ss')
        var rinstance = moment.parseZone(rownode.recent_instance).format('YYYY-MM-DD HH:mm:ss')

        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            consignee_code: rownode.consignee_code,
            first_instance: finstance,
            recent_instance: rinstance,
            screen: "consignment",
			user_email: user.email
        }
        redirectURL.post('/consignments/overspeedgpsroutes', params)
            .then((response) => {
                setOpenMap({
                    overly: "show-m",
                    sliderRouteTranslate: "slider-translate-60p",
                    mapinfo: response.data,
                    dealer: rownode.consignee_code,
                    consignment_code: "FO Number : " + rownode.fo_number,
                    maptruckno: rownode.truck_no,
                    routeTruck: { "truck_no": rownode.truck_no, "startTime": finstance, "endTime": rinstance },
                    loadshow: 'show-n',
                    sidebarSubHeader: "Consignment Leg Information",
                    overly: 'show-m',
                    rownode: rownode,
                    leg_no: rownode.leg_no,
                    tolls: response.data.tolls,
                    defTransitCoords: rownode.consignee_coordinates,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const onShowNoGPSRouteMap = (rownode) => {
        var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        eintance = eintance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim()
        if ((rownode.hasOwnProperty("exception_closed") && rownode.exception_closed == 1) || rownode.hasOwnProperty("packet_received_on"))
            eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        else
            eintance = rownode.modified_date.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
            truck_no: rownode.truck_no,
            consignment_code: rownode.consignment_code,
            first_instance: fintance,
            recent_instance: eintance,
            screen: "consignment",
			user_email: user.email
        }
        redirectURL.post('/consignments/nightdriveroutes', params)
            .then((response) => {
                if (response.data.coords.length == 0) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                }
                else {
                    var sdate = '';
                    var edate = '';
                    if (rownode.leg_start != '' && rownode.leg_start != undefined) {
                        sdate = rownode.leg_start;
                    }
                    if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
                        edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
                    }
                    setOpenMap({
                        overly: "show-m",
                        sliderRouteTranslate: "slider-translate-60p",
                        mapinfo: response.data,
                        dealer: rownode.consignee_code,
                        consignment_code: "FO Number : " + rownode.fo_number,
                        maptruckno: rownode.truck_no,
                        routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
                        loadshow: 'show-n',
                        sidebarSubHeader: "Consignment Leg Information",
                        overly: 'show-m',
                        rownode: rownode,
                        leg_no: rownode.leg_no,
                        tolls: response.data.tolls,
                        defTransitCoords: rownode.consignee_coordinates,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    const onClickOpenBulkUploadModal = (e) => {
        setOverlay("show-m")
        setBulkUploadModal({
            overly: "show-m",
            bulkPrioritySlide: "slider-translate-84p"
        })
    }
    const closeBulkUploadModal = () => {
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        fileRef.current.value = ''
        setOverlay("show-n")
        setModalLoadData([])
    }
    const closeMapModal = () => {
        setOverlay("show-n")
        setOpenMap({
            sliderRouteTranslate: "",
            showDiv: 'show-n',
            mapinfo: [],
            dealer: "",
            consignment_code: "",
            maptruckno: "",
            geofence_coordinates: [],
            parking_coordinates: [],
            routeTruck: { "truck_no": '', "startTime": '', "endTime": '' },
            loadshow: 'show-n',
            sidebarSubHeader: "",
            overly: 'show-m',
            rownode: {},
            leg_no: 0,
            tolls: []
        })
        setOpenMap2({
            open: false,
            rownode: {}
        })
        setMapDetails()
    }
    const openMapModal = () => {

    }

    const CounterClick = (ecounter) => {
        if (tabName.value == "active") {
            switch (ecounter) {
                case "consignments":
                    setRowData(activeTrips.all)
                    setCounter(counterOptions[0])
                    break;
                case "transit_delays":
                    setRowData(activeTrips.transit_delay)
                    setCounter(counterOptions[1])
                    break;
                case "overspeeding":
                    setRowData(activeTrips.overspeeding)
                    setCounter(counterOptions[4])
                    break;
                case "night_driving":
                    setRowData(activeTrips.night_driving)
                    setCounter(counterOptions[5])
                    break;
                case "stoppages":
                    setRowData(activeTrips.stoppages)
                    setCounter(counterOptions[6])
                    break;
                case "accidents":
                    setRowData(activeTrips.accidents)
                    setCounter(counterOptions[7])
                    break;
                case "deviation_identified":
                    setRowData(activeTrips.deviation_identified)
                    setCounter(counterOptions[8])
                    break;
                case "loading_delay":
                    setRowData(activeTrips.loading_delay)
                    setCounter(counterOptions[9])
                    break;
                case "unloading_delay":
                    setRowData(activeTrips.unloading_delay)
                    setCounter(counterOptions[10])
                    break;
                case "no_gps_data":
                    setRowData(activeTrips.no_gps_data)
                    setCounter(counterOptions[2])
                    break;
                case "no_gps_data_lifetime":
                    setRowData(activeTrips.no_gps_data_lifetime)
                    setCounter(counterOptions[3])
                    break;
                default:
                    break;
            }
        }
        else if (tabName.value == "completed") {
            switch (ecounter) {
                case "consignments":
                    setRowData(completedTrips.all)
                    setCounter(counterOptions[0])
                    break;
                case "transit_delays":
                    setRowData(completedTrips.transit_delay)
                    setCounter(counterOptions[1])
                    break;
                case "overspeeding":
                    setRowData(completedTrips.overspeeding)
                    setCounter(counterOptions[4])
                    break;
                case "night_driving":
                    setRowData(completedTrips.night_driving)
                    setCounter(counterOptions[5])
                    break;
                case "stoppages":
                    setRowData(completedTrips.stoppages)
                    setCounter(counterOptions[6])
                    break;
                case "accidents":
                    setRowData(completedTrips.accidents)
                    setCounter(counterOptions[7])
                    break;
                case "deviation_identified":
                    setRowData(completedTrips.deviation_identified)
                    setCounter(counterOptions[8])
                    break;
                case "loading_delay":
                    setRowData(completedTrips.loading_delay)
                    setCounter(counterOptions[9])
                    break;
                case "unloading_delay":
                    setRowData(completedTrips.unloading_delay)
                    setCounter(counterOptions[10])
                    break;
                case "no_gps_data":
                    setRowData(completedTrips.no_gps_data)
                    setCounter(counterOptions[2])
                    break;
                case "no_gps_data_lifetime":
                    setRowData(completedTrips.no_gps_data_lifetime)
                    setCounter(counterOptions[3])
                    break;
                default:
                    break;
            }
        }
        else if (tabName.value == "all") {
            switch (ecounter) {
                case "consignments":
                    setRowData(allTrips.all)
                    setCounter(counterOptions[0])
                    break;
                case "transit_delays":
                    setRowData(allTrips.transit_delay)
                    setCounter(counterOptions[1])
                    break;
                case "overspeeding":
                    setRowData(allTrips.overspeeding)
                    setCounter(counterOptions[4])
                    break;
                case "night_driving":
                    setRowData(allTrips.night_driving)
                    setCounter(counterOptions[5])
                    break;
                case "stoppages":
                    setRowData(allTrips.stoppages)
                    setCounter(counterOptions[6])
                    break;
                case "accidents":
                    setRowData(allTrips.accidents)
                    setCounter(counterOptions[7])
                    break;
                case "deviation_identified":
                    setRowData(allTrips.deviation_identified)
                    setCounter(counterOptions[8])
                    break;
                case "loading_delay":
                    setRowData(allTrips.loading_delay)
                    setCounter(counterOptions[9])
                    break;
                case "unloading_delay":
                    setRowData(allTrips.unloading_delay)
                    setCounter(counterOptions[10])
                    break;
                case "no_gps_data":
                    setRowData(allTrips.no_gps_data)
                    setCounter(counterOptions[2])
                    break;
                case "no_gps_data_lifetime":
                    setRowData(allTrips.no_gps_data_lifetime)
                    setCounter(counterOptions[3])
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        setOverlay("show-m")
        setLoadShow("show-m")
        let key = ''
        redirectURL.post('/details')
            .then((response) => {
                if (response.data.status === 'success') {
                    setKey(response.data.output[0].g_key);
                    key = response.data.output[0].g_key
                    loadScript("https://maps.googleapis.com/maps/api/js?key=" + key + "&libraries=places,drawing&callback=initMap");
                    window.initMap = async () => {
                        var lt = 28.4519751;
                        var ln = 77.0310713;
                        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
                            zoom: 13,
                            center: new window.google.maps.LatLng(lt, ln),
                            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        });
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        redirectURL.post('consignments/getConsigner')
            .then((response) => {
                setConsignersList(response.data.records)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        redirectURL.post("/consignments/getConsignmentsNewFilterKeys", { dept_code: "SNDG" })
            .then((resp) => {
                var cities = resp.data.cities;
                var filterCities = [];
                if (cities != undefined) {
                    if (cities.length > 0) {
                        cities.map(function (c) {
                            filterCities.push({
                                label: c,
                                value: c,
                            });
                        });
                    }
                }
                var states = resp.data.states;
                var filterStates = [];
                if (states != undefined) {
                    if (states.length > 0) {
                        states.map(function (s) {
                            filterStates.push({
                                label: s,
                                value: s,
                            });
                        });
                    }
                }
                var clusters = resp.data.clusters;
                var filterClusters = [];
                if (clusters != undefined) {
                    if (clusters.length > 0) {
                        clusters.map(function (cl) {
                            filterClusters.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                }
                var transporters = resp.data.transporters;
                var filterTransporters = [];
                if (transporters != undefined) {
                    if (transporters.length > 0) {
                        transporters.map(function (t) {
                            filterTransporters.push({
                                label: t.transporter_name + " (" + t.transporter_code + ")",
                                value: t.transporter_name,
                            });
                        });
                    }
                }
                setFilterCities(filterCities)
                setFilterStates(filterStates)
                setFilterTransporters(filterTransporters)
            })
        loadConsignmentsData()
        // fetchGridLayout(user.email, "consignments")
        //     .then(res => {
        //         if (res.data.status == "success" && Array.isArray(res.data.data[0].columnState) && res.data.data[0].columnState.length > 0) {
        //             setGridColumnState(res.data.data[0].columnState)
        //         }
        //     })
        //     .catch(err => console.log(err))
    }, [])

    const changeFileHandler = (e) => {
        setLoader(true)
        setOverly2("show-m")
        const formData = new FormData()
        formData.append("fileUpload", e.target.files[0])
        redirectURL.post("/readExcel", formData, {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        })
            .then((response) => {
                if (response.data.status == "success") {
                    if (response.data.data.length > 0) {
                        setModalLoadData(response.data.data)
                        setPlantData(response.data.plantData)
                        setTransporters(response.data.transporters)
                        setDealers(response.data.dealers)
                        // setPreviousData(response.data.previousData)
                        setOverly2("show-n")
                    }
                    else {
                        setModalLoadData([])
                    }
                }
                else {
                    setShow(true)
                    setTitle(response.data.message)
                    setType("danger")
                    setOverly2("show-n")
                    e.target.value = ''
                }
            })
            .catch(error => {
                console.log(error);
            })
        setLoader(false)
    }


    const onClickShowRoute = (rownode) => {
        setLoader(true)
        setOverlay("show-m")
        setLoadShow("show-m")
        let e = rownode.data
        var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
        var edate = '';
        var end_date;
        if (e.status == 1) {
            gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss')
        }
        if (e.status <= 2) {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = getHyphenDDMMMYYYYHHMM(end_date);
        }
        else if ((e.status == 3 || e.status == 4)) {
            if (e.recent_dealer_reported) {
                end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.recent_dealer_reported);
            }
        }
        else if (e.status == 5) {
            if (e.tvp_gate_in) {
                end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
            }
            else if (e.left_dealer_marked) {
                end_date = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss')
                // end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
            }
        }
        else if (e.status == 6) {
            if (e.tvp_gate_in) {
                end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
            } else if (e.force_closure_time) {
                end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        else {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = getHyphenDDMMMYYYYHHMM(end_date);
        }
        if (!end_date) {
            // When end date is unavailable then current date is considered as end date.
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            //console.log("Defining end_date ", end_date);
        }
        var reqData = {
            truck_no: e.truck_no,
            gate_out_time: gateouttime,
            seconddate: end_date,
            group_code: e.group_code,
            screen: "consignment",
            display_elis_base_route: 1,
            user_email: user.email
        }
        if (e.consignee_code !== undefined) reqData.consignee_code = e.consignee_code
        if (e.consignment_code !== undefined) reqData.consignment_code = e.consignment_code
        redirectURL.post('/consignments/maproutesnew', reqData, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((response) => {
                var records = response.data;
                // this.setState({
                //     loadshow: 'show-m'
                // });
                if (response?.data?.status == "failure" || response?.data?.status == "Failure") {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!(response?.data !== null && typeof response?.data === 'object')) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                try {
                    if (records.coords.length == 0) {
                        setShow(true)
                        setTitle("No GPS data available")
                        setType("danger")
                        setOverlay("show-n")
                        setLoadShow("show-n")
                        return
                    }
                    else {
                        if (records.hasOwnProperty("consignee_details"))
                            records["consignee_coordinates"] = records.consignee_details
                        if (records != '') {
                            var sdate = e.gate_out_time;
                            if (e.invoice_time) {
                                var sdate = e.invoice_time;
                            }
                            else {
                                if (e.gate_in_time) {
                                    var sdate = e.gate_in_time;
                                }
                                else {
                                    if (e.gate_out_time) {
                                        var sdate = e.gate_out_time;
                                    }
                                }
                            }
                            var edate = '';
                            if (e.status <= 4) {
                                end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                edate = getHyphenDDMMMYYYYHHMM(end_date);
                            }
                            else if (e.status == 5) {
                                if (e.left_dealer_marked) {
                                    edate = getHyphenDDMMMYYYYHHMM(e.left_dealer_marked);
                                }
                                else {
                                    end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                    edate = getHyphenDDMMMYYYYHHMM(end_date);
                                }
                            }
                            let consignee_city;
                            consignee_city = e.consignee_city
                            var params = {
                                "base_route_id": e.base_route_id,
                                "consigner_code": e.consigner_code,
                                "consignee_city": consignee_city
                            }
                            // redirectURL.post("/consignments/getDeviationRouteBaseRoute",
                            //     params).then((resp) => {
                            var baseRouteCoords = eval(records.base_route);
                            setOpenMap({
                                overly: "show-m",
                                sliderRouteTranslate: "slider-translate-60p",
                                mapinfo: records,
                                dealer: e.consignee_code,
                                consignment_code: "FO Number : " + e.fo_number,
                                maptruckno: e.truck_no,
                                routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                loadshow: 'show-n',
                                sidebarSubHeader: "Consignment Information",
                                rownode: e,
                                leg_no: 0,
                                tolls: records.tolls,
                                baseRouteCoords: baseRouteCoords
                            })
                            // })
                            setLoadShow("show-n")
                            var params =
                            {
                                consignee_code: e.consignee_code,
                                dept_code: e.dept_code
                            }
                            // redirectURL.post('/consignments/geofencedata', params).then((response) => {
                            //     var records1 = response.data.records;
                            //     let parkingGeodata = []
                            //     if (response.data.parkingGeofence[0] !== undefined) parkingGeodata = response.data.parkingGeofence[0]
                            //     var geofence_coords = []
                            //     if (records1.length) {
                            //         geofence_coords = records1[0].geofence_coordinates;
                            //     }
                            //     setOpenMap({
                            //         sliderRouteTranslate: "slider-translate-60p",
                            //         showDiv: 'show-m',
                            //         mapinfo: records,
                            //         dealer: e.consignee_code,
                            //         consignment_code: "Vehicle No. : " + e.truck_no,
                            //         maptruckno: e.truck_no,
                            //         geofence_coordinates: geofence_coords,
                            //         parking_coordinates: parkingGeodata,
                            //         routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                            //         loadshow: 'show-n',
                            //         sidebarSubHeader: "Consignment Information",
                            //         overly: 'show-m',
                            //         rownode: e,
                            //         leg_no: 0,
                            //         tolls: records.tolls
                            //     })
                            //     setLoadShow("show-n")
                            // })
                        }
                    }
                }
                catch (e) {
                    // setShow(true)
                    // setTitle("No GPS data available")
                    // setType("danger")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        setLoader(false)
    }

    const onClickShowRouteLegs = (rownode) => {
        setLoader(true)
        setOverlay("show-m")
        setLoadShow("show-m")
        let e = rownode.data
        var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
        var edate = '';
        var end_date;
        if (e.status == 1) {
            gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss')
        }
        if (e.status <= 2) {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = end_date
        }
        else if ((e.status == 3 || e.status == 4)) {
            if (e.recent_dealer_reported) {
                end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = edate = moment.parseZone(e.recent_dealer_reported).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        else if (e.status == 5) {
            if (e.tvp_gate_in) {
                end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = moment.parseZone(e.tvp_gate_in).format('YYYY-MM-DD HH:mm:ss')
            }
            else if (e.left_dealer_marked) {
                // end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                end_date = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss')
                edate = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        else if (e.status == 6) {
            if (e.tvp_gate_in) {
                end_date = e.tvp_gate_in.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
                edate = getHyphenDDMMMYYYYHHMM(e.tvp_gate_in);
            } else if (e.force_closure_time) {
                end_date = moment.parseZone(e.force_closure_time).format('YYYY-MM-DD HH:mm:ss')
            }
        }
        else {
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
            edate = moment.parseZone(end_date).format('YYYY-MM-DD HH:mm:ss')
        }
        if (!end_date) {
            // When end date is unavailable then current date is considered as end date.
            end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            //console.log("Defining end_date ", end_date);
        }
        var reqData = {
            truck_no: e.truck_no,
            gate_out_time: gateouttime,
            seconddate: end_date,
            screen: "consignment",
            display_elis_base_route: 1
        }
        if (e.consignee_code !== undefined) reqData.consignee_code = e.consignee_code
        if (e.consignment_code !== undefined) reqData.consignment_code = e.consignment_code
        reqData["user_email"] = user.email
        redirectURL.post('/consignments/maproutes', reqData, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((response) => {
                var records = response.data;
                // this.setState({
                //     loadshow: 'show-m'
                // });
                if (response?.data?.status == "failure") {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!(response?.data !== null && typeof response?.data === 'object')) {
                    setShow(true)
                    setTitle("No GPS data available")
                    setType("danger")
                    setOverlay("show-n")
                    setLoadShow("show-n")
                }
                try {
                    if (records.coords.length == 0) {
                        setShow(true)
                        setTitle("No GPS data available")
                        setType("danger")
                        setOverlay("show-n")
                        setLoadShow("show-n")
                    }
                    else {
                        if (records != '') {
                            var sdate = e.gate_out_time;
                            if (e.invoice_time) {
                                var sdate = e.invoice_time;
                            }
                            else {
                                if (e.gate_in_time) {
                                    var sdate = e.gate_in_time;
                                }
                                else {
                                    if (e.gate_out_time) {
                                        var sdate = e.gate_out_time;
                                    }
                                }
                            }
                            if (e.status <= 4) {
                                end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                edate = end_date
                            }
                            else if (e.status == 5) {
                                if (e.left_dealer_marked) {
                                    edate = moment.parseZone(e.left_dealer_marked).format('YYYY-MM-DD HH:mm:ss')
                                }
                                else {
                                    end_date = moment.parseZone().format('DD/MM/YYYY HH:mm:ss')
                                    edate = end_date
                                }
                            }
                            // if (e.deviation_flag == 1) {
                            let consignee_city;
                            consignee_city = e.consignee_city
                            var params = {
                                "base_route_id": e.base_route_id,
                                "consigner_code": e.consigner_code,
                                "consignee_city": consignee_city
                            }
                            // redirectURL.post("/consignments/getDeviationRouteBaseRoute",
                            //     params).then((resp) => {
                            var baseRouteCoords = eval(records.base_route);
                            setOpenMap({
                                overly: "show-m",
                                sliderRouteTranslate: "slider-translate-60p",
                                mapinfo: records,
                                dealer: e.consignee_code,
                                consignment_code: "FO Number : " + e.fo_number,
                                maptruckno: e.truck_no,
                                routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                loadshow: 'show-n',
                                sidebarSubHeader: "Consignment Information",
                                rownode: e,
                                leg_no: 0,
                                tolls: records.tolls,
                                baseRouteCoords: baseRouteCoords
                            })
                            // })
                            setLoadShow("show-n")
                            // }
                            // else {
                            var params =
                            {
                                consignee_code: e.consignee_code,
                                dept_code: e.dept_code
                            }
                            redirectURL.post('/consignments/geofencedata', params).then((response) => {
                                var records1 = response.data.records;
                                let parkingGeodata = []
                                if (response.data.parkingGeofence[0] !== undefined) parkingGeodata = response.data.parkingGeofence[0]
                                var geofence_coords = []
                                if (records1.length) {
                                    geofence_coords = records1[0].geofence_coordinates;
                                }
                                // setOpenMap({
                                //     sliderRouteTranslate: "slider-translate-60p",
                                //     showDiv: 'show-m',
                                //     mapinfo: records,
                                //     dealer: e.consignee_code,
                                //     consignment_code: "Consignment : " + e.consignment_code,
                                //     maptruckno: e.truck_no,
                                //     geofence_coordinates: geofence_coords,
                                //     parking_coordinates: parkingGeodata,
                                //     routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
                                //     loadshow: 'show-n',
                                //     sidebarSubHeader: "Consignment Information",
                                //     overly: 'show-m',
                                //     rownode: e,
                                //     leg_no: 0,
                                //     tolls: records.tolls
                                // })
                                setLoadShow("show-n")
                            })
                            // }
                        }
                    }
                }
                catch (e) {
                    // setShow(true)
                    // setTitle("No GPS data available")
                    // setType("danger")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
        setLoader(false)
    }

    const onShowTransitData = (params) => {
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code,
            consignee_code: params.data.consignee_code
        }
        redirectURL.post('/consignments/transitdelay', query)
            .then(resp => {
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: transitdelaycols,
                            defaultColDef: {
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(resp.data);
                        },
                    })
                }
                else {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: transitdelaycols,
                            defaultColDef: {
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback([]);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowStoppagesData = (params) => {
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code,
            old_truck_no: ''
        }
        redirectURL.post('/consignments/enroutes', query)
            .then(resp => {
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: stoppagescols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(resp.data);
                        },
                    })
                }
                else {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: stoppagescols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback([]);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowOverspeedingData = (params) => {
        let query = {
            truck_no: params.data.truck_no,
            consignment_code: params.data.consignment_code
        }
        redirectURL.post('/consignments/overspeed', query)
            .then(resp => {
                if (resp.data?.length > 0) {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: overspeedcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(resp.data);
                        },
                    })
                }
                else {
                    params.node.setExpanded(!params.node.expanded)
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: overspeedcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback([]);
                        },
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onShowNightDrivingData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: nightdrivingcols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                let nightdriving = []
                redirectURL.post('/consignments/nightdriving', {
                    consignment_code: params.data.consignment_code,
                    gate_out_time: params.data.gate_out_time,
                    truck_no: params.data.truck_no
                })
                    .then(async (response) => {
                        //console.log("Step 1 ",response.data)
                        nightdriving = response.data;
                        params.successCallback(nightdriving);
                        //console.log("Step 2 ",response.data)

                    });
            },
        })
    }

    const onShowLoadingData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: [
                    {
                        headerName: "Gate In Time",
                        field: "gate_in_time",
                        width: 180,
                        resizable: true,
                        valueGetter: function (params) {
                            if (params.data.gate_in_time) {
                                return params.data.gate_in_time;
                            }
                            else {
                                return "N/A"
                            }

                        }
                    },
                    {
                        headerName: "Gate Out Time",
                        field: "gate_out_time",
                        width: 144,
                        resizable: true,
                        valueGetter: function (params) {
                            if (params.data.gate_out_time) {
                                return params.data.gate_out_time;
                            }
                            else {
                                return "N/A"
                            }
                        }
                    },
                    {
                        headerName: "Loading Time",
                        field: "loading_time",
                        width: 180,
                    }
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                if (params.data.gate_in_time != undefined) {
                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                    var gdate1 = new Date(params.data.gate_in_time);
                    var fromDate = g1;
                    var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
                }
                else {
                    var g1 = getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
                    var gdate1 = new Date(params.data.inside_fence);
                    var fromDate = g1;
                    var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
                }
                if (params.data.gate_out_time != undefined) {
                    var g2 = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    var gdate2 = new Date(params.data.gate_out_time);
                    var toField = getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")
                }
                else {
                    var c = new Date();
                    var todaydate = moment(new Date(c)).format("x");
                    var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
                    var gdate2 = new Date(todaydate);
                    var toField = getHyphenDDMMMYYYYHHMM(new Date());
                    var secondsgate2 = todaydate
                }

                var total = secondsgate2 - secondsgate1;
                var diffMs = total;
                var diffDays = Math.ceil(diffMs / 86400000); // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
                let loadingTimeDifference = null;
                var dataarr = []
                dataarr.push({
                    "gate_in_time": fromDate,
                    "gate_out_time": toField,
                    "loading_time": secondsToString(diffMs / 1000)
                })
                params.successCallback(dataarr);
            },
        })
    }

    const onShowUnloadingData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        let unloading = []
        redirectURL.post('/consignments/getUnloadingLegs', {
            consignment_code: params.data.consignment_code,
            truck_no: params.data.truck_no
        })
            .then(async (response) => {
                if (response.data.length > 0) {
                    response.data.map(each => {
                        if (each.locked_dealer_reported == undefined) {
                            var g1 = each.first_dealer_reported;
                            var fromField = g1;
                        }
                        else {
                            var g1 = each.locked_dealer_reported;
                            var fromField = g1;
                        }
                        if (each.left_dealer_marked == undefined) {
                            var todaydate = moment().local().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                            var g2 = todaydate
                            var toField = g2;
                        }
                        else {
                            var g2 = each.left_dealer_marked
                            var toField = g2;
                        }
                        if (fromField == undefined || fromField == null) {
                            unloading.push({
                                "from_date": undefined,
                                "to_date": undefined,
                                "consignment_code": each.consignment_code,
                                "unloading_time": "N/A"
                            })
                        }
                        else {
                            var gdate1 = new Date(fromField);
                            var gdate2 = new Date(toField);
                            var frmd = gdate1.getTime();
                            var tod = gdate2.getTime();
                            var total = tod - frmd;
                            var diffMs = total;
                            let loadingTimeDifference = null;
                            loadingTimeDifference = secondsToString(diffMs / 1000)
                            unloading.push({
                                "from_date": fromField,
                                "to_date": toField,
                                "consignment_code": each.consignment_code,
                                "unloading_time": loadingTimeDifference
                            })
                        }
                    })
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: unloadingcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback(unloading);
                        },
                    })
                }
                else {
                    setdetailCellRendererParams({
                        detailGridOptions: {
                            columnDefs: unloadingcols,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params) => {
                            params.successCallback([]);
                        },
                    })
                }
            })
            .catch(err => console.log(err))
        // setdetailCellRendererParams({
        //     detailGridOptions: {
        //         columnDefs: unloadingcols,
        //         defaultColDef: {
        //             flex: 1,
        //         },
        //     },
        //     getDetailRowData: (params) => {
        //         if (params.data.locked_dealer_reported == undefined) {
        //             var g1 = getHyphenDDMMMYYYYHHMM(params.data.first_dealer_reported);
        //             var fromField = g1;
        //         }
        //         else {
        //             var g1 = getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
        //             var fromField = g1;
        //         }
        //         if (params.data.left_dealer_marked == undefined) {
        //             var c = new Date();
        //             var todaydate = c;
        //             var g2 = getHyphenDDMMMYYYYHHMM(todaydate);
        //             var toField = g2;
        //         }
        //         else {
        //             var g2 = getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
        //             var toField = g2;
        //         }
        //         var fstr = fromField.split("-");
        //         var gdate1 = new Date(fromField);
        //         var gdate2 = new Date(toField);
        //         var frmd = gdate1.getTime();
        //         var tod = gdate2.getTime();
        //         var total = tod - frmd;
        //         var diffMs = total;
        //         var diffDays = Math.floor(diffMs / 86400000); // days
        //         var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        //         var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
        //         var dataarr = []
        //         let loadingTimeDifference = null;
        //         loadingTimeDifference = secondsToString(diffMs / 1000)
        //         dataarr.push({
        //             "from_date": fromField,
        //             "to_date": toField,
        //             "unloading_time": loadingTimeDifference
        //         })
        //         params.successCallback(dataarr);
        //     },
        // })
    }

    const onShowTripDeviation = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: deviationlogscols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                let data = []
                redirectURL.post('/consignments/tripDeviationLogs', {
                    consignment_code: params.data.consignment_code,
                })
                    .then(async (response) => {
                        data = response.data;
                        params.successCallback(data)
                    });
            },
        })
    }

    const onShowNoGPSData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: nogpscols,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                let nogpsrecords = []
                redirectURL.post('/consignments/nogpsdata', {
                    consignment_code: params.data.consignment_code,
                    truck_no: params.data.truck_no
                })
                    .then(async (response) => {
                        nogpsrecords = response.data;
                        params.successCallback(nogpsrecords);

                    });
            },
        })
    }

    const onClickShowConsignmentsLegsData = (params) => {
        params.node.setExpanded(!params.node.expanded)
        let defs = columnDefs2
        if (params.data.consignment_legs.some(e => e.status == 6)) {
            defs.push(
                {
                    headerName: "Force Close Reason",
                    field: "force_closure_reason"
                })
            defs.push(
                {
                    headerName: "Force Close Done By",
                    field: "force_closure_performed_by"
                })
        }
        else if (params.data.consignment_legs.some(e => e.status == 8)) {
            defs.push(
                {
                    headerName: "Remarks",
                    field: "cancel_remarks",
                    valueGetter: function (params) {
                        if (params.data.status == 8) {
                            return "Consignment has been canceled";
                        }
                        else {
                            return "N/A"
                        }
                    }
                })
            defs.push(
                {
                    headerName: "Cancel Date",
                    field: "cancel_date",
                    valueGetter: function (params) {
                        return getHyphenDDMMMYYYYHHMM(params.data.cancel_date);
                    },
                })
        }
        if (!params.data.consignment_legs.every(item => [5, 6, 7, 8].includes(item.status))) {
            defs.push({
                headerName: "Force Close",
                field: "force_close",
                cellRendererSelector: function (param) {
                    try {
                        if (param.data._id != null && param.data._id != undefined) {
                            return {
                                component: ForceClose,
                                params: {
                                    onShowForceCloseModal: onShowForceCloseModal
                                }
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                },
                cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
            })
        }
        setdetailCellRendererParams({
            detailGridOptions: {
                columnDefs: defs,
                defaultColDef: {
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.consignment_legs);
            },
        })
    }
    const fragmentData = (resp) => {
        if (resp.data.status == "success") {
            // setRowData(resp.data.consignments)
            let consignment_legs = resp.data.consignment_legs ?? []
            let active_trips = resp.data.consignments.filter(e => (e.status == 1 || e.status == 2 || e.status == 3 || e.status == 4))
            let completed_trips = resp.data.consignments.filter(e => (e.status == 5 || e.status == 6 || e.status == 7 || e.status == 8 || e.status == 9))
            let active_transit_delay = []
            let active_overspeeding = []
            let active_night_driving = []
            let active_stoppages = []
            let active_accidents = []
            let active_deviation_identified = []
            let active_loadingdelay = []
            let active_unloadingdelay = []
            let active_no_gps_data = []
            let active_no_gps_data_lifetime = []
            let completed_transit_delay = []
            let completed_overspeeding = []
            let completed_night_driving = []
            let completed_stoppages = []
            let completed_accidents = []
            let completed_deviation_identified = []
            let completed_loadingdelay = []
            let completed_unloadingdelay = []
            let completed_no_gps_data = []
            let completed_no_gps_data_lifetime = []
            let transit_delay = []
            let overspeeding = []
            let night_driving = []
            let stoppages = []
            let accidents = []
            let deviation_identified = []
            let loading_delay = []
            let unloading_delay = []
            let no_gps_data = []
            let no_gps_data_lifetime = []
            active_trips.map(each => {
                let temp = consignment_legs.filter(e => e.group_code == each.group_code)
                if (temp)
                    each["consignment_legs"] = temp
                else
                    each["consignment_legs"] = []
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    active_transit_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    active_overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    active_night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    active_stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    active_accidents.push(each)
                }
                if (each.hasOwnProperty("deviation_identified") && each.deviation_identified == 1) {
                    active_deviation_identified.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    active_loadingdelay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    active_unloadingdelay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    active_no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    active_no_gps_data_lifetime.push(each)
                }
            })
            completed_trips.forEach(each => {
                let temp = consignment_legs.filter(e => e.group_code == each.group_code)
                if (temp)
                    each["consignment_legs"] = temp
                else
                    each["consignment_legs"] = []
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    completed_transit_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    completed_overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    completed_night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    completed_stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    completed_accidents.push(each)
                }
                if (each.hasOwnProperty("deviation_identified") && each.deviation_identified == 1) {
                    completed_deviation_identified.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    completed_loadingdelay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    completed_unloadingdelay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    completed_no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    completed_no_gps_data_lifetime.push(each)
                }
            })
            resp.data.consignments.forEach(each => {
                let temp = consignment_legs.filter(e => e.group_code == each.group_code)
                if (temp)
                    each["consignment_legs"] = temp
                else
                    each["consignment_legs"] = []
                if (each.hasOwnProperty("transit_delay") && each.transit_delay > 0) {
                    transit_delay.push(each)
                }
                if (each.hasOwnProperty("overspeeding_exception") && each.overspeeding_exception == 1) {
                    overspeeding.push(each)
                }
                if (each.hasOwnProperty("nightdriving_exception") && each.nightdriving_exception == 1) {
                    night_driving.push(each)
                }
                if (each.hasOwnProperty("enroute_exception") && each.enroute_exception == 1) {
                    stoppages.push(each)
                }
                if (each.hasOwnProperty("accident_exception") && each.accident_exception == 1) {
                    accidents.push(each)
                }
                if (each.hasOwnProperty("deviation_identified") && each.deviation_identified == 1) {
                    deviation_identified.push(each)
                }
                if (each.hasOwnProperty("loading_delay_exception") && each.loading_delay_exception == 1) {
                    loading_delay.push(each)
                }
                if (each.hasOwnProperty("unloading_delay_exception") && each.unloading_delay_exception == 1) {
                    unloading_delay.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 1) {
                    no_gps_data.push(each)
                }
                if (each.hasOwnProperty("no_gps_data") && each.no_gps_data == 0) {
                    no_gps_data_lifetime.push(each)
                }
            })
            setCountersActive({
                consignments: active_trips.length,
                transit_delays: active_transit_delay.length,
                overspeeding: active_overspeeding.length,
                night_driving: active_night_driving.length,
                stoppages: active_stoppages.length,
                accidents: active_accidents.length,
                deviation_identified: active_deviation_identified.length,
                loading_delay: active_loadingdelay.length,
                unloading_delay: active_unloadingdelay.length,
                no_gps_data: active_no_gps_data.length,
                no_gps_data_lifetime: active_no_gps_data_lifetime.length
            })
            setCountersCompleted({
                consignments: completed_trips.length,
                transit_delays: completed_transit_delay.length,
                overspeeding: completed_overspeeding.length,
                night_driving: completed_night_driving.length,
                stoppages: completed_stoppages.length,
                accidents: completed_accidents.length,
                deviation_identified: completed_deviation_identified.length,
                loading_delay: completed_loadingdelay.length,
                unloading_delay: completed_unloadingdelay.length,
                no_gps_data: completed_no_gps_data.length,
                no_gps_data_lifetime: completed_no_gps_data_lifetime.length
            })
            setCountersAll({
                consignments: resp.data.consignments.length,
                transit_delays: transit_delay.length,
                overspeeding: overspeeding.length,
                night_driving: night_driving.length,
                stoppages: stoppages.length,
                accidents: accidents.length,
                deviation_identified: deviation_identified.length,
                loading_delay: loading_delay.length,
                unloading_delay: unloading_delay.length,
                no_gps_data: no_gps_data.length,
                no_gps_data_lifetime: no_gps_data_lifetime.length
            })
            setActiveTrips({
                all: active_trips,
                transit_delay: active_transit_delay,
                overspeeding: active_overspeeding,
                night_driving: active_night_driving,
                stoppages: active_stoppages,
                accidents: active_accidents,
                deviation_identified: active_deviation_identified,
                loading_delay: active_loadingdelay,
                unloading_delay: active_unloadingdelay,
                no_gps_data: active_no_gps_data,
                no_gps_data_lifetime: active_no_gps_data_lifetime
            })
            setCompletedTrips({
                all: completed_trips,
                transit_delay: completed_transit_delay,
                overspeeding: completed_overspeeding,
                night_driving: completed_night_driving,
                stoppages: completed_stoppages,
                accidents: completed_accidents,
                deviation_identified: completed_deviation_identified,
                loading_delay: completed_loadingdelay,
                unloading_delay: completed_unloadingdelay,
                no_gps_data: completed_no_gps_data,
                no_gps_data_lifetime: completed_no_gps_data_lifetime
            })
            setAllTrips({
                all: resp.data.consignments,
                transit_delay: transit_delay,
                overspeeding: overspeeding,
                night_driving: night_driving,
                stoppages: stoppages,
                accidents: accidents,
                deviation_identified: deviation_identified,
                loading_delay: loading_delay,
                unloading_delay: unloading_delay,
                no_gps_data: no_gps_data,
                no_gps_data_lifetime: no_gps_data_lifetime
            })
            if (window.location.pathname == "/activeconsignments") {
                setRowData(active_trips)
                setTabName(tabOptions[0])
            }
            else if (window.location.pathname == "/deliveredconsignments") {
                setRowData(completed_trips)
                setTabName(tabOptions[1])
            }
            else if (window.location.pathname == "/allconsignments") {
                setRowData(resp.data.consignments)
                setTabName(tabOptions[2])
            }
        }
    }

    const loadConsignmentsData = async () => {
        let params = {
            sdate: moment.parseZone(new Date(startDate)).format("YYYY-MM-DD"),
            edate: moment.parseZone(new Date(endDate)).format("YYYY-MM-DD")
        }
        await redirectURL.post("consignments/getConsignments", params)
            .then(resp => {
                fragmentData(resp)
                setOverlay("show-n")
                setLoadShow("show-n")
            })
            .catch(err => console.log(err))
    }
    // const resetSearch1 = (e) => {
    //     setSeachValue('')
    //     setSearchType({ label: "Vehicle", value: "truck_no" })
    //     loadConsignmentsData()
    // }
    // const getTruckConsignments = async (e) => {
    //     e.preventDefault();
    //     let params = {
    //         searchtype: searchType?.value,
    //         searchnumber: searchValue
    //     }
    //     await redirectURL.post("consignments/getConsignments", params)
    //         .then(resp => {
    //             fragmentData(resp)
    //         })
    //         .catch(err => console.log(err))
    // }
    const changeReloadPage = () => {
        reloadPage.current = true
    }
    const saveConsignments = async (e) => {
        e.preventDefault();
        setOverlay("show-m")
        setLoadShow("show-m")
        let error = 0
        if (gridApi.current) {
            gridApi.current.stopEditing();
            const gridRowData = [];
            gridApi.current.forEachNode((node) => {
                // if (node.data.plant_code == "" || node.data.fo_number == "" || node.data.order_number == "" || node.data.invoice_date == "" || node.data.transporter_code == "" || node.data.dealer_code == "" || node.data.quantity == "" || node.data.execution_date == "" || node.data.expected_date == "" || node.data.expected_return_to_plant_date == "" || node.data.plant_code == undefined || node.data.fo_number == undefined || node.data.order_number == undefined || node.data.invoice_date == undefined || node.data.transporter_code == undefined || node.data.dealer_code == undefined || node.data.quantity == undefined || node.data.execution_date == undefined || node.data.expected_date == undefined || node.data.expected_return_to_plant_date == undefined) {
                if (node.data.plant_code == "" || node.data.fo_number == "" || node.data.invoice_date == "" || node.data.transporter_code == "" || node.data.dealer_code == "" || node.data.quantity == "" || node.data.expected_date == "" || node.data.driver_name == "" || node.data.driver_number == "" || node.data.driver_name == undefined || node.data.driver_number == undefined || node.data.plant_code == undefined || node.data.fo_number == undefined || node.data.invoice_date == undefined || node.data.transporter_code == undefined || node.data.dealer_code == undefined || node.data.quantity == undefined || node.data.expected_date == undefined) {
                    setShow(true)
                    setTitle("Please fill the required fields")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!plantData.some(e => e.consigner_code == node.data.plant_code)) {
                    setShow(true)
                    setTitle("Please give a valid Plant Code")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if ((!moment(node.data.invoice_date, "DD/MM/YYYY HH:mm", true).isValid())) {
                    setShow(true)
                    setTitle("Please give a valid Invoice Date (DD/MM/YYYY HH:mm)")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!transporters.some(e => e.transporter_code == node.data.transporter_code)) {
                    setShow(true)
                    setTitle("Please give a valid Transporter Code")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!dealers.some(e => e.consignee_code == node.data.dealer_code)) {
                    setShow(true)
                    setTitle("Please give a valid Dealer Code")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (isNaN(node.data.quantity)) {
                    setShow(true)
                    setTitle("Please give a valid quantity")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                else if (!(/^[6-9]\d{9}$/.test(node.data.driver_number))) {
                    setShow(true)
                    setTitle("Please give a valid Driver Number")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                // else if (!moment(node.data.execution_date, "DD/MM/YYYY HH:mm", true).isValid()) {
                //     setShow(true)
                //     setTitle("Please give a valid Execution Date (DD/MM/YYYY HH:mm)")
                //     setType("danger")
                //     gridApi.current.refreshCells()
                //     error = 1
                //     setOverlay("show-n")
                //     setLoadShow("show-n")
                //     return
                // }
                else if (!moment(node.data.expected_date, "DD/MM/YYYY HH:mm", true).isValid()) {
                    setShow(true)
                    setTitle("Please give a valid Expected Date (DD/MM/YYYY HH:mm)")
                    setType("danger")
                    gridApi.current.refreshCells()
                    error = 1
                    setOverlay("show-n")
                    setLoadShow("show-n")
                    return
                }
                // else if (!moment(node.data.expected_return_to_plant_date, "DD/MM/YYYY HH:mm").isValid()) {
                //     setShow(true)
                //     setTitle("Please give a valid Expected Return to Plant Date (DD/MM/YYYY HH:mm)")
                //     setType("danger")
                //     gridApi.current.refreshCells()
                //     error = 1
                //     setOverlay("show-n")
                //     setLoadShow("show-n")
                //     return
                // }
                // else if (previousData.some(e => ((e.order_number == node.data.order_number) && (e.fo_number == node.data.fo_number)))) {
                //     setShow(true)
                //     setTitle("Order number and FO number combination already exist for previous consignments")
                //     setType("danger")
                //     gridApi.current.refreshCells()
                //     error = 1
                //     setOverlay("show-n")
                //     setLoadShow("show-n")
                //     return
                // }
                else
                    gridRowData.push(node.data)
            })
            if (gridRowData.length > 0 && error === 0) {
                await redirectURL.post("/bulkUploadConsignments", { consignments: gridRowData, email: "" })
                    .then(response => {
                        if (response.data.status == "success") {
                            setShow(true)
                            setTitle(response.data.message)
                            setType("success")
                            setLoader(false)
                            setBulkUploadModal(false)
                            setModalLoadData([])
                            loadConsignmentsData()
                            setOverlay("show-n")
                            setLoadShow("show-n")
                        }
                        else {
                            setShow(true)
                            setTitle(response.data.message)
                            setType("danger")
                            setLoader(false)
                            setOverlay("show-n")
                            setLoadShow("show-n")
                        }
                    })
                    .catch(err => console.log(err))
            }
        }
    }
    const onConfirm = () => {
        setShow(false);
        setLoader(false)
        setTitle('')
        setType('default')
        if (reloadPage.current && reloadPage.current == 4) {
            setTimeout(async () => {
                await columnApiConsignments.current.applyColumnState({
                    state: gridColumnState,
                    applyOrder: true,
                })
            }, 1000);
        }
        else if (reloadPage.current && reloadPage.current != 4) {
            window.location.reload()
        }
    };

    const resetSearch2 = (e) => {
        setSearchCity({ label: '', value: '' })
        setSearchStates({ label: '', value: '' })
        setSearchTransporters({ label: '', value: '' })
        setSearchConsigner([{ label: 'All', value: 'all' }])
        setStartDate(new Date(moment.parseZone().subtract(1, "month")))
        setEndDate(new Date(moment.parseZone()))
        loadConsignmentsData()
    }

    const onGetConsignments = async (event) => {
        let query = {}
        if (searchCity.value != '')
            query["consignee_city"] = searchCity.value
        if (startDate == '' || startDate == "NaN-NaN-NaN") {
            var sDate = "";
        }
        else {
            query["sdate"] = moment.parseZone(new Date(startDate)).format("YYYY-MM-DD")
        }
        if (endDate == '' || startDate == "NaN-NaN-NaN") {
            var eDate = "";
        }
        else {
            query["edate"] = moment.parseZone(new Date(endDate)).format("YYYY-MM-DD")
        }
        if (searchConsigner == null || searchConsigner[0]?.value == '' || searchConsigner[0]?.value == 'all') {
        }
        else {
            query["consigner_code"] = searchConsigner.map(e => e.value)
        }
        if (searchState.value != '') {
            query["consignee_state"] = searchState.value
        }
        if (searchTransporters.value != "") {
            query["transporter_name"] = searchTransporters.value
        }
        await redirectURL.post("consignments/filterConsignments", query)
            .then(resp => {
                fragmentData(resp)
            })
            .catch(err => console.log(err))
    }

    const onGridReady = useCallback((params) => {
        gridApi.current = params.api
    }, []);

    const onGridReadyConsignments = useCallback(async (params) => {
        gridApiConsignments.current = params.api
        columnApiConsignments.current = params.api
        await fetchGridLayout(user.email, "consignments")
            .then(async res => {
                if (res.data.status == "success" && Array.isArray(res.data.data[0].columnState) && res.data.data[0].columnState.length > 0) {
                    setGridColumnState(res.data.data[0].columnState)
                    setTimeout(async () => {
                        await params.api.applyColumnState({
                            state: res.data.data[0].columnState,
                            applyOrder: true,
                        })
                    }, 1500);
                }
            })
            .catch(err => console.log(err))
        if (tabName?.value == "completed")
            params.api.setColumnsVisible(["force_close"], false);
        else
            params.api.setColumnsVisible(["force_close"], true);
    }, []);

    const onCellClicked = (row) => {
        if (row.colDef.field == "kpi_configrations") {
            row.node.setExpanded(false)
            const iconPosition = document.getElementById("rw_" + row.node.rowIndex);
            const rect = iconPosition.getBoundingClientRect();
            setTooltipPosition({
                top: rect.bottom + window.scrollY, // Adjusted for better positioning
                left: rect.left + window.scrollX
            })
            setVisibleGroupID((prevID) => (prevID === row.node.rowIndex ? null : row.node.rowIndex));
        }
    }

    var filterClassName = "form-group col-xl-12 col-lg-12";
    return (
        <div>
            <SweetAlert
                show={show}
                title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                onConfirm={onConfirm}
                confirmBtnBsStyle={type}
                confirmBtnCssClass="sweetButton"
                customClass='sweetWidth'
                type={type}>
            </SweetAlert>
            <Modal
                onHide={closeForceCloseModal}
                dialogClassName="my-modal"
                show={forcecloseModal}
                centered>
                <Modal.Header>
                    <Modal.Title>Force Close</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon btn-outline-primary" data-bs-dismiss="modal" aria-label="Close" onClick={closeForceCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    <ModelForceClose rownode={rownode} setShow={setShow} setTitle={setTitle} setType={setType} changeReloadPage={changeReloadPage} closeForceCloseModal={closeForceCloseModal} user={user} />
                </Modal.Body>
            </Modal>
            <h5 className="mb-15p fbold f16">
                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontWeight: "600" }}>Consignments Information</span>
            </h5>
            <div className="row" style={{ marginBottom: "1%" }}>
                <div className="col-xl-12 col-lg-12">
                    <button
                        // onClick={(e) => { setTabName(tabOptions[0]); setRowData(activeTrips.all); setCounter(counterOptions[0]) }}
                        onClick={(e) => navigate("/activeconsignments")}
                        className={(tabName.value == "active") ? "btn btn-info f14" : "btn tab-btn f14"}
                        style={{
                            display: (tabName.value == "active") ? "inline-flex" : ""
                        }}>
                        Active
                    </button>
                    <button
                        // onClick={(e) => { setTabName(tabOptions[1]); setRowData(completedTrips.all); setCounter(counterOptions[0]) }}
                        onClick={(e) => navigate("/deliveredconsignments")}
                        className={(tabName.value == "completed") ? "btn btn-info f14" : "btn tab-btn f14"}
                        style={{
                            display: (tabName.value == "completed") ? "inline-flex" : ""
                        }}>
                        Delivered
                    </button>
                    <button
                        // onClick={(e) => { setTabName(tabOptions[2]); setRowData(allTrips.all); setCounter(counterOptions[0]) }}
                        onClick={(e) => navigate("/allconsignments")}
                        className={(tabName.value == "all") ? "btn btn-info f14" : "btn tab-btn f14"}
                        style={{
                            display: (tabName.value == "all") ? "inline-flex" : ""
                        }}>
                        All
                    </button>
                    <button
                        title="Bulk Upload"
                        onClick={onClickOpenBulkUploadModal}
                        className={"float-right f14 btn btn-warning mr-2"}
                        type="button">
                        <i className="fa fa-upload"></i>&nbsp;&nbsp;&nbsp;Upload Consignments
                    </button>
                    {/* <button
                        // onClick={this.reloadPageData.bind(this)}
                        className={"btn btn-info"}
                        style={{ float: "right" }}>
                        Reset Filters
                    </button> */}
                    {/* <img className='mx-2' title='Search' onClick={(e) => setFilterToggle(prev => !prev)} src={Filter} style={{ height: "33px", width: "33px", cursor: "pointer", float: "right" }} /> */}
                    <i className='fa fa-search mx-2' title='Search' onClick={(e) => setFilterToggle(prev => !prev)} style={{ cursor: "pointer", float: "right", fontSize: "1rem", padding: "8px" }} />
                </div>
            </div>
            <div className={filterToggle ? "filter-in" : "filter-out"}>
                {
                    filterToggle &&
                    <Filters filterCardStyle1={filterCardStyle1} loadConsignmentsData={loadConsignmentsData}
                        consignersList={consignersList} filterCities={filterCities} setOverlay={setOverlay} setLoadShow={setLoadShow}
                        filterStates={filterStates} filterTransporters={filterTransporters} fragmentData={fragmentData}
                        filterToggle={filterToggle} columnApiConsignments={columnApiConsignments} gridColumnState={gridColumnState} />
                }
            </div>
            {
                <div className={""}>
                    <MainCounters setCounter={CounterClick} data={tabName.value == "active" ? countersActive : (tabName.value == "completed" ? countersCompleted : countersAll)} tabName={tabName} currentCounter={counter} />
                </div>
            }
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{`${tabName?.label} - ${counter?.label}`}</span>
                            </h5>
                        </div>
                        <div className="card-body pt-10px" >
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 mb-10p">
                                    {/* <span className="float-right" style={{ fontSize: "13px", fontWeight: "100", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "22px	" }}></i>&nbsp;Transit Delays|
                                        <i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i>GPS Not Available|
                                        <i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i>GPS Data Not Adequate|
                                        <i className="fa fa-tachometer darkblue" style={{ fontSize: "20px" }}></i>&nbsp;Overspeeding|
                                        <i className="icofont icofont-full-night txt-primary" style={{ fontSize: "22px" }}></i>&nbsp;Night Driving|
                                        <i className="icofont icofont-truck-alt darkorange" style={{ fontSize: "22px" }}></i>&nbsp;Loading Delay|
                                        {
                                            tabName?.value == "completed" || tabName?.value == "all" ?
                                                <>
                                                    <i className="icofont icofont-vehicle-delivery-van mildgreen" style={{ fontSize: "22px" }}></i>&nbsp;Unloading Delay|
                                                </>
                                                :
                                                <></>
                                        }
                                        <i className="icofont icofont-pause maroon" style={{ fontSize: "22px" }}></i>&nbsp;Stoppages|
                                        <i className="icofont icofont-barricade txt-danger" style={{ fontSize: "22px" }}></i>&nbsp;Probable Accident
                                    </span> */}

                                    <span className="float-right" style={{ fontSize: "15px", fontWeight: "400", lineHeight: "1.5" }}>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="fa fa-cubes" style={{ color: "#ff0000", fontSize: "20px" }}></i>&nbsp;&nbsp;KPI Configrations
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "20px" }}></i>&nbsp;&nbsp;Transit Delays
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;GPS Not Available
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "20px" }}></i>&nbsp;GPS Data Not Adequate
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="fa fa-tachometer darkblue" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Overspeeding
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-full-night txt-primary" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Night Driving
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-truck-alt darkorange" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Loading Delay
                                        </button>
                                        {tabName?.value == "completed" || tabName?.value == "all" ?
                                            <button type="button" className="btn btn-default mybtn">
                                                <i className="icofont icofont-vehicle-delivery-van mildgreen" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Unloading Delay
                                            </button>
                                            : ""}
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="icofont icofont-pause maroon" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Stoppages
                                        </button>
                                        <button type="button" className="btn btn-default mybtn">
                                            <i className="fa fa-random purple" style={{ fontSize: "20px" }}></i>&nbsp;&nbsp;Deviated Routes
                                        </button>
                                    </span>

                                </div>

                                <div className={"col-xl-12 mb-10p"}>
                                    <span className="layoutbtns">
                                        <button type="button" title="Reset Grid Layout" name="resetgrid" className="btn btn-info float-right action_btn" style={{ marginLeft: "10px" }} onClick={resetGrid}> <i className="fa fa-reply"></i> </button>
                                        <button type="button" title="Save Grid Layout" name="savegrid" className="btn btn-success float-right action_btn" onClick={saveGrid}> <i className="fa fa-save"></i> </button>
                                    </span>
                                </div>
                            </div>
                            <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                <AgGridReact
                                    columnDefs={columnDefs3}
                                    rowData={rowData}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={50}
                                    onGridReady={onGridReadyConsignments}
                                    enableRangeSelection={true}
                                    enableCellContextMenu={true}
                                    masterDetail={true}
                                    detailCellRendererParams={detailCellRendererParams}
                                    onCellClicked={onCellClicked}
                                    sideBar={{
                                        toolPanels: [
                                            {
                                                id: "columns",
                                                labelDefault: "Columns",
                                                labelKey: "columns",
                                                iconKey: "columns",
                                                toolPanel: "agColumnsToolPanel",
                                                toolPanelParams: {
                                                    suppressPivotMode: true,
                                                    suppressRowGroups: true,
                                                    suppressValues: true,
                                                }
                                            },
                                            {
                                                id: "filters",
                                                labelDefault: "Filters",
                                                labelKey: "filters",
                                                iconKey: "filter",
                                                toolPanel: "agFiltersToolPanel"
                                            }
                                        ]
                                    }}
                                    statusBar={{
                                        statusPanels: [
                                            {
                                                statusPanel: "agTotalAndFilteredRowCountComponent",
                                                align: "left"
                                            },
                                            {
                                                statusPanel: "agTotalRowCountComponent",
                                                align: "center"
                                            },
                                            { statusPanel: "agFilteredRowCountComponent" },
                                            { statusPanel: "agSelectedRowCountComponent" },
                                            { statusPanel: "agAggregationComponent" }
                                        ]
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                openMap.sliderRouteTranslate != '' ?
                    <div className={"slide-r " + (openMap.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                {(openMap.consignment_code)}
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={closeMapModal} >X</span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap
                                    rownode={openMap.rownode}
                                    mapFor={"consignment"}
                                    tolls={openMap.tolls}
                                    googleroutes={openMap.googelRoutes}
                                    truckno={openMap.maptruckno}
                                    dealer={openMap.dealer}
                                    mapinfo={openMap.mapinfo}
                                    deptcode={openMap.deptcode}
                                    defTransitCoords={openMap.defTransitCoords}
                                    baseRouteCoords={openMap.baseRouteCoords}
                                    geofence_coordinates={openMap.geofence_coordinates}
                                    parking_coordinates={openMap.parking_coordinates}
                                />
                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{openMap.sidebarSubHeader}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Vehicle No.</label>
                                                <div>{openMap.routeTruck.truck_no}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>{(!isEmpty(openMap.mapinfo.route_details)) ? getHyphenDDMMMYYYYHHMM(openMap.mapinfo.route_details.start_time) : "-"}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>{(!isEmpty(openMap.mapinfo.route_details)) ? getHyphenDDMMMYYYYHHMM(openMap.mapinfo.route_details.end_time) : "-"}</div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">GPS Distance</label>
                                                <div>{(!isEmpty(openMap.mapinfo.route_details)) ? Math.round(openMap.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    : ""
            }
            {
                openMap2.open ?
                    <DeviationRouteMap openMap={openMap2} closeMapModal={closeMapModal}
                        show={show} title={title} onConfirm={onConfirm} type={type}
                        setShow={setShow} setTitle={setTitle} setType={setType}
                        setLoadShow={setLoadShow} setOverlay={setOverlay} />
                    : ""
            }
            <div className={"slide-r " + (bulkUploadModal.bulkPrioritySlide)} style={{ overflow: "auto" }}>
                <h3 className="subH">Bulk Upload Consignments
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={closeBulkUploadModal} >X</span>
                </h3>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className={"dataLoad " + (overly2)} style={{ opacity: "0.5" }}>
                        <div style={{ position: "fixed", left: "50%", top: '50%' }}>
                            <div class="loader-box">
                                <div class="loader-box">
                                    <div class="rotate dashed colored"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="col-xl-6 col-lg-6 form-group">
                                <label className="">Upload File</label>
                                <input type="file" name="uploadFile" id="bulkUploadCritical" onChange={changeFileHandler} className="form-control" required ref={fileRef} />
                            </div>
                        </form>
                        <div className='col-xl-12 col-lg-12' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="form-group">
                                <a className="btn btn-primary" href={require('../../assets/files/Nestle Autometrics Consignments Format.xlsx')} target="_blank">Sample Template</a>
                            </div>
                            <div className='form-group' style={{ marginRight: "8px" }}>
                                <div><i className="fa fa-circle" style={{ color: "#c85f62" }}></i>&nbsp; Invalid Field Value</div>
                                {/* <div><i className="fa fa-circle" style={{ color: "#ffcc00" }}></i>&nbsp; Order Number and FO Number combination already exists</div> */}
                            </div>
                        </div>
                        <div className="ag-theme-balham mt-2" style={{ height: '58vh', width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDef}
                                rowData={modalloadData}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={50}
                                enableRangeSelection={true}
                                enableCellContextMenu={true}
                                stopEditingWhenGridLosesFocus={true}
                                sideBar={{
                                    toolPanels: [
                                        {
                                            id: "columns",
                                            labelDefault: "Columns",
                                            labelKey: "columns",
                                            iconKey: "columns",
                                            toolPanel: "agColumnsToolPanel",
                                            toolPanelParams: {
                                                suppressPivotMode: true,
                                                suppressRowGroups: true,
                                                suppressValues: true,
                                            }
                                        },
                                        {
                                            id: "filters",
                                            labelDefault: "Filters",
                                            labelKey: "filters",
                                            iconKey: "filter",
                                            toolPanel: "agFiltersToolPanel"
                                        }
                                    ]
                                }}
                                statusBar={{
                                    statusPanels: [
                                        {
                                            statusPanel: "agTotalAndFilteredRowCountComponent",
                                            align: "left"
                                        },
                                        {
                                            statusPanel: "agTotalRowCountComponent",
                                            align: "center"
                                        },
                                        { statusPanel: "agFilteredRowCountComponent" },
                                        { statusPanel: "agSelectedRowCountComponent" },
                                        { statusPanel: "agAggregationComponent" }
                                    ]
                                }} />
                        </div>
                        <br />
                        <div className="form-group">
                            <button type="submit" className="btn btn-success" onClick={saveConsignments}>Submit</button>
                            <button type="button" onClick={closeBulkUploadModal} className="btn btn-default">Cancel</button>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            {/* <div className="tooltip-icons" style={{ top: tooltipPosition.top, left: tooltipPosition.left, display: tooltipPosition.display }}>
                <span title='Transit Delay'>
                    <i className="icofont icofont-clock-time f20" style={{ color: style.transit_delay }}></i>
                </span>
                <span title='No GPS Data'>
                    <i className="icofont icofont-social-google-map f20" style={{ color: style.no_gps_data }}></i>
                </span>
                <span title='Overspeeding'>
                    <i className="fa fa-tachometer f20" style={{ color: style.overspeeding_exception }}></i>
                </span>
                <span title='Nightdriving'>
                    <i className="icofont icofont-full-night f20" style={{ color: style.nightdriving_exception }}></i>
                </span>
                <span title='Stoppages'>
                    <i className="icofont icofont-pause f20" style={{ color: style.enroute_exception }}></i>
                </span>
                <span title='Loading Delay'>
                    <i className="icofont icofont-truck-alt f20" style={{ color: style.loading_delay_exception }}></i>
                </span>
                <span title='Unloading Delay'>
                    <i className="icofont icofont-truck-loaded f20" style={{ color: style.unloading_delay_exception }}></i>
                </span>
            </div> */}
            <div className={"overlay-part " + (overly)} style={{ zIndex: "10" }}
                onClick={onClickHideAll}
            ></div>
            <div className={"dataLoadpage " + (loadshow)}></div>
            <div className={"dataLoadpageimg " + (loadshow)} style={{ top: "21em" }}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
        </div >
    );
}

export default Consignments;

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function secondsToString(seconds) {
    if (isNaN(seconds))
        return "N/A"
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
} 