// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/GroupIconRenderer.css */
.icon-container {
    position: relative;
    display: inline-block;
}

.group-icon {
    cursor: pointer;
}

.tooltip-icons-container {
    position: absolute;
    z-index: 1000;
}

.tooltip-icons {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
    position: absolute;
}

.tooltip-icons span {
    margin: 0 5px; /* Adjust based on your preference */
    cursor: pointer;
    padding-right:10px;
    border-right:1px solid #ccc;
}

.tooltip-icons span:last-child {
border-right:0px;
}
.tooltip-icons span>i {font-size: 13px !important;}`, "",{"version":3,"sources":["webpack://./src/components/tracktrace/GroupIconRenderer.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,2CAA2C;IAC3C,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa,EAAE,oCAAoC;IACnD,eAAe;IACf,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;AACA,gBAAgB;AAChB;AACA,uBAAuB,0BAA0B,CAAC","sourcesContent":["/* src/GroupIconRenderer.css */\n.icon-container {\n    position: relative;\n    display: inline-block;\n}\n\n.group-icon {\n    cursor: pointer;\n}\n\n.tooltip-icons-container {\n    position: absolute;\n    z-index: 1000;\n}\n\n.tooltip-icons {\n    display: flex;\n    flex-direction: row;\n    background-color: white;\n    border: 1px solid #ccc;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    padding: 5px;\n    position: absolute;\n}\n\n.tooltip-icons span {\n    margin: 0 5px; /* Adjust based on your preference */\n    cursor: pointer;\n    padding-right:10px;\n    border-right:1px solid #ccc;\n}\n\n.tooltip-icons span:last-child {\nborder-right:0px;\n}\n.tooltip-icons span>i {font-size: 13px !important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
