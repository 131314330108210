/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Link, IndexLink } from 'react-router-dom';
import LogoHeader from '../../../assets/icons/nestleheader.svg'
import WeatherMapComponent from "../../layouts/weathermap";
// Import custom components
import Search from './search.component';
import UserMenu from './user-menu.component';
import Notification from './notification.component';
import Language from './language.component';
import $ from "jquery";
import redirectURL from "../../redirectURL";
class Header extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            sidebar: false,
            topbar: false,
            navMenus: false,
            outerb: 'show-m',
            outerb1: 'show-n',
            overly: "show-n",
            sliderRouteTranslate: "",
            sliderTranslate: "",
            pbodyc: 'pbdy',
            tpmenu: require('../../../assets/icons/TOP-NAV.png'),
            sTop: 'tp40',
            mTop: 'tp10',
            showAlert: "show-m",
            tmenu: '',
            searchbar: false,
            loginType: "",
            userroles: "",
            favorites: [],
            departmentData: [],
            user: {},
            detailedWeatherMap: 0
        }
    }

    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    openCloseSidebar = () => {
        if (this.state.sidebar) {
            this.setState({ sidebar: false })
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        } else {
            this.setState({ sidebar: true })
            document.querySelector(".page-body-wrapper").classList.remove('sidebar-close');
        }
    }

    toggle() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }


    openCloseTopbar = () => {
        if (this.state.topbar) {
            this.setState({
                topbar: false,
                tpmenu: require('../../../assets/icons/TOP-NAV.png'),
                outerb: "show-m",
                outerb1: "show-n",
                sTop: "40px",
                tmenu: '',
                mTop: 'tp10',

            })
            document.querySelector(".page-body").classList.remove('pbdy');
            document.querySelector(".page-main-header").classList.remove('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp0");
            document.querySelector('.page-sidebar').classList.add("tp40");
        } else {
            this.setState({
                topbar: true,
                tpmenu: require('../../../assets/icons/topnav-blue.png'),
                outerb: "show-n",
                outerb1: "show-m",
                sTop: "0px",
                tmenu: '',
                mTop: 'tp40 r42',
            })
            document.querySelector(".page-body").classList.add('pbdy');
            document.querySelector(".page-main-header").classList.add('topbar-close');
            document.querySelector('.page-sidebar').classList.remove("tp40");
            document.querySelector('.page-sidebar').classList.add("tp0");
        }
    }


    componentWillMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            this.setState({ sidebar: false })
        }
    }

    componentDidMount() {
        //console.log("Header Sidebar :", this.state.sidebar);
        var contentwidth = window.innerWidth;
        if ((contentwidth) <= 991) {
            document.querySelector(".page-body-wrapper").classList.add('sidebar-close');
        }
        this.setState({
            loginType: localStorage.getItem("user_type"),
            userroles: localStorage.getItem("roles"),
        })
        var loading_port_code = localStorage.getItem("loading_port_code");
        if (loading_port_code != undefined) {
            $("#loadingPlant").val(loading_port_code)
        }
        console.log(localStorage.getItem('dept_code'), "deptCode")
        console.log(localStorage.getItem('is_enmovil_transporter'), "is_enmovil_transporter")
    }
    changeHandler = (e) => {
        this.setState({
            sliderRouteTranslate: "slider-translate-60p",
            overly: "show-m",
        })
    }
    onChangeLoadingPlant = (e) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var selectedPlant = $("#loadingPlant").val();
        var params = {
            selectedPlant: selectedPlant,
        }
        redirectURL.post("/lmconsignees/getLoadingPlantDetails", params)
            .then((response) => {
                var res = response.data;
                this.setState({
                    loadshow: 'show-n',
                    overly: 'show-n',
                })
                localStorage.setItem('loading_port_code', selectedPlant);
                localStorage.setItem('comp_code', res[0].comp_code);
                window.location.reload();
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    onClickHideAll = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            sliderTranslate: "",
            overly: "show-n"
        })
    }
    closeMenuAlert() {
        this.setState({
            showAlert: "show-n"
        })
    }
    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate: "",
            overly: "show-n"
        })
    }
    onShowUploadDiv = () => {
        //console.log("Click DCT Users Button")
        var requestParam = {
            userType: "DCTUSER"
        }
        redirectURL.post("/consignments/getUsersByUserType", requestParam)
            .then(async (response) => {
                var departments = response.data;
                var dctUsers = departments
                //console.log("records",records)
                if (departments.length > 0) {
                    redirectURL.post("/consignments/getUserLoginDetails", { "departments": dctUsers }).then((response) => {
                        console.log("response", response.data)
                        this.setState({
                            departmentData: response.data,
                            loadshow: 'show-n',
                            overly: 'show-n',
                        })
                    }).catch((e) => {
                        console.log(e);
                    })
                }
                else {
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
                this.setState({
                    uploadDivWidth: '30%',
                    sliderTranslate: "slider-translate",
                    showDiv: 'show-m',
                });
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        var sort = [
            {
                colId: 'username',
                sort: 'asc',
            },
        ];
        this.gridApi.setSortModel(sort);
    };

    render() {
        var columnwithDefsForDCT = [
            {
                headerName: "User Name",
                field: "username",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: "Email",
                field: "email",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Phone",
                field: "phone",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Online/Offline",
                field: "loginStatus",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                cellRenderer: function (params) {
                    if (params.data.loginStatus == 1) {
                        return '<i class="fa fa-circle" style="color:green" </i>';
                    }
                    else {
                        return '<i class="fa fa-circle" style="color:yellow" </i>';
                    }
                },
            }
        ]
        if (localStorage.getItem('dept_code') == 'SNDG' || localStorage.getItem('dept_code') == '"SNDG"') {
            var dept = ''
        }
        var noPadStyle = {};
        return (
            <div className="page-main-header cus-main-header" id="pheader">
                <div className="main-header-left">
                    <div className="logo-wrapper">
                        <a href={"/"}>
                            <img src={require('../../../assets/images/new-logo.png')} alt="" style={{ height: "40px" }} />
                        </a>
                    </div>
                </div>
                <div className="main-header-right row" style={noPadStyle}>
                    <div className="mobile-sidebar">
                        {/*<div className="media-body text-right switch-sm">
                            <label className="switch">
                                <input type="checkbox" id="sidebar-toggle" defaultChecked={this.state.sidebar} onClick={this.openCloseSidebar}  />
                                <span className="switch-state"></span>
                            </label>
                        </div>
                        */}
                    </div>
                    <div className="nav-right col">
                        <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')} style={{ marginBottom: "0px" }}>
                            {/* <li style={{ fontSize: "20px" }}>
                                {dept}
                            </li> */}
                            <li style={{ padding: "0px" }}>
                                <a className="text-dark">
                                    <img className="align-self-center pull-right mr-2" src={LogoHeader} alt="header-browser" style={{ height: "35px" }} />
                                </a>
                            </li>
                            <li style={{ padding: "0px" }}>
                                <a href="/" className="txt-dec-none clr333">
                                    <i className="icofont icofont-home"></i>
                                </a>
                            </li>
                            <li style={{ padding: "0px" }}>
                                <a href="#" className="" onClick={this.changeHandler.bind(this)} style={{ textDecoration: 'none' }}>
                                    <i className="icofont icofont-ui-weather" style={{ color: "#000" }}></i>
                                </a>
                            </li>
                            {/* <li style={{ cursor: "pointer" }} title='Weather Forecast'>
                                <a onClick={this.changeHandler.bind(this)} className="text-dark">
                                    <i className='fa fa-cloud' style={{color:"#a6abae"}} />
                                </a>
                            </li> */}
                            {/* {((this.state.loginType != "TRANSPORTER") && (localStorage.getItem('is_load_management') != 1)) ?
                                <li>
                                    <a href="#" className="txt-dec-none clr333" onClick={this.onShowUploadDiv.bind(this)}>
                                        <i className="icofont icofont-support"></i>
                                    </a>
                                </li>
                                : ""
                            } */}
                            <Notification />
                            <UserMenu />
                        </ul>
                        <div className="d-lg-none mobile-toggle" onClick={() => this.toggle()}>
                            <i className="icon-more"></i>
                        </div>
                    </div>
                </div>
                {this.state.sliderRouteTranslate != '' ?
                    <WeatherMapComponent context={this}
                        sliderValue={this.state.sliderRouteTranslate}
                        onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}
                        detailedWeatherMap={this.state.detailedWeatherMap}
                    />
                    : ""
                }
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <a href={"javascript:void(0)"} className={(this.state.tmenu)} id="tmenu">
                    <div id="outer" className={(this.state.outerb)}></div>
                    <div id="outer1" className={(this.state.outerb1)}><div id="sinner">&nbsp;</div></div>
                    <img src={this.state.tpmenu} className={"topmenu " + (this.state.mTop)} defaultChecked={this.state.topbar} onClick={this.openCloseTopbar} alt="" />
                </a>
            </div>

        );
    }
}
$(document).ready(function () {
    $("#sidebar-toggle").click(function () {
        $(".nav-left-arrow").css("display", "none");
        $(".nav-tooltip").css("display", "none");
        $(".close-menu-alert").css("display", "none");
    });
    $(".sidebaropen-icon").click(function () {
        setTimeout(function () {
            $(".nav-left-arrow").css("display", "block");
            $(".nav-tooltip").css("display", "block");
            $(".close-menu-alert").css("display", "block");
        }, 1000)

    });
})


export default Header