
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import Select from 'react-select';
// import _ from "lodash";
// import Modal from 'react-responsive-modal';
// import axios from 'axios';
import Grid from './gridActionComponent.js';
// import Breadcrumb from '../common/breadcrumb.component';
// import EditItem from './EditItem.js';
// import EditItemRenderer from './EditDetails.js'
import EditIconRenderer1 from './EdditIconRender1.js'
import redirectURL from "../redirectURL";
// var googleAnalytics = require("./googleAnalytics");

export default class GeoFence extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lists: [],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal: false,
			loadshow: "show-n"

		};
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		let currentAddGeoFenceLink = "/manage/addgeofence";
		switch (pathToMatch) {
			case "/manage/sndgeofences":
				department = 'SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/manage/sndaddgeofence";
				break;
			case "/manage/prtgeofences":
				department = 'LOG-PRT';
				departmentName = " (Spare Parts) ";
				currentAddGeoFenceLink = "/manage/prtaddgeofence";
				break;
			case "/manage/tnpgeofences":
				department = 'LOG-TNP';
				departmentName = " (Train and Production) ";
				currentAddGeoFenceLink = "/manage/tnpaddgeofence";
				break;
			case "/manage/scgeofences":
				department = 'LOG-SC';
				departmentName = " (Supply Chain) ";
				currentAddGeoFenceLink = "/manage/scaddgeofence";
				break;
			case "/manage/mllsndgeofences":
				department = 'SNDG';
				departmentName = " ";
				currentAddGeoFenceLink = "/manage/mllsndaddgeofence";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle: departmentName,
			currentAddGeoFenceLink: currentAddGeoFenceLink
		});
		return department;
	}
	componentDidMount() {
		this.setState({
			loadshow: "show-m"
		})
		// if (googleAnalytics.page.enableGA) {
		// 	googleAnalytics.logPageView();
		// }
		// get department code to filter records by department
		// let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		// this.setState({
		// 	// departmentCode:currentDepartmentCode
		// });
		redirectURL.post("/lists").then(
			(response) => {
				// let records = (response.data) ? response.data : null;
				console.log("/geofence/lists response.data = ", response.data);

				this.setState({
					lists: response.data,
					loadshow: "show-n"
				});
			}
		)
			.catch(function (error) {
				console.log(error);
			});
	}

	getDepartmentCodes = (params) => {
		var depts = params.value;
		var departments = [];
		var deptname = '';

		for (var item in depts) {
			deptname = deptname + depts[item].value + ", ";
			//console.log(depts[item].dept_code);
			departments.push(depts[item].value);
		}

		return deptname;
	}

	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		// console.log(this.props.match.path.indexOf("mllsndgeofences"));
		// if(this.props.match.path !== undefined && this.props.match.path !== null){
		//     if(this.props.match.path.indexOf("mllsndgeofences") >= 0)
		//         {
		//             var mllhide = false;
		//             var nrmhide = true;
		//         }
		// }

		// else
		// {
		// 	var mllhide = true;
		// 	var nrmhide = false;
		// }

		// const { usermanualmodal } = this.state;
		const columnwithDefs = [
			{
				headerName: "Geofence Name",
				field: "geofence_name",
				width: 220,
				filter: true,

			},
			{
				headerName: "Geofence Type",
				field: "geofence_type",
				width: 200,
				filter: true,
				valueGetter: params => {
					if (typeof params.data.geofence_type === 'object' && params.data.geofence_type.hasOwnProperty("value")) {
						return params.data.geofence_type.value
					}

					else {
						return "N/A";
					}
				}

			},

			{
				headerName: "Location Code",
				field: "consignee_code",
				width: 180,
				filter: true,

			},

			{
				headerName: "Notify Me",
				field: "notify_me",
				width: 180,
				filter: true,

			},
			{
				field: '_id',
				headerName: 'Edit',
				width: 100,
				// hide:nrmhide,
				cellRenderer: EditIconRenderer1

			},


		];
		return (

			<div className="container-fluid">
			<div className="mb-15p fbold f20" style={{ marginTop: '30px' }}>
				<h5>
					<i className="icon-map cus-i" style={{ marginLeft: '20px' }}></i> <span>Geofence {this.state.currentDepartmentTitle} </span>

					<a href="/addgeofence" className="btn btn-warning pull-right cs-btn"><i className="icon-plus"></i> Add Geofence</a>
				</h5>
			</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12" style={{ marginTop: '15px' }}>
						<div className="card">
							<div className="card-body">
								<Grid
									actionMethod={"deleteGeo"}
									screenurl={"/manage/geofences"}
									actionCall={"geofence"}
									columnwithDefs={columnwithDefs}
									rowData={this.state.lists}
									defaultColDef={{
										filter: true, // Enable filter for all columns by default
										sortable: true, // Enable sorting for all columns by default
									}} />
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
							</div>
						</div>
					</div>
				</div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}></span> */}
				{/* <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">Geofences</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of all Origin points along with fixed destination points where unloading happens and which belongs to MSIL like the ports used for exports, stockyards etc.
							</div>

							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								After clicking on Add GeoFence button below screen will be prompted for user to enter required details. The entered details will be reflected over Geo Fences screen.
							</div>
							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
						</div>
					</div>
				</Modal> */}
			</div>

		);
	}
}
