const ForceClose = (props) => {
    const handleClick = (params) => {
        props.onShowForceCloseModal(props?.data);
    };
    return (
        (props?.data?.status == 5 || props?.data?.status == 6 || props?.data?.status == 7) ?
            <></>
            :
            <a onClick={handleClick} title="Force Close"><button class="btn btn-danger custom-btn f12 label label-success">Force Close</button></a>
    )
}
export default ForceClose