import { useState } from "react";
import redirectURL from "../redirectURL";

const ModelForceClose = (props)=>{

    const [reasonForceClose, setReasonForceClose] = useState("");
    
    const formForceCloseHandler = (e) => {
        e.preventDefault()
        var consignemnt = props?.rownode;
        var consignment_code = consignemnt.consignment_code;
        var truck_no = consignemnt.truck_no;
        var dept_code = "SNDG";
        var email = props?.user?.email;
        var reqdata = {
            dept_code: dept_code,
            consignment_code: [consignment_code],
            truck_no: truck_no,
            email: email,
            reasonforceclose: reasonForceClose,
        }
        redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
            .then((response) => {
                if (response.data.status == 'Success') {
                    props?.setShow(true)
                    props?.setTitle("Successfully Force Closed the consignment")
                    props?.setType("success")
                    props?.closeForceCloseModal()
                    props?.changeReloadPage()
                }
                else {
                    props?.setShow(true)
                    props?.setTitle("Failed to Force Close")
                    props?.setType("danger")
                }
            })
            .catch(function (e) {
                console.log("Error ", e)
            })

    }
    return(
        <div>
        <form onSubmit={formForceCloseHandler}>
            <div className="col-xl-12 col-lg-12">
                <label style={{ color: "#EB1D54", fontSize: "13px", fontFamily: "Inter" }}>*</label>
                <label className="custom-label">
                    Reason For Force Close
                </label>
                <textarea
                    type="text"
                    value={reasonForceClose}
                    onChange={e => setReasonForceClose(e.target.value)}
                    autoComplete="off"
                    className="form-control"
                    required
                />
            </div>
            <div style={{ paddingTop: "2rem", display: "flex", justifyContent: "flex-end" }}>
                <button type='submit' className='primary-button'>Submit</button>
            </div>
        </form>
    </div>
    )
}
export default ModelForceClose