import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import SweetAlert from 'react-bootstrap-sweetalert';
import { getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import redirectURL from "../redirectURL";

export default class RouteGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
			pageTitle: "Tolls",
			currentDepartmentTitle: null,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
                editable: true,
                resizable: true
      		},
			rowData: null,

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "multiple" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
            maxBlocksInCache: 2,
            dept_code : ""
		};
        
	}
    
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	
	};
	

	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
	 }
    

    render(){
        
    	var columnwithDefs  = [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:120,
                editable:false
            },
            {
                headerName:"Consignments",
                field:"consignment_codes",
                width:160,
                editable:false
            },
            {
                headerName:"Consigner Code",
                field:"consigner_code",
                width:120,
                editable:false
            },
            {
                headerName:"Consignee City",
                field:"consignee_city",
                width:150,
                editable:false
            },
            {
                headerName:"Gate Out Time",
                field:"gate_out_time",
                width:150,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.gate_out_time != '' && params.data.gate_out_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Trip End Time",
                field:"trip_end_time",
                width:150,
                editable:false,
                valueGetter:function(params){
                    try{
                        if(params.data.trip_end_time != '' && params.data.trip_end_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.trip_end_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Standard Distance",
                field:"expected_distance",
                width:120,
                editable:false
            },
            {
                headerName:"Actual Trip Distance (KM)",
                field:"total_trip_dist_with_google_km",
                width:150,
                editable:false,
                valueGetter:function(params){
                    try{
                       
                        var kms = parseInt(params.data.total_trip_dist_with_google_km);
                        // if(params.data.missing_distance_km != undefined && params.data.missing_distance_km != NaN)
                        // {
                        //     kms = kms + parseInt(params.data.missing_distance_km);
                        // }
                       
                        return kms;
                    }
                    catch(e){
                        console.log(e)
                        return "";
                    }
                }
            },
            {
                headerName:"Missing Distance To City Center (KM)",
                field:"total_trip_dist_with_google_km",
                width:150,
                editable:false,
                valueGetter:function(params){
                    try{
                       
                        var kms = 0;
                        if(params.data.missing_distance_km != undefined && params.data.missing_distance_km != NaN)
                        {
                            kms = kms + parseInt(params.data.missing_distance_km);
                        }
                       
                        return kms;
                    }
                    catch(e){
                        console.log(e)
                        return "";
                    }
                }
            },
            {
                headerName:"Total Trip Distance (Km)",
                field:"total_trip_dist_with_google_km",
                width:150,
                editable:false,
                valueGetter:function(params){
                    try{
                       
                        var kms = parseInt(params.data.total_trip_dist_with_google_km);
                        if(params.data.missing_distance_km != undefined && params.data.missing_distance_km != NaN)
                        {
                            kms = kms + parseInt(params.data.missing_distance_km);
                        }
                       
                        return kms;
                    }
                    catch(e){
                        console.log(e)
                        return "";
                    }
                }
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:180,
                editable:false
            },

            // {
            //     headerName:"Total Travelled Trip Distance (Km)",
            //     field:"total_travelled_trip_dist_km",
            //     width:150,
            //     editable:false
            // },
            {
                headerName:"Route ID",
                field:"route_id",
                width:120,
                editable:false
            },
            {
                headerName:"Cluster",
                field:"cluster",
                width:120,
                editable:false
            }
        ]
        
        return ( 
        		<div className="container-fluid">
					<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div id="myGrid" style={{ height: "550px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.props.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    // frameworkComponents={this.state.frameworkComponents}
                                    // stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
      		    </div>
                    
			);
		}
}

