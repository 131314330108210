
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import redirectURL from "../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import { getDecoder } from '../common/utils';


const Configuration = () => {

    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [startDate, setStartDate] = useState();
    const [counter, setCounter] = useState("nightdriving")
    const [endDate, setEndDate] = useState();
    const [distance, setDistance] = useState(0);
    const [speed, setSpeed] = useState(0);
    const [miniDistance, setMiniDistance] = useState(0);
    const [beforeNotification, setBeforeNotification] = useState(0);
    const [afterNotification, setAfterNotification] = useState(0);
    const [nogpsdata, setNoGPSData] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [minStoppage, setMinStoppage] = useState(0);
    const [miniException, setMiniException] = useState(0);
    const [loadingDelayDuration, setLoadingDelayDuration] = useState(0);
    const [unLoadingDelayDuration, setUnLoadingDelayDuration] = useState(0);
    const [haulDistance, setHaulDistance] = useState(0);
    const [shortHaulDealer, setShortHaulDealer] = useState(0);
    const [longHaulDealer, setLongHaulDealer] = useState(0);
    const [autoClosure, setAutoClosure] = useState(0);
    const [emailProvider, setEmailProvider] = useState(true)
    const [nightDrivingRules, setNightDrivingRules] = useState()

    const header = [
        { label: "nightdriving", value: "Night Driving Settings" },
        { label: "overspeeding", value: "Overspeed Settings" },
        { label: "nogpsdata", value: "No GPS Data Settings" },
        { label: "enrouteStoppages", value: "Enroute Stoppages Settings" },
        { label: "loadingDelaySettings", value: "Loading Delay Settings" },
        { label: "unloadingDelaySettings", value: "UnLoading Delay Settings" },
        // { label: "dealerRadius", value: "Dealer Radius Settings" },
    ]
    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        pivot: false,
    }

    const columnDefs2 = [
        {
            headerName: "Service Provider",
            field: "plant_code",
            editable: true,
            cellStyle: params => {
                if (params.data.plant_code == undefined || params.data.plant_code == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Service Provider Email",
            field: "plant_code",
            editable: true,
            cellStyle: params => {
                if (params.data.plant_code == undefined || params.data.plant_code == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
        {
            headerName: "Sales and Dispatch Emails",
            field: "plant_code",
            editable: true,
            cellStyle: params => {
                if (params.data.plant_code == undefined || params.data.plant_code == "") {
                    return {
                        "backgroundColor": '#c85f62'
                    };
                }
            }
        },
    ]
    let user_data = getDecoder()

    const cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    var encode = cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");

    useEffect(() => {
        redirectURL.post('/getConfiguration', {})
            .then(resp => {
                if (resp.data.status == "success") {
                    if (resp.data.nightdriving.length > 0) {
                        let nightd = resp.data.nightdriving[0]
                        setStartDate(convertTimeStringToDate(nightd.start_time))
                        setEndDate(convertTimeStringToDate(nightd.end_time))
                        setDistance(nightd.min_continuous_driving_dist)
                    }
                    if (resp.data.overspeeding.length > 0) {
                        let overspeed = resp.data.overspeeding[0]
                        setSpeed(overspeed.raise_overspeeding_kmph)
                        setMiniDistance(overspeed.minimum_overspeed_duration)
                        setBeforeNotification(overspeed.time_to_invalidate_before_notification)
                        setAfterNotification(overspeed.time_to_invalidate_after_notification)
                    }
                    if (resp.data.nogpsdata.length > 0) {
                        setNoGPSData(resp.data.nogpsdata[0].nogpsdata)
                        setEmailProvider(resp.data.nogpsdata[0].email_provider)
                    }
                    if (resp.data.enrouteStoppages.length > 0) {
                        let enstoppage = resp.data.enrouteStoppages[0]
                        setMinStoppage(enstoppage.mini_stoppage)
                        setMiniException(enstoppage.mini_exception)
                        setNightDrivingRules(enstoppage.night_driving_rules)
                    }
                    if (resp.data.loadingDelaySettings.length > 0) {
                        setLoadingDelayDuration(resp.data.loadingDelaySettings[0].loading_delay_duration)
                    }
                    if (resp.data.unloadingDelaySettings.length > 0) {
                        setUnLoadingDelayDuration(resp.data.unloadingDelaySettings[0].unloading_delay_duration)
                    }
                    // if(resp.data.dealerRadius.length>0){
                    //     let dealerR=resp.data.dealerRadius[0]
                    //     setHaulDistance(dealerR.haul_distance)
                    //     setShortHaulDealer(dealerR.short_haul_dealer)
                    //     setLongHaulDealer(dealerR.long_haul_dealer)
                    //     setAutoClosure(dealerR.auto_closure)
                    // }
                }
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (window.location.pathname == "/confignd") {
            setCounter("nightdriving")
        }
        else if (window.location.pathname == "/configospd") {
            setCounter("overspeeding")
        }
        else if (window.location.pathname == "/confignogps") {
            setCounter("nogpsdata")
        }
        else if (window.location.pathname == "/configstoppages") {
            setCounter("enrouteStoppages")
        }
        else if (window.location.pathname == "/configldelay") {
            setCounter("loadingDelaySettings")
        }
        else if (window.location.pathname == "/configudelay") {
            setCounter("unloadingDelaySettings")
        }
    }, [window.location.pathname])

    const onSubmit = async (e) => {
        e.preventDefault();
        let body = {}
        switch (e.target.id) {
            case "nightdriving":
                body = {
                    user_email: user_data.email,
                    config_type: "nightdriving",
                    dept_code: "SNDG",
                    start_time: moment(startDate).format('HH:mm'),
                    end_time: moment(endDate).format('HH:mm'),
                    min_continuous_driving_dist: distance
                }
                console.log(moment(startDate).format('HH:mm'), "sssssssssssssss");
                if (startDate == undefined || startDate == null || startDate == "" || endDate == undefined || endDate == null || endDate == "") {
                    body = null
                }
                break;
            case "overspeeding":
                body = {
                    user_email: user_data.email,
                    config_type: "overspeeding",
                    dept_code: "SNDG",
                    speed: speed,
                    miniDistance: miniDistance,
                    time_to_invalidate_before_notification: beforeNotification,
                    time_to_invalidate_after_notification: afterNotification
                }
                if (speed == undefined || speed == null || speed == "" || miniDistance == undefined || miniDistance == null || miniDistance == "" || beforeNotification == undefined || beforeNotification == null || beforeNotification == "" || afterNotification == undefined || afterNotification == null || afterNotification == "") {
                    body = null
                }
                break;
            case "nogpsdata":
                body = {
                    user_email: user_data.email,
                    config_type: "nogpsdata",
                    dept_code: "SNDG",
                    nogpsdata: nogpsdata,
                    emailProvider: emailProvider
                }
                if (nogpsdata == undefined || nogpsdata == null || nogpsdata == "") {
                    body = null
                }
                break;
            case "enrouteStoppages":
                body = {
                    user_email: user_data.email,
                    config_type: "enrouteStoppages",
                    dept_code: "SNDG",
                    minStoppage: minStoppage,
                    miniException: miniException,
                    nightDrivingRules: nightDrivingRules
                }
                if (minStoppage == undefined || minStoppage == null || minStoppage == "" || miniException == undefined || miniException == null || miniException == "" || nightDrivingRules == undefined || nightDrivingRules == null || nightDrivingRules == "") {
                    body = null
                }
                break;
            case "loadingDelaySettings":
                body = {
                    user_email: user_data.email,
                    config_type: "loadingDelaySettings",
                    dept_code: "SNDG",
                    loadingDelayDuration: loadingDelayDuration
                }
                if (loadingDelayDuration == undefined || loadingDelayDuration == null || loadingDelayDuration == "") {
                    body = null
                }
                break;
            case "unloadingDelaySettings":
                body = {
                    user_email: user_data.email,
                    config_type: "unloadingDelaySettings",
                    dept_code: "SNDG",
                    unLoadingDelayDuration: unLoadingDelayDuration
                }
                if (unLoadingDelayDuration == undefined || unLoadingDelayDuration == null || unLoadingDelayDuration == "") {
                    body = null
                }
                break;
            // case "dealerRadius":
            //     body = {
            //         user_email: user_data.email,
            //         config_type: "dealerRadius",
            //         dept_code: "SNDG",
            //         haulDistance: haulDistance,
            //         shortHaulDealer: shortHaulDealer,
            //         longHaulDealer: longHaulDealer,
            //         autoClosure: autoClosure
            //     }
            //     break;
            default:
                break;
        }
        if (body != null && typeof body === 'object' && Object.keys(body).length > 0) {
            await redirectURL.post("/saveConfigurations", body)
                .then(res => {
                    if (res.data.status == "success") {
                        setShow(true)
                        setTitle(res.data.message)
                        setType("success")
                    }
                    else {
                        setShow(true)
                        setTitle(res.data.message)
                        setType("danger")
                    }
                })
                .catch(err => console.log(err))
        }
        else {
            setShow(true)
            setTitle("Please enter vaild data")
            setType("danger")
        }
    }
    const onConfirm = () => {
        setShow(false);
        setTitle('default')
        // setLoader(false)
    }

    return (
        <>
            <h5 className="mb-15p fbold f20">
                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontWeight: "600" }}>Configurations</span>
            </h5>
            <div className='card py-4'>
                <SweetAlert
                    show={show}
                    title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                    onConfirm={onConfirm}
                    confirmBtnBsStyle={type}
                    confirmBtnCssClass="sweetButton"
                    customClass='sweetWidth'
                    type={type}>
                </SweetAlert>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 style={{ fontSize: "1.5rem" }}>{header.find(e => e.label == counter).value}</h2>
            </div> */}
                {/* <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row" }}> */}
                {/* <div style={{ cursor: "pointer", paddingInline: "1.5rem", minHeight: "88vh" }}>
                    <div className={counter == "nightdriving" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("nightdriving")}>
                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "nightdriving" ? "icofont icofont-full-night icon-config" : "icofont icofont-full-night icon-config nightdriving"}`} style={{ fontSize: "22px" }}></i>&nbsp;Night Driving
                        </span>
                    </div>
                    <div className={counter == "overspeeding" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("overspeeding")}>
                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "overspeeding" ? "icofont icofont-speed-meter icon-config" : "icofont icofont-speed-meter icon-config overspeeding"}`} style={{ fontSize: "22px" }}></i>&nbsp;Overspeeding
                        </span>
                    </div>
                    <div className={counter == "nogpsdata" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("nogpsdata")}>

                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "nogpsdata" ? "icofont icofont-compass icon-config" : "icofont icofont-compass icon-config nogpsdata"}`} style={{ fontSize: "22px" }}></i>&nbsp;No GPS Data
                        </span>
                    </div>
                    <div className={counter == "enrouteStoppages" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("enrouteStoppages")}>

                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "enrouteStoppages" ? "icofont icofont-pause icon-config" : "icofont icofont-pause icon-config enrouteStoppages"}`} style={{ fontSize: "22px" }}></i>&nbsp;Enroute Stoppages
                        </span>
                    </div>
                    <div className={counter == "loadingDelaySettings" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("loadingDelaySettings")}>

                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "loadingDelaySettings" ? "icofont icofont-truck-alt icon-config" : "icofont icofont-truck-alt icon-config loadingDelaySettings"}`} style={{ fontSize: "22px" }}></i>&nbsp;Loading Delay
                        </span>
                    </div>
                    <div className={counter == "unloadingDelaySettings" ? 'configuration-menu active' : "configuration-menu"} onClick={(e) => setCounter("unloadingDelaySettings")}>

                        <span className='flex-left-center' style={{ fontSize: "17px", fontWeight: "600" }}><i className={`${counter == "unloadingDelaySettings" ? "icofont icofont-truck-loaded icon-config" : "icofont icofont-truck-loaded icon-config unloadingDelaySettings"}`} style={{ fontSize: "22px" }}></i>&nbsp;Unloading Delay
                        </span>
                    </div>
                </div> */}
                <div>
                    <div className="col-xl-12 col-lg-12">
                        <button
                            onClick={(e) => setCounter("nightdriving")}
                            className={(counter == "nightdriving") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "nightdriving") ? "inline-flex" : ""
                            }}>
                            Night Driving
                        </button>
                        <button
                            onClick={(e) => setCounter("overspeeding")}
                            className={(counter == "overspeeding") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "overspeeding") ? "inline-flex" : ""
                            }}>
                            Overspeeding
                        </button>
                        <button
                            onClick={(e) => setCounter("nogpsdata")}
                            className={(counter == "nogpsdata") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "nogpsdata") ? "inline-flex" : ""
                            }}>
                            No GPS Data
                        </button>
                        <button
                            onClick={(e) => setCounter("enrouteStoppages")}
                            className={(counter == "enrouteStoppages") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "enrouteStoppages") ? "inline-flex" : ""
                            }}>
                            Enroute Stoppages
                        </button>
                        <button
                            onClick={(e) => setCounter("loadingDelaySettings")}
                            className={(counter == "loadingDelaySettings") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "loadingDelaySettings") ? "inline-flex" : ""
                            }}>
                            Loading Delay
                        </button>
                        <button
                            onClick={(e) => setCounter("unloadingDelaySettings")}
                            className={(counter == "unloadingDelaySettings") ? "btn tab-btn btn-info" : "btn tab-btn "}
                            style={{
                                display: (counter == "unloadingDelaySettings") ? "inline-flex" : ""
                            }}>
                            Unloading Delay
                        </button>
                    </div>
                    <div className={`${counter == "nightdriving" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id='nightdriving' onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-4">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Start Time
                                </label>
                                <div className="col-xl-2 col-lg-2" style={{ padding: "0px" }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    End Time
                                </label>
                                <div className="col-xl-2 col-lg-2" style={{ padding: "0px" }}>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Continuous Driving Distance (KM)
                                </label><br />
                                <input type='number' value={distance} onChange={(e) => setDistance(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={`${counter == "overspeeding" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id='overspeeding' onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Speed (Kmph)
                                </label><br />
                                <input type='number' value={speed} onChange={(e) => setSpeed(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum overspeed Duration (Minutes)
                                </label><br />
                                <input type='number' value={miniDistance} onChange={(e) => setMiniDistance(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Time to Invalidate Overspeeding Before Notification: (Minutes)
                                </label><br />
                                <input type='number' value={beforeNotification} onChange={(e) => setBeforeNotification(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Time to Invalidate Overspeeding After Notification: (Minutes)
                                </label><br />
                                <input type='number' value={afterNotification} onChange={(e) => setAfterNotification(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={`${counter == "nogpsdata" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id="nogpsdata" onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Duration of No GPS Data (Minutes)
                                </label><br />
                                <input type='number' value={nogpsdata} onChange={(e) => setNoGPSData(e.target.value)} />
                            </div>
                            {/* <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Send Email to Service Provider
                                </label>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={emailProvider === 'Yes'}
                                            onChange={(e) => setEmailProvider(prev => "Yes")}
                                            required
                                        />
                                        &nbsp;Yes
                                    </label>&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={emailProvider === 'No'}
                                            onChange={(e) => setEmailProvider(prev => "No")}
                                            required
                                        />
                                        &nbsp;No
                                    </label>
                                </div>
                                <br />
                                <div className="ag-theme-alpine mt-8" style={{ height: '48vh', width: '642px', }}>
                                    <AgGridReact
                                        columnDefs={columnDefs2}
                                        rowData={rowData}
                                        defaultColDef={defaultColDef}
                                        pagination={true}
                                        paginationPageSize={50}
                                        enableRangeSelection={true}
                                        enableCellContextMenu={true}
                                        stopEditingWhenGridLosesFocus
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel",
                                                    toolPanelParams: {
                                                        suppressPivotMode: true,
                                                        suppressRowGroups: true,
                                                        suppressValues: true,
                                                    }
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                {
                                                    statusPanel: "agTotalAndFilteredRowCountComponent",
                                                    align: "left"
                                                },
                                                {
                                                    statusPanel: "agTotalRowCountComponent",
                                                    align: "center"
                                                },
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }} />
                                </div>
                            </div> */}

                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={`${counter == "enrouteStoppages" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id='enrouteStoppages' onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Stoppage Duration (Hours)
                                </label><br />
                                <input type='number' value={minStoppage} onChange={(e) => setMinStoppage(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Distance Travelled for Exception Closure (KM)
                                </label><br />
                                <input type='number' value={miniException} onChange={(e) => setMiniException(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Night driving rules to be applied
                                </label><br />
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            checked={nightDrivingRules === 'Yes'}
                                            onChange={(e) => setNightDrivingRules(prev => "Yes")}
                                            required
                                        />
                                        &nbsp;Yes
                                    </label>&nbsp;&nbsp;
                                    <label>
                                        <input
                                            type="radio"
                                            value="No"
                                            checked={nightDrivingRules === 'No'}
                                            onChange={(e) => setNightDrivingRules(prev => "No")}
                                            required
                                        />
                                        &nbsp;No
                                    </label>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>

                    <div className={`${counter == "loadingDelaySettings" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id='loadingDelaySettings' onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Loading Delay Duration (Minutes)
                                </label><br />
                                <input type='number' value={loadingDelayDuration} onChange={(e) => setLoadingDelayDuration(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className={`${counter == "unloadingDelaySettings" ? "show-m configuration-pannel" : "show-n"}`}>
                        <form id='unloadingDelaySettings' onSubmit={onSubmit}>
                            <div className="col-xl-12 col-lg-12 pt-3">
                                <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                                <label className="custom-label">
                                    Minimum Unloading Delay Duration (Minutes)
                                </label><br />
                                <input type='number' value={unLoadingDelayDuration} onChange={(e) => setUnLoadingDelayDuration(e.target.value)} />
                            </div>
                            <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                                <button type='submit' className='primary-button'>Submit</button>
                            </div>
                        </form>
                    </div>
                    {/* <div className={`${counter == "dealerRadius" ? "show-m configuration-pannel" : "show-n"}`}>
                    <form id='dealerRadius' onSubmit={onSubmit}>
                        <div className="col-xl-12 col-lg-12 pt-3">
                            <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                            <label className="custom-label">
                                Short Haul Distance Radius From Plant (KM)
                            </label><br />
                            <input type='number' value={haulDistance} onChange={(e) => setHaulDistance(e.target.value)} />
                        </div>
                        <div className="col-xl-12 col-lg-12 pt-3">
                            <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                            <label className="custom-label">
                                Short Haul Dealer Proximity Radius (KM)
                            </label><br />
                            <input type='number' value={shortHaulDealer} onChange={(e) => setShortHaulDealer(e.target.value)} />
                        </div>
                        <div className="col-xl-12 col-lg-12 pt-3">
                            <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                            <label className="custom-label">
                                Long Haul Dealer Proximity Radius (KM)
                            </label><br />
                            <input type='number' value={longHaulDealer} onChange={(e) => setLongHaulDealer(e.target.value)} />
                        </div>
                        <div className="col-xl-12 col-lg-12 pt-3">
                            <label style={{ color: "#EB1D54", fontSize: "13px" }}>*</label>&nbsp;
                            <label className="custom-label">
                                Trip Auto Closure Radius (KM)
                            </label><br />
                            <input type='number' value={autoClosure} onChange={(e) => setAutoClosure(e.target.value)} />
                        </div>
                        <div className="col-xl-12 col-lg-12 pt-3 pb-4">
                            <button type='submit' className='primary-button'>Submit</button>
                        </div>
                    </form>
                </div> */}
                </div>
            </div>
        </>
    )
}

export default Configuration

const convertTimeStringToDate = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
    return date;
};