
import React, { Component } from 'react';


const RoutemapAction = (props) => {
    
    const handleClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.onShowRouteMap(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-map-pins f20"></i> </button>
       
        </div>
    );
};

export default RoutemapAction;
