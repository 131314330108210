const EnrouteException = (props) => {
    const handleClick = (params) => {
        props.onShowStoppagesData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#a34518"
    }
    return (
        <a onClick={handleClick} title="Enroute Stoppages"><i className="icofont icofont-pause f20" style={{ color: color }}></i> </a>
    )
}
export default EnrouteException