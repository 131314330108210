import { Component, React, useEffect, useState } from 'react';
import Select from 'react-select';
import AgGrid from "./AgGrid"
import SweetAlert from 'react-bootstrap-sweetalert';
import { TabContent, TabPane } from 'reactstrap';
// import CKEditor from "react-ckeditor-component";
import _ from "lodash";
import redirectURL from "../redirectURL";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CountUp from 'react-countup';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS, saveGridLayout, fetchGridLayout, getHyphenYYYYMMDDHHMMSS, getDecoder } from '../common/utils';
import DrawMap from '../common/drawmap';
import { AgGridReact } from 'ag-grid-react';
import TMSException from './TMSException';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapAction from './routemapActionComponent';
import RoutemapenrouteAction from './routemapenroute';
import RoutemapTransitAction from './RoutemapTransitAction';
import NoGPSMap from './NoGPSMap';
import TicketHistory from './ticketHistory';
import ViewMap from './ViewMap';
// var infoBox = require('../common/google-infowindow');

var moment = require('moment');
// var googleAnalytics = require("../common/googleanalytics");

var containerslist = [];

export default class DepartmentTMS extends Component {

	constructor(props) {
		super(props);
		this.state = {
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderTicketTranslate: '',
			inboxSlider: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			editTicketStatus: '',
			basicType: "default",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true
			},
			columnwithDefs: [
				{
					headerName: "",
					field: "ticket_legs",
					width: 50,
					filter: false,
					cellRenderer: TMSException
				},
				{
					headerName: "",
					field: "_id",
					width: 50,
					filter: false,
					cellRenderer: ViewMap,
					cellRendererParams: {
						onClickShowRoute: this.onClickShowRoute
					},
				},
				{
					headerName: "",
					field: "_id",
					width: 50,
					filter: false,
					cellRenderer: TicketHistory,
					cellRendererParams: {
						onShowTicketHistory: this.onShowTicketHistory,
					},
				},
				{
					headerName: "Ticket",
					field: "ticket_type",
					width: 120,
					valueGetter: function (params) {
						var tickettype = params.data.ticket_type;
						var str = tickettype.replace(/_/g, " ")
						return str;
					},
					cellClass: "capitlize",
				},
				{
					headerName: "Ticket No",
					field: "ticket_number",
					width: 120,
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
				},
				{
					headerName: "FO Number(s)",
					field: "fo_numbers",
					width: 120,
				},
				{
					headerName: "Dealer Code",
					field: "consignee_codes",
					width: 120,
				},
				{
					headerName: "Transporter",
					field: "transporter_name",
					width: 120,
				},
				// {
				// 	headerName: "Department",
				// 	field: "dept_code",
				// 	width: 150,
				// },
				// {
				// 	headerName: "Actual LSP User",
				// 	field: "actual_lspuser",
				// 	width: 150,
				// },
				{
					headerName: "Status",
					field: "ticket_status",
					width: 100,
					valueGetter: function (params) {
						if (params.data.ticket_status == 1) {
							return "Open";
						}
						if (params.data.ticket_status == 2) {
							return "Work in progress";
						}
						if (params.data.ticket_status == 3) {
							return "Closed";
						}
						if (params.data.ticket_status == 4) {
							return "On hold";
						}
						if (params.data.ticket_status == 5) {
							return "Auto closed";
						}
						if (params.data.ticket_status == 6) {
							return "Invalid";
						}
						if (params.data.ticket_status == 7) {
							return "Closed - No response";
						}
						if (params.data.ticket_status == 8) {
							return "Force Closed";
						}
					}
				},

				{
					headerName: "Consignee City",
					field: "consignee_city",
					width: 180,
					// hide: showonlytms,
					valueGetter: function (params) {
						if (typeof params.data.consignee_city == undefined) {
							return '';
						}
						else {
							return params.data.consignee_city;
						}
					}
				},
				// {
				// 	headerName: "Transit Delay Hours",
				// 	field: "transit_delay_hours",
				// 	width: 180,
				// 	hide: showonlytms,
				// 	valueGetter: function (params) {
				// 		if (typeof params.data.transit_delay_hours == undefined) {
				// 			return '';
				// 		}
				// 		else {
				// 			return params.data.transit_delay_hours;
				// 		}
				// 	}
				// },
				{
					headerName: "Gate Out / Invoice Time",
					field: "gate_out_invoice_time",
					width: 180,
					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Created Date",
					field: "ticket_creation_date",
					width: 180,
					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Modified Date",
					field: "ticket_modification_date",
					width: 180,
					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},

			],
			rowData: null,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			//   overlayNoRowsTemplate: "",
			frameworkComponents: {
				// customLoadingCellRenderer: CustomLoadingCellRenderer,
				// sendemailticket:Sendemailticket,
				// kpiActions:KpiActions,
				// kpiTransitDelayActions:KpiTransitDelayActions,
				// kpiNightDriveActions:KpiNightDriveActions,
				// ticketkpienroute:Ticketkpienroute,
				// ticketloadingaction:Ticketloadingaction,
				// ticketunloadingaction:Ticketunloadingaction,
				// nogps:NoGps,
				// RoutemapAction:RoutemapAction,
				// RoutemapTransitAction:RoutemapTransitAction,
				// RoutemapenrouteAction:RoutemapenrouteAction,
				// RouteNightDrivemap:RouteNightDrivemap,
				// tickethistory:Tickethistory
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowSelection: 'multiple',
			detailCellRendererParams: {},
			totaltypesLen: 0,
			closedarrLen: 0,
			autoclosedarrLen: 0,
			invalidarrLen: 0,
			transitarrLen: 0,
			gpsarrLen: 0,
			enroutearrLen: 0,
			slowtrucksLen: 0,
			forcearrLen: 0,
			overspeedingLen: 0,
			nightdrivingLen: 0,
			sim_consent_pendingLen: 0,
			no_sim_dataLen: 0,
			consent_approved_no_locationLen: 0,
			totaltypes: [],
			closedarr: [],
			autoclosedarr: [],
			invalidarr: [],
			transitarr: [],
			gpsarr: [],
			enroutearr: [],
			slowtrucks: [],
			forcearr: [],
			overspeeding: [],
			nightdriving: [],
			sim_consent_pending: [],
			no_sim_data: [],
			consent_approved_no_location: [],
			sidebarSubHeader: '',
			allCommunicationTypes: [
				{ "value": "email", "label": "Email" },
				{ "value": "phone", "label": "Phone" },
				{ "value": "other", "label": "Other" }
			],
			allTicketStatusTypes: [
				{ "value": 1, "label": "Open" },
				{ "value": 3, "label": "Closed" },
				{ "value": 6, "label": "Invalid" },
				{ "value": 4, "label": "On Hold" }
			],
			com_code: null,
			ticket_status_code: null,
			ticket_comment: null,
			routeTruck: '',
			googelRoutes: '',
			defTransitCoords: '',
			mapinfo: '',
			usergridstate: [],
			user: '',
			leg_no: 0,
			transit: 0,
			gps: 0,
			overspeed: 0,
			enroute: 0,
			nightdrive: 0,
			loading: 0,
			unloading: 0,
			showMailBtn: 'show-n',
			showCommentBtn: 'show-n',
			showCommentBtnb: 'show-m',
			showMailBtnb: 'show-m',
			alldepartments: [],
			dept_code: 'SNDG',
			originalData: [],
			ticketEmailContent: '',
			ticketSubject: '',
			toEmail: '',
			ccEMail: '',
			SelectedTab: '1',
			toggleMore: false,
			emailformat: [],
			showleftmailside: 'show-n',
			ticketMailID: '',
			ticketslist: [],
			commentTickets: [],
			reponsecomment: "",
			satisfactory: '',
			draftlist: [],
			sendmaildiv: "col-xl-9",
			ticketHistoryLog: '',
			totalDrafts: '',
			closecounters: '',
			criticalTickets: "",
			title: "",
			startDate: moment.parseZone().subtract(2, 'days').format('YYYY-MM-DDTHH:mm:ss'),
			endDate: moment.parseZone().format('YYYY-MM-DDTHH:mm:ss'),
			defaultsdate: "",
			defaultedate: "",
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			pagetitle: "",
			eventGridAction: "gridAction",
		}
		this.communicationTypesDropdownOptions = this.communicationTypesDropdownOptions.bind(this);
		this.ticketStatusTypesDropdownOptions = this.ticketStatusTypesDropdownOptions.bind(this);
		this.onShowTicketDetails = this.onShowTicketDetails.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this)
		this.mailToggle = this.mailToggle.bind(this);
		this.updateContent = this.updateContent.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onShowTicketHistory = this.onShowTicketHistory.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onLoadShowTMSList = this.onLoadShowTMSList.bind(this);
	}
	componentDidMount() {
		const user = getDecoder()
		this.setState({
			user: user
		})
		var urlpath = '';
		var dpt = '';
		var title = '';
		// this.logPageView(); // Log page view to GA
		// let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		// this.setState({eventAction: eventAction});
		if (window.location.pathname == "/closetickets") {
			urlpath = '/departmentclosetickets';
			dpt = 'SNDG';
			title = 'Ticket Management System'
		}
		var closecounters = 0;
		let communicationTypes = [
			{ "com_code": "email", "com_name": "Email" },
			{ "com_code": "phone", "com_name": "Phone" },
			{ "com_code": "other", "com_name": "Other" }
		];
		this.setState({
			closecounters: closecounters
		})
		this.onLoadShowTMSList()
	};

	onLoadShowTMSList() {
		var edate = moment.parseZone(new Date(this.state.endDate)).utcOffset("+05:30").format("YYYY-MM-DD")
		var sdate = moment.parseZone(new Date(this.state.startDate)).utcOffset("+05:30").format("YYYY-MM-DD")
		let parameters = {
			startDate: sdate,
			endDate: edate,
			dept_code: this.state.dept_code,
			actiontype: 1
		}
		this.setState({
			dept_code: this.state.dept_code,
			startDate: sdate,
			endDate: edate,
			defaultsdate: sdate,
			defaultedate: edate,
		});
		redirectURL.post("/departmentclosetickets", parameters)
			.then((response) => {
				console.log("REsponse ", response.data)
				var records = response.data.records;

				var closedarr = []
				var autoclosedarr = []
				var invalidarr = []
				var forcearr = []

				var transitarr = [];
				var gpsarr = [];
				var enroutearr = [];
				var slowtrucks = [];
				var overspeeding = []
				var nightdriving = []
				var consent_approved_no_location = []
				var no_sim_data = []
				var sim_consent_pending = []
				if (records.length > 0) {
					records.map((item) => {
						if (item.ticket_status == 3 || item.ticket_status == 7) {
							closedarr.push(item)
						}
						else if (item.ticket_status == 5) {
							autoclosedarr.push(item)
						}
						else if (item.ticket_status == 6) {
							invalidarr.push(item)
						}
						else if (item.ticket_status == 8) {
							forcearr.push(item)
						}
						if (item.ticket_type == 'transit_delay') {
							transitarr.push(item)
						}
						else if (item.ticket_type == 'no_gps') {
							gpsarr.push(item)
						}
						else if (item.ticket_type == 'enroute_stoppage') {
							enroutearr.push(item)
						}
						else if (item.ticket_type == 'sim_consent_pending') {
							sim_consent_pending.push(item)
						}
						else if (item.ticket_type == 'consent_approved_no_location') {
							consent_approved_no_location.push(item)
						}
						else if (item.ticket_type == 'no_sim_data') {
							no_sim_data.push(item)
						}
						else if (item.ticket_type == 'slow_trucks') {
							slowtrucks.push(item)
						}
						else if (item.ticket_type == 'night_driving') {
							nightdriving.push(item)
						}
						else if (item.ticket_type == 'overspeeding') {
							overspeeding.push(item)
						}
						if (item.hasOwnProperty("is_critical") && item.is_critical == 1) {
							forcearr.push(item)
						}
					});
				}

				this.setState({
					criticalTickets: response.data.criticalRecords,
					rowData: records,
					totaltypesLen: records.length,
					closedarrLen: closedarr.length,
					autoclosedarrLen: autoclosedarr.length,
					invalidarrLen: invalidarr.length,
					transitarrLen: transitarr.length,
					gpsarrLen: gpsarr.length,
					overspeedingLen: overspeeding.length,
					nightdrivingLen: nightdriving.length,
					enroutearrLen: enroutearr.length,
					slowtrucksLen: slowtrucks.length,
					forcearrLen: forcearr.length,
					totaltypes: records,
					closedarr: closedarr,
					autoclosedarr: autoclosedarr,
					invalidarr: invalidarr,
					transitarr: transitarr,
					overspeeding: overspeeding,
					nightdriving: nightdriving,
					gpsarr: gpsarr,
					enroutearr: enroutearr,
					slowtrucks: slowtrucks,
					forcearr: forcearr,
					no_sim_data: no_sim_data,
					consent_approved_no_location: consent_approved_no_location,
					sim_consent_pending: sim_consent_pending,
					no_sim_dataLen: no_sim_data.length,
					consent_approved_no_locationLen: consent_approved_no_location.length,
					sim_consent_pendingLen: sim_consent_pending.length,
					loadshow: 'show-n'
				});


			})
			.catch(function (e) {
				console.log(e)
			})
	}

	// logPageView = () => {
	// 	try {
	// 	    if (googleAnalytics.page.enableGA) {
	// 	        googleAnalytics.logPageView();
	// 	        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
	// 	        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
	// 	        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
	// 	        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
	// 	    } else {
	// 	        console.log("GA disabled...");
	// 	    }
	// 	} catch(error) {
	// 	    console.error("Error occured while logging to GA, error = ", error);
	// 	}
	// }

	// Display Ticket Details
	onShowTicketDetails = (e) => {
		console.log("IN  onShowTicketDetails, e = ", e);
		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}
		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();
		var edate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		//sliderTicketDetails
		this.setState({
			sliderTicketDetails: "slider-translate-60p",
			showDiv: 'show-m',
			//mapinfo:records,
			mapinfo: [],
			dealer: e.consignee_code,
			consignment_code: "Consignment : " + e.consignment_code,
			maptruckno: e.truck_no,
			routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": edate },
			loadshow: 'show-n',
			sidebarSubHeader: "Consignment Ticket Details",
			overly: 'show-m',
			rownode: e,
			leg_no: 0
		});
	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}


	onGridReady = async params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.api;

		await fetchGridLayout(this.state.user?.email, "TMSClosed")
			.then(async res => {
				if (res.data.status == "success" && Array.isArray(res.data.data[0].columnState) && res.data.data[0].columnState.length > 0) {
					setTimeout(async () => {
						await params.api.applyColumnState({
							state: res.data.data[0].columnState,
							applyOrder: true,
						})
					}, 1000);
					this.setState({
						usergridstate: res.data.data[0].columnState
					})
				}
			})
			.catch(err => console.log(err))
	};

	onClickShowRoute = (rownode) => {
		rownode = rownode.data
		this.setState({
			overly: "show-m",
			loadshow: "show-m"
		})
		var truck_no = rownode.truck_no;
		if (rownode.ticket_type == "slow_trucks") {
			var gate_out_time = getHyphenYYYYMMDDHHMMSS(rownode.exception_start_time)
		}
		else {
			var gate_out_time = getHyphenYYYYMMDDHHMMSS(rownode.gate_out_invoice_time)
		}
		var current_time = moment.parseZone().format("YYYY-MM-DD")
		current_time = current_time.toString() + " 23:59:59";
		var formdata = {
			truck_no: truck_no,
			gate_out_time: gate_out_time,
			seconddate: current_time,
			user_email: this.state.user.email
		}
		redirectURL.post('/consignments/maproutes', formdata, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				if (response?.data?.status == "Failure") {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					})
					return
				}
				else if (!(response?.data !== null && typeof response?.data === 'object')) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					})
					return
				}
				try {
					if (records.coords.length == 0) {
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n',
							overly: "show-n",
						})
						return
					}
					else {
						try {
							response.data["consignee_coordinates"] = JSON.parse(rownode.consignee_locations)
							response.data["consigner_coordinates"] = JSON.parse(rownode.consigner_locations)
						} catch (e) {
						}

						let temp = ''
						let temp2 = ''
						try {
							temp = JSON.parse(rownode.consignee_locations)
							temp2 = JSON.parse(rownode.consigner_locations)
						} catch (e) {

						}
						this.setState({
							sliderRouteTranslate: "slider-translate-60p",
							mapinfo: response.data,
							consignment_code: rownode.truck_no,
							defTransitCoords: temp,
							plantcoords: temp2,
							maptruckno: rownode.truck_no,
							routeTruck: {
								"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
								"endTime": response.data['route_details']['end_time']
							},
							loadshow: 'show-n',
							tolls: response.data.tolls
						})
					}
				}
				catch (e) {
					console.log(e, "error");
				}
			})
			.catch(function (error) {
				console.log(error);
			})
	}
	onShowTransitRouteMap(rownode) {
		// var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		this.setState({
			overly: "show-m",
			loadshow: 'show-m'
		})
		var fintance = moment.parseZone(rownode.leg_start).format('YYYY-MM-DD HH:mm:ss')
		var eintance = moment.parseZone(rownode.leg_end_eta).format('YYYY-MM-DD HH:mm:ss')
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment",
			user_email: this.state.user.email
		}
		redirectURL.post('/consignments/nightdriveroutes', params)
			.then((response) => {
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Vehicle No. : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						rownode: rownode,
						tolls: response.data.tolls,
						leg_no: rownode.leg_no
					});
					this.renderMap();
				}
			})
			.catch(function (error) {
				console.log(error);
				this.setState({
					overly: "show-n",
					loadshow: 'show-n'
				})
			});

	}
	onShowNightDriveRouteMap(rownode) {
		var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment",
			user_email: this.state.user.email
		}
		this.setState({
			overly: "show-m",
			loadshow: 'show-m'
		})
		redirectURL.post('/consignments/nightdriveroutes', params)
			.then((response) => {
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					});
				}
				else {
					this.setState({
						sliderRouteTranslate: "slider-translate-60p",
						mapinfo: response.data,
						consignment_code: rownode.truck_no,
						defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
						maptruckno: rownode.truck_no,
						routeTruck: {
							"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
							"endTime": response.data['route_details']['end_time']
						},
						loadshow: 'show-n',
						tolls: response.data.tolls
					})
				}
			})
			.catch(function (error) {
				this.setState({
					overly: "show-n",
					loadshow: 'show-n'
				})
				console.log(error);
			});
	}
	onShowRouteMap(rownode) {
		// var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		var finstance = moment.parseZone(rownode.first_instance).format('YYYY-MM-DD HH:mm:ss')
		var rinstance = moment.parseZone(rownode.recent_instance).format('YYYY-MM-DD HH:mm:ss')

		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment",
			user_email: this.state.user.email
		}
		this.setState({
			overly: "show-m",
			loadshow: 'show-m'
		})
		redirectURL.post('/consignments/overspeedgpsroutes', params)
			.then((response) => {
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					});
				}
				else {
					this.setState({
						overly: "show-m",
						sliderRouteTranslate: "slider-translate-60p",
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						consignment_code: "Vehicle No. : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": finstance, "endTime": rinstance },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls,
						defTransitCoords: rownode.consignee_coordinates,
					})
				}
			})
			.catch(function (error) {
				this.setState({
					overly: "show-n",
					loadshow: 'show-n'
				})
				console.log(error);
			});
	}
	onShowEnrouteRouteMap(rownode) {
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if (rownode.reset_datetime) {
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		}
		else {
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		}
		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment",
			user_email: this.state.user.email
		}
		this.setState({
			overly: "show-m",
			loadshow: 'show-m'
		})
		redirectURL.post('/consignments/nightdriveroutes', params)
			.then((response) => {
				let records = response.data
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					});
				}
				else {
					var sdate = '';
					var edate = '';
					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
					}
					if (response.data.route_details.start_time != "" && response.data.route_details.start_time != undefined) {
						sdate = records.route_details.start_time;
					}
					if (response.data.route_details.end_time != "" && response.data.route_details.end_time != undefined) {
						edate = records.route_details.end_time;
					}
					this.setState({
						overly: "show-m",
						sliderRouteTranslate: "slider-translate-60p",
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						consignment_code: "Vehicle No. : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls,
						defTransitCoords: rownode.consignee_coordinates,
					})
				}
			})
			.catch(function (error) {
				this.setState({
					overly: "show-n",
					loadshow: 'show-n'
				})
				console.log(error);
			});
	}
	onShowNoGPSRouteMap(rownode) {
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
		eintance = eintance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim()
		if ((rownode.hasOwnProperty("exception_closed") && rownode.exception_closed == 1) || rownode.hasOwnProperty("packet_received_on"))
			eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		else if (rownode.hasOwnProperty("modified_date") && rownode.modified_date != '')
			eintance = rownode.modified_date.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_codes,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment",
			user_email: this.state.user.email
		}
		redirectURL.post('/consignments/nightdriveroutes', params)
			.then((response) => {
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n',
						overly: "show-n",
					})
				}
				else {
					var sdate = '';
					var edate = '';
					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getHyphenDDMMMYYYYHHMM(rownode.leg_end_eta)
					}
					this.setState({
						overly: "show-m",
						sliderRouteTranslate: "slider-translate-60p",
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						consignment_code: "Vehicle No. : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls,
						defTransitCoords: rownode.consignee_coordinates,
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onRowClicked = async (params) => {
		if (params.colDef.field == "ticket_legs") {
			if (params.data.ticket_type == 'transit_delay') {
				// let query = {
				// 	truck_no: params.data.truck_no,
				// 	consignment_code: params.data.consignment_codes,
				// 	consignee_code: params.data.consignee_codes
				// }
				// await redirectURL.post('/consignments/transitdelay', query)
				// 	.then(resp => {
				// 		if (resp.data?.length > 0) {
				// 			params.node.setExpanded(!params.node.expanded)
				// 			this.setState({
				// 				detailCellRendererParams: {
				// 					detailGridOptions: {
				// 						columnDefs: [
				// 							{
				// 								headerName: "",
				// 								field: "_id",
				// 								cellRenderer: RoutemapTransitAction,
				// 								cellRendererParams: {
				// 									onShowTransitRouteMap: this.onShowTransitRouteMap
				// 								},
				// 								width: 50,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}
				// 								}
				// 							},
				// 							{
				// 								headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
				// 								valueGetter: function (params) {
				// 									return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Leg End Time", field: "leg_end_eta",
				// 								width: 120, resizable: true,
				// 								valueGetter: function (params) {
				// 									return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}
				// 								}
				// 							},
				// 							{
				// 								headerName: "Exp. Leg Distance (KM)",
				// 								field: "leg_expected_distance", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									return Math.round(params.data.leg_expected_distance);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Actual Leg Distance Covered (KM)",
				// 								field: "actual_leg_distance", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
				// 										return Math.round(params.data.actual_leg_distance);
				// 									}
				// 									else {
				// 										return 0;
				// 									}
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Exp. Distance from Trip Start(KM)",
				// 								field: "expected_distance_start_to_leg", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
				// 										return Math.round(params.data.expected_distance_start_to_leg);
				// 									}
				// 									else {
				// 										return 0;
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}

				// 							},
				// 							{
				// 								headerName: "Actual Distance from Trip Start (KM)",
				// 								field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
				// 										return Math.round(params.data.actual_distance_traveled_start_to_leg);
				// 									}
				// 									else {
				// 										return 0;
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},

				// 							{
				// 								headerName: "Google Distance from Start (KM)",
				// 								field: "actual_start_to_leg_google_distance",
				// 								width: 140,
				// 								resizable: true,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},
				// 							{
				// 								headerName: "GPS Data Available",
				// 								field: "no_gps_data",
				// 								width: 140,
				// 								resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.no_gps_data == 1) {
				// 										return "Not Available";
				// 									}
				// 									if (params.data.no_gps_data == 0) {
				// 										return "Available";
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},


				// 							{
				// 								headerName: "Trip Completed",
				// 								field: "trip_completed",
				// 								width: 140,
				// 								resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.trip_completed == 1) {
				// 										return "Yes";
				// 									}
				// 									if (params.data.trip_completed == 2) {
				// 										return "";
				// 									}
				// 									if (params.data.trip_completed == 0) {
				// 										return "";
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							}
				// 						],
				// 						defaultColDef: {
				// 						},
				// 					},
				// 					getDetailRowData: function (params) {
				// 						params.successCallback(resp.data);
				// 					},
				// 				}
				// 			})
				// 		}
				// 		else {
				// 			params.node.setExpanded(!params.node.expanded)
				// 			this.setState({
				// 				detailGridOptions: {
				// 					columnDefs: [
				// 						{
				// 							headerName: "", field: "_id",
				// 							cellRendererSelector: function (param) {
				// 								try {
				// 									if (param.data._id != null && param.data._id != undefined) {
				// 										return {
				// 											component: RoutemapTransitAction,
				// 											params: {
				// 												onShowTransitRouteMap: this.onShowTransitRouteMap
				// 											}
				// 										}
				// 									}
				// 								} catch (err) {
				// 									console.log(err);
				// 								}
				// 							},
				// 							width: 50,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}
				// 							}
				// 						},
				// 						{
				// 							headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
				// 							valueGetter: function (params) {
				// 								return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Leg End Time", field: "leg_end_eta",
				// 							width: 120, resizable: true,
				// 							valueGetter: function (params) {
				// 								return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}
				// 							}
				// 						},
				// 						{
				// 							headerName: "Exp. Leg Distance (KM)",
				// 							field: "leg_expected_distance", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								return Math.round(params.data.leg_expected_distance);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Actual Leg Distance Covered (KM)",
				// 							field: "actual_leg_distance", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
				// 									return Math.round(params.data.actual_leg_distance);
				// 								}
				// 								else {
				// 									return 0;
				// 								}
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Exp. Distance from Trip Start(KM)",
				// 							field: "expected_distance_start_to_leg", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
				// 									return Math.round(params.data.expected_distance_start_to_leg);
				// 								}
				// 								else {
				// 									return 0;
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}

				// 						},
				// 						{
				// 							headerName: "Actual Distance from Trip Start (KM)",
				// 							field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
				// 									return Math.round(params.data.actual_distance_traveled_start_to_leg);
				// 								}
				// 								else {
				// 									return 0;
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},

				// 						{
				// 							headerName: "Google Distance from Start (KM)",
				// 							field: "actual_start_to_leg_google_distance",
				// 							width: 140,
				// 							resizable: true,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},
				// 						{
				// 							headerName: "GPS Data Available",
				// 							field: "no_gps_data",
				// 							width: 140,
				// 							resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.no_gps_data == 1) {
				// 									return "Not Available";
				// 								}
				// 								if (params.data.no_gps_data == 0) {
				// 									return "Available";
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},


				// 						{
				// 							headerName: "Trip Completed",
				// 							field: "trip_completed",
				// 							width: 140,
				// 							resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.trip_completed == 1) {
				// 									return "Yes";
				// 								}
				// 								if (params.data.trip_completed == 2) {
				// 									return "";
				// 								}
				// 								if (params.data.trip_completed == 0) {
				// 									return "";
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						}
				// 					],
				// 					defaultColDef: {
				// 					},
				// 				},
				// 				getDetailRowData: function (params) {
				// 					params.successCallback([]);
				// 				},
				// 			})
				// 		}
				// 	})
				// 	.catch(err => {
				// 		this.setState({
				// 			overly: "show-n",
				// 			loadshow: 'show-n'
				// 		})
				// 		console.log(err);
				// 	})
			}
			else if (params.data.ticket_type == 'no_gps') {
				params.node.setExpanded(!params.node.expanded)
				this.setState({
					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{
									headerName: "",
									field: "_id",
									cellRenderer: NoGPSMap,
									cellRendererParams: {
										onShowNoGPSRouteMap: this.onShowNoGPSRouteMap
									},
									resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}
									}
								},
								{
									headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Last Data Receieved",
									field: "last_packet_datetime",
									width: 180,
									resizable: true,
									valueGetter: function (params) {
										return getHyphenDDMMMYYYYHHMM(params.data.last_packet_datetime);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Data Received on",
									field: "packet_received_on",
									width: 180,
									resizable: true,
									valueGetter: function (params) {
										return getHyphenDDMMMYYYYHHMM(params.data.packet_received_on);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								}
							],
							defaultColDef: {
								flex: 1,
							},
						},
						getDetailRowData: (params) => {
							let nogpsrecords = []
							redirectURL.post('/consignments/nogpsdata', {
								consignment_code: params.data.consignment_codes,
								truck_no: params.data.truck_no
							})
								.then(async (response) => {
									nogpsrecords = response.data;
									params.successCallback(nogpsrecords);

								});
						},
					}
				})
			}
			else if (params.data.ticket_type == 'overspeeding') {
				let query = {
					truck_no: params.data.truck_no,
					consignment_code: params.data.consignment_codes
				}
				redirectURL.post('/consignments/overspeed', query)
					.then(resp => {
						if (resp.data?.length > 0) {
							params.node.setExpanded(!params.node.expanded)
							this.setState({
								detailCellRendererParams: {
									detailGridOptions: {
										columnDefs: [
											{
												headerName: "", field: "_id",
												width: 50,
												cellRenderer: RoutemapAction,
												cellRendererParams: {
													onShowRouteMap: this.onShowRouteMap
												},
												resizable: true
											},
											{ headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
											{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
											{
												headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
												valueGetter: function (params) {
													return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
												}
											},
										],
										defaultColDef: {
											flex: 1,
										},
									},
									getDetailRowData: (params) => {
										params.successCallback(resp.data);
									},
								}
							})
						}
						else {
							params.node.setExpanded(!params.node.expanded)
							this.setState({
								detailCellRendererParams: {
									detailGridOptions: {
										columnDefs: [
											{
												headerName: "", field: "_id",
												width: 50,
												cellRenderer: RoutemapAction,
												cellRendererParams: {
													onShowRouteMap: this.onShowRouteMap
												},
												resizable: true
											},
											{ headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
											{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
											{
												headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
												valueGetter: function (params) {
													return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
												}
											},
										],
										defaultColDef: {
											flex: 1,
										},
									},
									getDetailRowData: (params) => {
										params.successCallback([]);
									},
								}
							})
						}
					})
					.catch(err => {
						console.log(err);
					})
			}
			else if (params.data.ticket_type == 'night_driving') {
				params.node.setExpanded(!params.node.expanded)
				this.setState({
					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{
									headerName: "", field: "_id",
									cellRenderer: RouteNightDrivemap,
									cellRendererParams: {
										onShowNightDriveRouteMap: this.onShowNightDriveRouteMap.bind(this)
									},
									resizable: true,
								},
								{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
								{
									headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
									valueGetter: function (params) {
										return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
									}
								},
								{
									headerName: "Traveled Until", field: "recent_instance", resizable: true,
									valueGetter: function (params) {
										return getHyphenDDMMMYYYYHHMM(params.data.recent_instance);
									}
								},
								{
									headerName: "Distance Traveled (km)", field: "distance_from_first_instance", width: 180,
									resizable: true,
									valueGetter: function (params) {
										return Math.round(params.data.distance_from_first_instance)
									}
								}
							],
							defaultColDef: {
								flex: 1,
							},
						},
						getDetailRowData: (params) => {
							let nightdriving = []
							redirectURL.post('/consignments/nightdriving', {
								consignment_code: params.data.consignment_codes,
								gate_out_time: params.data.gate_out_time,
								truck_no: params.data.truck_no
							})
								.then(async (response) => {
									nightdriving = response.data;
									params.successCallback(nightdriving);
								});
						},
					}
				})
			}
			else if (params.data.ticket_type == 'enroute_stoppage') {
				let query = {
					truck_no: params.data.truck_no,
					consignment_code: params.data.consignment_codes,
					old_truck_no: ''
				}
				await redirectURL.post('/consignments/enroutes', query)
					.then(resp => {
						if (resp.data?.length > 0) {
							params.node.setExpanded(!params.node.expanded)
							this.setState({
								detailCellRendererParams: {
									detailGridOptions: {
										columnDefs: [
											// {
											// 	headerName: "",
											// 	field: "_id",
											// 	cellRenderer: RoutemapenrouteAction,
											// 	cellRendererParams: {
											// 		onShowEnrouteRouteMap: this.onShowEnrouteRouteMap
											// 	},
											// 	resizable: true,
											// },
											{
												headerName: "Break Start",
												field: "stoppage_start_time",
												width: 150,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.stoppage_start_time != undefined) {
														return getHyphenDDMMMYYYYHHMM(params.data.stoppage_start_time)
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Break End",
												field: "reset_datetime",
												width: 150,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.reset_datetime != undefined) {
														return getHyphenDDMMMYYYYHHMM(params.data.reset_datetime)
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Exception Closed On",
												field: "modified_date",
												width: 220,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.exception_closed == 1) {

														if (params.data.modified_date != undefined) {
															return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
														}
														else {
															return '';
														}
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Break Time",
												field: "elaped_time_from_last_moved_seconds",
												width: 180,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.exception_closed == 1) {
														var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
														if (typeof params.data.reset_datetime == 'undefined') {
															var edate = moment.parseZone().format("x");
														}
														else {
															var edate = moment.parseZone(params.data.reset_datetime).format("x");
														}
														var diff = (edate - sdate) / 1000;
														return secondsToString(diff);
													}
													else {
														return '';
													}
												}
											}
										],
										defaultColDef: {
											flex: 1,
										},
									},
									getDetailRowData: (params) => {
										params.successCallback(resp.data);
									},
								}
							})
						}
						else {
							params.node.setExpanded(!params.node.expanded)
							this.setState({
								detailCellRendererParams: {
									detailGridOptions: {
										columnDefs: [
											// {
											// 	headerName: "",
											// 	field: "_id",
											// 	cellRenderer: RoutemapenrouteAction,
											// 	cellRendererParams: {
											// 		onShowEnrouteRouteMap: this.onShowEnrouteRouteMap
											// 	},
											// 	resizable: true,
											// },
											{
												headerName: "Break Start",
												field: "stoppage_start_time",
												width: 150,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.stoppage_start_time != undefined) {
														return getHyphenDDMMMYYYYHHMM(params.data.stoppage_start_time)
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Break End",
												field: "reset_datetime",
												width: 150,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.reset_datetime != undefined) {
														return getHyphenDDMMMYYYYHHMM(params.data.reset_datetime)
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Exception Closed On",
												field: "modified_date",
												width: 220,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.exception_closed == 1) {

														if (params.data.modified_date != undefined) {
															return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
														}
														else {
															return '';
														}
													}
													else {
														return '';
													}

												}
											},
											{
												headerName: "Break Time",
												field: "elaped_time_from_last_moved_seconds",
												width: 180,
												resizable: true,
												valueGetter: function (params) {
													if (params.data.exception_closed == 1) {
														var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
														if (typeof params.data.reset_datetime == 'undefined') {
															var edate = moment.parseZone().format("x");
														}
														else {
															var edate = moment.parseZone(params.data.reset_datetime).format("x");
														}
														var diff = (edate - sdate) / 1000;
														return secondsToString(diff);
													}
													else {
														return '';
													}
												}
											}
										],
										defaultColDef: {
											flex: 1,
										},
									},
									getDetailRowData: (params) => {
										params.successCallback([]);
									},
								}
							})
						}
					})
					.catch(err => {
						console.log(err);
					})
			}
			else if (params.data.ticket_type == 'loading_delay') {
			}
			else if (params.data.ticket_type == 'unloading_delay') {
			}
			else if (params.data.ticket_type == 'slow_trucks') {
				// let query = {
				// 	truck_no: params.data.truck_no,
				// 	consignment_code: params.data.consignment_codes,
				// 	consignee_code: params.data.consignee_codes
				// }
				// await redirectURL.post('/consignments/transitdelay', query)
				// 	.then(resp => {
				// 		if (resp.data?.length > 0) {
				// 			params.node.setExpanded(!params.node.expanded)
				// 			this.setState({
				// 				detailCellRendererParams: {
				// 					detailGridOptions: {
				// 						columnDefs: [
				// 							{
				// 								headerName: "",
				// 								field: "_id",
				// 								cellRenderer: RoutemapTransitAction,
				// 								cellRendererParams: {
				// 									onShowTransitRouteMap: this.onShowTransitRouteMap
				// 								},
				// 								width: 50,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}
				// 								}
				// 							},
				// 							{
				// 								headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
				// 								valueGetter: function (params) {
				// 									return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Leg End Time", field: "leg_end_eta",
				// 								width: 120, resizable: true,
				// 								valueGetter: function (params) {
				// 									return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}
				// 								}
				// 							},
				// 							{
				// 								headerName: "Exp. Leg Distance (KM)",
				// 								field: "leg_expected_distance", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									return Math.round(params.data.leg_expected_distance);
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Actual Leg Distance Covered (KM)",
				// 								field: "actual_leg_distance", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
				// 										return Math.round(params.data.actual_leg_distance);
				// 									}
				// 									else {
				// 										return 0;
				// 									}
				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							},
				// 							{
				// 								headerName: "Exp. Distance from Trip Start(KM)",
				// 								field: "expected_distance_start_to_leg", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
				// 										return Math.round(params.data.expected_distance_start_to_leg);
				// 									}
				// 									else {
				// 										return 0;
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}

				// 							},
				// 							{
				// 								headerName: "Actual Distance from Trip Start (KM)",
				// 								field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
				// 										return Math.round(params.data.actual_distance_traveled_start_to_leg);
				// 									}
				// 									else {
				// 										return 0;
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},

				// 							{
				// 								headerName: "Google Distance from Start (KM)",
				// 								field: "actual_start_to_leg_google_distance",
				// 								width: 140,
				// 								resizable: true,
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},
				// 							{
				// 								headerName: "GPS Data Available",
				// 								field: "no_gps_data",
				// 								width: 140,
				// 								resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.no_gps_data == 1) {
				// 										return "Not Available";
				// 									}
				// 									if (params.data.no_gps_data == 0) {
				// 										return "Available";
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}


				// 							},


				// 							{
				// 								headerName: "Trip Completed",
				// 								field: "trip_completed",
				// 								width: 140,
				// 								resizable: true,
				// 								valueGetter: function (params) {
				// 									if (params.data.trip_completed == 1) {
				// 										return "Yes";
				// 									}
				// 									if (params.data.trip_completed == 2) {
				// 										return "";
				// 									}
				// 									if (params.data.trip_completed == 0) {
				// 										return "";
				// 									}

				// 								},
				// 								cellClass: function (params) {
				// 									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 										return 'green';
				// 									}
				// 									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 										return 'lightred';
				// 									}
				// 									else {
				// 										return '';
				// 									}

				// 								}
				// 							}
				// 						],
				// 						defaultColDef: {
				// 						},
				// 					},
				// 					getDetailRowData: function (params) {
				// 						params.successCallback(resp.data);
				// 					},
				// 				}
				// 			})
				// 		}
				// 		else {
				// 			params.node.setExpanded(!params.node.expanded)
				// 			this.setState({
				// 				detailGridOptions: {
				// 					columnDefs: [
				// 						{
				// 							headerName: "", field: "_id",
				// 							cellRendererSelector: function (param) {
				// 								try {
				// 									if (param.data._id != null && param.data._id != undefined) {
				// 										return {
				// 											component: RoutemapTransitAction,
				// 											params: {
				// 												onShowTransitRouteMap: this.onShowTransitRouteMap
				// 											}
				// 										}
				// 									}
				// 								} catch (err) {
				// 									console.log(err);
				// 								}
				// 							},
				// 							width: 50,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}
				// 							}
				// 						},
				// 						{
				// 							headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
				// 							valueGetter: function (params) {
				// 								return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Leg End Time", field: "leg_end_eta",
				// 							width: 120, resizable: true,
				// 							valueGetter: function (params) {
				// 								return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}
				// 							}
				// 						},
				// 						{
				// 							headerName: "Exp. Leg Distance (KM)",
				// 							field: "leg_expected_distance", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								return Math.round(params.data.leg_expected_distance);
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Actual Leg Distance Covered (KM)",
				// 							field: "actual_leg_distance", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
				// 									return Math.round(params.data.actual_leg_distance);
				// 								}
				// 								else {
				// 									return 0;
				// 								}
				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						},
				// 						{
				// 							headerName: "Exp. Distance from Trip Start(KM)",
				// 							field: "expected_distance_start_to_leg", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
				// 									return Math.round(params.data.expected_distance_start_to_leg);
				// 								}
				// 								else {
				// 									return 0;
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}

				// 						},
				// 						{
				// 							headerName: "Actual Distance from Trip Start (KM)",
				// 							field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
				// 									return Math.round(params.data.actual_distance_traveled_start_to_leg);
				// 								}
				// 								else {
				// 									return 0;
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},

				// 						{
				// 							headerName: "Google Distance from Start (KM)",
				// 							field: "actual_start_to_leg_google_distance",
				// 							width: 140,
				// 							resizable: true,
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},
				// 						{
				// 							headerName: "GPS Data Available",
				// 							field: "no_gps_data",
				// 							width: 140,
				// 							resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.no_gps_data == 1) {
				// 									return "Not Available";
				// 								}
				// 								if (params.data.no_gps_data == 0) {
				// 									return "Available";
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}


				// 						},


				// 						{
				// 							headerName: "Trip Completed",
				// 							field: "trip_completed",
				// 							width: 140,
				// 							resizable: true,
				// 							valueGetter: function (params) {
				// 								if (params.data.trip_completed == 1) {
				// 									return "Yes";
				// 								}
				// 								if (params.data.trip_completed == 2) {
				// 									return "";
				// 								}
				// 								if (params.data.trip_completed == 0) {
				// 									return "";
				// 								}

				// 							},
				// 							cellClass: function (params) {
				// 								if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
				// 									return 'green';
				// 								}
				// 								else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
				// 									return 'lightred';
				// 								}
				// 								else {
				// 									return '';
				// 								}

				// 							}
				// 						}
				// 					],
				// 					defaultColDef: {
				// 					},
				// 				},
				// 				getDetailRowData: function (params) {
				// 					params.successCallback([]);
				// 				},
				// 			})
				// 		}
				// 	})
				// 	.catch(err => {
				// 		this.setState({
				// 			overly: "show-n",
				// 			loadshow: 'show-n'
				// 		})
				// 		console.log(err);
				// 	})
			}
		}
		else {

		}
	}

	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			sliderTicketTranslate: '',
			tabsliderTranslate: '',
			inboxSlider: ''
		});

	}

	// onClickSaveGridState() {
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": "Save Grid Layout",
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }
	// 	window.colState = this.gridColumnApi.getColumnState();
	// 	window.groupState = this.gridColumnApi.getColumnGroupState();
	// 	window.sortState = this.gridApi.getSortModel();
	// 	window.filterState = this.gridApi.getFilterModel();

	// 	let screenpage = '';

	// 	if (window.location.pathname == "/tmstickets") {
	// 		screenpage = 'Ticket Management System (Sales and Dispatch)'
	// 	}

	// 	if (window.location.pathname == "/tnptickets") {
	// 		screenpage = 'Ticket Management System (Train and Production)'
	// 	}

	// 	if (window.location.pathname == "/prttickets") {
	// 		screenpage = 'Ticket Management System (Spare Parts)'
	// 	}

	// 	if (window.location.pathname == "/sctickets") {
	// 		screenpage = 'Ticket Management System (Supply Chain)'
	// 	}

	// 	/*Close Tikcets*/
	// 	var closecounters = 0;
	// 	if (window.location.pathname == "/sndclosetickets") {
	// 		screenpage = 'Closed Tickets (Sales and Dispatch)';
	// 	}

	// 	if (window.location.pathname == "/tnpclosetickets") {
	// 		screenpage = 'Closed Tickets (Train and Production)';
	// 	}

	// 	if (window.location.pathname == "/prtclosetickets") {
	// 		screenpage = 'Closed Tickets (Spare Parts)';
	// 	}

	// 	if (window.location.pathname == "/scclosetickets") {
	// 		screenpage = 'Closed Tickets (Supply Chain)';
	// 	}

	// 	let reqparams = {
	// 		gridcolumns: window.colState,
	// 		gridgroup: window.groupState,
	// 		gridcolsort: window.sortState,
	// 		gridcolfilter: window.filterState,
	// 		userId: localStorage.getItem("userid"),
	// 		screenurl: window.location.pathname,
	// 		screentitle: screenpage
	// 	}
	// 	//console.log("reqparams ", reqparams)
	// 	redirectURL.post("/saveGridStates", reqparams)
	// 		.then((response) => {
	// 			//console.log("State response ",response.data)
	// 			this.setState({
	// 				show: true,
	// 				basicTitle: "Successfully saved grid layout",
	// 				basicType: "success",
	// 				screenurl: window.location.pathname,
	// 				screentitle: screenpage
	// 			})
	// 		})
	// 		.catch(function (e) {
	// 			console.log("Error ", e)
	// 		})
	// }

	saveGrid = async (e) => {
		let coloumnState = this.gridColumnApi.getColumnState()
		if (this.state.user != "" && this.state.user.email != undefined) {
			let temp = await saveGridLayout(this.state?.user?.email, "TMSClosed", coloumnState)
			if (temp.data.status == "success") {
				this.setState({
					show: true,
					basicTitle: "Grid successfully updated",
					basicType: "success",
					usergridstate: coloumnState
				})
			}
			else {
				this.setState({
					show: true,
					basicTitle: "Failed to update grid",
					basicType: "danger"
				});
			}
		}
	}

	resetGrid = async () => {
		let coloumnState = []
		if (this.state.user != "" && this.state.user.email != undefined) {
			let temp = await saveGridLayout(this.state?.user?.email, "TMSClosed", coloumnState)
			this.setState({
				usergridstate: coloumnState
			})
			if (temp.data.status == "success") {
				this.setState({
					show: true,
					basicTitle: "Grid successfully reset",
					basicType: "success"
				});
				this.gridApi.resetColumnState()
			}
			else {
				this.setState({
					show: true,
					basicTitle: "Failed to reset grid",
					basicType: "danger"
				});
			}
		}
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	onClickSendEmail(data) {
		console.log("Ticket Data ", data)
		this.setState({
			sliderTicketTranslate: "slider-translate-40p",
			showDiv: 'show-m',
			loadshow: 'show-m',
			sidebarSubHeader: "Consignment Ticket Details",
			overly: 'show-m',
			rownode: data,
			commentTickets: [data.data.ticket_id]
		});
		//console.log("EMail ",data)
	}
	onClickCallNow(data) {
		console.log("Telephone Call ", data)
	}
	onClickCounterShowCriticalData(params) {
		this.gridApi.setRowData(this.state.criticalTickets);
		this.gridApi.onFilterChanged();
	}
	onClickCounterShowData(params) {
		console.log("Counter Params: ", params);
		let filterInstance = null;
		let filterInstance2 = null;
		let filterActivated = false;
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();

		filterInstance = this.gridApi.getFilterInstance("ticket_status");
		filterActivated = true;


		//console.log("filterActivated ",filterActivated)
		if (filterActivated) {

			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["ticket_status"]) {
					if (params == 'open') {
						return rec["ticket_status"] == 1;
					}
					if (params == 'workinprogress') {
						return rec["ticket_status"] == 2;
					}
					if (params == 'closed') {
						return rec["ticket_status"] == 3;
					}
					if (params == 'onhold') {
						return rec["ticket_status"] == 4;
					}
					if (params == 'autoclosed') {
						return rec["ticket_status"] == 5;
					}
					if (params == 'invalid') {
						return rec["ticket_status"] == 6;
					}
				}
			});
			if (isFilterDataAvailable.length) {
				filterInstance.selectNothing();
				if (params == 'open') {
					filterInstance.selectValue(1);
				}
				if (params == 'workinprogress') {
					filterInstance.selectValue(2);
				}
				if (params == 'closed') {
					filterInstance.selectValue(3);
				}
				if (params == 'onhold') {
					filterInstance.selectValue(4);
				}
				if (params == 'autoclosed') {
					filterInstance.selectValue(5);
				}
				if (params == 'invalid') {
					filterInstance.selectValue(6);
				}
				filterInstance.applyModel();
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else {
				// filterInstance.selectNothing();
				//filterInstance.selectValue("");
				// filterInstance.applyModel();
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}
		}
		else {
			this.gridApi.setRowData(this.state.rowData);
			this.gridApi.onFilterChanged();
		}
	}

	onClickTicketTypeCounterShowData(params) {
		try {
			this.setState({
				showMailBtn: 'show-n',
				showCommentBtn: 'show-n'
			});
			switch (params) {
				case "all":
					this.setState({
						rowData: this.state.totaltypes
					})
					break;
				case "transitdelay":
					this.setState({
						rowData: this.state.transitarr
					})
					break;
				case "nogps":
					this.setState({
						rowData: this.state.gpsarr
					})
					break;
				case "enroute":
					this.setState({
						rowData: this.state.enroutearr
					})
					break;
				case "slow_truck":
					this.setState({
						rowData: this.state.slowtrucks
					})
					break;
				case "overspeeding":
					this.setState({
						rowData: this.state.overspeeding
					})
					break;
				case "sim_consent_pending":
					this.setState({
						rowData: this.state.sim_consent_pending
					})
					break;
				case "consent_approved_no_location":
					this.setState({
						rowData: this.state.consent_approved_no_location
					})
					break;
				case "no_sim_data":
					this.setState({
						rowData: this.state.no_sim_data
					})
					break;
				case "night_driving":
					this.setState({
						rowData: this.state.nightdriving
					})
					break;
				case "closed":
					this.setState({
						rowData: this.state.closedarr
					})
					break;
				case "autoclose":
					this.setState({
						rowData: this.state.autoclosedarr
					})
					break;
				case "invalid":
					this.setState({
						rowData: this.state.invalidarr
					})
					break;
				case "force":
					this.setState({
						rowData: this.state.forcearr
					})
					break;
				default:
					this.setState({
						rowData: this.state.totaltypes
					})
					break;
			}
		}
		catch (e) {
			console.log("Counter Filter Error ", e)
		}
	}

	communicationTypesDropdownOptions() {
		var options = [];

		this.state.allCommunicationTypes.map((item) => {
			options.push(<option key={item.com_code} value={item.com_code}>{item.com_name}</option>)
		});
		return options;
	}
	ticketStatusTypesDropdownOptions() {
		var options = [];

		this.state.allTicketStatusTypes.map((item) => {
			options.push(<option key={item.ticket_status_code} value={item.ticket_status_code}>{item.ticket_status_name}</option>)
		});
		return options;
	}
	changeHandler(event) {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({
			[name]: value
		});
	}

	selectOptionsItems() {
		let items = [];
		//console.log("transporterslist ",this.state.alldepartments);
		items.push({ value: 'all', label: "All" });
		this.state.alldepartments.forEach(item => {
			items.push({ value: item.dept_code, label: item.dept_name });
		});
		return items;
	}
	changeArrayHandler = async dept_code => {

		this.setState(
			{ dept_code },
			() => console.log(`Option selected:`, this.state.dept_code)
		);
	}
	onRowSelected(event) {
		//console.log("row " + event.node.data.container_no+ " selected = " + event.node.selected);
		// var items=[];
		var rowCount = event.api.getSelectedNodes();
		// console.log("rowCount ", rowCount)
		// containerslist=[];
		// rowCount.map((item) => {
		//     containerslist.push(item.data.container_no)
		// })
		if (rowCount.length > 0) {
			this.setState({
				showMailBtn: 'show-m',
				showCommentBtn: 'show-m'
			});
		}
		else {
			this.setState({
				showMailBtn: 'show-n',
				showCommentBtn: 'show-n'
			});
		}
	}

	formHandler(event) {
		event.preventDefault();
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.formAction,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		if (this.state.ticket_status_code.value == 3 && this.state.satisfactory == '') {
			this.setState({
				show: true,
				basicTitle: "Satisfactory field should not be empty when status is close or closed no response",
				basicType: "danger"
			});
		}
		else {
			var fomrdata = {
				ticket_id: this.state.commentTickets,
				userId: this.state.user.email,
				communication_type: this.state.com_code.value,
				ticket_status: this.state.ticket_status_code.value,
				satisfactory: this.state.satisfactory,
				reponsecomment: this.state.reponsecomment,
				ticket_comment: this.state.ticket_comment
			}
			redirectURL.post('/saveticketcomment', fomrdata)
				.then((response) => {
					this.setState({
						formMessage: response.data.message,
						formStatus: response.data.status,
						alertFade: false,
						com_code: '',
						ticket_status_code: '',
						ticket_comment: '',
						commentTickets: '',
						reponsecomment: "",
						satisfactory: '',
						sliderTicketTranslate: '',
						overly: 'show-n',
						loadshow: 'show-n'
					})
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	onShowNoGPSKpi = async (params) => {
		//console.log(params);

		//console.log(params.column.colId);
		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.gpsAvailable;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			//console.log("Consign onShowNoGPSKpi params = ",params);
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								// cellRendererFramework:RoutemapNoGPSAction,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "packet_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Packet Latitude",
								field: "last_packed_latitude",
								width: 180,
								resizable: true,

							},
							{
								headerName: "Last Packet Longitude",
								field: "last_packed_longitude",
								width: 180,
								resizable: true,

							}
						],
						// overlayNoRowsTemplate: 'No data available',

					},
					getDetailRowData: function (param) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",param)
						redirectURL.post('/nogpsdata', {
							consignment_code: param.data.consignment_codes,
							truck_no: param.data.truck_no,
							last_packet_datetime: param.data.last_packet_datetime
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									if (item.packet_received_on) {
										var packetreceived = item.packet_received_on;
									}
									else {
										var packetreceived = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');;
									}
									var legdata = {
										_id: item._id,
										consignment_codes: item.consignment_codes,
										exception_closed: item.exception_closed,
										truck_no: item.truck_no,
										last_packed_latitude: item.last_packed_latitude,
										last_packed_longitude: item.last_packed_longitude,
										last_packet_datetime: item.last_packet_datetime,
										ready_to_notify: item.ready_to_notify,
										packet_received_on: packetreceived,
										reset_packet_latitude: item.reset_packet_latitude,
										reset_packet_longitude: item.reset_packet_longitude,
										no_gps_ticket: item.no_gps_ticket,
									}
									legsarr.push(legdata)
									//}
								})
								console.log('transitedelaydata ', transitedelaydata);

								param.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'ticket_type' || params.column.colDef.field == 'no_gps') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	// onShowNoGPSRouteMap(rownode) {
	// 	//console.log("rownode", rownode);
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": googleAnalytics.page.action.viewRoute,
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }
	// 	this.setState({
	// 		loadshow: 'show-m'
	// 	})
	// 	var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
	// 	//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var params = {
	// 		truck_no: rownode.truck_no,
	// 		consignment_code: rownode.consignment_code,
	// 		//consignee_code:rownode.consignee_code,
	// 		first_instance: fintance,
	// 		recent_instance: eintance,
	// 		screen: "consignment"
	// 	}
	// 	redirectURL.post('/nightdriveroutes', params)
	// 		.then((response) => {

	// 			//var records = JSON.parse(JSON.stringify(response)).data;
	// 			//console.log("Inner Grid ",response.data);
	// 			if (response.data.coords.length == 0) {
	// 				this.setState({
	// 					show: true,
	// 					basicTitle: 'No Route data available',
	// 					basicType: "danger",
	// 					loadshow: 'show-n'
	// 				});
	// 			}
	// 			else {
	// 				var sdate = '';
	// 				var edate = '';

	// 				if (rownode.leg_start != '' && rownode.leg_start != undefined) {
	// 					sdate = rownode.leg_start;
	// 				}
	// 				if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
	// 					edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
	// 				}
	// 				this.setState({
	// 					mapinfo: response.data,
	// 					dealer: rownode.consignee_code,
	// 					sliderRouteTranslate: "slider-translate-50p",
	// 					defTransitCoords: rownode.consignee_coordinates,
	// 					consignment_code: "Consignment : " + rownode.truck_no,
	// 					maptruckno: rownode.truck_no,
	// 					routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
	// 					loadshow: 'show-n',
	// 					sidebarSubHeader: "Consignment Leg Information",
	// 					overly: 'show-m',
	// 					rownode: rownode,
	// 					leg_no: rownode.leg_no

	// 				});

	// 				// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
	// 				this.renderMap();

	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// }

	onShowTransitKpi = async (params) => {

		if (params != 0) {
			// let eventLabel = googleAnalytics.page.action.transitDelay;
			// let eventOptions = {
			// 	"category": this.state.pagetitle,
			// 	"action": this.state.eventAction,
			// 	"label": eventLabel,
			// }
			// googleAnalytics.logEvent(eventOptions);
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								// cellRendererFramework:RoutemapTransitAction,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.leg_start);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Leg End Time", field: "leg_end_eta",
								width: 120, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width: 140, resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.leg_expected_distance);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width: 140, resizable: true,
								valueGetter: function (params) {
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
										return Math.round(params.data.actual_leg_distance);
									}
									else {
										return 0;
									}

								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width: 140, resizable: true,
								valueGetter: function (params) {
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
										return Math.round(params.data.expected_distance_start_to_leg);
									}
									else {
										return 0;
									}

								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}

							},
							{
								headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
								valueGetter: function (params) {
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
										return Math.round(params.data.actual_distance_traveled_start_to_leg);
									}
									else {
										return 0;
									}

								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}


							},

							{
								headerName: "Google Distance from Start (KM)",
								field: "actual_start_to_leg_google_distance",
								width: 140,
								resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}


							},
							{
								headerName: "GPS Data Available",
								field: "no_gps_data",
								width: 140,
								resizable: true,
								valueGetter: function (params) {
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if (params.data.no_gps_data == 1) {
										return "Not Available";
									}
									if (params.data.no_gps_data == 0) {
										return "Available";
									}

								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}


							},


							{
								headerName: "Trip Completed",
								field: "trip_completed",
								width: 140,
								resizable: true,
								valueGetter: function (params) {
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if (params.data.trip_completed == 1) {
										return "Yes";
									}
									if (params.data.trip_completed == 2) {
										return "";
									}
									if (params.data.trip_completed == 0) {
										return "";
									}

								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}


							}
						],
						// overlayNoRowsTemplate: 'No data available',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						console.log("Step 4 ", params)
						redirectURL.post('/tickettransitdelay', {
							consignment_code: params.data.consignment_codes,
							consignee_code: params.data.consignee_codes,
							truck_no: params.data.truck_no,
							leg_no: params.data.leg_no
						})
							.then(async (response) => {
								console.log("Step 1 ", response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'ticket_type') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowOverspeedKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		// let eventLabel = googleAnalytics.page.action.overspeed;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								// cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
							{
								headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],

					},
					getDetailRowData: function (param) {
						console.log("Step 4 ", param)
						redirectURL.post('/ticketoverspeed', {
							consignment_code: param.data.consignment_codes,
							truck_no: param.data.truck_no,
							first_instance: param.data.first_instance
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'ticket_type') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		//console.log("Night Drive ",params);

		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								// cellRendererFramework:RouteNightDrivemap,
								width: 50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (km)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/ticketnightdriving', {
							consignment_code: params.data.consignment_codes,
							//gate_out_time:params.data.gate_out_time, 
							truck_no: params.data.truck_no,
							first_instance: params.data.first_instance
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			//if(params.column.colId == 'nightdriving_exception')
			if (params.column.colDef.field == 'ticket_type') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onShowEnrouteStoppagesKpi = async (params) => {
		//console.log(e);
		//console.log("Enroute  ",params);
		// let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							// {
							// 	headerName: "", field: "_id",

							// 	// cellRendererFramework:RoutemapenrouteAction,
							// 	width: 50,
							// 	suppressSizeToFit: true,

							// },
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Break Time",
								field: "break_time",
								width: 180,
								resizable: true
							},
							{
								headerName: "Last Update on",
								field: "break_end",
								width: 220,
								resizable: true
							},
						]
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						if (params.data.stoppage_start_time) {
							var sstartime = params.data.stoppage_start_time
						}
						else {
							var sstartime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						}
						redirectURL.post('/ticketenroutes', {
							consignment_code: params.data.consignment_codes,
							//consignee_code:params.data.consignee_codes[0],
							truck_no: params.data.truck_no,
							stoppage_start_time: sstartime
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								var endata = []
								if (overspeedData.length > 0) {
									overspeedData.map((item) => {
										var cdatetime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
										console.log("Reset ", item.reset_datetime)
										console.log("Stoppage ", item.stoppage_start_time)
										//console.log(item.hasOwnProperty('reset_datetime'));
										if (item.hasOwnProperty('reset_datetime') === false) {
											var cdatetme = moment(cdatetime).format("X");
											var stoptime = moment.parseZone(item.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');

											var etne = moment(stoptime).format("X");
											if (cdatetme < etne) {
												var brtime = etne - cdatetme;

											}
											else {
												var brtime = cdatetme - etne;

											}
											var breaktime = secondsToString(brtime);
											var break_end = moment(cdatetime).format('DD-MM-YYYY HH:mm:ss');
											// console.log("cdatetime ", cdatetime);
											console.log("cdatetme ", cdatetme);
											// console.log("stoppage_start_time ", stoptime);
											// console.log("etne ",etne);
										}
										else {
											if (item.reset_datetime < item.stoppage_start_time) {
												var cdatetme = moment(cdatetime).format("X");
												var etne = moment(item.stoppage_start_time).format("X");
												var brtime = cdatetme - etne;
												var breaktime = secondsToString(brtime);
												var break_end = moment(cdatetime).format('DD-MM-YYYY HH:mm:ss');
												console.log("cdatetme 1", cdatetme);
												console.log("etne 1", etne);
											}
											else {

												var cdatetme = moment(item.reset_datetime).format("X");
												var etne = moment(item.stoppage_start_time).format("X");
												var brtime = cdatetme - etne;
												var breaktime = secondsToString(brtime);
												var break_end = moment(item.reset_datetime).format('DD-MM-YYYY HH:mm:ss');
												console.log("cdatetme here", cdatetme);
												console.log("etne here", etne);
											}
										}

										//  console.log("breaktime ", brtime)
										//  console.log("break_start ", item.stoppage_start_time)
										//  console.log("break_end ", break_end)
										endata.push({
											stoppage_start_time: item.stoppage_start_time,
											break_time: breaktime,
											break_end: break_end,
											truck_no: item.truck_no,
											truck_no: item.truck_no,
											consignment_codes: item.consignment_codes,
											ticket_id: item.ticket_id,
											ticket_type: item.ticket_type,
											ticket_status: item.ticket_status,
											consignment_object_ids: item.consignment_object_ids,
											dept_code: item.dept_code,
											transporter_name: item.transporter_name
										})
									})
								}
								params.successCallback(endata);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'ticket_type') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	// onShowEnrouteRouteMap(rownode) {
	// 	//console.log("Enroute Routemap rownode", rownode);
	// 	this.setState({
	// 		loadshow: 'show-m'
	// 	})
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": googleAnalytics.page.action.viewRoute,
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }
	// 	var fintance = moment.parseZone(rownode.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
	// 	// rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	if (rownode.reset_datetime) {
	// 		if (fintance > rownode.reset_datetime) {
	// 			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

	// 		}
	// 		else {
	// 			//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 			var eintance = moment.parseZone(rownode.reset_datetime).format('YYYY-MM-DD HH:mm:ss');
	// 		}
	// 	}
	// 	else {
	// 		var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

	// 	}
	// 	//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var params = {
	// 		truck_no: rownode.truck_no,
	// 		//consignment_code:rownode.consignment_code,
	// 		//consignee_code:rownode.consignee_code,
	// 		first_instance: fintance,
	// 		recent_instance: eintance,
	// 		screen: "consignment"
	// 	}
	// 	redirectURL.post('/nightdriveroutes', params)
	// 		.then((response) => {

	// 			//var records = JSON.parse(JSON.stringify(response)).data;
	// 			//console.log("Inner Grid ",response.data);
	// 			if (response.data.coords.length == 0) {
	// 				this.setState({
	// 					show: true,
	// 					basicTitle: 'No Route data available',
	// 					basicType: "danger",
	// 					loadshow: 'show-n'
	// 				});
	// 			}
	// 			else {
	// 				var sdate = '';
	// 				var edate = '';

	// 				if (rownode.leg_start != '' && rownode.leg_start != undefined) {
	// 					sdate = rownode.stoppage_start_time;
	// 				}
	// 				if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
	// 					edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
	// 				}
	// 				this.setState({
	// 					mapinfo: response.data,
	// 					dealer: rownode.consignee_code,
	// 					sliderRouteTranslate: "slider-translate-50p",
	// 					defTransitCoords: rownode.consignee_coordinates,
	// 					consignment_code: "Consignment : " + rownode.truck_no,
	// 					maptruckno: rownode.truck_no,
	// 					routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
	// 					loadshow: 'show-n',
	// 					sidebarSubHeader: "Consignment Leg Information",
	// 					overly: 'show-m',
	// 					rownode: rownode,
	// 					leg_no: rownode.leg_no

	// 				});

	// 				// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
	// 				this.renderMap();

	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// }

	onShowLoadingDelayKpi = async (params) => {
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		// let eventLabel = googleAnalytics.page.action.loadingDelay;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		var consignment = '';
		var reqdata = {
			consignment_code: params.data.consignment_codes
		}
		await redirectURL.post("/item", reqdata)
			.then((response) => {
				//console.log("consignment ",response.data)

				consignment = response.data;
			})
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.gate_in_time) {
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									//console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								},

							},
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,

							}

						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						if (consignment[0].gate_in_time != undefined) {
							var g1 = getDDMMYYYYHHMMSS(consignment[0].gate_in_time);
							//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
							var gdate1 = new Date(consignment[0].gate_in_time);
							var fromDate = g1;
						}
						else {

							var g1 = getDDMMYYYYHHMMSS(consignment[0].inside_fence);
							var gdate1 = new Date(consignment[0].inside_fence);
							var fromDate = g1;
						}
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if (consignment[0].gate_out_time != undefined) {
							var g2 = getDDMMYYYYHHMMSS(consignment[0].gate_out_time);
							var gdate2 = new Date(consignment[0].gate_out_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else {
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout - gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						let loadingTimeDifference = null;

						var dataarr = []

						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							"loading_time": diffHrs + " Hr(s) " + " " + diffMins + " Min(s)"
						})
						params.successCallback(dataarr);
					}

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if (params.column.colDef.field == 'ticket_type') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}



	onShowUnLoadingDelayKpi = async (params) => {
		//console.log(e);
		//console.log("Unloading ",params);
		// let eventLabel = googleAnalytics.page.action.unloadingDelay;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		var consignment = '';
		var reqdata = {
			consignment_code: params.data.consignment_codes
		}
		await redirectURL.post("/item", reqdata)
			.then((response) => {
				console.log("consignment ", response.data)

				consignment = response.data;
			})
		//console.log("Here consignment",consignment[0].first_dealer_reported);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],

					},
					getDetailRowData: function (param) {
						console.log("Step 4 ", param)
						console.log("Step 5 ", consignment)
						if (consignment[0].locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(consignment[0].first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(consignment[0].locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("param.data.left_dealer_marked ", param.data.left_dealer_marked)
						if (consignment[0].left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(consignment[0].left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						console.log("From Date ", fromField)
						console.log("To Date ", toField)
						//var total =0 ;
						console.log("gdate1 ", frmd)
						console.log("gdate2 ", tod)
						console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = param.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						if (diffDays) {
							loadingTimeDifference = diffDays + " Day(s) " + diffHrs + " Hr(s) " + " " + diffMins + " Min(s)";
						} else {
							loadingTimeDifference = diffHrs + " Hr(s) " + " " + diffMins + " Min(s)";
						}
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						param.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if (params.column.colDef.field == 'ticket_type') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}

	/*Google Route Maps Start*/

	// onShowRouteMap = async (rownode) => {
	// 	// console.log("rownode", rownode);
	// 	// console.log("rownode.consignment_code ", rownode.consignment_code)
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": googleAnalytics.page.action.viewRoute,
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }
	// 	var reqs = {
	// 		ticket_id: rownode.ticket_id
	// 	}
	// 	//console.log("reqs ", reqs)

	// 	this.setState({
	// 		loadshow: 'show-m'
	// 	})
	// 	var d = new Date(rownode.first_instance);
	// 	var r = new Date(rownode.recent_instance);
	// 	var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var params = {
	// 		truck_no: rownode.truck_no,
	// 		consignment_code: rownode.consignment_codes,
	// 		consignee_code: rownode.consignee_codes,
	// 		first_instance: finstance,
	// 		recent_instance: rinstance,
	// 		screen: "consignment"
	// 	}

	// 	await redirectURL.post('/overspeedgpsroutes', params)
	// 		.then((response) => {

	// 			//var records = JSON.parse(JSON.stringify(response)).data;
	// 			//console.log("Overspeed  ",rownode);
	// 			this.setState({
	// 				mapinfo: response.data,
	// 				//dealer:rownode.consignee_code,
	// 				sliderRouteTranslate: "slider-translate-50p",
	// 				consignment_code: rownode.truck_no,
	// 				maptruckno: rownode.truck_no,
	// 				routeTruck: {
	// 					"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
	// 					"endTime": response.data['route_details']['end_time']
	// 				},
	// 				overly: 'show-m',
	// 				loadshow: 'show-n',
	// 				rownode: rownode
	// 			});
	// 			//this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, 0, rownode.truck_no)

	// 			this.renderMap();


	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// }

	// onShowTransitRouteMap(rownode) {
	// 	//console.log("rownode", rownode);
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": googleAnalytics.page.action.viewRoute,
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }
	// 	this.setState({
	// 		loadshow: 'show-m'
	// 	})
	// 	var d = new Date(rownode.leg_start);
	// 	var r = new Date(rownode.leg_end_eta);
	// 	var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var params = {
	// 		truck_no: rownode.truck_no,
	// 		consignment_code: rownode.consignment_code,
	// 		consignee_code: rownode.consignee_code,
	// 		first_instance: fintance,
	// 		recent_instance: eintance,
	// 		screen: "consignment"
	// 	}
	// 	redirectURL.post('/nightdriveroutes', params)
	// 		.then((response) => {

	// 			//var records = JSON.parse(JSON.stringify(response)).data;
	// 			//console.log("Inner Grid ",response.data);
	// 			if (response.data.coords.length == 0) {
	// 				this.setState({
	// 					show: true,
	// 					basicTitle: 'No Route data available',
	// 					basicType: "danger",
	// 					loadshow: 'show-n'
	// 				});
	// 			}
	// 			else {
	// 				var sdate = '';
	// 				var edate = '';

	// 				if (rownode.leg_start != '' && rownode.leg_start != undefined) {
	// 					sdate = rownode.leg_start;
	// 				}
	// 				if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
	// 					edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
	// 				}
	// 				this.setState({
	// 					mapinfo: response.data,
	// 					dealer: rownode.consignee_code,
	// 					sliderRouteTranslate: "slider-translate-50p",
	// 					defTransitCoords: rownode.consignee_coordinates,
	// 					consignment_code: "Consignment : " + rownode.truck_no,
	// 					maptruckno: rownode.truck_no,
	// 					routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
	// 					loadshow: 'show-n',
	// 					sidebarSubHeader: "Consignment Leg Information",
	// 					overly: 'show-m',
	// 					rownode: rownode,
	// 					leg_no: rownode.leg_no
	// 				});

	// 				// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
	// 				this.renderMap();

	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// }

	// onShowNightDriveRouteMap(rownode) {
	// 	//console.log("nightdrivenode", rownode);
	// 	this.setState({
	// 		loadshow: 'show-m'
	// 	});
	// 	// if (googleAnalytics.page.enableGA) {
	// 	// 	let pageTitle = this.state.pagetitle;
	// 	// 	let eventOptions = {
	// 	// 		"category": pageTitle,
	// 	// 		"action": this.state.eventGridAction,
	// 	// 		"label": googleAnalytics.page.action.viewRoute,
	// 	// 	}
	// 	// 	googleAnalytics.logEvent(eventOptions);
	// 	// }

	// 	var d = new Date(rownode.first_instance);
	// 	var r = new Date(rownode.recent_instance);
	// 	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	// 	var params = {
	// 		truck_no: rownode.truck_no,
	// 		first_instance: fintance,
	// 		recent_instance: eintance,
	// 	}

	// 	redirectURL.post('/nightdriveroutes', params)
	// 		.then((response) => {

	// 			//var records = JSON.parse(JSON.stringify(response)).data;
	// 			console.log("nightroutedata", response.data);

	// 			this.setState({
	// 				mapinfo: response.data,
	// 				sliderRouteTranslate: "slider-translate-50p",
	// 				consignment_code: rownode.truck_no,
	// 				defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
	// 				maptruckno: rownode.truck_no,
	// 				routeTruck: {
	// 					"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
	// 					"endTime": response.data['route_details']['end_time']
	// 				},
	// 				loadshow: 'show-n',
	// 				overly: 'show-m'
	// 			});
	// 			this.renderMap();


	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});


	// }

	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		redirectURL.post('/googlemaproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				console.log("Google ", response)
				//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
				this.setState({
					googelRoutes: response.data.googleroute
				})
			})
			.catch(function (e) {
				console.log(e)
			})
	}
	onShowRouteDiv = (e) => {
		// if (googleAnalytics.page.enableGA) {
		//     let eventOptions = {
		// 		"category": this.state.pagetitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		//console.log("Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		var gateouttime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		if (e.gate_out_time) {
			var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		}

		var edate = '';
		var end_date;
		if (e.status == 2) {
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

			edate = getDDMMYYYYHHMMSS(end_date);
		}

		else if (e.status == 3 || e.status == 4) {
			if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
		}

		else if (e.status == 5) {
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
			}

		}
		else {
			if (e.created_date) {
				end_date = e.created_date.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.created_date);
			}

		}

		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}

		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
			truck_no: e.truck_no,
			consignment_code: e.consignment_code,
			consignee_code: e.consignee_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment",
			user_email: this.state.user.email
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("Props data ", e)

				this.setState({
					loadshow: 'show-m'
				});
				if (records.coords.length == 0) {
					//console.log("records", records.coords);
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					if (records != '') {
						var sdate = e.gate_out_time;
						var edate = '';
						if (e.status == 2) {
							//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
							//var edatesecond = new Date(ms);
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
							edate = getDDMMYYYYHHMMSS(end_date);
						}

						else if (e.status == 3 || e.status == 4) {
							edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
						}

						else if (e.status == 5) {
							edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
						}
						else {
							edate = getDDMMYYYYHHMMSS(e.created_date);
						}
						//console.log("End Date ", edate)
						this.setState({
							sliderRouteTranslate: "slider-translate-50p",
							showDiv: 'show-m',
							mapinfo: records,
							dealer: e.consignee_code,
							consignment_code: "Consignment : " + e.consignment_code,
							maptruckno: e.truck_no,
							routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": edate },
							loadshow: 'show-n',
							sidebarSubHeader: "Consignment Information",
							overly: 'show-m',
							rownode: e,
							leg_no: 0
						});
						// this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
						this.renderMap();

					}
				}


			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	renderMap = () => {
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	/*End Maps*/

	onClickSendEMails = async (etype) => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Send Email",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("etype", etype)
		//console.log("rowCount ", rowCount)
		if (rowCount.length > 0) {

			containerslist = [];
			var existdept = [];
			var existtransport = [];
			var transporter = [];
			var dept = [];
			rowCount.map((item) => {
				containerslist.push({
					"truck_no": item.data.truck_no,
					"ticket_id": item.data.ticket_id,
					"ticket_type": item.data.ticket_type,
					//"transporter_name":item.data.transporter_name,
					"consignment_codes": item.data.consignment_codes,
					"consignee_codes": item.data.consignee_codes,
					"dept_code": item.data.dept_code,

				})
				existdept.push(item.data.dept_code)
				existtransport.push(item.data.transporter_name)
				transporter.push(item.data.transporter_name);
				dept.push(item.data.dept_code);
			})

			const uniqueDepts = existdept.filter((value, index, self) => {
				return self.indexOf(value) === index
			})
			const uniqueTransporters = existtransport.filter((value, index, self) => {
				return self.indexOf(value) === index
			})
			//console.log("uniqueDepts ", JSON.stringify(containerslist))
			if (uniqueDepts.length == 1 && uniqueTransporters.length == 1) {
				var issues = {
					tickets: containerslist,
					escalation: etype,
					transporter_name: transporter[0],
					dept_code: dept[0]
				}
				await redirectURL.post("/submitIssuesMails", issues)
					.then((response) => {
						var records = response.data;
						//console.log("Issues Tickets ", records)
						try {
							this.setState({
								toggleMore: !this.state.toggleMore,
								inboxSlider: 'slider-translate-75p',
								showleftmailside: 'show-n',
								sendmaildiv: "col-xl-12",
								overly: 'show-m',
								emailformat: records,
								toEmail: records[0].toEmail,
								ccEmail: records[0].ccEMail,
								ticketEmailContent: records[0].body,
								ticketSubject: records[0].subject,
								ticketMailID: records[0]._id,
								ticketslist: records[0].tickets
							});
						}
						catch (e) {
							this.setState({
								toggleMore: !this.state.toggleMore,
								inboxSlider: '',
								overly: 'show-n',
								emailformat: []
							});
						}
					})
				//console.log("Ticketlist ",containerslist)
			}
			else {
				if (uniqueDepts.length > 0) {
					this.setState({
						show: true,
						basicTitle: "Multiple Departments or Transporters are not allowed",
						basicType: "danger",
						toggleMore: !this.state.toggleMore
					});
				}
				else {
					this.setState({
						show: true,
						basicTitle: "No Tickets selected",
						basicType: "danger",
						toggleMore: !this.state.toggleMore
					});
				}
				this.gridApi.deselectAll();
			}

		}
		else {
			alert("Please select atleast one item")
		}

	}
	onClickSaveMailNow = async (e) => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Save Mail",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		e.preventDefault();
		var mailData = await {
			ticketId: this.state.ticketMailID,
			tickets: this.state.ticketslist,
			toEmail: this.state.toEmail,
			ccEmail: this.state.ccEmail,
			ticketEmailContent: this.state.ticketEmailContent,
			ticketSubject: this.state.ticketSubject,
			userId: this.state.user?.email
		}
		// console.log("mailData ", this.state.ticketEmailContent)
		// console.log("mailData Userid ", mailData)
		setTimeout(
			await redirectURL.post("/saveTicketMail", mailData)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Mail saved successfully",
						basicType: "success"
					});
					//window.location.reload();
				}), 1000);

	}
	onClickSaveAndSendMailNow() {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Save and Send Mails",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		if (Array.isArray(this.state.toEmail)) {
			var tickmail = this.state.toEmail;
		}
		else {
			var tmas = this.state.toEmail.split(",")
			var tomalarr = [];
			if (tmas.length > 0) {
				tmas.forEach((item) => {
					tomalarr.push(item)
				})
			}
			var tickmail = tomalarr;
		}

		if (Array.isArray(this.state.ccEmail)) {
			var cctickmail = this.state.ccEmail;
		}
		else {
			var cctickmail = [this.state.ccEmail];
		}
		var mailData = {
			ticketId: this.state.ticketMailID,
			tickets: this.state.ticketslist,
			toEmail: tickmail,
			ccEmail: cctickmail,
			ticketEmailContent: this.state.ticketEmailContent,
			ticketSubject: this.state.ticketSubject,
			userId: this.state.user?.email
		}
		//console.log("mailData ", mailData)
		//console.log("mailData Userid ", this.state.user?.email)
		redirectURL.post("/saveandsendmail", mailData)
			.then((response) => {
				console.log(" response ", response)
				this.setState({
					inboxSlider: '',
					showleftmailside: 'show-n',
					sendmaildiv: "col-xl-12",
					overly: 'show-n',
					emailformat: [],
					toEmail: '',
					ccEmail: '',
					ticketEmailContent: '',
					ticketSubject: '',
					ticketMailID: '',
					show: true,
					basicTitle: response.data.message,
					basicType: "success"
				});
				window.location.reload();
			})
	}
	onClickInbox() {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Drafts",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		redirectURL.post("/drafttickets")
			.then((response) => {
				//console.log("Draft ", response.data)
				var drafts = response.data
				if (drafts.length > 0) {
					this.setState({
						inboxSlider: 'slider-translate-75p',
						overly: 'show-m',
						showleftmailside: 'show-m',
						sendmaildiv: "col-xl-9",
						draftlist: drafts,
						toEmail: '',
						ccEmail: '',
						ticketEmailContent: '',
						ticketSubject: '',
						ticketMailID: ''
					});
				}
				else {
					this.setState({
						inboxSlider: '',
						overly: 'show-n',
						showleftmailside: 'show-n',
						sendmaildiv: "col-xl-12",
						toEmail: '',
						ccEmail: '',
						ticketEmailContent: '',
						ticketSubject: '',
						ticketMailID: '',
						show: true,
						basicTitle: "No drafts found",
						basicType: "warning"
					});
				}

			})
	}

	onClickDraftItem = (params) => {
		//console.log("params ", params)

		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Show Draft Item",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var reqparams = {
			ticketemailid: params._id
		}
		redirectURL.post("/ticketemailinfo", reqparams)
			.then((response) => {
				console.log("Draft ", response.data)
				var records = response.data
				this.setState({
					emailformat: records,
					toEmail: records[0].toEmail,
					ccEmail: records[0].ccEMail,
					ticketEmailContent: records[0].body,
					ticketSubject: records[0].subject,
					ticketMailID: records[0]._id,
					ticketslist: records[0].tickets
				});
			})
	}

	/*Inbox actions Start*/
	mailToggle(tab) {
		if (this.state.SelectedTab !== tab) {
			this.setState({
				SelectedTab: tab
			});
		}
	}

	updateContent(newContent) {
		console.log("newContent ", newContent)
		this.setState({
			ticketEmailContent: newContent.editor.getData()
		})
	}


	onBlur = async (evt) => {
		//console.log("onBlur event called with event info: ", evt.editor.getData());
		await this.setState({
			ticketEmailContent: evt.editor.getData()
		})
	}

	afterPaste(evt) {
		//console.log("afterPaste event called with event info: ", evt);
		this.setState({
			ticketEmailContent: evt.editor.getData()
		})
	}

	HandleToggleMore() {
		this.setState({
			toggleMore: !this.state.toggleMore
		})
	}

	onChangeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onShowTicketHistory = async (rowdata) => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Show Ticket History",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var transitedelaydata = [];
		await this.setState({
			detailCellRendererParams: {
				suppressRefresh: true,
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Communication Type",
							field: "communication_type",
							width: 150,
							resizable: true,
						},
						{
							headerName: "Response Email",
							field: "reponsecomment",
							width: 400,
							resizable: false,
						},
						{
							headerName: "Comment",
							field: "ticket_comment",
							width: 400,
							resizable: false,
						},
						{
							headerName: "Quality of Response",
							field: "satisfactory",
							width: 400,
							resizable: false,
						},
						{
							headerName: "Comment By",
							field: "username",
							width: 150,
							resizable: true,
						},
						{
							headerName: "Created Date",
							field: "created_date",
							width: 150,
							resizable: true,
							valueGetter: function (params) {
								return getDDMMYYYYHHMMSS(params.data.created_date)
							}
						},

					],
					defaultColDef: {
						flex: 1,
					},
				},
				getDetailRowData: function (params) {
					//console.log("Step 4 ",transitedelaydata)
					var qu = {
						ticket_id: params.data.ticket_id,
						ticket_no: params.data.ticket_number,
					}
					redirectURL.post('/tickethistorybyid', qu)
						.then(async (response) => {
							console.log("Step 1 ", response.data)
							params.successCallback(response.data);
							//console.log("Step 2 ",response.data)
						});
				}
			}
		});

		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if (rowdata.column.colDef.field == '_id') {
			rowdata.node.setExpanded(!rowdata.node.expanded);
			//console.log("expanded ",params.node.expanded)
			if (rowdata.node.expanded == false) {
				transitedelaydata = []
			}
		}
		else {
			rowdata.node.setExpanded(false);
		}
	}
	onClickCommentBtn = (data) => {

		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Comment Button",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		this.setState({
			sliderTicketTranslate: "slider-translate-40p",
			showDiv: 'show-m',
			loadshow: 'show-n',
			sidebarSubHeader: "Ticket Details",
			overly: 'show-m',
			ticket_status_code: this.state.allTicketStatusTypes.find(e => e.value == data.ticket_status),
			commentTickets: [data.ticket_number]
		});
	}



	// handlerStartDateTime = (event, currentDate, selectedDate) => {
	// 	var d = new Date(event._d);

	// 	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

	// 	this.setState({
	// 		startDate:startdate
	// 	});
	// 	//let value = event.target.value;
	//     //this.setState({'startDate':value});
	// }

	// handlerEndDateTime  = (event, currentDate, selectedDate) => {
	// 	var d = new Date(event._d);

	//     var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
	//     this.setState({
	// 		endDate:edate
	// 	});
	// }

    handleStartDateChange = (date) => {
        if (this.state.endDate && date > this.state.endDate) {
            alert("From Date cannot be greater than To Date.");
        } else {
            this.setState({ startDate: date });
        }
    };

    handleEndDateChange = (date) => {
        if (this.state.startDate && date < this.state.startDate) {
            alert("To Date cannot be less than From Date.");
        } else {
            this.setState({ endDate: date });
        }
    };


	onShowDeptData = async () => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Filter",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var records = [];
		this.setState({
			loadshow: 'show-m'
		})
		if (this.state.startDate == '' || this.state.endDate == '') {
			var actiontype = 0;
		}
		else {
			actiontype = 1;
		}
		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sdate = this.state.defaultsdate
		}
		else {
			var sdate = this.state.startDate
		}

		if (this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN") {
			var edate = this.state.defaultedate
		}
		else {
			var edate = this.state.endDate
		}
		var reqparms = {
			startDate: sdate,
			endDate: edate,
			dept_code: this.state.dept_code,
			actiontype: actiontype
		}
		var urlpath;
		if (window.location.pathname == "/tmstickets" ||
			window.location.pathname == "/tnptickets" ||
			window.location.pathname == "/prttickets" ||
			window.location.pathname == "/sctickets") {
			urlpath = '/departementtickets';

		}

		/*Close Tikcets*/

		if (window.location.pathname == "/sndclosetickets" ||
			window.location.pathname == "/tnpclosetickets" ||
			window.location.pathname == "/prtclosetickets" ||
			window.location.pathname == "/scclosetickets") {
			urlpath = '/departmentclosetickets';

		}

		this.onLoadShowTMSList(urlpath, reqparms)


	}


	/*End*/

	onClickSaveGridState() {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Save Grid Layout",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		if (window.location.pathname == "/tmstickets") {
			screenpage = 'Ticket Management System (Sales and Dispatch)'
		}

		if (window.location.pathname == "/tnptickets") {
			screenpage = 'Ticket Management System (Train and Production)'
		}

		if (window.location.pathname == "/prttickets") {
			screenpage = 'Ticket Management System (Spare Parts)'
		}

		if (window.location.pathname == "/sctickets") {
			screenpage = 'Ticket Management System (Supply Chain)'
		}

		/*Close Tikcets*/
		var closecounters = 0;
		if (window.location.pathname == "/sndclosetickets") {
			screenpage = 'Closed Tickets (Sales and Dispatch)';
		}

		if (window.location.pathname == "/tnpclosetickets") {
			screenpage = 'Closed Tickets (Train and Production)';
		}

		if (window.location.pathname == "/prtclosetickets") {
			screenpage = 'Closed Tickets (Spare Parts)';
		}

		if (window.location.pathname == "/scclosetickets") {
			screenpage = 'Closed Tickets (Supply Chain)';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {

		if (this.state.usergridstate.length > 0) {
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": "Reset Grid Layout",
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};


	render() {
		//console.log("ticketHistoryLog ", this.state.ticketHistoryLog)
		var tktHistory = [];
		if (this.state.ticketHistoryLog.length > 0) {
			this.state.ticketHistoryLog.forEach(item => {
				tktHistory.push({
					username: item.username,
					ticket_comment: item.ticket_comment,
					communication_type: item.communication_type,
					ticket_status: item.ticket_status,
					created_date: item.created_date
				})
			});
		}
		let hideonlyInvlid = (window.location.pathname == "/invalidtickets") ? true : false;
		let showonlytms = (this.state.isinvalidtickets == '') ? false : true;

		var draftcount = this.state.totalDrafts;
		const contactcolumnwithDefs = [

			{
				headerName: "Contact Name",
				field: "contact_name",
				width: 180
			},

			{
				headerName: "Contact Number",
				field: "phone_no",
				width: 180,
				cellClass: ['wraptext'],
				valueGetter: function (params) {
					var nos = '';
					if (params.data.phone_no != '' && params.data.phone_no.length > 0) {
						console.log("params.data.phone_no ", params.data.phone_no.toString().replace("[", "").replace("]", "").replace(/"/g, "").replace(/'/g, ""))
						// params.data.phone_no.map((item) => {
						// 	nos=nos+params.data.phone_no+",";
						// });
						// return nos;
						return params.data.phone_no.toString().replace("[", "").replace("]", "").replace(/"/g, "").replace(/'/g, "");
					}
					else {
						return '';
					}
				}

			},

			{
				headerName: "Escalation Level",
				field: "escalation_level",
				width: 180,
				sort: 'asc'
			}
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
				<h5 className="mb-15p fbold f20">
					<i className="icofont icofont-ticket cus-i"></i><span style={{ fontWeight: "600" }}>Closed Tickets</span>
				</h5>
				{this.state.alert}
				<div className="row">					<div className="row col-xl-12 col-lg-12">
						<div className="col-xl-3 col-lg-3 form-group">
							<label>From Date</label>
							<DatePicker
								selected={this.state.startDate}
								onChange={this.handleStartDateChange}
								className="form-control"
								// id="ndfromDate"
								autoComplete="off"
								placeholderText="From date"
								dateFormat="dd/MM/yyyy"
							/>
						</div>
						<div className="col-xl-3 col-lg-3 form-group">
							<label>To Date</label>
							<DatePicker
								selected={this.state.endDate}
								onChange={this.handleEndDateChange}
								className="form-control"
								// id="ndtoDate"
								autoComplete="off"
								placeholderText="To date"
								dateFormat="dd/MM/yyyy"
							/>
						</div>
						<div className={"col-xl-3 col-lg-3 form-group float-left"}>
							<label></label><br />
							<button type="button" onClick={this.onShowDeptData.bind(this)} className="btn btn-success mt-9p">Submit</button>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8 col-sm-8 beffect">
						<div className="card" style={{ height: "176px" }}>
							<div className="card-body" style={{ padding: "14px" }}>
								<div className="crm-numbers pb-0">
									<div className="row">
										{/* <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "all")}>
											<span className="f13">
												Total
											</span>
											<h4 className="txt-pink-medium f30">
												<span className="counter">
													<CountUp end={this.state.totaltypesLen} />
												</span>
											</h4>
										</div> */}
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "transitdelay")}>
											<h4 className="txt-pink-medium f30">
												<span className="counter">
													<CountUp end={this.state.transitarrLen} />
												</span>
											</h4>
											<span className="f13">
												Transit Delay
											</span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "nogps")}>
											<h4 className="txt-danger f30">
												<span className="counter">
													<CountUp end={this.state.gpsarrLen} />
												</span>
											</h4>
											<span className="f13">
												No GPS Data </span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "enroute")}>
											<h4 className="maroon f30">
												<span className="counter">
													<CountUp end={this.state.enroutearrLen} />
												</span>
											</h4>
											<span className="f13">
												Stoppages
											</span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "sim_consent_pending")}>
											<h4 className="maroon f30">
												<span className="counter">
													<CountUp end={this.state.sim_consent_pendingLen} />
												</span>
											</h4>
											<span className="f13">
												Consent Pending
											</span>
										</div>
									</div>
									<div className='row'>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "no_sim_data")}>
											<h4 className="darkorange  f30">
												<span className="counter">
													<CountUp end={this.state.no_sim_dataLen} />
												</span>
											</h4>
											<span className="f13">
												No SIM data (&gt;1 hr)
											</span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "overspeeding")}>
											<h4 className="darkblue  f30">
												<span className="counter">
													<CountUp end={this.state.overspeedingLen} />
												</span>
											</h4>
											<span className="f13">
												Overspeeding
											</span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "night_driving")}>
											<h4 className="txt-primary  f30">
												<span className="counter">
													<CountUp end={this.state.nightdrivingLen} />
												</span>
											</h4>
											<span className="f13">
												Night Driving
											</span>
										</div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this, "consent_approved_no_location")}>
											<h4 className="txt-primary  f30">
												<span className="counter">
													<CountUp end={this.state.consent_approved_no_locationLen} />
												</span>
											</h4>
											<span className="f13">
												Consent Approved - No SIM Data
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*Ticket Status*/}
					<div className="col-xl-4 col-lg-4 col-sm-4 beffect">
						<div className="card" style={{ height: "176px" }}>
							<div className="card-body" style={{ padding: "35px" }}>
								<div className="crm-numbers" style={{ padding: "0px" }}>
									<div className="row">
										<div className="col cpointer pt-26px" onClick={this.onClickTicketTypeCounterShowData.bind(this, "closed")} style={{ marginTop: "-15px" }}>
											<h4 className="txt-pink-medium f30">
												<span className="counter">
													<CountUp end={this.state.closedarrLen} />
												</span>
											</h4>
											<span className="f13">
												Closed
											</span>
										</div>
										<div className="col cpointer pt-26px" onClick={this.onClickTicketTypeCounterShowData.bind(this, "autoclose")} style={{ marginTop: "-15px" }}>
											<h4 className="txt-secondary f30">
												<span className="counter">
													<CountUp end={this.state.autoclosedarrLen} />
												</span>
											</h4>
											<span className="f13">
												Auto-Close</span>
										</div>
										<div className="col cirlce-d cpointer pt-26px" onClick={this.onClickTicketTypeCounterShowData.bind(this, "invalid")} style={{ marginTop: "-15px" }}>
											<h4 className="txt-info f30">
												<span className="counter">
													<CountUp end={this.state.invalidarrLen} />
												</span>
											</h4>
											<span className="f13">
												Invalid</span>
										</div>
										{/* <div className="col cirlce-d cpointer pt-26px" onClick={this.onClickTicketTypeCounterShowData.bind(this, "force")} style={{ marginTop: "-15px" }}>
											<h4 className="purple f30">
												<span className="counter">
													<CountUp end={this.state.forcearrLen} />
												</span>
											</h4>
											<span className="f13">
												Force Close</span>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header pt-10px" >
								<div className="col-xl-12 col-lg-12">
									<h5>
										{/* <i className="icofont icofont-ticket cus-i"></i> 
										<span>Ticket Management System</span>
										<button type="button" onClick={this.onClickInbox.bind(this)} className="btn btn-warning float-right">
											Draft {(draftcount != '')?("("+draftcount+")"):""}
										</button>*/}

										<span className="layoutbtns float-right">
											<div className="col-sm-12">

												<button type="button" title="Reset Grid Layout" name="resetgrid" className="btn btn-info float-right action_btn" style={{ marginLeft: "10px" }} onClick={this.resetGrid}> <i className="fa fa-reply"></i> </button>
												<button type="button" title="Save Grid Layout" name="savegrid" className="btn btn-success float-right action_btn" onClick={this.saveGrid}> <i className="fa fa-save"></i> </button>
											</div>
										</span>
									</h5>

								</div>
							</div>
							<div className="row card-body pt-15px" >
								<div id="myGrid" style={{ height: "550px", width: "100%" }} className="col-xl-12 col-lg-12 ag-theme-balham">
									<AgGridReact
										columnDefs={this.state.columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										masterDetail={true}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										rowSelection={this.state.rowSelection}
										suppressRowClickSelection={true}
									/>

									{/* <div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>
				<div className={"slide-r " + (this.state.sliderTicketTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							{(this.state.sidebarSubHeader)}
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">

							{/*Ticket form start*/}


							<div className="crm-numbers pb-0">

								<div className="col-xl-12 col-lg-12 row">
									{/* "FORM START" */}
									<form className="row theme-form" onSubmit={this.formHandler.bind(this)}>

										<div className="col-md-12 col-sm-6 col-xl-6 col-lg-6">
											<div className="form-group">
												<label className="col-form-label">Communication Type</label>
												<Select
													className="border-radius-0"
													isMulti={false}
													value={this.state.com_code}
													onChange={e => this.setState({ com_code: e })}
													style={{ borderRadius: "0px" }}
													options={this.state.allCommunicationTypes} required />
											</div>
										</div>
										<div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 ">
											<div className="form-group">
												<label className="col-form-label">Status</label>
												<Select
													className="border-radius-0"
													isMulti={false}
													value={this.state.ticket_status_code}
													onChange={e => this.setState({ ticket_status_code: e })}
													style={{ borderRadius: "0px" }}
													options={this.state.allTicketStatusTypes} required />
											</div>
										</div>
										<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
											<div className="form-group">
												<label className="col-form-label">Quality of Response</label>
												<div>
													<input type="radio" name="satisfactory" onChange={this.changeHandler} value="Yes" /> Satisfactory
													&nbsp;&nbsp;<input type="radio" name="satisfactory" onChange={this.changeHandler} value="No" /> Not Satisfactory
												</div>
											</div>
										</div>
										{(tktHistory.length) ?
											<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
												<div className="form-group">
													<label className="col-form-label">History:</label>
													<ul class="crm-activity ch300" style={{ overflowY: "auto" }}>
														{tktHistory.map((item) =>
															<li class="media">
																<span class="mr-3 font-primary">
																	{item.username[0]}
																</span>
																<div class="align-self-center media-body">
																	<h6 class="mt-0">
																		{item.ticket_comment}
																	</h6>
																	<ul class="dates">
																		<li class="digits">
																			Communication: {item.communication_type}
																		</li>
																		<li class="digits">
																			Created at: {getDDMMYYYY(item.created_date)}
																		</li>
																		<li class="digits">
																			By: {item.username}
																		</li>
																	</ul>
																</div>
															</li>
														)}
													</ul>
												</div>
											</div>

											: ""}

										<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
											<div className="form-group">
												<label className="col-form-label">Comments</label>
												<textarea required className="form-control" rows="6" column="10" name="reponsecomment" value={this.state.reponsecomment} onChange={this.changeHandler} placeholder="Please provide details" />
											</div>
										</div>
										<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
											<div className="form-group">
												<label className="col-form-label">Paste Response Email</label>
												<textarea required className="form-control" rows="6" column="10" name="ticket_comment" value={this.state.ticket_comment} onChange={this.changeHandler} placeholder="Please provide details" />
											</div>
										</div>
										<div className="form-row col-xl-12 col-sm-12 col-lg-12">
											<div className="col-sm-6">
												<button type="submit" className="btn btn-secondary">Save</button>
											</div>

										</div>

									</form>

									{/* "FORM END" */}
								</div>
							</div>

							{/*End*/}


						</div>
					</div>

				</div>

				{/*Google Route Maps Starts*/}
				{
					(this.state.routeTruck != '') ?
						<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
							<div className="slide-r-title">
								<h4>
									{(this.state.consignment_code)}
									<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

								</h4>
							</div>
							<div className="slide-r-body" style={{ position: "relative" }}>

								<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
									<DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />

									<div className="col-xl-12 col-lg-12 n-p-0">
										<div className="crm-numbers pb-0">
											<h3 className="subH">{this.state.sidebarSubHeader}</h3>
											<div className="col-xl-12 col-lg-12 row">
												<div className="col route-block">
													<label className="sidebar-label">Truck No</label>
													<div>{this.state.routeTruck.truck_no}</div>
												</div>
												<div className="col route-block">
													<label className="sidebar-label">Start Time</label>
													<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
												</div>
												<div className="col route-block">
													<label className="sidebar-label">End Time</label>
													<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
												</div>

												<div className="col route-block">
													<label className="sidebar-label">GPS Distance</label>
													<div>{(this.state.mapinfo.route_details != '') ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
												</div>
												{(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
													:
													<div className="col route-block">
														<label className="sidebar-label">Google Distance</label>
														<div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
													</div>
												}


											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
						: ""}

				<div className={"slide-r " + (this.state.inboxSlider)} style={{ overflow: "hidden" }}>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							{/* Inbox Start  */}
							<div className="email-wrap">
								<div className="row">
									<div className={"col-xl-3 col-md-6 " + (this.state.showleftmailside)}>
										<div className="email-right-aside">
											<div className="card email-body">
												<div className="pr-0 b-r-light">
													<div className="email-top n-p-0">
														<div className="row">
															<div className="col">
																<h5>Draft</h5>
															</div>

														</div>
													</div>

													<div className="inbox">
														{this.state.draftlist.map((item, index) => (
															<div className="media cpointer brd-top" onClick={this.onClickDraftItem.bind(this, item)}>
																<div className="media-body">
																	<h6 className="mt-0" style={{ textAlign: "left" }}>{item.subject}</h6>
																</div>
															</div>
														))}

													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={" col-md-12 " + (this.state.sendmaildiv)}>
										<div className="email-right-aside">
											<div className="card email-body radius-left">
												<div className="pl-0">
													<TabContent className="tab-content" activeTab={this.state.SelectedTab}>
														<TabPane tabId="1">
															<div className="email-compose">
																<div className="email-top compose-border n-p-0">
																	<div className="row">
																		<div className="col-sm-8 xl-50">
																			<h4 className="mb-0">Send Mail</h4>
																		</div>
																		<div className="col-sm-4 btn-middle xl-50">
																			<button type="button"
																				onClick={this.onClickSaveMailNow.bind(this)}
																				className="btn btn-secondary btn-mail text-center mb-0 mt-0 fright">
																				<i className="fa fa-save mr-2"></i> SAVE
																			</button>
																			<button type="button"
																				onClick={this.onClickSaveAndSendMailNow.bind(this)}
																				className="btn btn-primary btn-mail text-center mb-0 mt-0 mr-5p fright">
																				<i className="fa fa-paper-plane mr-2"></i> SEND
																			</button>
																		</div>
																	</div>

																</div>
																<div className="email-wrapper">
																	<form className="theme-form">
																		<div className=" row form-group">
																			<label htmlFor="exampleInputEmail1"
																				className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
																			<input type="email" className="form-control col-xl-10 col-lg-10"
																				id="exampleInputEmail1" name="toEmail" value={this.state.toEmail}
																				onChange={this.onChangeHandler} aria-describedby="emailHelp" />
																		</div>
																		<div className="form-group row">
																			<label htmlFor="exampleInputEmail1"
																				className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
																			<input type="email" className="form-control col-xl-10 col-lg-10"
																				id="exampleInputEmail1" name="ccEmail" value={this.state.ccEmail}
																				onChange={this.onChangeHandler} aria-describedby="emailHelp" />
																		</div>
																		<div className="form-group row">
																			<label
																				htmlFor="exampleInputPassword1" className=" col-xl-2 col-lg-2">Subject</label>
																			<input type="text" className="form-control col-xl-10 col-lg-10" value={this.state.ticketSubject}
																				onChange={this.onChangeHandler} name="ticketSubject" id="exampleInputPassword1" />
																		</div>
																		<div className="form-group mb-0">
																			<label className="text-muted">Message</label>
																			{/* <CKEditor
																						min-height="500px"
																							config={ {height : "400px"}}
																							activeClass="p10"
																							content={this.state.ticketEmailContent}
																							events={{
																								"blur": this.onBlur.bind(this),
																								"afterPaste": this.afterPaste.bind(this),
																								"updateContent": this.updateContent.bind(this)
																							}}
																						/> */}
																		</div>

																	</form>
																</div>
															</div>
														</TabPane>

													</TabContent>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Inbox End  */}


						</div>
					</div>
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					{/* <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div> */}
				</div>
				{/* <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}
			</div>

		);
	}
}
function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

const OpenCommentSlider = (props) => {
	return (
		<i className="fa fa-comments txt-info f20" onClick={e => props?.onClickCommentBtn(props.data)}></i>
	)
}