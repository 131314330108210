// import React, { Component } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

// import Map from './mapLocationComponent';

// // import redirectURL from '../components/redirectURL';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';

// export default class TruckSidebarComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.state={
//             truckData:this.props.truckInfo,
//             consignmentlists:this.props.consignments,
//             defaultColDef: {
//                 sortable: true,
//                 filter: true,
//                 editable: true,
//             },
// 			rowData: null,
// 			animateRows: true,

//         };
//        // console.log("Contxt", this.props.context);
//        // this.showRelatedData()
//     }
//     handleClick = (e) => {
//         e.stopPropagation();
//         this.props.context.onCloseUploadDiv();
//     };

//     onGridReady = params => {
// 	    this.gridApi = params.api;
// 	    //console.log("Grid is: ", this.gridOptionsWrapper);
// 	    this.gridColumnApi = params.columnApi;
	
// 	   //    ChartMenu.prototype.saveChart = function (e) {
// 	// 	   var event = {
// 	// 	      data: this.gridOptionsWrapper.getApi(),
// 	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
// 	// 	    };
// 	// 	   //console.log("Testing", event);
// 	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
// 	// 	   this.dispatchEvent(event);
// 	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
// 	//    };
	
// 	};
	

// 	 onGridState = () =>{
// 		 //console.log(this.gridApi);
		
// 		 /*Get  Current Columns State and Store in this.colState */
// 		   this.colState = this.gridColumnApi.getColumnState();

// 		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
// 		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

// 		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
// 		  * User should enable the Pivot mode.*/
// 		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
// 		 /*Get Current Filter State and Store in window.filterState */
// 		   window.filterState = this.gridApi.getFilterModel();
// 		   this.gridApi.setFilterModel(window.filterState);
		    
		  
		   
// 	 }
     
     
//     render(){
//     const consigneecols = [
//         {
//             headerName: "Consignment Code",
//             field: "consignment_code",
//             width: 150,
//             filter: "agTextColumnFilter"
//         },
//         {
//             headerName: "Consigner Code",
//             field: "consigner_code",
//             width: 150,
//             filter: "agTextColumnFilter"
//         },
//         {
//             headerName: "Consignee Code",
//             field: "consignee_code",
//             width: 150,
//             filter: "agTextColumnFilter"
//         },
//         {
//             headerName: "Cluster",
//             field: "cluster",
//             width: 150,
//             filter: "agTextColumnFilter"
//         },
//         {
//             headerName: "Gate In time",
//             field: "gate_in_time",
//             width: 150,
//             filter: "agTextColumnFilter",
//             valueGetter:function(params){
//                 return getDDMMYYYYHHMMSS(params.data.gate_in_time)
//             }
//         },
//         {
//             headerName: "Gate Out time",
//             field: "gate_out_time",
//             width: 150,
//             filter: "agTextColumnFilter",
//             valueGetter:function(params){
//                 return getDDMMYYYYHHMMSS(params.data.gate_out_time)
//             }
//         },
//     ];
//         return ( 
//                 <div className="col-xl-12 xol-lg-12">
//                     <div className="row">
//                         <div className="slide-r-title col-xl-12 col-lg-12" style={{padding:"0px"}}>
//                             <h4>
//                                 Truck No #{this.props.truckNo}
//                             </h4>
//                         </div>
//                         <div className="slide-r-body col-xl-12 col-lg-12" style={{position:"relative"}}>
//                             <div className="col-xl-12 col-lg-12">
//                             <Accordion preExpanded='info'>
//                                 <AccordionItem uuid='info'>
//                                     <AccordionItemHeading>
//                                         <AccordionItemButton>
//                                             Basic Information:
//                                         </AccordionItemButton>
//                                     </AccordionItemHeading>
//                                     <AccordionItemPanel key={this.props.truckInfo}>
                                        
//                                          <table className="table table-border">
//                                              <tbody>
//                                              {this.props.truckInfo.map(item=>{
//                                             return [<tr>
//                                                 <th>Transporter Name</th>
//                                                 <td>{item.transporter_name}</td>
//                                             </tr>,
//                                             <tr>
//                                                 <th>Transporter Code</th>
//                                                 <td>{item.transporter_code}</td>
//                                             </tr>,
//                                             <tr>
//                                                 <th>Department Code</th>
//                                                 <td>{item.dept_code}</td>
//                                             </tr>,

//                                             <tr>
//                                                 <th>Last Data Received On</th>
//                                                 <td>{item.last_gps_date}</td>
//                                             </tr>]
//                                              })
//                                              }
//                                             </tbody>
//                                         </table>
                                           
                                        
//                                     </AccordionItemPanel>
//                                 </AccordionItem>
                                
//                                 <AccordionItem>
//                                     <AccordionItemHeading>
//                                         <AccordionItemButton>
//                                               Recent Consignments:
//                                         </AccordionItemButton>
//                                     </AccordionItemHeading>
//                                     <AccordionItemPanel key={this.state.consignmentlists}>
                                         
//                                         <div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
//                                             <AgGridReact
//                                                 columnDefs={consigneecols}
//                                                 defaultColDef={this.state.defaultColDef}
//                                                 rowData={this.props.consignments}
//                                                 enableCharts={false}
//                                                 enableRangeSelection={false}
//                                                 onGridReady={this.onGridReady}
//                                                 onGridState={this.onGridState}
                                            
//                                             />
//                                         </div>
                                           
//                                     </AccordionItemPanel>
//                                 </AccordionItem>
                                
//                                 <AccordionItem>
//                                     <AccordionItemHeading>
//                                         <AccordionItemButton>
//                                          Current Location:
//                                         </AccordionItemButton>
//                                     </AccordionItemHeading>
//                                     <AccordionItemPanel>
//                                         <Map width={"92%"} coordinates={this.props.currenttrucklocation} marginLeft={"15px"} />
//                                     </AccordionItemPanel>
//                                 </AccordionItem>
//                             </Accordion>

//                             </div>
//                             <div className="col-xl-12 col-lg-12 mt-30p">
//                                 <button onClick={this.handleClick.bind(this)} className="btn btn-default">Close</button>
//                             </div>
//                         </div>
//                      </div>
//                 </div>
// 			);
// 		}
// }












