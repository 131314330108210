import React from 'react';

const EditIconRenderer1 = (props) => {
    const handleClick = () => {
        const geoname = props.data._id;
        const modifiedName = geoname;
        window.location.href = `/editgeofence/${modifiedName}`;
    };

    return (
        <span
            className="custom-btn f12 label label-success"
            style={{ padding: "0px", width: "46px", height: "21px", display: 'inline-block', textAlign: 'center', lineHeight: '21px', cursor: 'pointer' }}
            title="Edit"
            onClick={handleClick}
        >
           <i className="icofont icofont-edit" title="Edit"></i> Edit
        </span>
    );
};

export default EditIconRenderer1;
