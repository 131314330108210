// var redirectURL = require('../redirectURL');
// var moment = require('moment');
import redirectURL from '../redirectURL'
import moment from "moment"
//let now = moment();
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format());
//console.log(moment.parseZone("2020-02-17T10:51:55.000Z").format("DD-MM-YYYY hh:mm:ss"))
export var getDDMMYYYY = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD/MM/YYYY');
    }
}
// exports.getDDMMYYYY = getDDMMYYYY;

export var getDDMMMYYYY = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD/MMM/YYYY');
    }
}
// exports.getDDMMMYYYY = getDDMMMYYYY;


export var getDDMMYYYYHHMM = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD/MM/YYYY hh:mm');
    }
}
// exports.getDDMMYYYYHHMM = getDDMMYYYYHHMM;


export var getDDMMYYYYHHMMSS = function (date_string) {
    //console.log("date_string ", date_string);
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD/MM/YYYY HH:mm:ss');
    }

}
// exports.getDDMMYYYYHHMMSS = getDDMMYYYYHHMMSS;


export var getYYYYMMDD = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('YYYY/MM/DD');
    }
}
// exports.getYYYYMMDD = getYYYYMMDD;

export var getYYYYMMDDHHMMSS = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('YYYY/MM/DD HH:mm:ss');
    }
}
// exports.getYYYYMMDDHHMMSS = getYYYYMMDDHHMMSS;

export var getHyphenYYYYMMDDHHMMSS = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('YYYY-MM-DD HH:mm:ss');
    }
}
// exports.getHyphenYYYYMMDDHHMMSS = getHyphenYYYYMMDDHHMMSS;


export var getHyphenYYYYMMDD = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('YYYY-MM-DD');
    }
}
// exports.getHyphenYYYYMMDD = getHyphenYYYYMMDD;

export var getHyphenDDMMYYYYHHMMSS = function (date_string) {
    //console.log("date_string ", date_string);
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MM-YYYY HH:mm:ss');
    }

}
// exports.getHyphenDDMMYYYYHHMMSS = getHyphenDDMMYYYYHHMMSS;

export var getHyphenDDMMMYYYYHHMM = function (date_string) {
    //console.log("date_string ", date_string);
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }

}
// exports.getHyphenDDMMMYYYYHHMM = getHyphenDDMMMYYYYHHMM;


export var getHyphenDDMMYYYY = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MM-YYYY');
    }
}
// exports.getHyphenDDMMYYYY = getHyphenDDMMYYYY;

export var getHyphenDDMMMYYYY = function (date_string) {
    if (date_string == '' || date_string == undefined) {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }
}
// exports.getHyphenDDMMMYYYY = getHyphenDDMMMYYYY;


export var getDDMMMYYYYDefault = function (date_string) {
    //console.log("date_string ", date_string);
    //var d = date_string.split(" ")

    if (date_string == '1970-01-01' || date_string == undefined || date_string == "") {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MMM-YYYY');
    }

}
// exports.getDDMMMYYYYDefault = getDDMMMYYYYDefault;


// var getDDMMMYYYYHHMMDefault = function(date_string) {
//     //var d = date_string.split(" ")

//    if(date_string == '' || date_string == undefined)
//     {
//         return '';
//     }
//     else{
//         return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
//     }

// }
// exports.getHyphenDDMMMYYYYHHMM = getDDMMMYYYYHHMMDefault;

export var getDDMMMYYYYHHMMDefault = function (date_string) {
    //console.log("date_string ", date_string);
    if (date_string == '' || date_string == undefined || date_string == "1970-01-01T00:00:00.000Z") {
        return '';
    }
    else {
        return moment.parseZone(date_string).format('DD-MMM-YYYY HH:mm');
    }

}
// exports.getDDMMMYYYYHHMMDefault = getDDMMMYYYYHHMMDefault;

export var convertSecondsToHHMM = function (seconds) {
    if (seconds == "0" || seconds == "") {
        return "";
    }
    else {
        seconds = Number(seconds);
        var hh = Math.floor((seconds / 3600));
        var hrr = Math.floor(seconds - (hh * 3600));
        var mm = Math.floor(hrr / 60)
        if (mm < 10) {
            var mins = "0" + mm;
        }
        else {
            var mins = mm;
        }

        if (hh < 10) {
            var hrs = "0" + hh;
        }
        else {
            var hrs = hh;
        }
        return hrs + ":" + mins;
    }
}

// exports.convertSecondsToHHMM = convertSecondsToHHMM;

export var saveGridLayout = async function (email, page, colState, counter = null) {
    try {
        let saveGridParams = {
            email: email,
            page: page,
            colState: colState
        }
        if (counter)
            saveGridParams["counter"] = counter
        return redirectURL.post("/saveAggridLayout", saveGridParams)
    } catch (error) {
        console.error('Failed to save grid state:', error);
        return "fail"
    }
}
// exports.saveGridLayout = saveGridLayout;

export var fetchGridLayout = async function (email, page, counter = null) {
    try {
        let body = {
            email: email,
            page: page
        }
        if (counter)
            body["counter"] = counter
        return redirectURL.post('/fetchAggridLayout', body)
    } catch (error) {
        console.error('Failed to save grid state:', error);
        return "fail"
    }
}
// exports.fetchGridLayout = fetchGridLayout;

export const getDecoder = function () {
    try {

        const decipher = salt => {
            const textToChars = text => text.split('').map(c => c.charCodeAt(0));
            const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
            return encoded => encoded.match(/.{1,2}/g)
                .map(hex => parseInt(hex, 16))
                .map(applySaltToChar)
                .map(charCode => String.fromCharCode(charCode))
                .join('');
        }
        const decode = decipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr")
        return JSON.parse(decode(localStorage.getItem("k")))
    } catch (error) {
        console.error('Failed to save grid state:', error);
        return "fail"
    }
}