import { useEffect, useMemo } from "react";
import { getDecoder, getHyphenDDMMMYYYYHHMM } from "../common/utils"
import redirectURL from "../redirectURL";
var infoBox = require('../../common/google-infowindow');

const DeviationRouteMap = (props) => {

    var map = ''
    var currentwindow;
    const user = useMemo(() => getDecoder(), []);
    useEffect(() => {
        if (!isEmpty(props.openMap.rownode)) {
            let rownode = props.openMap.rownode
            var intance = rownode.deviation_timestamp.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
            var params = {
                "truck_no": rownode.truck_no,
                "gate_out_time": intance,
                "seconddate": intance,
                "screen": "consignment",
                "display_elis_base_route": 1,
                "consignee_code": rownode.consignee_code,
                "consignment_code": rownode.consignment_code,
                "user_email": user.email
            }
            props.setOverlay("show-m")
            props.setLoadShow("show-m")
            redirectURL.post('/consignments/maproutes', params)
                .then((response) => {
                    var records = response.data;
                    if (response?.data?.status == "failure") {
                        props.setShow(true)
                        props.setTitle("No GPS data available")
                        props.setType("danger")
                        props.setOverlay("show-n")
                        props.setLoadShow("show-n")
                        return
                    }
                    else if (!(response?.data !== null && typeof response?.data === 'object')) {
                        props.setShow(true)
                        props.setTitle("No GPS data available")
                        props.setType("danger")
                        props.setOverlay("show-n")
                        props.setLoadShow("show-n")
                    }
                    try {
                        if (records.coords.length == 0) {
                            props.setShow(true)
                            props.setTitle("No GPS data available")
                            props.setType("danger")
                        }
                        else {
                            if (records != '') {
                                var baseRouteCoords = eval(records.base_route);
                                loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
                                window.initMap = () => {
                                    map = new window.google.maps.Map(document.getElementById('map_canvas'), {
                                        zoom: 5,
                                        center: new window.google.maps.LatLng(28.4519751, 77.0310713),
                                        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                                    });
                                    if (baseRouteCoords.length > 0) {
                                        const flightPath = new window.google.maps.Polyline({
                                            path: baseRouteCoords,
                                            geodesic: true,
                                            strokeColor: "#000FF",
                                            strokeOpacity: 1.0,
                                            strokeWeight: 3,
                                            icons: [
                                                {
                                                    icon: {
                                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                        strokeColor: "#32CD32",
                                                        fillColor: "#32CD32",
                                                        fillOpacity: 1,
                                                        strokeWeight: 2,
                                                    },
                                                    repeat: "200px",
                                                    path: [],
                                                },
                                            ],
                                        });
                                        flightPath.setMap(map)

                                        const bounds = new window.google.maps.LatLngBounds();
                                        for (const coord of baseRouteCoords) {
                                            bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
                                        }
                                        map.fitBounds(bounds);

                                        new window.google.maps.Marker({
                                            position: new window.google.maps.LatLng(baseRouteCoords[0].lat, baseRouteCoords[0].lng),
                                            icon: require("../../assets/icons/source_icon.png"),
                                            map: map,
                                        })
                                        new window.google.maps.Marker({
                                            position: new window.google.maps.LatLng(baseRouteCoords[baseRouteCoords.length - 1].lat, baseRouteCoords[baseRouteCoords.length - 1].lng),
                                            icon: require("../../assets/icons/destination_icon.png"),
                                            map: map,
                                        })
                                        let devmarker = new window.google.maps.Marker({
                                            position: new window.google.maps.LatLng(rownode.deviation_lat, rownode.deviation_lng),
                                            icon: require("../../assets/icons/truck-end.png"),
                                            map: map,
                                        })

                                        var infowindow = new window.google.maps.InfoWindow();
                                        window.google.maps.event.addListener(devmarker, 'click', (function (marker) {
                                            return function () {
                                                var contentarr = []
                                                var header = "Deviation Point"
                                                contentarr.push({ "key": "Deviation at ", "value": getHyphenDDMMMYYYYHHMM(props?.openMap?.rownode?.deviation_timestamp) })
                                                var contentString = infoBox(devmarker.icon, header, contentarr, '')
                                                infowindow.setContent(contentString);
                                                currentwindow = infowindow;
                                                infowindow.open(map, devmarker);
                                            }
                                        })(devmarker));
                                    }
                                }
                                props.setLoadShow("show-n")
                            }
                        }
                    }
                    catch (e) {
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [props])
    return (
        <div className={"slide-r " + (props.openMap.open ? "slider-translate-60p" : "")} style={{ overflow: "auto" }}>
            <div className="slide-r-title">
                <h4>
                    {"Deviation Map"}
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={e => props.closeMapModal()} >X</span>
                </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                    <div id="map_canvas" className="" style={{ width: '100%', height: "80vh" }}></div>
                    <div className="col-xl-12 col-lg-12 n-p-0">
                        <div className="crm-numbers pb-0">
                            <div className="col-xl-12 col-lg-12 row">
                                <div className="col route-block">
                                    <label className="sidebar-label">Truck No.</label>
                                    <div>{props?.openMap?.rownode?.truck_no}</div>
                                </div>
                                <div className="col route-block">
                                    <label className="sidebar-label">Deviation Time</label>
                                    <div>{(!isEmpty(props?.openMap?.rownode)) ? getHyphenDDMMMYYYYHHMM(props?.openMap?.rownode?.deviation_timestamp) : "-"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeviationRouteMap

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
