const DeviationMap=(props)=>{
    const handleClick = (e) => {
        e.stopPropagation();
        props.onShowDeviationRouteMap(props.data);
    };

    return (
        <div>
			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-map-pins f20"></i> </button>
       
        </div>
    );
}
export default DeviationMap