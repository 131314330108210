
const ConsignmentActions = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log("MAP Icon ", props)
        // props.context.componentParent.onCloseUploadDiv(e);
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.onShowRouteData(props);
    };

    return (
        <a onClick={handleRouteClick} title="View Route"><i className="icofont icofont-map-pins f20"></i> </a>
    );
};

export default ConsignmentActions;
