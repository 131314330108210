
const Edit = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.onClickEditBlock(props);
    };

    return (
            <a onClick={handleRouteClick}  title="Edit"><i className="icofont icofont-edit f15"></i>&nbsp;</a>
        
    );
};

export default Edit;
