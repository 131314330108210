import IconNoSim from '../../assets/icons/no_gps.png'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TMSException = (params) => {

    if (params.data.ticket_type == 'transit_delay') {
        return
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-example">Transit Delay</Tooltip>}><i className="icofont icofont-clock-time f20" style={{ color: "#FF5370" }}></i>
        </OverlayTrigger>
    }
    else if (params.data.ticket_type == 'no_gps') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">No GPS Data</Tooltip>}><i className="icofont icofont-social-google-map txt-secondary f20"></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'overspeeding') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Overspeeding</Tooltip>}><i className="fa fa-tachometer f20" style={{ color: "#3300c2" }}></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'night_driving') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Night Driving</Tooltip>}><i className="icofont icofont-full-night f20" style={{ color: "#ab8ce4" }}></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'enroute_stoppage') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Stoppages</Tooltip>}><i className="icofont icofont-pause f20" style={{ color: "#a34518" }}></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'loading_delay') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Loading Delay</Tooltip>}><i className="icofont icofont-truck-alt f20" style={{ color: "#d3a337" }}></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'unloading_delay') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Unloading Delay</Tooltip>}><i className="icofont icofont-truck-loaded f20" style={{ color: "#7ad337" }}></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'slow_trucks') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Slow Trucks</Tooltip>}><i className="fa fa-truck darkorange f20"></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'sim_consent_pending') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Consent Pending</Tooltip>}><i className="icofont icofont-not-allowed f20"></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'no_sim_data') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">No SIM data (&gt; hr)</Tooltip>}><i className="icon-signal txt-danger f20"></i>
            </OverlayTrigger>
        )
    }
    else if (params.data.ticket_type == 'consent_approved_no_location') {
        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-example">Consent Approved - No SIM Data</Tooltip>}><img src={IconNoSim} style={{ width: "20px" }} />
            </OverlayTrigger>
        )
    }
    else {
        return (<></>)
    }
}
export default TMSException