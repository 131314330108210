
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import Multiselect from 'multiselect-dropdown-react';
// import Breadcrumb from '../common/breadcrumb.component';
import redirectURL from "../redirectURL";

var googleAnalytics = require("./googleAnalytics.jsx");


var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var newcoordinates = [];
var geoFencingArea;
const fenchtypeoptions = [
    { value: 'Plant', label: 'Plant' },
    { value: 'Customer', label: 'Customer' },

];


export default class EditGeoFence extends Component {
    constructor(props) {
        super(props);
    this.state = {
        pageTitle: "Edit GeoFence",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        routemap: '',
        departments: [],
        coords: [],
        geo_name: "",
        location_code:'',
        geo_type: '',
        fence_type: '',
        dept_code: [],
        notify: '',
        erroMessage: '',
        successMessage: '',
        selectedDepts: [],
        oldgeofencename: '',
        coordinates: [],
        redUrl: "",
        show: false,
        basicType: "default",
        basicTitle: "",
        plantDetails: null,
    }

};


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async () => {
        
      
        console.log(this.props,'params97')
        var geoname = window.location.pathname;
        var originalName = geoname.split('/').pop();
        console.log(originalName,'originalName')
        var fdata = {
            "geofencename": originalName,
            // dept_code : dpt
        }


        await redirectURL.post('list', fdata, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response) => {
                var redurl  = ""
                // if (this.props.match.path.indexOf("mlleditGeofence") >= 0) {
                //     var redurl = '/manage/mllsndgeofences';

                // }
                // else {

                // }
                coordinates = JSON.parse(response.data[0].geofence_coordinates);
                // console.log("cc ", coordinates)
                //console.log("response.data[0].geofence_coordinates ", response.data[0].geofence_coordinates)
                var geotypesarr = '';

                geotypesarr = { value: response.data[0].geofence_type.value, label: response.data[0].geofence_type.label };
                
                this.setState({
                    redUrl: redurl,
                    geo_name: response.data[0].geofence_name,
                    geo_type: response.data[0].geofence_type,
                     location_code: response.data[0].consignee_code,
                    fence_type: response.data[0].fence_loading_type,
                    // dept_code: alldepts,
                    notify: response.data[0].notify_me,
                     oldgeofencename:originalName, 
                    // selectedDepts: alldepts,
                    coords: response.data[0].geofence_coordinates,
                    coordinates: coordinates
                });

            })
            .catch(function (error) {
                console.log(error)
            });
        setTimeout(
            function () {
                this.showMap();
            }
                .bind(this),
            2000
        );


    }

    getDropDownList = () => {
        let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        redirectURL.post('/getTypeDropDownForgeoFence', { dept_code: dpt }).then(response => {
            // console.log("response118", response.data)
            let typeListOptions = response.data.data1
            let plantListOptoins = response.data.pD1
            // console.log('typeListOptions',typeListOptions)
            // console.log('plantListOptoins',plantListOptoins)
            this.setState({
                typeListOptions,
                plantListOptoins
            })

        })
    }

    fenceTypeHandler = (event) => {
        console.log(event.target)
    }


    changeHandler = geo_type => {

        this.setState(
            { geo_type, },
            () => console.log(`Option selected:`, this.state.geo_type)
        );

    }

    changePlantHandler = plantDetails => {
        this.setState({ plantDetails })
    }

    checkHandler = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    changeInputHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    
    changeLocationHandler(event) {
        const { name, value } = event.target;
        this.setState({
          [name]: value
        });
      }

    changeArrayHandler = selectedDepts => {


        this.setState(
            { selectedDepts },
            // () => console.log(`Option selected:`, this.state.selectedDepts)
        );
    }
    changeGeoTypeHandler = geo_type => {

        this.setState(
            { geo_type },
            () => console.log(`GeoType Option selected:`, this.state.geo_type)
        );


    }

    checkNotifyHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    applyCoords = (e) => {
        console.log(e);
    }

    formHandler = (event) => {

        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmitted,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var formdata1 = new FormData(event.target);
        console.log(coordinates.length, formdata1);


        if (coordinates.length > 0) {
            var formdata = {
                geo_name: this.state.geo_name,
                dept_code: this.state.selectedDepts,
                geo_type: this.state.geo_type,
                location_code: this.state.location_code, 
                fence_type: this.state.fence_type,
                notify: this.state.notify,
                coordinates: coordinates,
                oldgeofencename: this.state.oldgeofencename
            }
            console.log(this.state.oldgeofencename,'this.state.oldgeofencename')
          
            if (this.state.plantDetails !== null && this.state.plantDetails !== undefined) formdata.plant_code = this.state.plantDetails.value

            redirectURL.post("/updateGeo", formdata, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {

                if (response.data.message === 'Success') {
                    this.setState({
                        successMessage: "Successfully updated",
                        show: true,
                        basicType: "success",
                        basicTitle: "Successfully updated geofence"
                    });
                }

            }
            )
                .catch(function (error) {
                    console.log(error);
                });

        }
        else {
            this.setState({
                erroMessage: 'Please Add Coordinates'
            });
        }

    }

    showMap = () => {
        this.renderMap();
    }
    renderMap = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD1AFWPoJqPaemwGO4A7P7Q6CsUJ1TLGJk&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }

    departmentDropDown() {
        let items = [];
        this.state.departments.map(item => {
            items.push({ value: item.dept_code, label: item.dept_name });
            //items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
        });
        return items;
    }

    initMap = () => {
        //console.log("coordinates ", this.state.coordinates)
        var coordsvalues;
        var geoFencingObj;
        if (this.state.coords != '') {

        }
        geoFencingObj = coordinates;
        // console.log("here coordinates", geoFencingObj);
        if (geoFencingObj.length > 0) {
            var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
                zoom: 13,
                center: new window.google.maps.LatLng(geoFencingObj[0].lat, geoFencingObj[0].lng),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                disableDefaultUI: true,
                zoomControl: true
            });

            //added by nanda for places dropdown...
            var input = document.getElementById('search');
            var searchBox = new window.google.maps.places.SearchBox(input);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener('bounds_changed', function () {

                searchBox.setBounds(map.getBounds());
            });

            var markers = [];
            searchBox.addListener('places_changed', function () {
                var places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                // Clear out the old markers.
                markers.forEach(function (marker) {
                    marker.setMap(null);
                });
                markers = [];
                // For each place, get the icon, name and location.
                var bounds = new window.google.maps.LatLngBounds();
                places.forEach(function (place) {
                    if (!place.geometry) {
                        console.log("Returned place contains no geometry");
                        return;
                    }
                    var icon = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };

                    // Create a marker for each place.
                    markers.push(new window.google.maps.Marker({
                        map: map,
                        icon: icon,
                        title: place.name,
                        position: place.geometry.location
                    }));

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.

                        bounds.union(place.geometry.viewport);
                    } else {

                        bounds.extend(place.geometry.location);
                    }
                });
                map.fitBounds(bounds);
            });
            //End of adding by nanda
            geoFencingArea = new window.google.maps.Polygon({
                //map:map,
                paths: geoFencingObj,
                editable: true,
                strokeColor: '#1D9567',
                strokeOpacity: 0.70,
                strokeWeight: 0,
                fillColor: '#1D9567',
                fillOpacity: 0.35,
                draggable: true

            });
            geoFencingArea.setMap(map);
            fencingLength = geoFencingObj;

            window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at', getPolygonCoords);
            window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);

        }
    }


    closeAlert = async () => {
        await this.setState({
            show: false
        });
        window.location.href = '/geofence';
    };
    
    render() {
       
        let order_cnt = [];

        const dStyles = {
            width: '100%',
            height: '500px'
        }
        const { dept_code, geo_type, fence_type, notify, selectedDepts } = this.state;

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="mb-15p fbold f20">
                    <h5>
                        <i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
                    </h5>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="row card-body">

                                <form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="c-lbl"><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Geofence Name</label>
                                            <input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Geofence Name" />
                                        </div>

                                        <div className="form-group">
                                            <label className="c-lbl"><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp; Location Code</label>
                                            <input type="text" name="location_code" value={this.state.location_code} onChange={this.changeLocationHandler.bind(this)} className="form-control" placeholder="Location Code"
                                            autoComplete='off' required />
                                        </div>

                                        <div className="form-group">
                                            <label className="c-lbl"><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Geofence Type</label>
                                            <Select value={this.state.geo_type} onChange={this.changeHandler.bind(this)} style={{ borderRadius: "0px" }}
                                                // options={this.state.typeListOptions}
                                                options={fenchtypeoptions}
                                                required />
                                        </div>


                                        <div className="form-group">
                                            <label className="c-lbl"><span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Notify When</label>
                                            <div className="row col-xl-12 col-lg-12">
                                                <div className="radio radio-primary col col-xl-6 col-lg-6">
                                                    <input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify === 'Vehicle Comes in'} value="Vehicle Comes in" />
                                                    <label for="radio44" className="">Vehicle Comes in</label>
                                                </div>
                                                <div className="radio radio-info col col-xl-6 col-lg-6">
                                                    <input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify === 'Vehicle Goes out'} value="Vehicle Goes out" />
                                                    <label for="radio55" className="">Vehicle Goes out</label>
                                                </div>
                                                <div className="radio radio-warning col col-xl-6 col-lg-6">
                                                    <input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify === 'Both'} value="Both" />
                                                    <label for="radio66" className="">Both</label>
                                                </div>
                                                <div className="radio radio-danger col col-xl-6 col-lg-6">
                                                    <input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify === 'Never'} value="Never" />
                                                    <label for="radio77" className="">Never</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
                                            <a href="/geofence" className="btn btn-info cs-btn">Cancel</a>

                                        </div>
                                    </div>

                                </form>
                                <div className="col-xl-8 col-lg-8">

                                    {this.state.erroMessage === '' ? '' :
                                        <div className="alert alert-danger"></div>
                                    }
                                    <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />


                                    <div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>

                                    <div className="col-xl-12 col-lg-12">
                                        <input type="hidden" name="coords" id="coords" value={this.state.coords} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = Math.floor(hours);
    var minutes = (n) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}


function clearSelection() {
    if (selectedShape) {
        selectedShape.setEditable(false);
        selectedShape = null;
    }
}

function setSelection(shape) {
    clearSelection();
    selectedShape = shape;
    //console.log("selectedShape",selectedShape);
    shape.setEditable(true);

    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
    fencingLength = 0;
    if (selectedShape) {
        selectedShape.setMap(null);
    }
    // To show:
    drawingManager.setOptions({
        drawingControl: true
    });
}


function selectColor(color) {
    selectedColor = color;
    for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];

    }


    var polylineOptions = drawingManager.get('polylineOptions');
    polylineOptions.strokeColor = color;
    drawingManager.set('polylineOptions', polylineOptions);

    var rectangleOptions = drawingManager.get('rectangleOptions');
    rectangleOptions.fillColor = color;
    drawingManager.set('rectangleOptions', rectangleOptions);

    var circleOptions = drawingManager.get('circleOptions');
    circleOptions.fillColor = color;
    drawingManager.set('circleOptions', circleOptions);

    var polygonOptions = drawingManager.get('polygonOptions');
    polygonOptions.fillColor = color;
    drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
    if (selectedShape) {
        if (selectedShape.type === window.google.maps.drawing.OverlayType.POLYLINE) {
            selectedShape.set('strokeColor', color);
        } else {
            selectedShape.set('fillColor', color);
        }
    }
}

function makeColorButton(color) {
    var button = document.createElement('span');
    button.className = 'color-button';
    button.style.backgroundColor = color;
    window.google.maps.event.addDomListener(button, 'click', function () {
        selectColor(color);
        setSelectedShapeColor(color);
    });

    return button;
}

function buildColorPalette() {
    var colorPalette = document.getElementById('color-palette');
    for (var i = 0; i < colors.length; ++i) {
        var currColor = colors[i];
        var colorButton = makeColorButton(currColor);
        colorPalette.appendChild(colorButton);
        colorButtons[currColor] = colorButton;
    }
    selectColor(colors[0]);
}

function getSelectedShape(coords) {
    var lt = []
    //console.log("coordsLatlngs ",coords);
    for (let value of Object.values(coords)) {
        //console.log("Val is ",value); // John, then 30
        lt.push(value)
    }
    //console.log("Combine ",lt);
    document.getElementById('coords').value = lt;
    //return lt;
}

function getPolygonCoords() {

    var len = geoFencingArea.getPath().getLength();
    console.log(len);
    var htmlStr = "";
    coordinates = [];
    for (var i = 0; i < len; i++) {

        var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
        coordinates.push(latlngd);
    }
}
