import React, { Component } from 'react';
import ReactDOM from 'react-dom';
 
export default class EditAction extends Component {
    editItem = (e) => {
        console.log("Inside child component", e);
    }
    render() {
        return (
            <span><button onClick={this.editItem.bind(this)}>Edit</button></span>
        );
    }
}