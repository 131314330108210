/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';


import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import OnTimeDashboard from './ontimedashboardsnd.js';

var moment = require('moment');

export default class OnTimeDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '',
            endDate: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
            showexclude: 0
        }
    }
    componentDidMount() {
        var edate = moment.parseZone().format('YYYY-MM-DD');
        var sdate = moment.parseZone().subtract(90, 'days').format('YYYY-MM-DD');
        // var sdate = "2020-05-01";
        var dept_code = 'SNDG';
        var preqparams = {
            dept_code: dept_code
        }
        this.setState({
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate
        })

    }
    componentWillReceiveProps(newprops) {
        if (newprops.showexclude != this.state.showexclude) {
            this.setState({
                showexclude: newprops.showexclude
            });
        }
        else {
            this.setState({
                showexclude: 0
            });
        }
    }

    onClickLoadDept(deptcode) {
        this.setState({
            dept_code: deptcode
        })
    }

    render() {
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl = '';
        var transiturl = '';
        var transporturl = '';
        if (this.state.dept_code == "SNDG") {
            taburl = "/snddashboardtemplate";
            loadingurl = "/sndloadingdashboard";
            transiturl = "/sndtransitanalytics";
            transporturl = "/sndtransportdashboard";
        }
        if (this.state.dept_code == "LOG-TNP") {
            taburl = "/tnpdashboardtemplate";
            loadingurl = "/tnploadingdashboard";
            transiturl = "/tnptransitanalytics";
            transporturl = "/tnptransportdashboard";
        }
        if (this.state.dept_code == "LOG-PRT") {
            taburl = "/prtdashboardtemplate";
            loadingurl = "/prtloadingdashboard";
            transiturl = "/prttransitanalytics";
            transporturl = "/prttransportdashboard";
        }
        if (this.state.dept_code == "LOG-SC") {
            taburl = "/scdashboardtemplate";
        }

        if (this.state.dept_code == "LOG-PRT") {
            var dailyurl = "/prtdailyrunningdashboard";
        }
        else {
            var dailyurl = "/dailyrunningdashboard";
        }

        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                        {/*
                            <li>
                            {(this.props.match.path == "/dailyrunningmanagement")?
                                    <a href={"/dashboardsummary"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>

                            :""}
                            {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                    <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                                {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                                {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                            </li>
                            {(this.state.dept_code != 'LOG-SC')?
                            <li className="btn-group active">
                                <a href="javascript:;"  className="loadclickm active" id="load" onClick={this.onClickLoadDept.bind(this,'load')}>
                                <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />

                                <ul className="dashboard-sub" style={{"display":"none"}}>
                                     {(this.props.match.path == "/dailyrunningmanagement")?
                                    <li className="active">
                                        <a href="/dailyrunningmanagement">
                                            Daily Running
                                        </a>
                                    </li>
                                    :""}
                                    {((this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT' ) && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href={dailyurl}>
                                                Daily Running
                                            </a>
                                        </li>
                                        :""}

                                        {(this.props.match.path == "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/loadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>

                                        :""}
                                        {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/sndloadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>

                                        :""}
                                        {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/prtloadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>

                                        :""}
                                        {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/tnploadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>

                                        :""}

                                           <li>
                                                {(this.state.dept_code == "SNDG")?
                                                <a href="/sndunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}
                                                {(this.state.dept_code == "LOG-PRT")?
                                                <a href="/prtunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}
                                                {(this.state.dept_code == "LOG-TNP")?
                                                <a href="/tnpunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}

                                            </li>

                                    {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/sndtransportdashboard">
                                            Transporter Daily
                                        </a>
                                        </li>
                                        :""}

                                        {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li className="active">
                                        <a href="/ontimedashboard">
                                            On TIme Performance
                                        </a>
                                        </li>
                                        :""}
                                    </ul>
                                </a>
                            </li>
                            :""}
                             */}
                    </ul>
                </div>
                <h5 className="col-xl-12 col-lg-12">
                    <i className="icofont icofont-dashboard-web cus-i"></i> <span style={{ fontWeight: "600" }}>Ontime Performance</span>
                </h5>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className=" col-xl-12 col-lg-12">
                        <OnTimeDashboard
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            //locations={this.state.locations}
                            //transporters={this.state.transporters}
                            dept_code={this.state.dept_code}
                            showexclude={this.state.showexclude}
                        />
                    </div>

                </div>
            </div>
        );
    }
}


$(document).ready(function () {
    $(".loadclickm").on("mouseover", function () {
        var id = $(this).attr("id");
        $(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide()
        if (id == "load") {
            $("#" + id).addClass("active");
            $(".dashboard-sub").show()
        }

    })

    $(".loadclickm").on("mouseout", function () {
        var id = $(this).attr("id");
        //$(".loadclickm").removeClass("active");
        $(".dashboard-sub").hide()
        // $("#"+id).addClass("active");
        // $("#"+id+">dashboard-sub").show()

    })
});
