
import React from 'react'

 
const PrintItems = (props) => {
    return (
        <span>
        {
            (props.data.is_pod_submit != "" && props.data.is_pod_submit != undefined)?
            <span>
                <a href={"/printepoditems/"+props.data.consignment_code} target="_blank" className="label label-success f12"  style={{padding:"5px 10px",color:"#333","border":"0px"}}>Print</a>
            </span>
          :""
        }
        </span>
    );
};

export default PrintItems;
