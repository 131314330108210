import { React, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as XLSX from 'xlsx';
import EditButtonRenderer from './EditButtonRenderer';
import CSVFileValidator from 'csv-file-validator';


function Masters() {
    var [rowData, setRowData] = useState([]);
    var [columnDefs, setColumnDefs] = useState([

        {
            headerName: "Dealer Name",
            field: "consignee_name",
            width: 200,
            filter: true,
        },
        {
            headerName: "Dealer Code",
            field: "consignee_code",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer Address",
            field: "consignee_address",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer City",
            field: "consignee_city",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer State",
            field: "consignee_state",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer Zone",
            field: "consignee_zone",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer Latitude",
            field: "consignee_lat",
            width: 180,
            filter: true,
        },
        {
            headerName: "Dealer Longitude",
            field: "consignee_lng",
            width: 180,
            filter: true,
        },
        {
            headerName: "",
            field: "_id",
            width: 90,
            cellRenderer: EditButtonRenderer,
            cellRendererParams: {
                onClickEdit: (data) => showEditBlock(data), // Trigger showEditBlock when the edit button is clicked
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
    ]);
    const defaultColDef = {
        resizable: true,
        filter: true,
        sortable: true,
        wrapText: true
    };
    var [showloader, setshowloader] = useState("show-m");
    var [showAddSlider, setshowAddSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    var [sliderText, setSliderText] = useState("");
    const [csvContent, setCsvContent] = useState([]);
    const [file, setFile] = useState('');

    const [isEditMode, setIsEditMode] = useState(false);
    const [showBulkUploadSlider, setShowBulkUploadSlider] = useState("");
    const [bulkUploadFile, setBulkUploadFile] = useState(null);
    const [consignee_name, setdealername] = useState("");
    const [consignee_code, setdealercode] = useState("");
    const [consignee_address, setdealeraddress] = useState("");
    const [consignee_city, setdealercity] = useState("");
    const [consignee_state, setdealerstate] = useState("");
    const [consignee_zone, setdealerzone] = useState("");
    const [consignee_lat, setdealerlat] = useState("");
    const [consignee_lng, setdealerlng] = useState("");


    const [currentDealerId, setcurrentDealerId] = useState(null); // To store the current editing transporter id

    const [show, setShow] = useState(false);
    const [basicType, setBasicType] = useState("");
    const [basicTitle, setBasicTitle] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setshowloader("show-n");
        }, 2000);
        loadData();
    }, []);

    const loadData = async () => {
        const parameters = {};
        await redirectURL.post('/getDealerDetails', parameters)
            .then(response => {
                if (response.data.status === "success") {
                    setRowData(response.data.records);
                }
                setshowloader("show-n");
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const showAddBlock = () => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Add Dealer");
        setIsEditMode(false);
    };

    const showEditBlock = (data) => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Edit Dealer");
        setIsEditMode(true);
        setcurrentDealerId(data._id);
        setdealername(data.consignee_name);
        setdealercode(data.consignee_code);
        setdealeraddress(data.consignee_address);
        setdealercity(data.consignee_city);
        setdealerstate(data.consignee_state);
        setdealerzone(data.consignee_zone);
        setdealerlat(data.consignee_lat);
        setdealerlng(data.consignee_lng);

    };

    const showBulkUploadBlock = () => {
        setShowBulkUploadSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Bulk Upload Dealers");
    };

    const capitalizeFirstLetter = (value) => {
        return value.replace(/\b\w/g, char => char.toUpperCase());
    };


    const handleBulkUploadSubmit = async (e) => {
        e.preventDefault()

        if (csvContent.length > 0) {
            try {

                // Get the current date in dd-mm-yyyy format
                const createdDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

                const updatedCsvContent = csvContent.map(item => ({
                    ...item,
                    created_date: createdDate,
                }));

                const response = await redirectURL.post('/bulkUploadDealer', updatedCsvContent, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('userid'),
                    },
                });

                if (response.data.message === 'success') {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle("Bulk Upload Successful");
                    setBasicType("success");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
                else if (response.data.status === "partial") {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle(`${response.data.uploadedCount} Records uploaded out of ${response.data.totalRecords}`);
                    setBasicType("warning");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
            } catch (error) {
                console.error('Error during bulk upload:', error);
                setShow(true);
                setBasicTitle('Bulk Upload Error');
                setBasicType('danger');
            }
        } else {
            setShow(true);
            setBasicTitle('Cannot enter empty data');
            setBasicType('danger');
        }
    };


    const handleLatChange = (e) => {
        const value = e.target.value;
        const latRegex = /^-?\d{0,2}(\.\d{0,7})?$/; 
        if (value === "" || latRegex.test(value)) {
            setdealerlat(value); 
        }
    };

    const handleLngChange = (e) => {
        const value = e.target.value;
        const lngRegex = /^-?\d{0,2}(\.\d{0,7})?$/; // Allow up to 6 digits after the decimal point
        if (value === "" || lngRegex.test(value)) {
            setdealerlng(value); // Update the state if valid
        }
    };


    const handleBulkUpload = async (e) => {
        if (e.target.files.length !== 0) {
            const config = {
                headers: [

                    {
                        name: 'consignee_name',
                        inputName: 'consignee_name',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_code',
                        inputName: 'consignee_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_address',
                        inputName: 'consignee_address',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_city',
                        inputName: 'consignee_city',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_state',
                        inputName: 'consignee_state',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_zone',
                        inputName: 'consignee_zone',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'consignee_lat',
                        inputName: 'consignee_lat',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },{
                        name: 'consignee_lng',
                        inputName: 'consignee_lng',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    }

                ],
            };

            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- _]*$/); // Allowed characters

            try {
                const csvData = await CSVFileValidator(e.target.files[0], config);
                console.log('csvData', csvData);

                let keys = Object.keys(csvData.data[0]);
                let items = ['consignee_name', 'consignee_code', 'consignee_address', 'consignee_city', 'consignee_state', 'consignee_zone','consignee_lat','consignee_lng'];
                let check = csvData.inValidData.length === 0 && items.every(item => keys.includes(item));

                console.log(check, "check")



                if (!check) {
                    e.target.value = ""; // Clear file input
                    setShow(true);
                    setBasicTitle('Dont upload other fields or special characters');
                    setBasicType('danger');
                } else {
                    setCsvContent(csvData.data);
                }
            } catch (err) {
                console.error('Error validating CSV:', err);
            }

            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = e.target.result;
                console.log(contents);
            };
            reader.readAsText(e.target.files[0]);

            setFile(e.target.files[0]);

            if (e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel') {
                e.target.value = null;
                setShow(true);
                setBasicType('danger');
                setBasicTitle('Please upload file having extensions .csv only.');
            }
        }
    };


    const hideAll = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }
        resetForm();
        setBulkUploadFile(null);
        setoverlay("show-n");
        setshowAddSlider("");
        setShowBulkUploadSlider("");
        setcurrentDealerId(null);
    };


    const saveMasterForm = async (e) => {
        e.preventDefault();
        const params = {
            consignee_name: consignee_name,
            consignee_code: consignee_code,
            consignee_address: consignee_address,
            consignee_city: consignee_city,
            consignee_state: consignee_state,
            consignee_zone: consignee_zone,
            consignee_lat:consignee_lat,
            consignee_lng:consignee_lng
        };

        if (isEditMode) {
            params._id = currentDealerId;
            await redirectURL.post('/updateDealer', params).then((resp) => {
                if (resp.data.status === "success") {
                    hideAll();
                    resetForm();
                    setShow(true);
                    setBasicTitle("Updated data");
                    setBasicType("success");
                    loadData();
                }
            });
        } else {
            await redirectURL.post('/addDealer', params).then((resp) => {
                if (resp.data.status === "success") {
                    hideAll();
                    resetForm();
                    setShow(true);
                    setBasicTitle("Added data");
                    setBasicType("success");
                    loadData();
                } else if (resp.data.status === "failure") {
                    hideAll();
                    setShow(true);
                    setBasicTitle(resp.data.message);
                    setBasicType("danger");
                }
            }).catch(err => {
                console.log(err, "error");
            });
        }
    };

    const resetForm = () => {
        setdealername("");
        setdealercode("");
        setdealeraddress("");
        setdealercity("");
        setdealerstate("");
        setdealerzone("");
        setIsEditMode(false);
    };

    const resetUpload = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }
    };




    return (
        <div>
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={() => {
                        setShow(false);
                    }}>
                </SweetAlert>
            </div>
            <div className="container-fluid mt-10p">
                <div className="row">
                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="col-lg-12 d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                                <h4 className="screen-name">Dealer Master</h4>
                                <div>
                                    {
                                        Constants.VEHICLES.BTN_ADD === 1 ? (
                                            <>
                                                <button className="btn btn-warning" onClick={showAddBlock}>
                                                    <i className="icon-plus"></i>&nbsp;Add Dealer
                                                </button>
                                                <button className="btn btn-success ml-2" onClick={showBulkUploadBlock}>
                                                    <i className="icon-upload"></i>&nbsp;Bulk Upload
                                                </button>
                                            </>
                                        ) : ""
                                    }
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                    <AgGridReact
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        defaultColDef={defaultColDef}
                                        pagination={true}
                                        paginationPageSize={50}
                                        enableRangeSelection={true}
                                        enableCellContextMenu={true}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel",
                                                    toolPanelParams: {
                                                        suppressPivotMode: true,
                                                        suppressRowGroups: true,
                                                        suppressValues: true,
                                                    }
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                {
                                                    statusPanel: "agTotalAndFilteredRowCountComponent",
                                                    align: "left"
                                                },
                                                {
                                                    statusPanel: "agTotalRowCountComponent",
                                                    align: "center"
                                                },
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay Part */}
            <div className={"overlay-part " + overlay} onClick={hideAll}></div>

            {/* Add/Edit Dealer Slider */}
            <div className={"slide-r " + showAddSlider} onClick={(e) => e.stopPropagation()}>
                <div className="slide-r-title">
                    <h4 className="">{sliderText}
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll}>X</span>
                    </h4>
                </div>
                <div className="slide-r-body">
                    <form className="" style={{ padding: "20px" }} onSubmit={saveMasterForm}>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Name
                            </label>
                            <input
                                type="text"
                                name="consignee_name"
                                id="consignee_name"
                                value={consignee_name}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => setdealername(capitalizeFirstLetter(e.target.value))}
                                autoComplete='off'
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Code
                            </label>
                            <input
                                type="text"
                                name="consignee_code"
                                id="consignee_code"
                                value={consignee_code}
                                onChange={e => setdealercode(capitalizeFirstLetter(e.target.value))}
                                autoComplete='off'
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                            {isEditMode && (
                                <p style={{ color: "#EB1D54", fontSize: "11px", marginTop: "5px" }}>
                                    *Dealer Code cannot be changed
                                </p>
                            )}
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Address
                            </label>
                            <input
                                type="text"
                                name="consignee_address"
                                autoComplete='off'
                                id="consignee_address"
                                value={consignee_address}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => setdealeraddress(capitalizeFirstLetter(e.target.value))}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer City
                            </label>
                            <input
                                type="text"
                                name="consignee_city"
                                autoComplete='off'
                                id="consignee_city"
                                value={consignee_city}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => setdealercity(capitalizeFirstLetter(e.target.value))}
                                required
                            />
                        </div>

                        {/* Dealer State */}
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer State
                            </label>
                            <input
                                type="text"
                                name="consignee_state"
                                autoComplete='off'
                                id="consignee_state"
                                value={consignee_state}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => setdealerstate(capitalizeFirstLetter(e.target.value))}
                                required
                            />
                        </div>

                        {/* Dealer Zone */}
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Zone
                            </label>
                            <input
                                type="text"
                                name="consignee_zone"
                                autoComplete='off'
                                id="consignee_zone"
                                value={consignee_zone}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => setdealerzone(capitalizeFirstLetter(e.target.value))}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Latitude
                            </label>
                            <input
                                type="text"
                                name="consignee_lat"
                                autoComplete='off'
                                id="consignee_lat"
                                value={consignee_lat}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => handleLatChange(e)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Dealer Longitude
                            </label>
                            <input
                                type="text"
                                name="consignee_lng"
                                autoComplete='off'
                                id="consignee_lng"
                                value={consignee_lng}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={e => handleLngChange(e)}
                                required
                            />
                        </div>

                        <div className="form-group" style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-success"
                                style={{
                                    padding: "10px 20px",
                                    border: "none",
                                    borderRadius: "4px",
                                    backgroundColor: "#28a745",
                                    color: "white",
                                }}
                            >
                                {isEditMode ? 'Update Dealer' : 'Add Dealer'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Bulk Upload Slider */}
            <div className={"slide-r " + showBulkUploadSlider} onClick={(e) => e.stopPropagation()}>
                <div className="slide-r-title">
                    <h4 className="">{sliderText}
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll}>X</span>
                    </h4>
                </div>
                <div className="slide-r-body">
                    <div style={{ padding: "20px" }}>
                        <form method="POST" id="upform" className="theme-form" onSubmit={handleBulkUploadSubmit}>
                            <div className="form-group">
                                <label className="">Upload File</label>
                                <input type="file" id="bulkUploadInput" onChange={handleBulkUpload} className="form-control" required />
                                <div style={{ color: "#ff0000", marginTop: "10px" }}>
                                    {/* ** Allowed Space and Special Characters are <span style={{ fontWeight: "700", fontSize: "18px", fontFamily: "arial" }}>-._,&#</span> */}
                                </div>

                            </div>

                            <div className="form-group">
                                <button className="btn btn-success">Submit</button>
                                <button type="button" onClick={resetUpload} className="btn btn-default">Cancel</button>
                            </div>

                        </form>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../assets/json/bulk_master_dealer.csv')} target="_blank">Sample Template</a>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Masters;
