import moment from 'moment'

const StopButton = (params) => {
    if (![undefined, null, "undefined", ""].includes(params.data.expiry_date_extension_disabled)) {
        if (params.data.expiry_date_extension_disabled == 0 || params.data.expiry_date_extension_disabled == "0") {
            var legal_name_of_consignor = this.state.legal_name_of_consignor;
            if (legal_name_of_consignor.includes(params.data.legal_name_of_consignor) || this.state.enabled_autoextension == 1) {
                if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
                    var validityDate = params.data.eway_bill_valid_date;
                    var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                    var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                    var d = moment.duration(ms);
                    var difference = parseFloat(d.asDays());
                    var is_expired = ""
                    if (difference > 0 && difference <= 1) {
                        is_expired = "Expiring Today";
                    } else if (difference > 1 && difference <= 2) {
                        is_expired = "Expiring Tomorrow";
                    } else if (difference <= 0) {
                        is_expired = "Expired";
                    } else {
                        is_expired = "Active";
                    }
                    if (is_expired !== 'Expired') {
                        var htmloption = '<button class="btn btn-danger" style="padding-top:0px">stop</button>';
                        return (<button class="btn btn-danger" style={{ paddingTop: "0px" }}>stop</button>)
                    } else {
                        return (<button class="btn btn-danger" style={{ paddingTop: "0px", cursor: "not-allowed" }} disabled>stop</button>)
                    }
                }
            }
        } else {
            return (<button class="btn btn-danger" style={{ paddingTop: "0px", cursor: "not-allowed" }} disabled>stop</button>)
        }
    } else {
        return (<button class="btn btn-danger" style={{ paddingTop: "0px", cursor: "not-allowed" }} disabled>stop</button>)
    }
}
export default StopButton