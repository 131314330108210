import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import redirectURL from "../redirectURL";
import GPSDashboard from './gpsdashboards.js';

var moment = require('moment');

export default class GPSDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            transporters: [],
            location: '',
            transporter: '',
            startDate: '',
            endDate: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            paginationPageSize: 50,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            columnwithDefs: [{
                headerName: "Plant Name",

            }],
            dept_code: "all"
        }
    }
    componentDidMount() {
        var edate = moment.parseZone().format('YYYY-MM-DD');
        //var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
        var sdate = "2020-05-01";
        var dept_code = 'SNDG';
        if (window.location.pathname == "/sndgpsdashboard") {
            dept_code = "SNDG";

        }

        if (window.location.pathname == "/tnpgpsdashboard") {
            dept_code = "LOG-TNP";
        }

        if (window.location.pathname == "/prtgpsdashboard") {
            dept_code = "LOG-PRT";
        }
        var preqparams = {
            dept_code: dept_code
        }

        this.setState({
            dept_code: dept_code,
            startDate: sdate,
            endDate: edate
        })

    }

    // componentWillReceiveProps(newprops){
    //     //console.log("newprops.dept_code ", newprops)
    //     if(newprops.dept_code != this.state.dept_code)
    //     {

    //             this.setState({
    //                 dept_code:this.props.dept_code
    //             })


    //     }
    // }

    onClickLoadDept(deptcode) {
        this.setState({
            dept_code: deptcode
        })
    }

    render() {
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl = '';
        var transiturl = '';
        var transporturl = '';
        if (this.state.dept_code == "SNDG") {
            taburl = "/snddashboardtemplate";
            loadingurl = "/sndloadingdashboard";
            transiturl = "/sndtransitanalytics";
            transporturl = "/sndtransportdashboard";
        }
        if (this.state.dept_code == "LOG-TNP") {
            taburl = "/tnpdashboardtemplate";
            loadingurl = "/tnploadingdashboard";
            transiturl = "/tnptransitanalytics";
            transporturl = "/tnptransportdashboard";
        }
        if (this.state.dept_code == "LOG-PRT") {
            taburl = "/prtdashboardtemplate";
            loadingurl = "/prtloadingdashboard";
            transiturl = "/prttransitanalytics";
            transporturl = "/prttransportdashboard";
        }
        if (this.state.dept_code == "LOG-SC") {
            taburl = "/scdashboardtemplate";
        }

        if (this.state.dept_code == "LOG-PRT") {
            var dailyurl = "/prtdailyrunningdashboard";
        }
        else {
            var dailyurl = "/dailyrunningdashboard";
        }

        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                        <li>
                            {(this.state.dept_code == "SNDG") ?
                                <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-PRT") ?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}
                            {(this.state.dept_code == "LOG-TNP") ?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this, 'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                                : ""}

                        </li>
                        {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
                        {(this.state.dept_code != 'LOG-SC') ?
                            <li className="btn-group active">
                                <a href="javascript:;" className="loadclickm active" id="load" onClick={this.onClickLoadDept.bind(this, 'load')}>
                                    <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />

                                    <ul className="dashboard-sub" style={{ "display": "none" }}>

                                        {(this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT') ?
                                            <li>
                                                <a href={dailyurl}>
                                                    Daily Running
                                                </a>
                                            </li>
                                            : ""}
                                        {(this.state.dept_code == "SNDG") ?
                                            <li>
                                                <a href="/sndloadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-PRT") ?
                                            <li>
                                                <a href="/prtloadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-TNP") ?
                                            <li>
                                                <a href="/tnploadingdashboard">
                                                    Loading Dashboard
                                                </a>
                                            </li>

                                            : ""}
                                        {/* {(localStorage.getItem('roles').includes("SITE_ADMIN") == true)?
                                        <li>
                                            <a href="/unloadingdashboard">
                                                Unloading Dashboard
                                            </a>
                                        </li>
                                        :""} */}
                                        <li>
                                            {(this.state.dept_code == "SNDG") ?
                                                <a href="/sndunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}
                                            {(this.state.dept_code == "LOG-PRT") ?
                                                <a href="/prtunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}
                                            {(this.state.dept_code == "LOG-TNP") ?
                                                <a href="/tnpunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                : ""}

                                        </li>


                                        {(this.state.dept_code == "SNDG") ?
                                            <li>
                                                <a href="/sndtransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-PRT") ?
                                            <li>
                                                <a href="/prttransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}
                                        {(this.state.dept_code == "LOG-TNP") ?
                                            <li>
                                                <a href="/tnptransitanalytics">
                                                    Transit Analytics
                                                </a>
                                            </li>

                                            : ""}


                                        {(this.state.dept_code == 'SNDG') ?
                                            <li>
                                                <a href="/sndtransportdashboard">
                                                    Transporter Daily
                                                </a>
                                            </li>
                                            : ""}

                                        {
                                            (this.state.dept_code == 'SNDG') ?
                                                <li className="active">
                                                    <a href="/sndgpsdashboard">
                                                        GPS Dashboard
                                                    </a>
                                                </li>
                                                : ""}

                                        {
                                            (this.state.dept_code == 'LOG-PRT') ?
                                                <li className="active">
                                                    <a href="/prtgpsdashboard">
                                                        GPS Dashboard
                                                    </a>
                                                </li>
                                                : ""}

                                        {
                                            (this.state.dept_code == 'LOG-TNP') ?
                                                <li className="active">
                                                    <a href="/tnpgpsdashboard">
                                                        GPS Dashboard
                                                    </a>
                                                </li>
                                                : ""}

                                        {(this.state.dept_code == 'SNDG' && window.location.pathname != "/dailyrunningmanagement") ?
                                            <li>
                                                <a href="/ontimedashboard">
                                                    On TIme Performance
                                                </a>
                                            </li>
                                            : ""}
                                    </ul>
                                </a>
                            </li>
                            : ""}
                        {/* :""} */}
                    </ul>
                </div>
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>GPS Dashboard</span>

                        </h5>
                    </div>

                    <div className="card-body n-p-0 mt-10p">


                        <div className=" col-xl-12 col-lg-12 mt-30p">
                            <GPSDashboard
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                //locations={this.state.locations}
                                //transporters={this.state.transporters}
                                dept_code={this.state.dept_code}
                            />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}




$(document).ready(function () {

    $(".loadclick").click(function () {
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("." + id).addClass("active");
    })
})