 
const ConsignmentActions = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // console.log("MAP Icon ", props)
        // props.context.componentParent.onCloseUploadDiv(e);
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.onShowDaywiseData(props);
    };

    return (
            <a onClick={handleRouteClick}  title="Day wise trip"><i className="icofont icofont-list f20"></i>&nbsp;</a>
        
    );
};

export default ConsignmentActions;
