import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Dashboard.css'; // Import the CSS file for styling
import weatherIcon from '../../assets/icons/weatherIcon.svg';
import Sunny from '../../assets/icons/sunny.svg';
import StrongSurfaceWinds from '../../assets/icons/strongSurfaceWind.svg';
import Hailstrom from '../../assets/icons/hail.svg';
import HeavyRainsWinds from '../../assets/icons/heavyRainWinds.svg'
import Thunderstorm from '../../assets/icons/thunderStorm.svg';
import ThunderstormHeavyRains from '../../assets/icons/thunderstormHeavyRain.svg';
import HeavyRain from '../../assets/icons/heavy_rain.svg'

// Helper function to chunk an array
const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const WeatherCard = ({ icon, imgSrc, location, isSelected, onClick }) => {
  return (
    <div
      className={`weather-card ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <div className="icon">
        {imgSrc ? <img src={imgSrc} alt={location} style={{width:'20px'}} className="weather-icon" /> : <p style={{fontSize:"12px"}}>{icon}</p>}
      </div>
      <div className="location">{location}</div>
    </div>
  );
};

const WeatherComponent = ({ plantWeatherData }) => {

  const weatherOrder = ['Thunderstorm', 'thunderstorm', 'Rain', 'rain', 'Wind', 'wind', 'Hailstorm', 'hailstorm', 'Sunny', 'sunny'];

  // Create a map for quick lookup of index positions
  const weatherIndex = weatherOrder.reduce((acc, weather, index) => {
      acc[weather.toLowerCase()] = index;  // Normalize to lowercase
      return acc;
  }, {});
  
  // Function to extract and normalize weather type from the field
  const extractWeatherType = (weather_today) => {
      // Adjust this regex pattern if necessary based on the exact format of the weather_today field
      const match = weather_today.match(/(Thunderstorm|thunderstorm|Rain|rain|Wind|wind|Hailstorm|hailstorm|Sunny|sunny)/i);
      return match ? match[0].toLowerCase() : '';  // Normalize to lowercase
  };
  
  // Sort the array using the custom order
  const WeatherData = plantWeatherData.sort((a, b) => {
      const weatherA = extractWeatherType(a.weather_today);
      const weatherB = extractWeatherType(b.weather_today);
      
      // Use the normalized index for comparison
      return (weatherIndex[weatherA] || Infinity) - (weatherIndex[weatherB] || Infinity);
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0, // Ensure the first slide is shown
    // arrows: true // Disable default arrows
  };

  const getWeathericon = (weather) => {
    if (weather.includes("Thunderstorm","thunderstorm")) return Thunderstorm;
    else if (weather.includes("Rain" , "rain")) return HeavyRain;
    else if (weather.includes("Winds","wind","winds")) return StrongSurfaceWinds;
    else if (weather.includes("Thunderstorm","thunderstorm")&&weather.includes("Rain" , "rain")) return ThunderstormHeavyRains;
    else if(weather.includes("Hailstrom","hailstrom")) return Hailstrom
    else if(weather.includes("Rain" , "rain")&&weather.includes("Winds","wind","winds"))  return HeavyRainsWinds
    else if(weather.includes("sunny","Sunny")) return Sunny
    else return null ;
  };

  const handleCardClick = (location) => {
    setSelectedLocation(location);
  };

  // Chunk plantWeatherData into arrays of 4
  const weatherChunks = WeatherData.length > 0 ? chunkArray(WeatherData, 8) : [];

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Ensure the slider starts at the first slide
  useEffect(() => {
    // Timeout to ensure slider has been initialized
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    }, 1000); // Adjust timeout if needed

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, [plantWeatherData]); // Dependency on plantWeatherData to ensure it runs when data changes

  return (
    <div className="">
      <div className="weather-header">
        <div style={{display:"flex"}}>
          <div>
            <img style={{ width: "20px", height: "auto" }} src={weatherIcon} alt="Weather Icon" />
          </div>
          <div style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "10px" }}>Weather</div>
        </div>
        <div style={{display:"flex", float:"right"}}>
          <div 
            className="arrow-symbol" 
            style={{marginRight:"2px", cursor: "pointer"}} 
            onClick={goToPrev}
          >
            {"<"}
          </div>
          <div 
            className="arrow-symbol" 
            style={{cursor: "pointer"}} 
            onClick={goToNext}
          >
            {">"}
          </div>
        </div>
      </div>

      <Slider {...settings} ref={sliderRef}>
        {weatherChunks.map((chunk, index) => (
          <div key={index}>
            <div className="weather-grid">
              {chunk.map((weather, idx) => (
                <>
                 <WeatherCard
                  key={idx}
                  icon={"No Data"}
                  imgSrc={getWeathericon(weather.weather_today)}
                  location={weather.plant_name}
                  isSelected={selectedLocation === weather.plant_name}
                  onClick={() => handleCardClick(weather.plant_name)}
                />
                </>
               
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WeatherComponent;