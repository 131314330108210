import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import RoutemapTransitAction from './RoutemapTransitAction.js'
const transitdelaycols = [
    {
        headerName: "", field: "_id",
        cellRendererFramework: RoutemapTransitAction,
        width: 50,
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }
    },
    {
        headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }
        }
    },
    {
        headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.leg_start);
        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }
    },
    {
        headerName: "Leg End Time", field: "leg_end_eta",
        width: 120, resizable: true,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.leg_end_eta);
        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }
        }
    },
    {
        headerName: "Exp. Leg Distance (KM)",
        field: "leg_expected_distance", width: 140, resizable: true,
        valueGetter: function (params) {
            return Math.round(params.data.leg_expected_distance);
        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }
    },
    {
        headerName: "Actual Leg Distance Covered (KM)",
        field: "actual_leg_distance", width: 140, resizable: true,
        valueGetter: function (params) {
            //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
            if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
                return Math.round(params.data.actual_leg_distance);
            }
            else {
                return 0;
            }
        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }
    },
    {
        headerName: "Exp. Distance from Trip Start(KM)",
        field: "expected_distance_start_to_leg", width: 140, resizable: true,
        valueGetter: function (params) {
            //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
            if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
                return Math.round(params.data.expected_distance_start_to_leg);
            }
            else {
                return 0;
            }

        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }

    },
    {
        headerName: "Actual Distance from Trip Start (KM)",
        field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
        valueGetter: function (params) {
            //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
            if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
                return Math.round(params.data.actual_distance_traveled_start_to_leg);
            }
            else {
                return 0;
            }

        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }


    },

    {
        headerName: "Google Distance from Start (KM)",
        field: "actual_start_to_leg_google_distance",
        width: 140,
        resizable: true,
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }


    },
    {
        headerName: "GPS Data Available",
        field: "no_gps_data",
        width: 140,
        resizable: true,
        valueGetter: function (params) {
            //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
            if (params.data.no_gps_data == 1) {
                return "Not Available";
            }
            if (params.data.no_gps_data == 0) {
                return "Available";
            }

        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }


    },


    {
        headerName: "Trip Completed",
        field: "trip_completed",
        width: 140,
        resizable: true,
        valueGetter: function (params) {
            //console.log("Actual Leg Dist ", params.data.actual_leg_distance);
            if (params.data.trip_completed == 1) {
                return "Yes";
            }
            if (params.data.trip_completed == 2) {
                return "";
            }
            if (params.data.trip_completed == 0) {
                return "";
            }

        },
        cellClass: function (params) {
            if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
                return 'green';
            }
            else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
                return 'lightred';
            }
            else {
                return '';
            }

        }


    }
];
export { transitdelaycols };