import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var highchartoptions;
var criticaldata=[]
export default class ColumnOverallOntime extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}

	}
	componentDidMount = async () =>{

		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()

	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown
			})
            //console.log("newprops ",newprops);
		//}

		if(this.props.isfilter == "yes")
		{
			console.log("Yes")
			// highchartoptions.update({
			// 	drilldown: {
			// 		series: this.props.drildown
			// 	}
			// });
			// var ddCurrent = highchartoptions.series[0].userOptions.id;
			// var ddSeries = highchartoptions.options.drilldown.series;
			// if (ddCurrent === undefined) {
			// 	highchartoptions.series[0].setData(highchartoptions.options.series[0].data);
			// } else {
			// 	for (var i = 0, ie = ddSeries.length; i < ie; ++i) {
			// 		if (ddSeries[i].id === ddCurrent) {
			// 			highchartoptions.series[0].setData(ddSeries[i].data);
			// 		}
			// 	}
			// }
			// console.log("highchartoptions ", highchartoptions)
		}

	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }

    render(){
        //console.log("this.props.code) ", this.props.code)
    //console.log("drildown ", this.state.drilldowndata)
    //console.log("props drildown ", this.props.drildown)
		var isfilter = this.props.isfilter;
		var drillseries = this.props.drildown;
        const dStyles={
				width:'100%',
				height:'100%'
		}
		highchartoptions = {
            chart: {
                type: 'column',

				
            },
            title: {
				text: this.props.title,
				//align:'left'
            },
            subtitle: {
                text: this.props.subtitle
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
				// type: 'category',
				title:{
					text:this.props.xaxistitle
                },
                categories: ["Ontime"]
            },
            yAxis: {
            title:{
                text:"Ontime (%)"
            }
          },
          
            legend: {
                enabled: true
            },

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    }
                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}%<br>No of trips: {point.ontime}'
            },

            // series: this.props.series,
			series: [
                {
                    name: "Ontime",
                    colorByPoint: true,
                    data: this.props.series
                }
			],
			credits:false
        };

        return (
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {
	return function(a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }

  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }
