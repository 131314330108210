import moment from 'moment'

const CancelButton = (params) => {

    if (params.data.eway_bill_status != "Cancelled") {
        if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
            var validityDate = params.data.eway_bill_valid_date;
            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
            var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
            var d = moment.duration(ms);
            var difference = parseFloat(d.asDays());
            if (difference > 0) {
                return <button class="btn btn-danger" style={{ padding: "0 6px 6px" }}>Cancel</button>
            }
            else {
                return <button class="btn btn-danger" style={{padding:"0 6px", color:"#ccc", cursor:"not-allowed"}} disabled>Cancel</button>
            }
        }
        else {
            return <button class="btn btn-danger" style={{ padding: "0 6px 6px" }}>Cancel</button>
        }
    }
    else {
        return <button class="btn btn-danger" style={{padding:"0 6px", color:"#ccc", cursor:"not-allowed"}} disabled>Cancel</button>
    }
}
export default CancelButton