import React from 'react';

const Rating = (props) => {
    const handleClick = (e) => {
        e.stopPropagation();
        props.onClickShowFeedbackForm(props);
    }
    return (
        <span>
            {
                <span>
                    <button type="button" onClick={handleClick} className="label btn-success f12" style={{ padding: "5px 10px", color: "#333", "border": "0px" }}>Feedback</button>
                </span>
            }
        </span>
    );
};

export default Rating;
