import React, { useEffect, useState } from 'react';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

const HeatMap = ({ plantLocationData }) => {


  console.log(plantLocationData, "plantLocationData")
  useEffect(() => {
    if (!window.google) {
      loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=visualization,drawing&callback=initMap", () => {
        renderMap();
      });
    } else {
      renderMap();
    }
  }, [plantLocationData]);

  const renderMap = () => {
    window.initMap = initMap;
    initMap();
  };

  const initMap = () => {

    var center_location = JSON.parse(plantLocationData[0].consigner_coordinates)
    var lt = center_location.lat;
    var ln = center_location.lng;

    var mapOptions = {
      zoom: 4,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      labels: true,
      mapTypeControlOptions: {
        mapTypeIds: ['satellite', 'roadmap'],
      },
      center: new window.google.maps.LatLng(lt, ln),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
    };

    var map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
    // const heatmapData = plantLocationData.map(e=>{

    //     try {
    //         var location = JSON.parse(e)
    //         return new window.google.maps.LatLng(location.lat, location.lng)

    //     } catch (error) {
    //         if(typeof e == typeof "string"){
    //             var location = e

    //             console.log(e , "srinu")
    //         }
    //         else{
    //             var location = e
    //             return new window.google.maps.LatLng(location.lat, location.lng)

    //         }
    //     }

    // })
    // // const heatmapData = [
    // //     new window.google.maps.LatLng(28.4519751, 77.0310713),
    // //     new window.google.maps.LatLng(28.4529751, 77.0320713),
    // //     new window.google.maps.LatLng(28.4539751, 77.0330713),
    // //     new window.google.maps.LatLng(28.4549751, 77.0340713),
    // //     // Add more LatLng points as needed
    // //   ];

    // console.log(heatmapData)

    // const heatmap = new window.google.maps.visualization.HeatmapLayer({
    //   data: heatmapData,
    // });

    // heatmap.setMap(map);
    var markers = []
    plantLocationData.map(e => {
      const location = JSON.parse(e.consigner_coordinates);
      var marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(location.lat, location.lng),
        icon: require('../../assets/icons/business.png'),
        map: map,
      });
      var infowindow = new window.google.maps.InfoWindow();
      marker.addListener('click', function () {
        var contentarr = []
        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
        var header = (e.consigner_name == "TVP Bangalore" || e.consigner_name == "Dobaspet") ? "TVP Bangalore & Dobaspet" : e.consigner_name
        contentarr.push({ "key": "Count", "value": (e.consigner_name == "TVP Bangalore" || e.consigner_name == "Dobaspet") ? plantLocationData.filter(f => f.consigner_name == "TVP Bangalore")[0].count + plantLocationData.filter(f => f.consigner_name == "Dobaspet")[0].count : e.count })
        var contentString = infoBox(marker.icon, header + "&nbsp;&nbsp;&nbsp;&nbsp;", contentarr, '')

        infowindow.setContent(contentString);
        infowindow.setContent(contentString);
        // currentwindow = infowindow;
        infowindow.open(map, marker);
      });

      marker && markers.push(marker)


    });

    // var markerCluster = new MarkerClusterer(map, heatmapData, {
    //   imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    //   // gridSize: 50, // Adjust as needed
    //   // maxZoom: 15 // Adjust as needed
    // });
    const markerCluster = new MarkerClusterer({ map, markers });



    var bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(lt, ln));
  };

  const loadScript = (url, callback) => {
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.head.appendChild(script);
  };

  return (
    <div id="map" className="" style={{ width: '100%', height: "75vh" }}></div>
  );
};

export default HeatMap;