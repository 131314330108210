import React, { Component } from 'react';
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import redirectURL from "../redirectURL";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            style: {},
            style2: {},
            username: '',
            show: false,
            title: '',
            type: 'default',
        };
    }

    
	onConfirm = () => {
		this.setState({
			show: false
		});
	}

    componentDidMount() {
        setTimeout(function () {
            this.setState({ style: { display: 'none' } });
        }.bind(this), 1000)
    }

    changeInputHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    onSubmitHandler = async (e) => {
        e.preventDefault()
        this.setState({
            style: { display: 'block' },
            style2: { display: 'none' },
        })
        let md = this.cipher("Z744cRBHMjQTsi6cOlNaRKauSEF6MiEr");
        let r = md(this.state.username)

        await redirectURL.post('/resetPassword', {e:r})
            .then((response) => {
                if (response.data.status == "success") {

                    this.setState({
                        show:true,
                        type:"success",
                        title:response.data.message,
                        style: { display: 'none' },
                        username:''
                    })
                }
                else {
                    this.setState({
                        show:true,
                        type:"danger",
                        title:response.data.message,
                        style: { display: 'none' },
                        style2: { display: 'block' },
                    })
                }
            }).catch(err => console.log(err))
    }

    render() {
        let style = this.state.style;
        if (localStorage.getItem("k") !== "" && localStorage.getItem("k") !== undefined && localStorage.getItem("k") !== null) {
            window.location.href = '/landingpage'
        }

        return (
            <div>
                <SweetAlert
                    show={this.state.show}
                    title={<span style={{ fontSize: "1rem" }}>{this.state.title}</span>}
                    onConfirm={this.onConfirm}
                    confirmBtnBsStyle={this.state.type}
                    confirmBtnCssClass="sweetButton"
                    customClass='sweetWidth'
                    type={this.state.type}>
                </SweetAlert>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4 style={this.state.style2}>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                {/*login page start*/}
                <div className="login-bg">
                    <div className="card mt-4 mb-0 login-form">
                        <div className='login-custom'>
                            <h3 className='pb-3'>Forgot Password</h3>
                            <form className="theme-form" onSubmit={this.onSubmitHandler} >
                                <div className="form-group">
                                    <label className="col-form-label pt-0">Username</label>
                                    <input required type="text" name='username' className="form-control form-control-lg login-textbox" value={this.state.username} onChange={this.changeInputHandler.bind(this)} />
                                </div>
                                <div className="p-0 " style={{ display: "flex", justifyContent: "end" }}>
                                    <a href='/' style={{ color: 'black', marginRight:"1rem" }}>Login</a>
                                </div>
                                <div className=" login-button">
                                    <button type="submit" className="btn login-textbox" style={{ backgroundColor: "#a064ba", color: "#fff" }}>Reset Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*login page end*/}
                {/*page-wrapper Ends*/}

            </div>

        )
    }
}


export default ForgotPassword;
