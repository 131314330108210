import React, { Component } from 'react';
import redirectURL from "../../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDecoder } from '../utils';

const user = getDecoder()
class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            basicTitle: '',
            basicType: 'default',
            show: false,
            name: '',
            user: {},
            favorites: []
        }
    }
    componentDidMount() {
        this.getData()
    }
    showForm = (e) => {
        let temp = this.state.favorites.find(e => window.location.pathname == e.path)
        if (temp) {
            this.deleteItem(temp._id)
        }
        else {
            this.setState({
                modal: true
            })
        }
    }
    showForm2 = (e) => {
        this.setState({
            modal: false
        })
    }
    deleteItem = (e) => {
        redirectURL.post("/deleteFavorites", { id: e })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        basicType: "success",
                        show: true,
                        basicTitle: "Deleted from favourites list",
                        name: '',
                        modal: false
                    })
                    localStorage.setItem("favorites", "")
                    this.getData()
                }
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    submitForm = (e) => {
        if (this.state.name == null || this.state.name == undefined || this.state.name == '') {
            this.setState({
                basicType: "error",
                show: true,
                basicTitle: "Please give the page a name"
            })
        }
        else {
            redirectURL.post("/saveFavorites", { email: user.email, name: this.state.name, path: window.location.pathname })
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            basicType: "success",
                            show: true,
                            basicTitle: "Added to favourites list",
                            name: '',
                            modal: false
                        })
                        localStorage.setItem("favorites", "")
                        this.getData()
                    }
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
    }
    getData = () => {
        if (localStorage.getItem("favorites") == undefined || localStorage.getItem("favorites") == "") {
            if (user == undefined) {
                window.location.href = '/'
            }
            redirectURL.post("/getFavorites", { email: user.email })
                .then((response) => {
                    localStorage.setItem("favorites", JSON.stringify(response.data.data))
                    this.setState({
                        favorites: response.data.data
                    })
                })
                .catch(function (e) {
                    console.log("Error ", e)
                })
        }
        else {
            try {
                this.setState({
                    favorites: JSON.parse(localStorage.getItem("favorites"))
                })
            } catch (error) {

            }
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        })
    }
    render() {
        let faves = this.state.favorites?.map(each =>
            <li>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        <i className="icofont icofont-favourite" style={{ fontSize: "14px" }} />&nbsp;&nbsp;&nbsp;
                        <a href={each.path} className='fav-link' >{each.name}
                        </a>
                    </div>
                    <i className='fa fa-trash-o' onClick={(e) => this.deleteItem(each._id)} />
                </div>
            </li>
        )
        return (
            <>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {
                    this.state.modal ?
                        <li className="onhover-dropdown">
                            <a className="txt-dark" title='Click here to add this page to your Favorites' onClick={this.showForm}>
                                {/* <i className='icon-star' /> */}
                                {
                                    this.state.favorites.some(e => window.location.pathname == e.path) ?
                                        <i className='fa fa-star' /> :
                                        <i className='fa fa-star-o' />
                                }
                            </a>
                            <ul className="notification-dropdown onhover-show-div" style={{ visibility: "visible", opacity: "1", transform: "translateY(0px)" }}>
                                <div className='pt-4'>
                                    {/* <form onSubmit={this.submitForm}> */}
                                    <div className='form-group col-xl-12 col-lg-12'>
                                        <input type="text" name="name" maxLength={20} onChange={e => this.setState({ name: e.target.value })} className="form-control" autoComplete='off' placeholder="Add to Favorites" required />
                                    </div>
                                    <div className='form-group col-xl-12 col-lg-12'>
                                        <button onClick={this.showForm2} type='button' className='btn btn-warning' style={{ padding: "3px" }}>Cancel</button>&nbsp;&nbsp;
                                        <button onClick={this.submitForm} type='button' className='btn btn-success' style={{ padding: "3px" }}>Submit</button>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </ul>
                        </li>
                        :
                        <li className="onhover-dropdown">
                            <a className="txt-dark" title='Click here to add this page to your Favorites' onClick={this.showForm}>
                                {/* <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/notification.png")} alt="header-notification" /> */}
                                {
                                    this.state.favorites.some(e => window.location.pathname == e.path) ?
                                        <i className='fa fa-star' /> :
                                        <i className='fa fa-star-o' />
                                }
                                {/* <i className='icon-star' /> */}
                                {/* <span className="badge badge-pill badge-primary notification">3</span> */}
                            </a>
                            <ul className="notification-dropdown onhover-show-div">
                                <li>Favourites
                                    {/* <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">3 New</span> */}
                                </li>
                                {
                                    this.state.favorites.length > 0 ?
                                        faves :
                                        <li>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                No Favourites Added
                                            </div>
                                        </li>
                                }
                                {/* <li style={{height:"100px"}}>
                    </li>  */}
                                {/*<li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-shopping-cart bg-primary"></i>
                            <div className="media-body">
                                <h6 className="mt-0">Your order ready for Ship..!</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>Just Now</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-download-alt bg-success"></i>
                            <div className="media-body">
                                <h6 className="mt-0 txt-success">Download Complete</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>5 minutes ago</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="media">
                            <i className="align-self-center notification-icon icofont icofont-recycle bg-danger"></i>
                            <div className="media-body">
                                <h6 className="mt-0 txt-danger">250 MB trush files</h6>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetuer elit.</p>
                                <span><i className="icofont icofont-clock-time p-r-5"></i>25 minutes ago</span>
                            </div>
                        </div>
                    </li>
                    <li className="text-center">You have Check <a href="#">all</a> notification  </li> */}
                            </ul>
                        </li>
                }
            </>
        );
    }
}

export default Notification