import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ScrollContext } from 'react-router-scroll-4';
// import './index.scss';
import "./assets/css/style.css";
import "./assets/css/flag-icon.css";
import "./assets/css/fontawesome.css";
import "./assets/css/icofont.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.css";
import "./assets/css/page-builder.css";
import "./assets/css/print.css";
import "./assets/css/prism.css";
import "./assets/css/svg-icon.css";
import "./assets/css/sweetalert2.css";
import "./assets/css/themify.css";
import "./assets/css/timepicker.css";
import "../src/assets/css/encss.css"


// ** Import custom components **
import store from './store';
import App from './components/app';

// Dashboards
// import Default from './components/dashboard/default/index.component'
import Error404 from './components/pages/error-404'
import Login from './components/pages/login'
import Register from './components/pages/register'
import ForgetPassword from './components/pages/forget-password'
import ResetPassword from './components/pages/reset-password'
import Comingsoon from './components/pages/comingsoon'
import Maintenance from './components/pages/maintenance'
import Consignments from './components/tracktrace/Consignments';
import Epod from './components/tracktrace/Epod';

import Dealers from './components/tracktrace/Dealers';
import Vehicles from './components/tracktrace/Vehicles';
import SimTracking from './components/tracktrace/SimTracking.jsx';
import Transporters from './components/tracktrace/Transporters';
import TransitTimes from './components/tracktrace/TransitTimes';
import TicketManagementSystem from './components/tracktrace/TicketManagementSystem';
import DealerDetentionReport from './components/tracktrace/DealerDetentionReport';
import SNDDefault from './components/tracktrace/sndDefault';




import Geofence from './components/tracktrace/Geofence.jsx'
import AddGeofence from './components/tracktrace/AddGeofence.jsx'
import EditGeofence from './components/tracktrace/EditGeofence.jsx'
import Configuration from './components/tracktrace/configuration.jsx';
// import Default from './components/tracktrace/truckwithingeofence.js'
import TrucksWithInGeofence from './components/tracktrace/truckwithingeofence.js';
import ManageNearByConsignmentTrucks from './components/manage/consignment-nearby-trucks'
// import SafetyReport from './components/tracktrace/safetyreport.js';
import ConsignmentsGeofence from './components/layouts/consignmentgeofence.js';
import OptimizeDeviationRoutes from './components/tracktrace/PRTOptimizeDeviationRoutes.js';
import ChangePassword from './components/manage/changePassword.js';
import DailyRunningDashboardComponent from './components/dashboard/DailyRunningDashboardComponent.js';
import LoadingDashboard from './components/dashboard/loadingDashboardComponent.js';
import UnLoadingDashboard from './components/dashboard/unLoadingDashboardComponent';

import TransitAnalytics from './components/dashboard/trasitAnalytics';
// import DailyRunningDashboard from './components/dashboard/dailyrunningdashboard.js';
// import DailyRunningTransporter from './components/dashboard/dailyrunningtransporter.js';
import GPSDashboardComponent from './components/dashboard/gpsdashboardComponent.js';
import OnTimeDashboardComponent from './components/dashboard/ontimedashboardComponent.js';
import DeviationRoutes from './components/tracktrace/routeDeviationreportComponent.js';
import NightDriving from './components/dashboard/NightDriving';
import StoppageReport from './components/dashboard/StoppageReport';

import OverspeedReport from './components/dashboard/OverspeedReport';
import GenerateEwayBill from './components/ewaybill/generatenewewaybill.js';
import EwayBillData from './components/ewaybill/ewaybillsdata.js';
import EwayBillRequests from './components/ewaybill/ewaybillrequests.js';
import ConsolidateEwayBill from './components/ewaybill/consolidateewaybill.js';
import ConsolidateEwayBillRequests from './components/ewaybill/consolidateewaybillrequests.js';
import MenuSideBar from './components/common/sidebar/menuSidebar.jsx';
import DealerZoneWiseRoutes from './components/tracktrace/dealerZoneWiseRoutes.js';
import SavedBaseRoutes from './components/tracktrace/savedBaseRoutes.js';
import TopBaseRouteSummary from './components/tracktrace/topBaseRoutesSummary.js';
import DailyRunningTransporterDashboardComponent from './components/dashboard/dailrunningtransporterdashboardComponent.js';
import Samplepage from './components/icon/themify-icon.jsx';
import ManageConsignments from './components/tracktrace/sndgconsignments.jsx';
import ClosedTickets from './components/tracktrace/ClosedTickets.js';
import EwayBillConsolidatedReports from './components/ewaybill/ewaybillConsolidateReport.js';
import ExtendEwayBill from './components/ewaybill/extendedewaybill.js';
import EwayBillsOtherPartyData from './components/ewaybill/ewaybillsotherpartydata.js';
import LandingPage from './components/tracktrace/LandingPage';
import EwayBillData2 from './components/ewaybill/ewaybillsdata2.js';
import PEPODPrint from './components/tracktrace/printepod.js';
import MapView from './common/mapView.js';

class Root extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <Router basename={'/'}>
                    {/* <ScrollRestoration> */}
                    <Routes>
                        <Route path={"/"} element={<Login />} />
                        <Route path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgetPassword />} />
                        <Route path={`${process.env.PUBLIC_URL}/trackconsignment/:unumber`} element={<MapView />} />

                        <Route element={<App />}>
                            {/* <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} element={<Default />    } /> */}
                            <Route path={`${process.env.PUBLIC_URL}/change-password`} element={<ChangePassword />} />
                            <Route path={`${process.env.PUBLIC_URL}/landingpage`} element={<LandingPage />} />
                            <Route path={`${process.env.PUBLIC_URL}/activeconsignments`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/deliveredconsignments`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/allconsignments`} element={<Consignments />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/sndconsignments`} element={<ManageConsignments />} /> */}
                            {/* <Route path={`${process.env.PUBLIC_URL}/snddeliveredconsignments`} element={<ManageConsignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndallconsignments`} element={<ManageConsignments />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/epod`} element={<Epod />} />
                            <Route path={`${process.env.PUBLIC_URL}/printepoditems/:consignment_code`} element={<PEPODPrint />} />
                            <Route path={`${process.env.PUBLIC_URL}/dealers`} element={<Dealers />} />
                            <Route path={`${process.env.PUBLIC_URL}/vehicles`} element={<Vehicles />} />
                            <Route path={`${process.env.PUBLIC_URL}/transporters`} element={<Transporters />} />
                            <Route path={`${process.env.PUBLIC_URL}/simtrackingdrivers`} element={<SimTracking />} />
                            <Route path={`${process.env.PUBLIC_URL}/transittimes`} element={<TransitTimes />} />
                            <Route path={`${process.env.PUBLIC_URL}/tmstickets`} element={<TicketManagementSystem />} />
                            <Route path={`${process.env.PUBLIC_URL}/closetickets`} element={<ClosedTickets />} />
                            <Route path={`${process.env.PUBLIC_URL}/dealerDetentionReport`} element={<DealerDetentionReport />} />
                            <Route path={`${process.env.PUBLIC_URL}/snddefault`} element={<SNDDefault />} />

                            <Route path={`${process.env.PUBLIC_URL}/confignd`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configospd`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/confignogps`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configstoppages`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configldelay`} element={<Configuration />} />
                            <Route path={`${process.env.PUBLIC_URL}/configudelay`} element={<Configuration />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/gpsintegration`} element={<TrucksWithInGeofence />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/searchvicinity`} element={<TrucksWithInGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/nearbysndvehicles`} element={<ManageNearByConsignmentTrucks />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/sndsafetyreport`} element={<SafetyReport />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/sndgeofence`} element={<ConsignmentsGeofence />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} element={<OptimizeDeviationRoutes />} /> */}

                            <Route path={`${process.env.PUBLIC_URL}/dailyrunningdashboard`} element={<DailyRunningDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndloadingdashboard`} element={<LoadingDashboard />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndunloadingdashboard`} element={<UnLoadingDashboard />} />

                            <Route path={`${process.env.PUBLIC_URL}/sndtransitanalytics`} element={<TransitAnalytics />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndtransportdashboard`} element={<DailyRunningTransporterDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/sndgpsdashboard`} element={<GPSDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/ontimedashboard`} element={<OnTimeDashboardComponent />} />
                            <Route path={`${process.env.PUBLIC_URL}/nightdriving`} element={<NightDriving />} />
                            <Route path={`${process.env.PUBLIC_URL}/overspeedreport`} element={<OverspeedReport />} />
                            <Route path={`${process.env.PUBLIC_URL}/stoppagereport`} element={<StoppageReport />} />

                            <Route path={`${process.env.PUBLIC_URL}/generatenewewaybill`} element={<GenerateEwayBill />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/1`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/2`} element={<EwayBillData2 />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybilldata/3`} element={<EwayBillData />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybillrequests`} element={<EwayBillRequests />} />
                            <Route path={`${process.env.PUBLIC_URL}/consolidateewaybill`} element={<ConsolidateEwayBill />} />
                            <Route path={`${process.env.PUBLIC_URL}/extendewaybill/:token`} element={<ExtendEwayBill />} />
                            <Route path={`${process.env.PUBLIC_URL}/consolidateewaybillrequests`} element={<ConsolidateEwayBillRequests />} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/ewaybillreport`} element={<EwayBillData />} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/ewaybillreport`} element={<EwayBillConsolidatedReports />} />
                            <Route path={`${process.env.PUBLIC_URL}/ewaybillotherpartydata`} element={<EwayBillsOtherPartyData />} />
                            <Route path={`${process.env.PUBLIC_URL}/sidebar`} element={<MenuSideBar />} />


                            <Route path={`${process.env.PUBLIC_URL}/iconsssss`} element={<Samplepage />} />

                            <Route path={`${process.env.PUBLIC_URL}/optimizeroutes`} element={<OptimizeDeviationRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/dealerzonewiseroutes`} element={<DealerZoneWiseRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/deviationanalysisdata`} element={<DeviationRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/saproutes`} element={<SavedBaseRoutes />} />
                            <Route path={`${process.env.PUBLIC_URL}/topbaseroutessummary`} element={<TopBaseRouteSummary />} />

                            <Route path={`${process.env.PUBLIC_URL}/geofence`} element={<Geofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/addgeofence`} element={<AddGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/editgeofence`} element={<EditGeofence />} />
                            <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} element={<EditGeofence />} />

                            {/* <Route path={`${process.env.PUBLIC_URL}/consignments-active`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/consignments-delivered`} element={<Consignments />} />
                            <Route path={`${process.env.PUBLIC_URL}/consignments-all`} element={<Consignments />} /> */}
                        </Route>

                    </Routes>
                    {/* </ScrollRestoration> */}
                </Router>
            </Provider>
        )
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);


