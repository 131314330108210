const DownloadEwayBill = (e) => {

    var status = e.data.status;
    var check = 0;
    if (status == "Success") {
        if (e.data.response_from_api != undefined && e.data.response_from_api != "") {
            if (e.data.response_from_api.url != undefined && e.data.response_from_api.url != "") {
                check = 1;
            }
        }
    }
    if (check == 1) {
        return <i title="Download E-Way Bill" style={{ fontSize: "20px" }} class="icofont icofont-download"></i>;
    }
    else {
        return "";
    }
}
export default DownloadEwayBill