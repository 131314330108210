
import React, { Component } from 'react';


const Implementroute = (props) => {
  // console.log("props ",props.data)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickRouteImplement(props);
    };
   
    return (
        <div>
            <span>
                {(props.data.route_approval_status != "" && props.data.route_approval_status != undefined)?
                    ((props.data.route_approval_status == "approved")?
                        <button type="button" onClick={handleClick} className="btn btn-secondary">Implement Route</button>
                        :""
                    )
                    
            :""}
            </span>
        </div>
    );
};

export default Implementroute;
