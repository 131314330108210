export const MENUITEMS2 = [
    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "all",
        "name": "Consignments",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Consignments",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Consignments",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/consignments",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
          
        ]
    },
    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "all",
        "name": "Configuration",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Configuration",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Configuration",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/configuration",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
          
        ]
    },

    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "all",
        "name": "Route Analytics",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Route Analytics",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Optimized Routes",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/optimizeroutes",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Multi Trips Routes",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dealerzonewiseroutes",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Base Routes Summary",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/deviationanalysisdata",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "SAP Routes",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/saproutes",
                "env_show": "prod",
                "active": true,
                "active": false,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "gps_integration_status",
                "name": "Top Base Routes Summary",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/topbaseroutessummary",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
          
        ]
    },


    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "tms",
        "name": "Ticket Management System",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Ticket Management System",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "utilities",
                "name": "Ticket Management",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/ticketmanagementsystem",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

           
            
           
          
        ]
    },



    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "utilities",
        "name": "Utilities",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Route Analytics",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "utilities",
                "name": "Vehicles Within Geofence",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/snddepartmentvicinity/sndg",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "utilities",
                "name": "Vehicle Location(Map)",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/nearbysndtrucks",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "utilities",
                "name": "Geofence Incursions",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/sndgeofence",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
           
          
        ]
    },


    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "dash",
        "name": "Dashboards",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Dashboard",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Loading Dashboard",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/sndloadingdashboard",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Unloading Dashboard",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/sndunloadingdashboard",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Daily Running Dashboard",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dailyrunningdashboard",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Transit Analytics",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/sndtransitanalytics",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Transporter Dashboard",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/sndtransportdashboard",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            // {
            //     "_id": "5e4bdd748e94f52259e33742",
            //     "code": "dash",
            //     "name": "Transporter Dashboard",
            //     "description": "Consignments",
            //     "icon": "support",
            //     "is_group_header": false,
            //     "group_heading": null,
            //     "parent_code": "all",
            //     "is_link": true,
            //     "path": "/sndtransportdashboard",
            //     "env_show": "prod",
            //     "active": false,
            //     "is_active": true,
            //     "display_order": 0,
            //     "level1": "All",
            //     "level2": "All",
            //     "created_datetime": "2020-04-20T11:09:38.532Z",
            //     "modified_datetime": "2020-04-20T11:09:38.532Z",
            //     "is_mobile": true,
            //     "children": []
            // },


            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Ontime Performance",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/ontimedashboard",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
           
           
          
        ]
    },



    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "eway",
        "name": "E-wayBills",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "E-WayBills",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": "Generate New",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/generatenewewaybill",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": "E-Way Bill Reports",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/ewaybilldata/1",
                "env_show": "prod",
               "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": "E-Waybill Requests",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/ewaybillrequests",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": " Consolidate E-Way Bills",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/consolidateewaybill",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": "Consolidate Requests",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/consolidateewaybillrequests",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "eway",
                "name": "EwayBill Consolidated Reports",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/ewaybillreport",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
           
          
        ]
    },

    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "master",
        "name": "Masters",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Masters",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
          
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "master",
                "name": "Manage Transit Times",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/transittimes",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "master",
                "name": "Geofence",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/geofence",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "master",
                "name": "Dealer Master",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dealers",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "master",
                "name": "Vehicle Master",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/vehicles",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "master",
                "name": "Transporter Master",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/transporters",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

          
        ]
    },



    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "an",
        "name": "Reports",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Reports",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Nightdriving Report",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/nightdriving",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Overspeed Report",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/overspeedreport",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "an",
                "name": "Stoppage Report",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/stoppagereport",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "dash",
                "name": "Dealer Detention Report",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/dealerDetentionReport",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },
        ]
    },


    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "st",
        "name": "Sim Tracking",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "Sim Tracking",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "st",
                "name": "Sim Tracking",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/simtrackingdrivers",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

           
          
          
        ]
    },


    {
        "_id": "5e4bdce18e94f52259e3356b",
        "code": "epod",
        "name": "E-Pod",
        "description": "All",
        "icon": "support",
        "is_group_header": true,
        "group_heading": "E-Pod",
        "parent_code": null,
        "is_link": false,
        "path": null,
        "env_show": "prod",
        "active": false,
        "is_active": 1,
        "display_order": 0,
        "level1": "main",
        "created_datetime": "2020-02-18T12:16:14.000Z",
        "modified_datetime": "2020-02-18T12:42:14.000Z",
        "children": [
            {
                "_id": "5e4bdd748e94f52259e33742",
                "code": "epod",
                "name": "E-Pod",
                "description": "Consignments",
                "icon": "support",
                "is_group_header": false,
                "group_heading": null,
                "parent_code": "all",
                "is_link": true,
                "path": "/epod",
                "env_show": "prod",
                "active": false,
                "is_active": true,
                "display_order": 0,
                "level1": "All",
                "level2": "All",
                "created_datetime": "2020-04-20T11:09:38.532Z",
                "modified_datetime": "2020-04-20T11:09:38.532Z",
                "is_mobile": true,
                "children": []
            },

           
          
          
        ]
    },



]