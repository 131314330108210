import React, { useEffect, useState } from 'react';

import redirectURL from "../redirectURL";

import { getHyphenDDMMMYYYYHHMM } from '../common/utils';

import './Dashboard.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


import CurvedLineChart from './CurvedLineChart.js';
import DonutChart from './DonutChart.js';
import WeatherComponent from './WeatherReportComponent.js';
import BarChartWithCard from './BarChartWithDataPreview.js';
import HeatMap from './heamap.js';
import inTransitIcon from '../../assets/icons/traveling.svg' // Example icon paths
import nearbyIcon from '../../assets/icons/nearByIcon.svg'
import reachedIcon from '../../assets/icons/reachedIcon.svg'
import TrendUpRed from "../../assets/icons/trend_up_red.svg"
import TrendDownGreen from "../../assets/icons/trend_down_green.svg"
import delayIcon from '../../assets/icons/delayIcon.svg'
import Select from 'react-select';

import TrendUpGreen from '../../assets/icons/trendUp.svg'
import TrendDownRed from '../../assets/icons/trendDown.svg'

// import { color } from 'highcharts'; 


var moment = require("moment")

const data = {
    inTransit: 50,
    nearby: 25,
    reached: 25,
};

const closure = [
    { color: '#147AD6', label: 'Auto', key: "AutoClosed" },
    // { color: '#E8BD47', label: 'Proximity', key: "ProximityClosed" },
    { color: '#E84747', label: 'Force', key: "ForceClosed" },
];

const delays = [
    { color: '#147AD6', label: '0-1 Days', key: '0-1Days' },
    { color: '#E8BD47', label: '1-2 Days', key: '1-2Days' },
    { color: '#E84747', label: 'Critical', key: 'Critical' },
];

const allconsignments = [
    { color: '#49beaa', label: 'On Time', key: 'ontime_count' },
    { color: '#F44336', label: 'Delay', key: 'delay_count' },
]

const Dashboard = () => {


    var currentMonth = moment.parseZone(new Date()).utcOffset("+05:30")._d.getUTCMonth()
    var currentYear = moment.parseZone(new Date()).utcOffset("+05:30")._d.getUTCFullYear()

    const [selectedData, setSelectedData] = useState(null);
    // const [consignmentsData,setConsignmentsData] = useState([])
    const [selectedViolation, setSelectedViolation] = useState('overspeed');

    const [activeConsignmentsCardData, setActiveConsignmentsCardData] = useState([{}])
    const [allConsignmentsCardData, setAllConsignmentsCardData] = useState([{}])
    const [plantWeatherData, setPlantWeatherData] = useState([])
    const [closureDetailsCardData, setClosureDetailsCardData] = useState([])
    const [consignmentsDataByMonth, setConsignmentsDataByMonth] = useState({
        categories: ['APR', 'MAY', 'JUN', 'JUL', "AUG", "SEP", "OCT", "NOV", "DEC", 'JAN', 'FEB', 'MAR'].slice(0, (currentMonth + 10) % 12),
        title: "Consignments by Month",
        yAxis: 'Consignments'
    })
    const [delaysData, setDelaysData] = useState({})

    const [alertsData, setAlertsData] = useState({})
    const [alertsDataForChart, setAlertsDataForChart] = useState([])
    const [plantLocationData, setPlantLocationData] = useState([])
    const [trendPercentage, setTrendPercentage] = useState({})
    const [alertsDetails, setAlertsDetails] = useState([])
    const [state, setState] = useState({
        rowData: [],
        columnwithDefs: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            pivot: true,
            enableValue: true,
            enableRowGroup: false,
        },
        rowSelection: "single",
        paginationPageSize: 50,
    })
    const [selectedFilter, setSelectedFilter] = useState({ label: "Current Month", value: 'month' })


    const handleButtonClick = (violation, alertsData) => {
        setSelectedViolation(violation)
        if (violation == "overspeed") {
            var data = alertsData.overspeedingAlertsData

            var alertsData = {
                categories: data.map(e => e.transporter_code),
                yAxis: "Alerts Count",
                series: [{
                    name: 'Transporter',
                    data: data.map(e => e.alerts_count),
                    color: '#167AD6',
                    // point: {
                    //   events: {
                    //     click: handlePointClick,
                    //   },
                    // },
                }],
            }


            setAlertsDataForChart(alertsData)
        }
        else {
            var data = alertsData.nightdrivingAlertsData

            var alertsData = {
                categories: data.map(e => e.transporter_code),
                yAxis: "Alerts Count",
                series: [{
                    name: 'Transporter',
                    data: data.map(e => e.alerts_count),
                    color: '#384551DE',
                    // point: {
                    //   events: {
                    //     click: handlePointClick,
                    //   },
                    // },
                }],
            }

            setAlertsDataForChart(alertsData)
        }



    };
    const countComparison2 = (thisMonthCountConsignmentsCount, lastMonthConsignmentCount, thisMonthCount, lastMonthCount) => {
        var currentMonthPercentage = (thisMonthCount / thisMonthCountConsignmentsCount) * 100
        var lastMonthPercentage = (lastMonthCount / lastMonthConsignmentCount) * 100
        return (currentMonthPercentage - lastMonthPercentage).toFixed(2)
    }
    const handleColumnClick = async (data) => {

        const columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150,
                editable: false,
            },

            {
                headerName: "Timestamp",
                field: "first_instance",
                width: 200,
                editable: false,

                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.first_instance);
                },
            },
            {
                headerName: "Address",
                field: "first_instance_address",
                width: 150,
                editable: false,
            }
        ]

        var params = {
            transporter_code: data.category,
            alert_type: selectedViolation
        }
        await redirectURL.post("/dashboard/getAlertDetailsForTrasporter", params).then(response => {
            var alertDeails = response.data
            setState({ ...state, rowData: alertDeails, columnwithDefs: columnwithDefs })
            alertDeails.length > 0 && setSelectedData(data)
        })

    };

    const countComparison = (thisMonthCount, lastMonthCount, daysElapsed, currentMonthDays) => {
        const thisMonthAveragePerDay = daysElapsed ? thisMonthCount / daysElapsed : 0;
        const estimatedThisMonthTotal = thisMonthAveragePerDay * currentMonthDays;

        const difference = estimatedThisMonthTotal - lastMonthCount;
        const percentageChange = lastMonthCount === 0 ? 0 : ((difference / lastMonthCount) * 100).toFixed(2)
        return percentageChange
    }

    const aggregateCounts = (objectsArray) => {
        return objectsArray.reduce((acc, obj) => {
            for (let key in obj) {
                if (key !== "month") {
                    acc[key] = (acc[key] || 0) + obj[key];
                }
            }
            return acc;
        }, {});
    }

    useEffect(() => {
        getInitialData(selectedFilter.value)
    }, [selectedFilter])

    const getInitialData = (value) => {

        var startYear = ""
        var startMonth = ""
        if (value == "month") {
            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // Months are 0-indexed


            if (currentMonth == 4) {
                startYear = currentYear;
                startMonth = "03"
            }
            else if (currentMonth > 4) { // May (4th month) to December
                startYear = currentYear;
                startMonth = "04"
            } else { // January to March
                startYear = currentYear - 1;
                startMonth = "04"
            }
        }
        else {
            const now = new Date()
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // Months are 0-indexed
            var startYear = ""
            var startMonth = ""
            if ([4, 5, 6].includes(currentMonth)) {
                startYear = currentYear;
                startMonth = "01"
            }
            else if ([7, 8, 9, 10, 11, 12].includes(currentMonth)) {
                startYear = currentYear;
                startMonth = "04"
            }
            else { // January to March
                startYear = currentYear - 1;
                startMonth = "04"
            }
        }


        var params = {
            startOfYearMonth: `${startYear}-${startMonth}`,
        }

        redirectURL.post("/dashboard/getConsignmentDataForDashboard", params).then(response => {
            var consignments = response.data.consignments

            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1;
            let currentPeriodDays = 0;
            let daysElapsed = 0;

            if (value === "month") {
                const year_month = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;
                const lastMonth = now.getMonth();
                const year_last_month = `${currentMonth === 1 ? currentYear - 1 : currentYear}-${lastMonth.toString().padStart(2, '0')}`;

                var last_period_counts_data = consignments.filter(e => e.month === year_last_month);
                var current_period_counts_data = consignments.filter(e => e.month === year_month);

                last_period_counts_data = aggregateCounts(last_period_counts_data);
                current_period_counts_data = aggregateCounts(current_period_counts_data);

                currentPeriodDays = moment().endOf("month").date();
                daysElapsed = now.getDate();
            } else {
                let last_quarter_start, last_quarter_end, current_quarter_start, current_quarter_end;

                if (currentMonth >= 1 && currentMonth <= 3) {
                    last_quarter_start = `${currentYear - 1}-10`;
                    last_quarter_end = `${currentYear - 1}-12`;
                    current_quarter_start = `${currentYear}-01`;
                    current_quarter_end = `${currentYear}-03`;
                } else if (currentMonth >= 4 && currentMonth <= 6) {
                    last_quarter_start = `${currentYear}-01`;
                    last_quarter_end = `${currentYear}-03`;
                    current_quarter_start = `${currentYear}-04`;
                    current_quarter_end = `${currentYear}-06`;
                } else if (currentMonth >= 7 && currentMonth <= 9) {
                    last_quarter_start = `${currentYear}-04`;
                    last_quarter_end = `${currentYear}-06`;
                    current_quarter_start = `${currentYear}-07`;
                    current_quarter_end = `${currentYear}-09`;
                } else {
                    last_quarter_start = `${currentYear}-07`;
                    last_quarter_end = `${currentYear}-09`;
                    current_quarter_start = `${currentYear}-10`;
                    current_quarter_end = `${currentYear}-12`;
                }

                // Calculate the days elapsed and current period days
                for (let i = parseInt(current_quarter_start.split('-')[1]); i <= parseInt(current_quarter_end.split('-')[1]); i++) {
                    const daysInMonth = moment().year(currentYear).month(i - 1).endOf("month").date();
                    if (i < currentMonth) {
                        daysElapsed += daysInMonth;
                    } else if (i === currentMonth) {
                        daysElapsed += now.getDate();
                    }
                    currentPeriodDays += daysInMonth;
                }

                var last_period_counts_data = consignments.filter(e => e.month >= last_quarter_start && e.month <= last_quarter_end);
                var current_period_counts_data = consignments.filter(e => e.month >= current_quarter_start && e.month <= current_quarter_end);

                last_period_counts_data = aggregateCounts(last_period_counts_data);
                current_period_counts_data = aggregateCounts(current_period_counts_data);
            }




            var trendPercentage = {
                consignments: 0,
                ontime_count: 0,
                delay_count: 0,
                ForceClosed: 0,
                AutoClosed: 0,
                // ProximityClosed: 0,
                "0-1Days": 0,
                "1-2Days": 0,
                Critical: 0,
                "Overall_Delay": 0
            }
            trendPercentage["consignments"] = countComparison(current_period_counts_data.consignments, last_period_counts_data.consignments, daysElapsed, currentPeriodDays)
            trendPercentage["ontime_count"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.consignments - current_period_counts_data.delay,
                last_period_counts_data.consignments - last_period_counts_data.delay
            )
            trendPercentage["delay_count"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.delay,
                last_period_counts_data.delay
            )
            trendPercentage["ForceClosed"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.force_closed,
                last_period_counts_data.force_closed
            )
            trendPercentage["AutoClosed"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.auto_closed,
                last_period_counts_data.auto_closed
            )
            // trendPercentage["ProximityClosed"] = countComparison2(
            //     current_period_counts_data.consignments,
            //     last_period_counts_data.consignments,
            //     current_period_counts_data.proximity_closed,
            //     last_period_counts_data.proximity_closed
            // )
            trendPercentage["0-1Days"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.delay_under_1day,
                last_period_counts_data.delay_under_1day
            )
            trendPercentage["1-2Days"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.delay_under_2days,
                last_period_counts_data.delay_under_2days
            )
            trendPercentage["Critical"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.critical,
                last_period_counts_data.critical
            )
            trendPercentage["Overall_Delay"] = countComparison2(
                current_period_counts_data.consignments,
                last_period_counts_data.consignments,
                current_period_counts_data.delay_under_1day + current_period_counts_data.delay_under_2days + current_period_counts_data.critical,
                last_period_counts_data.delay_under_1day + last_period_counts_data.delay_under_2days + last_period_counts_data.critical
            )
            trendPercentage = sanitizeData(trendPercentage)
            setTrendPercentage(trendPercentage)

            var ActiveCount = current_period_counts_data.in_transit_count + current_period_counts_data.nearby_count

            var activeConsignmentsData = {
                InTransitCount: current_period_counts_data.in_transit_count,
                NearByCount: current_period_counts_data.nearby_count,
                // ReachedCount: current_period_counts_data.reached_count,
                activeConsignmentsCount: ActiveCount,
                InTransitPercentage: ((current_period_counts_data.in_transit_count / ActiveCount) * 100).toFixed(2),
                NearByPercentage: ((current_period_counts_data.nearby_count / ActiveCount) * 100).toFixed(2),
                // ReachedPercentage: ((current_period_counts_data.reached_count / ActiveCount) * 100).toFixed(2),
                TransitDelayCount: current_period_counts_data.in_transit_delay,
                NearByIn24Hrs: current_period_counts_data.nearby_within_24hrs,
                NearByNotLast24Hrs: current_period_counts_data.nearby_outside_24hrs,
                // ReachedIn24Hrs: current_period_counts_data.reached_within_24hrs,
                // ReachedNotLast24Hrs: current_period_counts_data.reached_outside_24hrs

            }


            // console.log(activeConsignmentsData, "activeConsignmentsData");
            activeConsignmentsData = sanitizeData(activeConsignmentsData)
            // console.log(activeConsignmentsData, "activeConsignmentsData2");


            setActiveConsignmentsCardData(activeConsignmentsData)
            var total_consignmnets_count = current_period_counts_data.consignments
            var consignmentsCarddata = {
                consignments: 0,
                ontime_count: 0,
                delay_count: 0,
                Series: [
                    {
                        name: 'All Consignments',
                        data: [
                            {
                                name: 'On Time',
                                y: 0,
                                color: '#49beaa',
                                value: 0
                            },
                            {
                                name: 'Delay',
                                y: 0,
                                color: '#F44336',
                                value: 0
                            },
                        ],
                    },
                ]
            }
            var consignmentsCarddata = {
                consignments: isNaN(total_consignmnets_count) ? 0 : total_consignmnets_count,
                ontime_count: isNaN(total_consignmnets_count - current_period_counts_data.delay) ? 0 : (total_consignmnets_count - current_period_counts_data.delay),
                delay_count: isNaN(current_period_counts_data.delay) ? 0 : current_period_counts_data.delay,
                Series: [
                    {
                        name: 'All Consignments',
                        data: [
                            {
                                name: 'On Time',
                                y: isNaN(((total_consignmnets_count - current_period_counts_data.delay) / total_consignmnets_count) * 100) ? 0 : (((total_consignmnets_count - current_period_counts_data.delay) / total_consignmnets_count) * 100),
                                color: '#49beaa',
                                value: isNaN(total_consignmnets_count - current_period_counts_data.delay) ? 0 : ((total_consignmnets_count - current_period_counts_data.delay) / total_consignmnets_count) * 100
                            },
                            {
                                name: 'Delay',
                                y: isNaN((current_period_counts_data.delay / total_consignmnets_count) * 100) ? 0 : ((current_period_counts_data.delay / total_consignmnets_count) * 100),
                                color: '#F44336',
                                value: isNaN(current_period_counts_data.delay) ? 0 : current_period_counts_data.delay
                            },
                        ],
                    },
                ]
            }
            setAllConsignmentsCardData(consignmentsCarddata)

            // Weather report Data
            var plantWeather = response.data.plantWeather
            setPlantWeatherData(plantWeather)

            //Clouser Details Card
            var closed = current_period_counts_data.force_closed + current_period_counts_data.auto_closed
            // var closed = current_period_counts_data.force_closed + current_period_counts_data.auto_closed + current_period_counts_data.proximity_closed
            var closureDetailsCardData = {
                ForceClosed: current_period_counts_data.force_closed,
                AutoClosed: current_period_counts_data.auto_closed,
                // ProximityClosed: current_period_counts_data.proximity_closed,
                Closed: closed,
                Series: [
                    {
                        name: 'Consignments',
                        data: [
                            {
                                name: 'Auto Closed',
                                y: (current_period_counts_data.auto_closed / closed) * 100,
                                color: '#147AD6',
                                value: current_period_counts_data.auto_closed
                            },
                            // {
                            //     name: 'Proximity Closure',
                            //     y: (current_period_counts_data.proximity_closed / closed) * 100,
                            //     color: '#E8BD47',
                            //     value: current_period_counts_data.proximity_closed
                            // },
                            {
                                name: 'Force Close',
                                y: (current_period_counts_data.force_closed / closed) * 100,
                                color: '#E84747',
                                value: current_period_counts_data.force_closed

                            },
                        ],
                    },
                ]
            }
            setClosureDetailsCardData(closureDetailsCardData)

            var total_delays = current_period_counts_data.delay_under_1day + current_period_counts_data.delay_under_2days + current_period_counts_data.critical

            var delaysData = {
                "0-1Days": current_period_counts_data.delay_under_1day,
                "1-2Days": current_period_counts_data.delay_under_2days,
                "Critical": current_period_counts_data.critical,
                total_delays: total_delays,
                series: [
                    {
                        name: 'Delays',
                        data: [
                            { name: '0-1Days', y: (current_period_counts_data.delay_under_1day / total_delays) * 100, color: '#147AD6', value: current_period_counts_data.delay_under_1day },

                            { name: '1-2Days', y: (current_period_counts_data.delay_under_2days / total_delays) * 100, color: '#E8BD47', value: current_period_counts_data.delay_under_2days },

                            { name: 'Critical', y: (current_period_counts_data.critical / total_delays) * 100, color: '#E84747', value: current_period_counts_data.critical },

                        ]
                    }
                ],
            }

            setDelaysData(delaysData)

            var consignmentsByMonthData = consignments
            setConsignmentsDataByMonth({
                ...consignmentsDataByMonth,
                ...{
                    categories: consignmentsByMonthData.map(e => { if (isWithinLastTwoYears(e.month)) return convertToMonthYear(e.month) }),
                    series: [{
                        type: 'line',
                        name: `Current Financial Year`,
                        data: consignmentsByMonthData.map(item => { if (isWithinLastTwoYears(item.month)) return item.consignments }),
                        color: '#0000FF',
                    }]
                }
            })
        })

        var startYear = ""
        var startMonth = ""
        if (value == "month") {
            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // Months are 0-indexed



            startYear = currentYear
            startMonth = currentMonth.toString().padStart(2, '0')

        }
        else {
            const now = new Date()
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth() + 1; // Months are 0-indexed
            var startYear = ""
            var startMonth = ""
            if ([4, 5, 6].includes(currentMonth)) {
                startYear = currentYear;
                startMonth = "04"
            }
            else if ([7, 8, 9].includes(currentMonth)) {
                startYear = currentYear;
                startMonth = "07"
            }
            else if ([10, 11, 12].includes(currentMonth)) {
                startYear = currentYear;
                startMonth = "10"
            }
            else { // January to March
                startYear = currentYear
                startMonth = "01"
            }
        }


        var params = {
            startOfYearMonth: `${startYear}-${startMonth}`,
        }

        redirectURL.post("/dashboard/getAlertsDataForDashboard", params).then(response => {
            setAlertsData(response.data)
            var data = response.data.overspeedingAlertsData

            var alertsData = {
                categories: data.map(e => e.transporter_code),
                yAxis: "Alerts Count",
                series: [{
                    name: 'Transporter',
                    data: data.map(e => e.alerts_count),
                    color: '#167AD6',
                    // point: {
                    //   events: {
                    //     click: handlePointClick,
                    //   },
                    // },
                }],
            }

            setAlertsDataForChart(alertsData)
        })
        redirectURL.post("/dashboard/getPlantLocationsForDashboard", params).then(response => {
            var plantLocationData = response.data


            setPlantLocationData(plantLocationData)
        })
    }

    const getTrendColor = (trend, value) => {
        if (trend == "right") {
            return value > 0 ? "#0F911E" : "#E84747"
        }
        else {
            return value < 0 ? "#0F911E" : "#E84747"
        }
    }



    return (
        <div className="container-fluid " style={{ padding: "20px" }}>
            <div className="row" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <div style={{ fontWeight: "bold", marginRight: "20px" }}>
                        Last updated at:
                        <span style={{ fontSize: "14px", marginLeft: "5px", fontWeight: "normal", color: "#555" }}>
                            {getLast10thMinuteDate()}
                        </span>
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                        Next update at:
                        <span style={{ fontSize: "14px", marginLeft: "5px", fontWeight: "normal", color: "#555" }}>
                            {getNext10thMinuteDate()}
                        </span>
                    </div>
                </div>

                <div className='col-xl-2 col-md-2 ml-5'>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        // id="selectedFilter"
                        value={selectedFilter}
                        style={{ borderRadius: "0px" }}
                        options={[
                            {
                                label: "Current Month",
                                value: "month"
                            },
                            {
                                label: "Current Quarter",
                                value: "quarter"
                            }
                        ]}
                        onChange={e => {
                            setSelectedFilter(e)
                            // getInitialData(e.value)
                        }}
                        required />
                </div>


            </div>
            <div className="row" style={{ alignItems: "flex-start" }}>
                <div className="col-xl-6 col-md-6">
                    <div className="card" style={{ minHeight: "340px" }}>
                        <div className="card-body">
                            <div className="header">
                                <h5 style={{ fontSize: "16px", color: "#384551", fontWeight: "bold" }}>Active Consignments </h5>
                            </div>
                            <div className="legend">
                                <div className="legend-item">
                                    <span className="dot in-transit"></span> In Transit
                                </div>
                                <div className="legend-item">
                                    <span className="dot nearby"></span> Nearby

                                </div>
                                {/* <div className="legend-item">
                                    <span className="dot reached"></span> Reached

                                </div> */}
                            </div>
                            <div className="progress-bar-container">
                                <div className="bar in-transit" style={{ width: `${activeConsignmentsCardData.InTransitPercentage}%`, borderBottomLeftRadius: "5px", borderTopLeftRadius: "5px" }}>

                                </div>
                                <div className="bar nearby" style={{ width: `${activeConsignmentsCardData.NearByPercentage}%` }}>
                                </div>
                                {/* <div className="bar reached" style={{ width: `${activeConsignmentsCardData.ReachedPercentage}%`, borderBottomRightRadius: "5px", borderTopRightRadius: "5px" }}>
                                </div> */}
                            </div>
                            <div className="progress-values-container">
                                <div className="bar" style={{ width: `${activeConsignmentsCardData.InTransitPercentage}%`, borderBottomLeftRadius: "5px", borderTopLeftRadius: "5px" }}>
                                    {activeConsignmentsCardData.InTransitPercentage}%
                                </div>
                                <div className="bar" style={{ width: `${activeConsignmentsCardData.NearByPercentage}%` }}>
                                    {activeConsignmentsCardData.NearByPercentage}%

                                </div>
                                {/* <div className="bar" style={{ width: `${activeConsignmentsCardData.ReachedPercentage}%`, borderBottomRightRadius: "5px", borderTopRightRadius: "5px" }}>
                                    {activeConsignmentsCardData.ReachedPercentage}%

                                </div> */}
                            </div>
                            {/* <div className="legend">
                                <div className="legend-item">
                                    <span className="dot in-transit"></span> In Transit
                                    - <span style={{ fontWeight: "bold" }}>{activeConsignmentsCardData.InTransitPercentage}%</span>
                                </div>
                                <div className="legend-item">
                                    <span className="dot nearby"></span> Nearby
                                    - <span style={{ fontWeight: "bold" }}>{activeConsignmentsCardData.NearByPercentage}%</span>

                                </div>
                                <div className="legend-item">
                                    <span className="dot reached"></span> Reached
                                    - <span style={{ fontWeight: "bold" }} >{activeConsignmentsCardData.ReachedPercentage}%</span>

                                </div>
                            </div> */}
                            <div style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <div className="row-style">
                                    <div className="status-style">
                                        <img src={inTransitIcon} alt="In Transit" className="icon-style" /> In Transit
                                    </div>
                                    <div className="count-style-no-border">{activeConsignmentsCardData.InTransitCount}</div>
                                    <div style={{ flex: '0 0 40%' }}></div>
                                </div>
                                <div className="row-style">
                                    <div className="status-style">
                                        <img src={nearbyIcon} alt="Nearby" className="icon-style" /> Near By
                                    </div>
                                    <div className="count-style">{activeConsignmentsCardData.NearByCount}</div>
                                    <div className="time-container-style">
                                        <div className="time-element-container-style">
                                            <div className="time-style">{activeConsignmentsCardData.NearByIn24Hrs}</div>
                                            <div style={{ fontSize: "10px" }} >{"> 24 Hr"}</div>
                                        </div>
                                        <div className="time-element-container-style" style={{ paddingLeft: '10px' }}>
                                            <div className="time-less-than-24hr-style">{activeConsignmentsCardData.NearByNotLast24Hrs}</div>
                                            <div style={{ fontSize: "10px" }} >{"< 24 Hr"}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row-style"> */}
                                {/* <div className="status-style">
                                        <img src={reachedIcon} alt="Reached" className="icon-style" /> Reached
                                    </div> */}
                                {/* <div className="count-style">{activeConsignmentsCardData.ReachedCount}</div> */}
                                {/* <div className="time-container-style"> */}
                                {/* <div className="time-element-container-style">
                                            <div className="time-style">{activeConsignmentsCardData.ReachedIn24Hrs}</div>
                                            <div style={{ fontSize: "10px" }} >{"> 24 Hr"}</div>
                                        </div> */}
                                {/* <div className="time-element-container-style" style={{ paddingLeft: '10px' }}>
                                            <div className="time-less-than-24hr-style">{activeConsignmentsCardData.ReachedNotLast24Hrs}</div>
                                            <div style={{ fontSize: "10px" }} >{"< 24 Hr"}</div>
                                        </div> */}
                                {/* </div> */}
                                {/* </div> */}
                                <div className="row-style" style={{ borderBottom: "none" }}>
                                    <div className="status-style">
                                        <img src={delayIcon} alt="Delay" className="icon-style" /> Delay
                                    </div>
                                    <div className="count-style-no-border">{activeConsignmentsCardData.TransitDelayCount}</div>
                                    <div style={{ flex: '0 0 40%' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3 " >
                    <div className="card" style={{ minHeight: "340px" }}>
                        <div className="card-body">
                            <div style={{ display: "flex" }}>
                                <h5 style={{ fontSize: "16px", color: "#384551DE", fontWeight: "bold" }}>
                                    All Consignments
                                </h5>
                                {parseFloat(trendPercentage.consignments) != 0 && (parseFloat(trendPercentage.consignments) > 0 ? <div>
                                    <img src={TrendUpGreen} alt='trend-up' style={{ width: "16px", marginLeft: "5px" }} />
                                    <span style={{ fontSize: "10px", color: "#0F911E", marginLeft: "2px" }}> {trendPercentage.consignments && trendPercentage.consignments > 0 ? "+" : "-"}{trendPercentage.consignments}%</span>
                                </div> : <div>
                                    <img src={TrendDownRed} alt='trend-down' style={{ width: "16px", marginLeft: "5px" }} />
                                    <span style={{ fontSize: "10px", color: "#E84747", marginLeft: "2px" }}> {trendPercentage.consignments && trendPercentage.consignments > 0 ? "+" : "-"}{(trendPercentage.consignments || 0).toString().slice(1)}%</span>
                                </div>)}
                            </div>
                            <DonutChart
                                CenterText={allConsignmentsCardData.consignments || 0}
                                Series={allConsignmentsCardData.Series}
                                // width={180}
                                height={180}
                            />
                            {/* <div className="status-list">
                                {allconsignments.map((status, index) => (
                                    <div className="status-item" key={index}>
                                        <div className="status-label">
                                            <span className="status-dot" style={{ backgroundColor: status.color }}></span>
                                            {status.label}
                                        </div>
                                        <div className="status-count" style={{ borderColor: status.color, color: status.color }}>
                                            {allConsignmentsCardData[status.key]}
                                        </div>
                                    </div>
                                ))}
                                
                            </div> */}

                            <table className="status-table" style={{ width: "100%", borderCollapse: "collapse" }}>

                                <tbody>
                                    {allconsignments.map((status, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "8px", fontSize: "12px" }}>
                                                <span className="status-dot" style={{ backgroundColor: status.color, display: "inline-block", width: "8px", height: "8px", borderRadius: "50%", marginRight: "8px" }}></span>
                                                {status.label}
                                            </td>
                                            <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", borderColor: status.color, color: status.color }}>
                                                {allConsignmentsCardData[status.key]}
                                            </td>
                                            {trendPercentage[status.key] != 0 && <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", color: status.key != "ontime_count" ? getTrendColor("opposite", trendPercentage[status.key]) : getTrendColor("right", trendPercentage[status.key]) }}>
                                                {/* {status.key !== "0-1Days" && trendPercentage[status.key] !== 0 && ( */}
                                                <span>
                                                    {trendPercentage[status.key] > 0 ? "+" : "-"}
                                                    {(trendPercentage[status.key] || 0).toString().replace('-', '')}%
                                                </span>
                                                {/* )} */}
                                            </td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="card" style={{ minHeight: "340px" }}>
                        <div className="card-body" >
                            <WeatherComponent plantWeatherData={plantWeatherData} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ alignItems: "flex-start" }}>
                <div className="col-xl-3 col-md-3 " >
                    <div className="card" style={{ minHeight: "408px" }}>
                        <div className="card-body">
                            <div >
                                <h5 style={{ fontSize: "16px", color: "#384551DE", fontWeight: "bold", marginBottom: 0 }}>
                                    Closure Summary
                                </h5>
                            </div>
                            <DonutChart
                                CenterText={closureDetailsCardData.Closed || 0}
                                Series={closureDetailsCardData.Series}
                                // width={"100%"}
                                height={200}

                            />
                            <table className="status-table" style={{ width: "100%", borderCollapse: "collapse" }}>

                                <tbody>
                                    {closure.map((status, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "8px", fontSize: "12px" }}>
                                                <span className="status-dot" style={{ backgroundColor: status.color, display: "inline-block", width: "8px", height: "8px", borderRadius: "50%", marginRight: "8px" }}></span>
                                                {status.label}
                                            </td>
                                            <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", borderColor: status.color, color: status.color }}>
                                                {closureDetailsCardData[status.key]}
                                            </td>
                                            {trendPercentage[status.key] != 0 && <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", color: status.key != "AutoClosed" ? getTrendColor("opposite", trendPercentage[status.key]) : getTrendColor("right", trendPercentage[status.key]) }}>
                                                {/* {status.key !== "0-1Days" && trendPercentage[status.key] !== 0 && ( */}
                                                <span>
                                                    {trendPercentage[status.key] > 0 ? "+" : "-"}
                                                    {(trendPercentage[status.key] || 0).toString().replace('-', '')}%
                                                </span>
                                                {/* )} */}
                                            </td>}

                                        </tr>
                                    ))}
                                </tbody>


                            </table>
                            <div style={{ width: "100%", textAlign: "right" }}>
                                <p style={{ fontSize: "10px", color: "#384551DE", marginTop: "0" }}>
                                    % vs last {selectedFilter.value}
                                </p>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3 " >
                    <div className="card" style={{ minHeight: "408px" }}>
                        <div className="card-body">

                            <div style={{ display: "flex" }}>
                                <h5 style={{ fontSize: "16px", color: "#384551DE", fontWeight: "bold" }}>
                                    Delay Summary
                                </h5>
                                {parseFloat(trendPercentage.Overall_Delay) > 0 ? <div>
                                    <img src={TrendUpRed} alt='trend-up' style={{ width: "16px", marginLeft: "5px" }} />
                                    <span style={{ fontSize: "10px", color: "#E84747", marginLeft: "2px" }}> {trendPercentage.Overall_Delay > 0 ? "+" : "-"}{trendPercentage.Overall_Delay}%</span>

                                </div> : <div>
                                    <img src={TrendDownGreen} alt='trend-down' style={{ width: "16px", marginLeft: "5px" }} />
                                    <span style={{ fontSize: "10px", color: "#0F911E", marginLeft: "2px" }}> {trendPercentage.Overall_Delay > 0 ? "+" : "-"}{(trendPercentage.Overall_Delay || 0).toString().slice(1)}%</span>
                                </div>}
                            </div>
                            <DonutChart
                                CenterText={delaysData.total_delays || 0}
                                Series={delaysData.series}
                                // width={200}
                                height={200}

                            />
                            <table className="status-table" style={{ width: "100%", borderCollapse: "collapse" }}>

                                <tbody>
                                    {delays.map((status, index) => (
                                        <tr key={index}>
                                            <td style={{ padding: "8px", fontSize: "12px" }}>
                                                <span className="status-dot" style={{ backgroundColor: status.color, display: "inline-block", width: "8px", height: "8px", borderRadius: "50%", marginRight: "8px" }}></span>
                                                {status.label}
                                            </td>
                                            <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", borderColor: status.color, color: status.color }}>
                                                {delaysData[status.key]}
                                            </td>
                                            <td style={{ padding: "8px", fontSize: "12px", textAlign: "right", color: trendPercentage[status.key] < 0 ? "#0F911E" : "#E84747" }}>
                                                {/* {status.key !== "0-1Days" && trendPercentage[status.key] !== 0 && ( */}
                                                <span>
                                                    {trendPercentage[status.key] > 0 ? "+" : "-"}
                                                    {(trendPercentage[status.key] || 0).toString().replace('-', '')}%
                                                </span>
                                                {/* )} */}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>


                            </table>
                            <div style={{ width: "100%", textAlign: "right" }}>
                                <p style={{ fontSize: "10px", color: "#384551DE", marginTop: "0" }}>
                                    % vs last {selectedFilter.value}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-6 ">
                    <div className="card" style={{ maxHeight: "408px" }}>
                        <div className="card-body" style={{ padding: "20px" }}>
                            <h5 style={{ fontSize: "16px", color: "#384551DE", fontWeight: "bold" }}>
                                Consignments By Month (FY {new Date().getFullYear()} - {parseInt(new Date().getFullYear()) + 1})
                            </h5>
                            <CurvedLineChart
                                series={consignmentsDataByMonth.series}
                                categories={consignmentsDataByMonth.categories}
                                title={consignmentsDataByMonth.title}
                                yAxis={consignmentsDataByMonth.yAxis}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ alignItems: "flex-start" }}>
                <div className="col-xl-6 col-md-6 ">


                    {/* <BarChartWithCard  onColumnClick ={handleColumnClick} /> */}
                    {selectedData ?
                        <div className="card" style={{ minHeight: "450px", padding: "20px" }}>
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h5>
                                    {`${state.rowData[0].transporter_name} - ${state.rowData[0].transporter_code}`}
                                </h5>

                                {/* <button style={{ marginRight: '5px' }} type="button" onClick={() => setSelectedData(null)} className="btn btn-success">Back</button> */}
                                <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setSelectedData(null)}>
                                    <div style={{
                                        border: 'solid black',
                                        borderWidth: '0 3px 3px 0',
                                        display: 'inline-block',
                                        padding: '3px',
                                        transform: 'rotate(135deg)',
                                        WebkitTransform: 'rotate(135deg)',
                                    }}></div>
                                    Back
                                </div>
                            </div>
                            <div className="card-body" style={{ padding: "0px" }} >
                                <div id="myGrid" style={{ height: "430px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        columnDefs={state.columnwithDefs}
                                        defaultColDef={state.defaultColDef}
                                        rowData={state.rowData}
                                        rowSelection={state.rowSelection}
                                        enableCharts={false}
                                        paginationPageSize={state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="card" >

                            <div className="card-body" style={{ minHeight: "518px", padding: "20px" }}>
                                <div className="violations-container">
                                    <div className="violations-header">
                                        <h2>Violation Summary</h2>
                                        <span className="sub-header">Top 10 Transporters</span>
                                    </div>
                                    <div className="violations-actions">
                                        <button
                                            className={`violation-button`}
                                            style={{

                                                backgroundColor: selectedViolation === 'overspeed' ? '#167AD6' : 'transparent',
                                                color: selectedViolation === 'overspeed' ? '#fff' : '#333',
                                                border: '1px solid #167AD6'

                                            }}
                                            onClick={() => handleButtonClick('overspeed', alertsData)}
                                        >
                                            Overspeed
                                        </button>
                                        <button
                                            className={`violation-button`}
                                            style={{
                                                backgroundColor: selectedViolation === 'nightdrive' ? '#384551DE' : '',
                                                color: selectedViolation === 'nightdrive' ? '#fff' : '#333',
                                                border: '1px solid #384551DE'

                                            }}
                                            onClick={() => handleButtonClick('nightdrive', alertsData)}
                                        >
                                            Night Drive
                                        </button>
                                    </div>
                                </div>
                                <BarChartWithCard
                                    categories={alertsDataForChart.categories || []}
                                    yAxis={alertsDataForChart.yAxis || ""}
                                    series={alertsDataForChart.series || []}
                                    onColumnClick={handleColumnClick}

                                />
                            </div>
                        </div>

                    }

                </div>
                <div className="col-xl-6 col-md-6 ">
                    <div className="card" style={{ minHeight: "460px" }}>
                        <div className="card-body" style={{ padding: "20px" }}>
                            <h5 style={{ fontSize: "16px", color: "#384551", fontWeight: "bold", marginBottom: "20px" }}>Consignments plant location </h5>

                            {plantLocationData.length > 0 ? <HeatMap
                                plantLocationData={plantLocationData} /> : ""}

                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Dashboard;

function getLast10thMinuteDate() {
    const now = new Date();

    // Get the current minutes
    const minutes = now.getMinutes();

    // Calculate the last 10th minute
    const last10thMinute = Math.floor(minutes / 10) * 10;

    // Set the minutes to the last 10th minute
    return moment.parseZone(new Date()).minutes(last10thMinute).format("YYYY-MM-DD HH:mm")
}


function getNext10thMinuteDate() {
    const now = new Date();

    // Get the current minutes
    const minutes = now.getMinutes();


    // Calculate the next 10th minute
    const next10thMinute = Math.ceil(minutes / 10) * 10;

    // If the next10thMinute is 60, set it to 0 and increment the hour
    if (next10thMinute === 60) {
        return moment.parseZone(new Date()).hours(now.getHours() + 1).minutes(next10thMinute).format("YYYY-MM-DD HH:mm")
    } else {
        return moment.parseZone(new Date()).minutes(next10thMinute).format("YYYY-MM-DD HH:mm")
    }

    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
}


function sanitizeData(params) {
    if (typeof params == "object" && params !== null) {
        for (let keys in params) {
            if (typeof params[keys] == "object" && params[keys] !== null) {
                params[keys] = sanitizeData(params[keys])
            }
            else {
                if (params[keys] == undefined || params[keys] == null || params[keys] == '' || isNaN(params[keys])) {
                    params[keys] = 0
                }
            }
        }
    }
    return params
}
function convertToMonthYear(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Extract the year and the month
    const year = date.getFullYear();
    const month = months[date.getMonth()];  // getMonth() returns month index (0-11)

    return month + " " + year
}
function isWithinLastTwoYears(monthYear) {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const yearBeforeLast = currentYear - 2;

    const date = new Date(monthYear);
    const year = date.getFullYear();

    // return [currentYear, lastYear, yearBeforeLast].includes(year);
    return [currentYear].includes(year);
}