import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import redirectURL from "../../redirectURL";
import menu_icon from '../../../assets/icons/menu-icon.png'
import logo from '../../../assets/icons/nestle.png'
import { useLocation, useNavigate } from 'react-router-dom';

const MenuSideBar = () => {

    const [menu, setMenu] = useState(false)
    const [menuList, setMenuList] = useState([])
    const [menus, setMenus] = useState([])
    const navigate = useNavigate()
    const location = useLocation();
    const menusMap = []

    const onClickExpand = () => {
        setMenu(prev => !prev)
    }
    useLayoutEffect(() => {
        let body = {
            role: localStorage.getItem("role")
        }
        redirectURL.post('/getMenus', body)
            .then(res => {
                if (res.data.status == 200) {
                    let temp = new Map()
                    if (res.data.menus?.length > 0) {
                        res.data.menus.map(each => {
                            if (each.is_active == 1) {
                                if (each.hasOwnProperty("is_parent") && each.is_parent) {
                                    if (each.is_link) {
                                        let temp2 = temp.has(each.menu_code)
                                        if (!temp2)
                                            temp.set(each.menu_code, [{
                                                menu_title: each.menu_title,
                                                menu_path: each.menu_path
                                            }])
                                        else {
                                            let t = temp.get(each.menu_code)
                                            t.push({
                                                menu_title: each.menu_title,
                                                menu_path: each.menu_path
                                            })
                                            temp.set(each.menu_code, t)
                                        }
                                    }
                                    else {
                                        let temp2 = temp.has(each.menu_code)
                                        if (!temp2)
                                            temp.set(each.menu_code, [{
                                                menu_title: each.menu_title,
                                                menu_path: each.menu_path
                                            }])
                                        else {
                                            let t = temp.get(each.menu_code)
                                            t.push({
                                                menu_title: each.menu_title,
                                                menu_path: each.menu_path
                                            })
                                            temp.set(each.menu_code, t)
                                        }
                                    }
                                }
                                else {
                                    let temp2 = temp.get(each.parent_code)
                                    if (temp2) {
                                        temp2.push({
                                            menu_title: each.menu_title,
                                            menu_path: each.menu_path
                                        })
                                        temp.set(each.parent_code, temp2)
                                    }
                                    else {
                                        temp.set(each.parent_code, [{
                                            menu_title: each.menu_title,
                                            menu_path: each.menu_path
                                        }])
                                    }
                                }
                            }
                        })
                    }
                    setMenus(res.data.menus)
                    setMenuList(temp)
                }
            })
            .catch(err => console.log(err))
    }, [])
    menuList.forEach((value, key) => {
        let temp = value[0]
        let subMenu = false
        if (Array.isArray(value) && value.length > 1)
            subMenu = true
        let temp2 = []
        if (value.length > 1)
            temp2 = value.filter(e => e.menu_path != "/")
        console.log(value, "vvvvvvvvvvvvvvv", temp2);
        menusMap.push(
            <div className={menu ? "menu-expand" : "menu-small"} title={temp.menu_title}>
                <div className={temp.menu_path == location.pathname ? 'display-menu active-menu' : 'display-menu'} onClick={(e) => { navigate(temp.menu_path); setMenu(false) }}>
                    <div className='display-menu-icon'>
                        <a href="javascript:void(0)">
                            <img id={temp.menu_title} src={menu_icon} className='img-tag' style={{ padding: "10px" }} />
                        </a>
                    </div>
                    <div>
                        <a href="javascript:void(0)" className={menu ? 'show-m sidebar-item' : 'show-n'}>
                            <span className='' style={{ marginLeft: "10px", whiteSpace: "nowrap", fontWeight: "600", fontSize: "16px" }}>{temp.menu_title}</span>
                        </a>
                    </div>
                    <div className='sidebar-submenu'>
                        <div style={{ paddingInline: "12px", paddingBlock: "8px", textWrap:"nowrap" }}>
                            <ul>
                                {
                                    temp2.map(item => {
                                        return (
                                            <div onClick={(e) => { navigate(item.menu_path); setMenu(false) }}><li><i className='icofont icofont-hand-right' />{item.menu_title}</li></div>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/* {subMenu?
                <ul 
                className={`sidebar-submenu ${temp.menu_path == location.pathname?'menu-open_':''}`} 
                style={ temp.menu_path == location.pathname?{ opacity: 1, transition: 'opacity 500ms ease-in' }: {}}
                >
                    <div className="arrowleft"></div>
                    {value.map((childrenItem, index) =>
                        <li key={index} className={childrenItem.children?childrenItem.active?'active':'':''}>
                            { (childrenItem.type === 'sub' )?
                            <a href="javascript:void(0)" >
                                <i className="fa fa-angle-right"></i>{childrenItem.title}</a>
                                :''}

                            { (childrenItem.type === 'link' )?
                                <Link
                                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                    className={childrenItem.active?'active':''}
                                    // onClick={() => this.setNavActive(childrenItem)}
                                >
                                    <i className="fa fa-angle-right"></i>{childrenItem.title} </Link>
                                :''}
                            {childrenItem.children?
                                <ul className={`sidebar-submenu ${childrenItem.active?'menu-open_':''}`}>
                                    {childrenItem.children.map((childrenSubItem, key) =>
                                        <li className={childrenSubItem.active?'active':''} key={key}>
                                            { (childrenSubItem.type === 'link' )?
                                                <Link
                                                    to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                    className={childrenSubItem.active?'active':''}
                                                >
                                                    <i className="fa fa-angle-right"></i>{childrenSubItem.title}</Link>
                                            :''}
                                        </li>
                                        )}
                                </ul>
                                :''}
                        </li>
                    )}
                </ul>:""} */}
            </div>
        )
    })

    return (
        <div className='sidebar-main'>
            <div className={menu ? "menu-expand" : "menu-small"}>
                <div className='display-menu-collapse' onClick={onClickExpand}>
                    <div className='display-menu-icon'>
                        <a href="javascript:void(0)">
                            <img src={logo} style={{ padding: "10px", height: "50px", width: "50px" }} />
                        </a>
                    </div>
                    <div>
                        <a href="javascript:void(0)" className={menu ? 'show-m sidebar-item' : 'show-n'}>
                            <span className='' style={{ marginLeft: "10px", whiteSpace: "nowrap", fontWeight: "600", fontSize: "16px" }}>MENU</span>
                        </a>
                    </div>
                </div>
            </div>
            {
                menusMap
            }
        </div>
    )
}
export default React.memo(MenuSideBar)