import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



const CurvedLineChart = ({series , categories , title , yAxis}) => {
    
    const options = {
        chart: {
            height: 350 // Adjust the height of the chart
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '16px',
                fontWeight : "bold"
            },
        },
        subtitle: {
            // text: '15 JAN - 15 JUNE',
            align: 'left',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: yAxis,
            },
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true, // Disable markers
                },
                lineWidth: 2, // Adjust line width
                dashStyle: 'Solid', // Solid line style
            },
            spline: {
                lineWidth: 2, // Adjust line width for spline
                marker: {
                    enabled: false, // Disable markers
                },
            },
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b>',
        },

        credits: {
            enabled: false,
        },
        legend: {
            enabled: false, // Adjust as needed
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical'
        },
        series: series
    }

    return (
      
          <HighchartsReact highcharts={Highcharts} options={options} />
         
      );
    
    }

export default CurvedLineChart;