import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDecoder, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import redirectURL from '../redirectURL';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
// var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class GenerateEwayBill extends Component{
        constructor(props){
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            benefitCert:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            erpPO:"",
            erpPoNo:[],
            erpPoDate:[],
            ringiNo:"",
            ringiDesc:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            transporter_document_date:"",
            LC : "",
            LCno : "",
            nature : "",
            freeTrade : "",
            benefitCert : "",
            disableERP:"disabled",
            disableLC:"disabled",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            erpPo_date :"",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            pagetitle:"New E - WayBill Request",
            supply_type : "",
            sub_supply_type : "",
            transaction_type : "",
            document_type : "",
            document_number : "",
            document_date : "",
            legal_name_of_consignor : "",
            gstin_of_consignor : "",
            address1_of_consignor : "",
            address2_of_consignor : "",
            pincode_of_consignor : "",
            place_of_consignor : "",
            state_of_consignor : "",
            legal_name_of_consignee : "",
            gstin_of_consignee : "",
            address1_of_consignee : "",
            address2_of_consignee : "",
            pincode_of_consignee : "",
            place_of_consignee : "",
            state_of_supply : "",
            itemList : "",
            taxable_amount : "",
            cgst_amount : "",
            sgst_amount : "",
            igst_amount : "",
            cess_amount : "",
            cess_nonadvol_value : "",
            other_value : "",
            total_invoice_value : "",
            transporter_name : "",
            transporter_id : "",
            transportation_distance : "",
            transportation_mode : "",
            vehicle_type : "",
            vehicle_number : "",
            transporter_document_number : "",
            transporter_document_date : "",
            showOthers : "show-n",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    // logPageView = () => {
    //     try {
    //         if (googleAnalytics.page.enableGA) {
    //             googleAnalytics.logPageView();
    //             let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    //             let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
    //             let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
    //             this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
    //         } else {
    //             console.log("GA disabled...");
    //         }
    //     } catch(error) {
    //         console.error("Error occured while logging to GA, error = ", error);
    //     }
    // }
    componentDidMount(){
    	// this.logPageView(); // Log page view to GA
        const user =getDecoder()
        var params = {
            transporter_code : user?.transportercode
        }
        redirectURL.post("/ewaybill/getTransporterGSTDetails",params).then((response)=>{
            var resp = response.data;
            if(resp.length > 0)
            {
                this.setState({
                    transporter_gstin : resp[0].gstin,
                    user:user
                });
            }
        }).catch((e)=>{
            console.log(e);
        })
        // loadDateTimeScript();        
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
    //window.location.href="/tptlogin/ewaybillrequests";
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
    if(name == "sub_supply_type")
    {
        if(value == "Others")
        {
            this.setState({showOthers : "show-m"});
        }
        else
        {
            this.setState({showOthers : "show-n"});
        }
    }
    if(name == "transportation_mode")
    {
        if(value == "road")
        {
            $("#vehicle_type").removeAttr("disabled",true);
            $("#vehicle_number").removeAttr("disabled",true);
            $("#trn_doc_no").html("Transporter Doc. No");
        }
        else if(value == "ship or ship cum road/rail")
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").removeAttr("disabled",true);
            $("#trn_doc_no").html("Bill of lading No");
        }
        else if(value == "air")
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").attr("disabled",true);
            $("#trn_doc_no").html("Airway Bill No");
        }
        else
        {
            $("#vehicle_type").attr("disabled",true);
            $("#vehicle_number").attr("disabled",true);
            $("#trn_doc_no").html("RR No");
        }
    }
}

getConsignorGst = (event) => {
    var value = $("#gstin_of_consignor").val();
    if(value != "")
    {
        var params = {
           "gstin" : value
        }
        redirectURL.post("/ewaybill/getDetailsByGSTIN",params).then((response)=>{   
            var resp = response.data.gstDetails;
            if(resp !="" && resp !=undefined)
            {
                $("#legal_name_of_consignor").val(resp.name);
                $("#address1_of_consignor").val(resp.address1);
                $("#address2_of_consignor").val(resp.address2);
                $("#pincode_of_consignor").val(resp.pincode);
                $("#place_of_consignor").val(resp.place);
                $("#state_of_consignor option").filter(function() {
                    return $(this).text() ==(resp.state).toUpperCase();
                }).prop("selected", true);
                var stateId = $("#state_of_consignor").val();
                this.setState({
                    legal_name_of_consignor : resp.name,
                    address1_of_consignor : resp.address1,
                    address2_of_consignor : resp.address2,
                    pincode_of_consignor : resp.pincode,
                    place_of_consignor : resp.place,
                    state_of_consignor : stateId
                });
                var from_pincode = $("#pincode_of_consignor").val();
                var to_pincode = $("#pincode_of_consignee").val();
                if(from_pincode !=undefined && from_pincode !="" && to_pincode !=undefined && to_pincode !="")
                {
                    this.getApproxDistance(response.data.access_token);
                }
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}

getConsigneeGst = (event) => {
    var value = $("#gstin_of_consignee").val();
    if(value != "")
    {
        var params = {
           "gstin" : value
        }
        redirectURL.post("/ewaybill/getDetailsByGSTIN",params).then((response)=>{   
            var resp = response.data.gstDetails;
            if(resp !="" && resp !=undefined)
            {
                $("#legal_name_of_consignee").val(resp.name);
                $("#address1_of_consignee").val(resp.address1);
                $("#address2_of_consignee").val(resp.address2);
                $("#pincode_of_consignee").val(resp.pincode);
                $("#place_of_consignee").val(resp.place);
                $("#state_of_supply option").filter(function() {
                    return $(this).text() ==(resp.state).toUpperCase();
                }).prop("selected", true);
                var stateId = $("#state_of_supply").val();
                this.setState({
                    legal_name_of_consignee : resp.name,
                    address1_of_consignee : resp.address1,
                    address2_of_consignee : resp.address2,
                    pincode_of_consignee : resp.pincode,
                    place_of_consignee : resp.place,
                    state_of_supply : stateId
                });
                var from_pincode = $("#pincode_of_consignor").val();
                var to_pincode = $("#pincode_of_consignee").val();
                if(from_pincode !=undefined && from_pincode !="" && to_pincode !=undefined && to_pincode !="")
                {
                    this.getApproxDistance(response.data.access_token);
                }
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}

calculateTotal = () => {
    var itemsLength = $("#itemsLength").val();
    var totalAmnt = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var totalIgst = 0;
    if(itemsLength > 0)
    {
        for(var i=0;i<itemsLength;i++)
        {
            var ttAmnt = $("#taxable_amount_"+i).val();
            var cgst_rate=$("#cgst_rate_"+i).val();
            var sgst_rate=$("#sgst_rate_"+i).val();
            var igst_rate=$("#igst_rate_"+i).val();
            if(ttAmnt !="")
            {
                totalAmnt = parseFloat(totalAmnt)+parseFloat(ttAmnt)
            }
            if(cgst_rate !="")
            {
                totalCgst = parseFloat(totalCgst)+parseFloat(cgst_rate)
            }
            if(sgst_rate !="")
            {
                totalSgst = parseFloat(totalSgst)+parseFloat(sgst_rate)
            }
            if(igst_rate !="")
            {
                totalIgst = parseFloat(totalIgst)+parseFloat(igst_rate)
            }
        }
        $("#taxable_amount").val(totalAmnt);        
        $("#cgst_amount").val(totalCgst);        
        $("#sgst_amount").val(totalSgst);        
        $("#igst_amount").val(totalIgst);
        var grossAmount = totalAmnt+totalCgst+totalSgst+totalIgst;
        $("#total_invoice_value").val(grossAmount);
    }
}

getApproxDistance = (access_token) => {
    var from_pincode = $("#pincode_of_consignor").val();
    var to_pincode = $("#pincode_of_consignee").val();
    if(from_pincode !=undefined && from_pincode !="" && to_pincode !=undefined && to_pincode !="")
    {
        var params = {
            "fromPincode" : from_pincode,
            "toPincode" : to_pincode,
            "access_token" : access_token
        }
        redirectURL.post("/ewaybill/getApproxDistanceFromAPI",params).then((response)=>{   
            var resp = response.data.distanceDetails;
            console.log(resp,"resp")
            if(resp !="" && resp !=undefined)
            {
                $("#transportation_distance").val(resp.distance);
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}

dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var transportercode = this.state.user?.transportercode;
    var params = {
        transporter_code : transportercode[0],
        gstin_of_generator : this.state.transporter_gstin,
    };
    params.supply_type = this.state.supply_type;
    params.sub_supply_type = this.state.sub_supply_type;
    params.transaction_type = this.state.transaction_type;
    params.document_type = this.state.document_type;
    params.document_number = this.state.document_number;
    params.document_date = moment.parseZone(new Date(this.state.document_date)).utcOffset("00:00").format("DD/MM/YYYY")
    params.legal_name_of_consignor = this.state.legal_name_of_consignor;
    params.gstin_of_consignor = this.state.gstin_of_consignor;
    params.address1_of_consignor = this.state.address1_of_consignor;
    params.address2_of_consignor = this.state.address2_of_consignor;
    params.pincode_of_consignor = this.state.pincode_of_consignor;
    params.place_of_consignor = this.state.place_of_consignor;
    params.state_of_consignor = this.state.state_of_consignor;
    params.actual_from_state_name = $("#state_of_consignor option:selected").text();
    params.legal_name_of_consignee = this.state.legal_name_of_consignee;
    params.gstin_of_consignee = this.state.gstin_of_consignee;
    params.address1_of_consignee = this.state.address1_of_consignee;
    params.address2_of_consignee = this.state.address2_of_consignee;
    params.pincode_of_consignee = this.state.pincode_of_consignee;
    params.place_of_consignee = this.state.place_of_consignee;
    params.state_of_supply = this.state.state_of_supply.toUpperCase();
    params.actual_to_state_name = $("#state_of_supply option:selected").text();
    var itemsLength = $("#itemsLength").val();
    var itemList = [];
    for(var i=0;i<itemsLength;i++)
    {
        var product_name=$("#product_name_"+i).val();
        var product_description=$("#product_description_"+i).val();
        var hsn_code=$("#hsn_code_"+i).val();
        var quantity=$("#quantity_"+i).val();
        var unit_of_product=$("#unit_of_product_"+i).val();
        var taxable_amount=$("#taxable_amount_"+i).val();
        var cgst_rate=$("#cgst_rate_"+i).val();
        var sgst_rate=$("#sgst_rate_"+i).val();
        var igst_rate=$("#igst_rate_"+i).val();
        var cess_rate=$("#cess_rate_"+i).val();
        var cessNonAdvol=$("#cessNonAdvol_"+i).val();
        if(hsn_code !='' && taxable_amount !='' && cgst_rate !='' && sgst_rate !='' && igst_rate !='' && cess_rate !='' && cessNonAdvol !='')
        {
            var erptemp = {"product_name":product_name,"product_description":product_description,"hsn_code":hsn_code,"quantity":quantity,"unit_of_product":unit_of_product,"taxable_amount":taxable_amount,"cgst_rate":parseFloat(cgst_rate),"sgst_rate":parseFloat(sgst_rate),"igst_rate":parseFloat(igst_rate),"cess_rate":parseFloat(cess_rate),"cessNonAdvol":parseFloat(cessNonAdvol)}
            itemList.push(erptemp)
        }
    }
    params.itemList = JSON.stringify(itemList);
    params.taxable_amount = $("#taxable_amount").val();
    params.cgst_amount = $("#cgst_amount").val();
    params.sgst_amount = $("#sgst_amount").val();
    params.igst_amount = $("#igst_amount").val();
    params.cess_amount = $("#cess_amount").val();
    params.cess_nonadvol_value = $("#cess_nonadvol_value").val();
    params.other_value = $("#other_value").val();
    params.total_invoice_value = $("#total_invoice_value").val();
    params.transporter_name = this.state.transporter_name;
    params.transporter_id = this.state.transporter_id;
    params.transportation_distance = $("#transportation_distance").val();
    params.transportation_mode = this.state.transportation_mode;
    params.vehicle_type = this.state.vehicle_type;
    params.vehicle_number = this.state.vehicle_number;
    params.transporter_document_number = this.state.transporter_document_number;
    params.transporter_document_date = moment.parseZone(new Date(this.state.transporter_document_date)).utcOffset("00:00").format("DD/MM/YYYY")
    params.generate_status = 1;
    params.data_source = "erp";
    params.user_ref = "1232435466sdsf234";
    params.location_code = "XYZ";
    if(this.state.transportation_mode !=undefined && this.state.transportation_mode !="")
    {
        params.eway_bill_status = "ABC";
    }
    else
    {
        params.eway_bill_status = "A";
    }
    params.auto_print = "Y";
    params.email = "";
    redirectURL.post("/ewaybill/generateEwayBill",params).then((response)=>{
        if(response.data.status==200){
            this.setState({
                basicTitle:response.data.message,
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
            });
            window.location.href="/tptlogin/ewaybillrequests";
        }
        else{
            this.setState({
                basicTitle:response.data.message,
                basicType : "danger",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
            });
        }
    }).catch((e)=>{
        console.log(e);
    })      
}

handleAddRow = () => {
    const item = {
        erpPoNo: "",
        erpPoDate: "",
    };
    this.setState({
        rows: [...this.state.rows, item]
    });
};
handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}
render(){
    
    const headStyle = {
        marginBottom: "1.5%"
    }
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className={"col-xl-12 col-lg-12 "+this.state.requestData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    {/* <span className="layoutbtns pull-right">
										<a className="btn btn-danger" href="/tptlogin/ewaybillrequests">
											Back
										</a>
                                    </span> */}
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-12">
                                        <h5 style={headStyle}>Transaction Details</h5>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Supply Type</label>
                                            <select name="supply_type" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Supply Type</option>
                                                <option value="Outward">Outward</option>
                                                <option value="Inward">Inward</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Sub Type </label>
                                            <select name="sub_supply_type" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Sub Type</option>
                                                <option value="Supply">Supply</option>
                                                <option value="Import">Import</option>
                                                <option value="Export">Export</option>
                                                <option value="Job Work">Job Work</option>
                                                <option value="Job work Returns">Job work Returns</option>
                                                <option value="Sales Return">Sales Return</option>
                                                <option value="SKD/CKD">SKD/CKD/Lots</option>
                                                <option value="Recipient Not Known">Recipient Not Known</option>
                                                <option value="For Own Use">For Own Use</option>
                                                <option value="Exhibition or Fairs">Exhibition or Fairs</option>
                                                <option value="Line Sales">Line Sales</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.showOthers}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Specify Sub Type</label>
                                            <input type="text" name="others_sub_supply_type" id="others_sub_supply_type" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Transaction Type </label>
                                            <select name="transaction_type" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Transaction Type</option>
                                                <option value="1">Regular</option>
                                                <option value="2">Bill To - Ship To</option>
                                                <option value="3">Bill From - Dispatch From</option>
                                                <option value="4">Combination of 2 and 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Document Type </label>
                                            <select name="document_type" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Document Type</option>
                                                <option value="Tax Invoice">Tax Invoice</option>
                                                <option value="Bill of Supply">Bill Of Supply</option>
                                                <option value="Bill of Entry">Bill Of Entry</option>
                                                <option value="Delivery Challan">Delivery Challan</option>
                                                <option value="Credit Note">Credit Note</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Document No 
                                            {/* <i className="fa fa-question-circle" title="Enter Doc No which is alphanumeric and special characters such as / and - only" aria-hidden="true"></i> */}
                                            </label>
                                            <input type="text" name="document_number" id="document_number" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Document Date</label>
                                            {/* <input type="text" name="document_date" id="document_date" className="datetimepicker_date form-control" required/> */}
                                            <DatePicker
                                            selected={this.state.document_date}
                                            onChange={e=>{this.setState({document_date:e})}}
                                            name="document_date" 
                                            className="datepicker-custom"
                                            dateFormat="dd/MM/yyyy"/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="col-xl-12">
                                        <h5 style={headStyle}>Bill From</h5>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Name</label>
                                            <input type="text" name="legal_name_of_consignor" id="legal_name_of_consignor" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;GSTIN </label>
                                            <input type="text" name="gstin_of_consignor" id="gstin_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} onBlur={this.getConsignorGst} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Address Line 1</label>
                                            <input type="text" name="address1_of_consignor" id="address1_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Address Line 2</label>
                                            <input type="text" name="address2_of_consignor" id="address2_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;PINCODE</label>
                                            <input type="text" name="pincode_of_consignor" id="pincode_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Place</label>
                                            <input type="text" name="place_of_consignor" id="place_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;State </label>
                                            <select name="state_of_consignor" id="state_of_consignor" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required>
                                                <option value="">Select State</option>
                                                <option value="35">ANDAMAN AND NICOBAR</option>
                                                <option value="28">ANDHRA PRADESH</option>
                                                <option value="12">ARUNACHAL PRADESH</option>
                                                <option value="18">ASSAM</option>
                                                <option value="10">BIHAR</option>
                                                <option value="4">CHANDIGARH</option>
                                                <option value="22">CHHATTISGARH</option>
                                                <option value="26">DADAR AND NAGAR HAVELI</option>
                                                <option value="25">DAMAN AND DIU</option>
                                                <option value="7">DELHI</option>
                                                <option value="30">GOA</option>
                                                <option value="24">GUJARAT</option>
                                                <option value="6">HARYANA</option>
                                                <option value="2">HIMACHAL PRADESH</option>
                                                <option value="1">JAMMU AND KASHMIR</option>
                                                <option value="20">JHARKHAND</option>
                                                <option value="29">KARNATAKA</option>
                                                <option value="32">KERALA</option>
                                                <option value="31">LAKSHADWEEP</option>
                                                <option value="23">MADHYA PRADESH</option>
                                                <option value="27">MAHARASHTRA</option>
                                                <option value="14">MANIPUR</option>
                                                <option value="17">MEGHALAYA</option>
                                                <option value="15">MIZORAM</option>
                                                <option value="13">NAGALAND</option>
                                                <option value="21">ORISSA</option>
                                                <option value="34">PONDICHERRY</option>
                                                <option value="3">PUNJAB</option>
                                                <option value="8">RAJASTHAN</option>
                                                <option value="11">SIKKIM</option>
                                                <option value="33">TAMIL NADU</option>
                                                <option value="36">TELANGANA</option>
                                                <option value="16">TRIPURA</option>
                                                <option value="9">UTTAR PRADESH</option>
                                                <option value="5">UTTARAKHAND</option>
                                                <option value="19">WEST BENGAL</option>
                                                <option value="97">OTHER TERRITORY</option>
                                                <option value="99">OTHER COUNTRY</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="col-xl-12">
                                        <h5 style={headStyle}>Bill To</h5>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Name</label>
                                            <input type="text" name="legal_name_of_consignee" id="legal_name_of_consignee" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;GSTIN </label>
                                            <input type="text" name="gstin_of_consignee" id="gstin_of_consignee" placeholder="" className="form-control requestFields"  onBlur={this.getConsigneeGst} onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Address Line 1</label>
                                            <input type="text" name="address1_of_consignee" id="address1_of_consignee" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Address Line 2</label>
                                            <input type="text" name="address2_of_consignee" id="address2_of_consignee" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className={"col-xl-3 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;PINCODE </label>
                                            <input type="text" name="pincode_of_consignee" id="pincode_of_consignee" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Place</label>
                                            <input type="text" name="place_of_consignee" id="place_of_consignee" placeholder="" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;State</label>
                                            <select name="state_of_supply" id="state_of_supply" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required>
                                                <option value="">Select State</option>
                                                <option value="35">ANDAMAN AND NICOBAR</option>
                                                <option value="28">ANDHRA PRADESH</option>
                                                <option value="12">ARUNACHAL PRADESH</option>
                                                <option value="18">ASSAM</option>
                                                <option value="10">BIHAR</option>
                                                <option value="4">CHANDIGARH</option>
                                                <option value="22">CHHATTISGARH</option>
                                                <option value="26">DADAR AND NAGAR HAVELI</option>
                                                <option value="25">DAMAN AND DIU</option>
                                                <option value="7">DELHI</option>
                                                <option value="30">GOA</option>
                                                <option value="24">GUJARAT</option>
                                                <option value="6">HARYANA</option>
                                                <option value="2">HIMACHAL PRADESH</option>
                                                <option value="1">JAMMU AND KASHMIR</option>
                                                <option value="20">JHARKHAND</option>
                                                <option value="29">KARNATAKA</option>
                                                <option value="32">KERALA</option>
                                                <option value="31">LAKSHADWEEP</option>
                                                <option value="23">MADHYA PRADESH</option>
                                                <option value="27">MAHARASHTRA</option>
                                                <option value="14">MANIPUR</option>
                                                <option value="17">MEGHALAYA</option>
                                                <option value="15">MIZORAM</option>
                                                <option value="13">NAGALAND</option>
                                                <option value="21">ORISSA</option>
                                                <option value="34">PONDICHERRY</option>
                                                <option value="3">PUNJAB</option>
                                                <option value="8">RAJASTHAN</option>
                                                <option value="11">SIKKIM</option>
                                                <option value="33">TAMIL NADU</option>
                                                <option value="36">TELANGANA</option>
                                                <option value="16">TRIPURA</option>
                                                <option value="9">UTTAR PRADESH</option>
                                                <option value="5">UTTARAKHAND</option>
                                                <option value="19">WEST BENGAL</option>
                                                <option value="97">OTHER TERRITORY</option>
                                                <option value="99">OTHER COUNTRY</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="col-xl-12">
                                        <h5>Item Details</h5>
                                    </div>
                                    <div className="">
                                        {this.state.rows.map((item, idx) => (
                                        <div className="row col-xl-12" style={{"borderBottom": "1px dotted #ccc","padding": "2%"}} key={idx}>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12">Product Name</label>
                                                <input type="text" name={"product_name_"+idx} id={"product_name_"+idx}className="form-control"  />
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12">Description</label>
                                                <input type="text" name={"product_description_"+idx} id={"product_description_"+idx}className="form-control" />
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;HSN </label>
                                                <input type="text" name={"hsn_code_"+idx} id={"hsn_code_"+idx}className="form-control" required/>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12">Quantity</label>
                                                <input type="text" name={"quantity_"+idx} id={"quantity_"+idx}className="form-control"/>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12">Unit</label>
                                                <select name={"unit_of_product_"+idx} id={"unit_of_product_"+idx} className="form-control requestFields"  onChange={this.changeHandler} >
                                                    <option value="">Select Unit</option>
                                                    <option value="BAG">BAGS</option>
                                                    <option value="BAL">BALE</option>
                                                    <option value="BDL">BUNDLES</option>
                                                    <option value="BKL">BUCKLES</option>
                                                    <option value="BOU">BILLION OF UNITS</option>
                                                    <option value="BOX">BOX</option>
                                                    <option value="BTL">BOTTLES</option>
                                                    <option value="BUN">BUNCHES</option>
                                                    <option value="CAN">CANS</option>
                                                    <option value="CBM">CUBIC METERS</option>
                                                    <option value="CCM">CUBIC CENTIMETERS</option>
                                                    <option value="CMS">CENTI METERS</option>
                                                    <option value="CTN">CARTONS</option>
                                                    <option value="DOZ">DOZENS</option>
                                                    <option value="DRM">DRUMS</option>
                                                    <option value="GGK">GREAT GROSS</option>
                                                    <option value="GMS">GRAMMES</option>
                                                    <option value="GRS">GROSS</option>
                                                    <option value="GYD">GROSS YARDS</option>
                                                    <option value="KGS">KILOGRAMS</option>
                                                    <option value="KLR">KILOLITRE</option>
                                                    <option value="KME">KILOMETRE</option>
                                                    <option value="MLT">MILILITRE</option>
                                                    <option value="MTS">METRIC TON</option>
                                                    <option value="NOS">NUMBERS</option>
                                                    <option value="PAC">PACKS</option>
                                                    <option value="PCS">PIECES</option>
                                                    <option value="PRS">PAIRS</option>
                                                    <option value="QTL">QUINTAL</option>
                                                    <option value="ROL">ROLLS</option>
                                                    <option value="SET">SETS</option>
                                                    <option value="SQF">SQUARE FEET</option>
                                                    <option value="SQM">SQUARE METERS</option>
                                                    <option value="SQY">SQUARE YARDS</option>
                                                    <option value="TBS">TABLETS</option>
                                                    <option value="TGM">TEN GROSS</option>
                                                    <option value="THD">THOUSANDS</option>
                                                    <option value="TON">TONNES</option>
                                                    <option value="TUB">TUBES</option>
                                                    <option value="UGS">US GALLONS</option>
                                                    <option value="UNT">UNITS</option>
                                                    <option value="YDS">YARDS</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Taxable (Rs.) </label>
                                                <input type="text" name={"taxable_amount_"+idx} id={"taxable_amount_"+idx} onBlur={this.calculateTotal} className="form-control" required/>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CGST </label>
                                                <select name={"cgst_rate_"+idx} id={"cgst_rate_"+idx} className="form-control"  onChange={this.calculateTotal} required>
                                                    <option value="">Select</option>
                                                    <option value="0.5">0.500</option>
                                                    <option value="0.75">0.750</option>
                                                    <option value="3.75">3.750</option>
                                                    <option value="0">0</option>
                                                    <option value="2.5">2.500</option>
                                                    <option value="6">6</option>
                                                    <option value="9">9</option>
                                                    <option value="14">14</option>
                                                    <option value="0">0</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;SGST </label>
                                                <select name={"sgst_rate_"+idx} id={"sgst_rate_"+idx} className="form-control"  onChange={this.calculateTotal} required>
                                                    <option value="">Select</option>
                                                    <option value="0.5">0.500</option>
                                                    <option value="0.75">0.750</option>
                                                    <option value="3.75">3.750</option>
                                                    <option value="0">0</option>
                                                    <option value="2.5">2.500</option>
                                                    <option value="6">6</option>
                                                    <option value="9">9</option>
                                                    <option value="14">14</option>
                                                    <option value="0">0</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;IGST </label>
                                                <select name={"igst_rate_"+idx} id={"igst_rate_"+idx} className="form-control"  onChange={this.calculateTotal} required>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="1.5">1.500</option>
                                                    <option value="7.5">7.500</option>
                                                    <option value="0">0</option>
                                                    <option value="5">5</option>
                                                    <option value="12">12</option>
                                                    <option value="18">18</option>
                                                    <option value="28">28</option>
                                                    <option value="3">3</option>
                                                    <option value="0.1">0.100</option>
                                                    <option value="0.25">0.250</option>
                                                    <option value="0.5">0.500</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CESS Advol Rate(%) </label>
                                                <select name={"cess_rate_"+idx} id={"cess_rate_"+idx} className="form-control"  onChange={this.changeHandler} required>
                                                    <option value="">Select</option>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="3">3</option>
                                                    <option value="5">5</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="12.5">12.500</option>
                                                    <option value="15">15</option>
                                                    <option value="17">17</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="36">36</option>
                                                    <option value="49">49</option>
                                                    <option value="60">60</option>
                                                    <option value="61">61</option>
                                                    <option value="65">65</option>
                                                    <option value="71">71</option>
                                                    <option value="72">72</option>
                                                    <option value="89">89</option>
                                                    <option value="96">96</option>
                                                    <option value="142">142</option>
                                                    <option value="160">160</option>
                                                    <option value="204">204</option>
                                                    <option value="290">290</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CESS non.Advol. Rate  
                                                {/* <i className="fa fa-question-circle" title="(Please calculate and enter total CESS Non Advalorem Amount)" aria-hidden="true"></i> */}
                                                </label>
                                                <select name={"cessNonAdvol_"+idx} id={"cessNonAdvol_"+idx} className="form-control"  onChange={this.changeHandler} required>
                                                    <option value="0">0</option>
                                                    <option value="400">400</option>
                                                    <option value="2076">2076</option>
                                                    <option value="2747">2747</option>
                                                    <option value="3668">3668</option>
                                                    <option value="4006">4006</option>
                                                    <option value="4170">4170</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                                <h6 style={{marginTop:"2.5em",cursor:"pointer"}} onClick={() => this.handleRemoveRow(idx)}>Clear</h6>
                                            </div>
                                        </div>
                                        ))}
                                        <input type="hidden" id="itemsLength" value={this.state.rows.length}/>
                                        <div class="row col-sm-12" style={headStyle}>
                                            <div className="row col-xl-12 input_fields_wrap m-top-1" id="addMrERp">
                                                <div class="col-xl-12">
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRow}>Add More Items +</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Total Tax'ble Amount </label>
                                            <input type="text" name="taxable_amount" id="taxable_amount" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CGST Amount </label>
                                            <input type="text" name="cgst_amount" id="cgst_amount" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;SGST Amount </label>
                                            <input type="text" name="sgst_amount" id="sgst_amount" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;IGST Amount </label>
                                            <input type="text" name="igst_amount" id="igst_amount" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CESS Advol Amount </label>
                                            <input type="text" name="cess_amount" id="cess_amount" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;CESS Non Advol </label>
                                            <input type="text" name="cess_nonadvol_value" id="cess_nonadvol_value" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>                                        
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12">Other Amount(+/-) 
                                                {/* <i className="fa fa-question-circle" title="(Enter anyother charges or amount or discount mentioned in the invoice. Incase of discount give with the -ve amount.)" aria-hidden="true"></i> */}
                                                </label>
                                            <input type="text" name="other_value" id="other_value" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Total Inv. Amount  
                                            {/* <i className="fa fa-question-circle" title="(Total Amt (Total Taxable Amt+CGST+SGST+IGST+CESS Non Advol+Other Amt)" aria-hidden="true"></i> */}
                                            </label>
                                            <input type="text" name="total_invoice_value" id="total_invoice_value" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    
                                    <div class="hr-dashed"></div>
                                    <div className="col-xl-12">
                                        <h5 style={headStyle}>Transportation Details</h5>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-4 "}>
                                            <label className="col-xl-12 col-lg-12">Transportation Name</label>
                                            <input type="text" name="transporter_name" className="form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Transporter ID 
                                            {/* <i className="fa fa-question-circle" title="(Enter Transporter GSTIN or Transporter Enrollement ID (TRANSIN))" aria-hidden="true"></i> */}
                                            </label>
                                            <input type="text" name="transporter_id" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div> 
                                        <div className={"col-xl-4 "}>
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom:"0px" }}>*</label>&nbsp;Approximate Distance (in KM) 
                                            {/* <i className="fa fa-question-circle" title="(Enter Transporter GSTIN or Transporter Enrollement ID (TRANSIN))" aria-hidden="true"></i> */}
                                            </label>
                                            <input type="text" name="transportation_distance" id="transportation_distance" placeholder="" className="form-control requestFields num-decimal-cls"  onChange={this.changeHandler} required/>
                                        </div>                                      
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="col-xl-12">
                                        <h5 style={headStyle}>PART-B</h5>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-2">
                                            <label className="col-xl-12 col-lg-12">Mode</label>
                                            <select class="form-control col-xl-12 col-lg-12" name="transportation_mode"  onChange={this.changeHandler}  >
                                                <option value="">-- Select Mode --</option>
                                                <option value="road">Road</option>
                                                <option value="rail">Rail</option>
                                                <option value="air">Air</option>
                                                <option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Vehicle Type</label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="vehicle_type" id="vehicle_type"  onChange={this.changeHandler}>
                                                <option value="">-- Select Vehicle Type --</option>
                                                <option value="regular">Regular</option>
                                                <option value="over dimensional cargo">Over Dimensional Cargo</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-2"}>
                                            <label className="col-xl-12 col-lg-12">Vehicle No</label>
                                            <input type="text" name="vehicle_number" id="vehicle_number" className="form-control requestFields" onChange={this.changeHandler}/>
                                        </div>
                                        <div className={"col-xl-2"}>
                                            <label className="col-xl-12 col-lg-12" id="trn_doc_no">Transporter Doc. No</label>
                                            <input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Date</label>
                                            {/* <input type="text" name="transporter_document_date" id="transporter_document_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} /> */}
                                            <DatePicker
                                            selected={this.state.transporter_document_date}
                                            onChange={e=>{this.setState({transporter_document_date:e})}}
                                            name="transporter_document_date" 
                                            className="datepicker-custom"
                                            dateFormat="dd/MM/yyyy"/>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


// function loadDateTimeScript(){
// 	// alert("timeDate");
// 	$('.datetimepicker_date').datetimepicker({
// 		//mask:'39-19-9999',
//         yearStart: 2017,
//         format:'d/m/Y',
//         maxDate:true,
// 		timepicker:false,
//         prevButton:false,
//         nextButton:false,
//     });
    
//     $('.cargo_date').datetimepicker({
// 		//mask:'39-19-9999',
//         format:'d/m/Y',
//         maxDate:true,
// 		timepicker:false,
// 	});

//     var index  =window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
// 	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
// 	script.async=true
//     script.defer = true
//     script.crossOrigin = "anonymous"
// 	index.parentNode.insertBefore(script,index)
// }

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-decimal-cls", function(evt) {
    var self = $(this);
    self.val(self.val().replace(/[^0-9\.]/g, ''));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) 
    {
        evt.preventDefault();
    }
});
$(document).on("input", ".gst", function(evt) {
    if($(this).val() !="" && $(this).val().length == 15)
    {
        var statecode = $(this).val().substring(0, 2);
        var pancarno = $(this).val().substring(2, 12);
        var entityNumber = $(this).val().substring(12, 13);
        var defaultZvalue = $(this).val().substring(13, 14);
        var checksumdigit = $(this).val().substring(14, 15);
        if ($(this).val().length != 15) {
            alert('GST Number is invalid');
            $(this).focus();
            return false;
        }
        if (pancarno.length != 10) {
            alert('GST number is invalid ');
            $(this).focus();
            return false;
        }
        if (defaultZvalue !== 'Z') {
            alert('GST Number is invalid Z not in Entered Gst Number');
            $(this).focus();
        }

        if ($.isNumeric(statecode)) {
            $('#gst_state_code').val(statecode).trigger('change');
        } else {
            alert('Please Enter Valid State Code');
            $(this).focus();
        }

        if ($.isNumeric(checksumdigit)) {
            return true;
        } else {
            alert('GST number is invalid last character must be digit');
            $(this).focus();
        }
    }
});

// $(document).onBlur("input", "#gstin_of_consignor", function(evt) {
   
// });