import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getDecoder, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import CSVFileValidator from 'csv-file-validator'
import CountUp from 'react-countup';
import redirectURL from '../redirectURL';
import $ from 'jquery';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import EbillWayInfo from './ewaybillinfo';
import ExtendValidity from './extendValidity';
import StopButton from './stopButton';
import ViewButton from './ViewButton';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class EwayBillsOtherPartyData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            oldTruckNo: "",
            truckNumList: "",
            loadshow: 'show-n',
            overly: 'show-n',
            truckLocation: "",
            replaced_date_time: "",
            route_details: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            route_info: "",
            animateRows: true,
            consignment_details: "",
            dept_code: "",
            replacement_truck: "",
            pagetitle: "Eway Bill Other Party Data",
            showonlytransport: 'show-m',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            loginplant: "",
            orginal_data: [],
            bulkslide: "",
            orginalRowData: [],
            hero_tab: 'btn-success',
            non_hero_tab: 'btn-light',
            hide_stop: false,
            row_id: '',
            // Updated as per hero DCT on 29-07-2024
            hero_trucks: ["NL01Q8729", "NL01AA4802", "NL01Q2299", "NL01Q8736", "NL01Q8724", "NL01Q9205", "NL01Q2363", "NL01AA0013", "NL01Q4588", "NL01Q9219", "NL01Q8734", "NL01Q9225", "NL01AA0032", "NL01AA4820", "PB11BY9675", "NL01Q9929", "NL01Q8723", "NL01AA4800", "NL01N7666", "NL01Q8701", "NL01AA5743", "NL01AA0042", "NL01Q2304", "NL01Q8722", "NL01Q8727", "NL01Q9206", "NL01AA4811", "NL01Q9217", "NL01AA0030", "NL01L8670", "NL01AG4542", "NL01Q8705", "NL01AA0038", "NL01AA5742", "NL01AA5744", "NL01AC3969", "NL01N7844", "NL01Q8725", "NL01Q9203", "NL01Q9226", "NL01Q9928", "NL01Q4592", "NL01L8672", "NL01Q9224", "NL01Q9220", "NL01Q8707", "NL01Q8730", "NL01Q8712", "NL01AA5741", "NL01AA0018", "NL01Q8718", "NL01Q2320", "NL01AA0036", "NL01AA0011", "NL01Q8910", "NL01Q9218", "NL01Q2361", "NL01Q9204", "NL01N7845", "NL01Q9930", "NL01Q9223", "NL01Q8702", "NL01Q8731", "NL01Q8737", "NL01AA6724", "NL01Q8716", "NL01Q8714", "NL01AA5188", "NL01AB4334", "NL01AB4335"],
            org_name: "",
            legal_name_of_consignor: [],
            enabled_autoextension: 0,
            uploadFile: ''
        };
    }

    async componentDidMount() {
        const user =getDecoder()
        var from_date = moment.parseZone(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
        var to_date = moment.parseZone(new Date()).format("YYYY-MM-DD")
        this.setState({
            eway_from_date: from_date,
            eway_to_date: to_date,
            loadshow: 'show-m',
            overly: 'show-m',
			user:user
        })
        var params = {
            tpt_code: user?.transportercode,
            from_date: from_date,
            to_date: to_date,
        }

        await redirectURL.post("/ewaybill/getTransporterDetails", params).then(async response => {
            var tptDetails = response.data
            if (tptDetails.length > 0) {
                if (tptDetails[0].is_non_enmovil_trucks_enabled_for_auto_extension) {
                    this.setState({
                        enabled_autoextension: parseInt(tptDetails[0].is_non_enmovil_trucks_enabled_for_auto_extension)
                    })
                }
                await redirectURL.post("/ewaybill/getEwayBillOtherPartyData", params).then(response => {
                    var records = response.data
                    var hero_trucks = this.state.hero_trucks
                    if (records.length > 0) {
                        var org_name = "";
                        if (tptDetails[0].org_name != undefined && tptDetails[0].org_name != null && tptDetails[0].org_name != "" && tptDetails[0].org_name != "undefined") {
                            org_name = tptDetails[0].org_name;
                        }
                        var legal_name_of_consignor = [];
                        if (tptDetails[0].legal_name_of_consignor != undefined && tptDetails[0].legal_name_of_consignor != null && tptDetails[0].legal_name_of_consignor != "" && tptDetails[0].legal_name_of_consignor != "undefined") {
                            legal_name_of_consignor = tptDetails[0].legal_name_of_consignor
                            records = records.filter(e => legal_name_of_consignor.includes(e.legal_name_of_consignor))
                        }
                        //tmsssssssssssssssssss
                        // if (localStorage.getItem("transportercode").indexOf("304550") >= 0) {
                        //     records = records.filter(record => {
                        //         if (record.VehiclListDetails.length > 0 && hero_trucks.includes(record.VehiclListDetails[0].vehicle_number)) {
                        //             return record;
                        //         }
                        //     });
                        // }
                        this.setState({
                            org_name: org_name,
                            legal_name_of_consignor: legal_name_of_consignor,
                            rowData: records,
                            orginalRowData: records,
                            TotalRecords: response.data
                        })
                    }
                    else {
                        this.setState({
                            rowData: [],
                            orginalRowData: [],
                            TotalRecords: []
                        })
                    }
                    this.getUserGridState()
                    this.loadCountersData()
                })
            }
        })
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n'
        })
    }

    getUserGridState() {
        let params = {
            screentitle: this.state.pagetitle,
            userId: this.state.user?.email,
            screenurl: window.location.pathname
        }
        redirectURL.post("/consignments/usergridstates", params)
            .then(async (resp) => {
                //   console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();

            })

    }

    restoreGridStates() {
        // console.log("this.state.usergridstate ", this.state.usergridstate)
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                // console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
        }
        catch (e) { }
    }

    getEWayBillsAssignedtomeforTransportData = async () => {
        if (![undefined, null, "", "undefined"].includes(this.state.eway_from_date)) {
            if (![undefined, null, "", "undefined"].includes(this.state.eway_to_date)) {
                if (new Date(this.state.eway_from_date < new Date(this.state.eway_to_date))) {
                    this.setState({
                        loadshow: 'show-m',
                        overly: 'show-m'
                    })
                    var params = {
                        tpt_code: this.state.user?.transportercode,
                        from_date: moment.parseZone(new Date(this.state.eway_from_date)).utcOffset("+05:30").format("YYYY-MM-DD"),
                        to_date: moment.parseZone(new Date(this.state.eway_to_date)).utcOffset("+05:30").format("YYYY-MM-DD"),
                        // tpt_code: JSON.stringify(["304550"])
                    }

                    await redirectURL.post("/ewaybill/getEwayBillOtherPartyData", params).then(response => {
                        var records = response.data
                        var hero_trucks = this.state.hero_trucks
                        if (records.length > 0) {
                            var legal_name_of_consignor = this.state.legal_name_of_consignor;
                            if (legal_name_of_consignor != undefined && legal_name_of_consignor != null && legal_name_of_consignor != "" && legal_name_of_consignor != "undefined") {
                                records = records.filter(e => legal_name_of_consignor.includes(e.legal_name_of_consignor))
                            }
                            //tmssssssssssss
                            // if (localStorage.getItem("transportercode").indexOf("304550") >= 0) {
                            //     records = records.filter(record => {
                            //         if (record.VehiclListDetails.length > 0 && hero_trucks.includes(record.VehiclListDetails[0].vehicle_number)) {
                            //             return record;
                            //         }
                            //     });
                            // }
                            this.setState({
                                rowData: records,
                                orginalRowData: records,
                                TotalRecords: response.data,
                                hero_tab: 'btn-success',
                                non_hero_tab: "btn-light",
                                hide_stop: false

                            })
                        }
                        else {
                            this.setState({
                                rowData: [],
                                orginalRowData: [],
                                TotalRecords: [],
                                hero_tab: 'btn-success',
                                non_hero_tab: "btn-light",
                                hide_stop: this.state.enabled_autoextension == 1 ? false : true
                            })
                        }
                    })

                    this.loadCountersData()
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "From Date can't be greater than To Date"
                    })
                }
            }
            else {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Select To Date"
                })
            }
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Select From Date"
            })
        }
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi1.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi1.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState);
    }

    handlerStartDateTime = (date) => {
        if (this.state.eway_to_date && date > this.state.eway_to_date) {
            this.setState({
                show: true,
                basicTitle: 'To Date should be greater than From Date',
                basicType: "danger",
                loadshow: 'show-n'
            });
        } else {
            this.setState({ eway_from_date: date });
        }
    }

    handlerEndDateTime = (date) => {
        if (this.state.eway_from_date && date < this.state.eway_from_date) {
            this.setState({
                show: true,
                basicTitle: 'From Date should be less than To Date',
                basicType: "danger",
                loadshow: 'show-n'
            });
        } else {
            this.setState({ eway_to_date: date });
        }
    }


    onRowClicked = (e) => {
        if (e.colDef.field == "eway_bill_number") {
            if (e.data != undefined && e.data != "") {
                // var ipData = [];
                // var inputDetails = e.data;
                // ipData.push(inputDetails);
                // var content = [];
                // ipData.map(function(i){
                //     var keys = Object.keys(i);
                //     keys.map(function(k){
                //         if(k !="_id")
                //         {
                //             var hed = k.split("_").join(" ");
                //             content.push({
                //                 "label":hed.toUpperCase(),
                //                 "value":i[k]
                //             });
                //         }
                //     })
                // })
                // console.log(content,"content")
                this.setState({
                    inputDetails: e.data,
                    overly: "show-m",
                    bulkslide: "slider-translate-60p",
                    sidebarSubHeader: "Eway Bill Details Of #" + e.data.eway_bill_number,
                    sliderType: 2,
                });
            }
        }
        if (e.colDef.field == "stop_button") {
            if (![undefined, null, "undefined", ""].includes(e.data.expiry_date_extension_disabled)) {
                if (e.data.expiry_date_extension_disabled == 0 || e.data.expiry_date_extension_disabled == "0") {
                    var legal_name_of_consignor = this.state.legal_name_of_consignor;
                    if (legal_name_of_consignor.includes(e.data.legal_name_of_consignor)) {
                        if (e.data.eway_bill_valid_date != undefined && e.data.eway_bill_valid_date != '') {
                            var validityDate = e.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            var is_expired = ""
                            if (difference > 0 && difference <= 1) {
                                is_expired = "Expiring Today";
                            }
                            else if (difference > 1 && difference <= 2) {
                                is_expired = "Expiring Tomorrow";
                            }
                            else if (difference <= 0) {
                                is_expired = "Expired";
                            }
                            else {
                                is_expired = "Active";
                            }

                            if (is_expired != "Expired") {
                                var cnf = window.confirm("Do you want to disable Automatic Extension ?")




                                if (cnf == true) {
                                    this.setState({
                                        show1: true,
                                        basicType1: 'info',
                                        basicTitle1: 'Reason to Stop',
                                        row_id: e.data
                                    })
                                }
                            }
                        }
                    }
                    else if (this.state.non_hero_tab == "btn-success") {
                        if (e.data.eway_bill_valid_date != undefined && e.data.eway_bill_valid_date != '') {
                            var validityDate = e.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            var is_expired = ""
                            if (difference > 0 && difference <= 1) {
                                is_expired = "Expiring Today";
                            }
                            else if (difference > 1 && difference <= 2) {
                                is_expired = "Expiring Tomorrow";
                            }
                            else if (difference <= 0) {
                                is_expired = "Expired";
                            }
                            else {
                                is_expired = "Active";
                            }
                            if (is_expired != "Expired") {
                                var cnf = window.confirm("Do you want to disable Automatic Extension ?")
                                if (cnf == true) {
                                    this.setState({
                                        show1: true,
                                        basicType1: 'info',
                                        basicTitle1: 'Reason to Stop',
                                        row_id: e.data
                                    })
                                }
                            }
                        }
                    }

                }
            }
        }
        if (e.colDef.field == "vehicle_details") {
            var VehiclListDetails = e.data.VehiclListDetails;
            if (VehiclListDetails != undefined && VehiclListDetails != "") {
                this.setState({
                    bulkslide: "slider-translate-60p",
                    sliderType: 4,
                    loadshow: 'show-n',
                    sidebarSubHeader: "Vehicle Details Of #" + e.data.eway_bill_number,
                    overly: 'show-m',
                    VehiclListDetails: VehiclListDetails
                });
            }
        }
    }

    OnConfirmStopAutometicExtension = (input) => {
        var params = {
            row_id: this.state.row_id._id,
            remarks: input
        }
        console.log(params)
        redirectURL.post("/ewaybill/updateAutomaticExtension", params).then(response => {
            if (response.data.status == "success") {
                this.setState({
                    show1: false,
                    show: true,
                    basicType: "success",
                    basicTitle: `Automatic Extention disabled for ${this.state.row_id.eway_bill_number}`
                })
            } else {
                this.setState({
                    show1: false,
                    show: true,
                    basicType: "danger",
                    basicTitle: `Automatic Extention disabled Failed`
                })
            }
        })
    }

    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = 'Eway Bill Other Party Data';
        // if(this.props.match.path == "/sndconsignments")
        // {
        // 	screenpage='snd active consignments';
        // }
        // console.log("window.location.pathname;",window.location.pathname)

        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: this.state.user?.email,
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        // console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                // console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                // console.log("Error ", e)
            })
    }

    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: this.state.user?.email,
            screenurl: window.location.pathname,
            screentitle: this.state.pagetitle
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })

    };


    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            sliderForBulkUpload: "",
            uploadFile: ""
        });
    }

    changeFileHandler = async (e) => {
        const file = e.target.files[0];

        // Check if the file is a CSV file
        if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload a file with .csv extension only.',
            });
            return;
        }

        // File validation configuration
        const config = {
            headers: [
                {
                    name: 'E-Way Bill No',
                    inputName: 'eway_bill_number',
                    required: true,
                    requiredError: (headerName, rowNumber, columnNumber) => {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                    }
                },
                {
                    name: 'Reason To Stop',
                    inputName: 'remarks',
                    required: true,
                    requiredError: (headerName, rowNumber, columnNumber) => {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
                    }
                }
            ]
        };

        try {
            // Validate CSV file
            const csvData = await CSVFileValidator(file, config);
            console.log(csvData.data);
            this.setState({
                csvcontent: csvData.data
            });

            // Read the file content
            const contents = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.onerror = (err) => reject(err);
                reader.readAsText(file);
            });

            console.log(contents);

            this.setState({
                file: file
            });
        } catch (err) {
            console.error("Error processing file: ", err);
            // Handle errors if necessary
        }
    }


    onClickGetSliderForBulkUpload = () => {
        this.setState({
            sliderForBulkUpload: 'slider-translate',
            overly: 'show-m',
        })
    }

    onSubmitBulkUpload = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var csvData = this.state.csvcontent
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var params = {
                    csvcontent: csvData,
                    updated_by: this.state.user?.email,
                    transporter_code: this.state.user?.transportercode
                }
                console.log(params)
                redirectURL.post('/ewaybill/bulkUploadForStopEways', params).then(response => {
                    const { status, message, notUpdatedEways } = response.data;
                    let basicTitle = '';
                    let basicType = 'danger';

                    if (status === 'success') {
                        basicTitle = message;
                        basicType = 'success';
                    } else if (status === 'partial_success') {
                        basicTitle = `${message} ${notUpdatedEways.join(', ')}`;
                        basicType = 'success';
                    } else {
                        basicTitle = message;
                    }

                    this.setState({
                        show: true,
                        basicTitle: basicTitle,
                        basicType: basicType,
                        loadshow: 'show-n',
                        overly: 'show-n',
                        sliderForBulkUpload: '',
                        uploadFile: ''
                    });
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }

    loadCountersData = async () => {
        var gridData = this.state.orginalRowData;
        var activeData = [];
        var expiringtomorrowData = [];
        var expiringtodayData = [];
        var expiredData = [];
        var cancelledData = [];
        // var nonHeroData = []
        await gridData.map(function (e) {
            if (e.eway_bill_status == "Cancelled") {
                cancelledData.push(e);
            }
            else {
                if (e.eway_bill_extended_validity_date != undefined && e.eway_bill_extended_validity_date != '') {
                    var validityDate = e.eway_bill_extended_validity_date;
                    var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                    var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                    var d = moment.duration(ms);
                    var difference = parseFloat(d.asDays());
                    if (difference > 0 && difference <= 1) {
                        expiringtodayData.push(e);
                    }
                    else if (difference > 1 && difference <= 2) {
                        expiringtomorrowData.push(e);
                    }
                    else if (difference <= 0) {
                        expiredData.push(e);
                    }
                    else {
                        activeData.push(e);
                    }
                }
                else {
                    if (e.eway_bill_valid_date != undefined && e.eway_bill_valid_date != '') {
                        var validityDate = e.eway_bill_valid_date;
                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var difference = parseFloat(d.asDays());
                        if (difference > 0 && difference <= 1) {
                            expiringtodayData.push(e);
                        }
                        else if (difference > 1 && difference <= 2) {
                            expiringtomorrowData.push(e);
                        }
                        else if (difference <= 0) {
                            expiredData.push(e);
                        }
                        else {
                            activeData.push(e);
                        }
                    }
                }
            }
        });
        await this.setState({
            activeData: activeData,
            expiringtomorrowData: expiringtomorrowData,
            expiringtodayData: expiringtodayData,
            expiredData: expiredData,
            cancelledData: cancelledData,
            // nonHeroData:nonHeroData,
            activeCnt: activeData.length,
            expiredCnt: expiredData.length,
            expiringtomorrow: expiringtomorrowData.length,
            expiringtoday: expiringtodayData.length,
            cancelledCnt: cancelledData.length,
            // nonHeroCnt : nonHeroData.length
        });
    }

    onClickTab = async (tab) => {
        if (tab == "hero") {
            var rowData = this.state.TotalRecords
            var hero_trucks = this.state.hero_trucks
            //tmsssssssssssssssssssssss
            // if (localStorage.getItem("transportercode").indexOf("304550") >= 0) {
            //     rowData = rowData.filter(record => {
            //         console.log(record, "record_655")
            //         if (![undefined, "undefined", null, ""].includes(record.VehiclListDetails) && record.VehiclListDetails.length > 0 && hero_trucks.includes(record.VehiclListDetails[0].vehicle_number)) {
            //             return record;
            //         }
            //     });
            // }
            var legal_name_of_consignor = this.state.legal_name_of_consignor;
            if (legal_name_of_consignor != undefined && legal_name_of_consignor != null && legal_name_of_consignor != "" && legal_name_of_consignor != "undefined") {
                rowData = rowData.filter(e => legal_name_of_consignor.includes(e.legal_name_of_consignor))
            }
            await this.setState({
                rowData: rowData,
                orginalRowData: rowData,
                hero_tab: 'btn-success',
                non_hero_tab: "btn-light",
                hide_stop: false
            })
            this.loadCountersData()
        } else {
            var rowData = this.state.TotalRecords
            var legal_name_of_consignor = this.state.legal_name_of_consignor;
            if (legal_name_of_consignor != undefined && legal_name_of_consignor != null && legal_name_of_consignor != "" && legal_name_of_consignor != "undefined") {
                rowData = rowData.filter(e => !legal_name_of_consignor.includes(e.legal_name_of_consignor))
            }
            await this.setState({
                rowData: rowData,
                orginalRowData: rowData,
                hero_tab: 'btn-light',
                non_hero_tab: "btn-success",
                hide_stop: this.state.enabled_autoextension === 1 ? false : true
            })
            this.loadCountersData()
        }
    }

    async onclickCounter(counterType) {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        });
        var rowData = [];
        if (counterType == "1") {
            rowData = this.state.activeData;
        }
        else if (counterType == "2") {
            rowData = this.state.expiringtomorrowData;
        }
        else if (counterType == "3") {
            rowData = this.state.expiringtodayData;
        }
        else if (counterType == "4") {
            rowData = this.state.expiredData;
        }
        else if (counterType == "5") {
            rowData = this.state.orginalRowData;
        }
        // else if(counterType = "non-hero"){
        //     rowData = this.state.nonHeroData;
        // }
        else {
            rowData = this.state.cancelledData;
        }
        this.setState({
            rowData: rowData,
            loadshow: "show-n",
            overly: "show-n",
        });
        //this.gridApi.setRowData(rowData);
    }

    closeAlert = () => {
        this.setState({
            show: false,
            show1: false,
        })
        if (this.state.basicType == "success" || this.state.basicType1 == "success") window.location.reload()
    }

    render() {


        var columnwithDefs = [
            {
                headerName: "Vehicle Number",
                field: "vehicle_number",
                colId: "vehicle_number",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    if (params.data.VehiclListDetails != undefined && params.data.VehiclListDetails != "") {
                        var VehiclListDetails = params.data.VehiclListDetails;
                        var vehicleNumber = [];
                        VehiclListDetails.map(function (v) {
                            if (v.vehicle_number != undefined && v.vehicle_number != "") {
                                vehicleNumber.push(v.vehicle_number);
                            }
                        })
                        if (vehicleNumber.length > 0) {
                            return vehicleNumber.join(",");
                        }
                    }
                }
            },
            {
                headerName: "E-Way Bill No",
                field: "eway_bill_number",
                colId: "eway_bill_number",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true,
                cellStyle: { 'font-weight': 'bold' },
            },
            {
                headerName: "E-Way Bill Date",
                field: "eway_bill_date",
                colId: "eway_bill_date",
                filter: true,
                width: 150,
                resizable: true,
                // comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_date);
                },
                hide: false
            },
            {
                headerName: "Original Expiry Date",
                width: 160,
                field: "original_valid_date",
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (![undefined, null, "undefined", ""].includes(params.data.original_valid_date)) {
                        return getHyphenDDMMMYYYYHHMM(params.data.original_valid_date)
                    }
                },
            },
            {
                headerName: "E-Way Bill Validity",
                field: "eway_bill_valid_date",
                colId: "eway_bill_valid_date",
                filter: true,
                width: 150,
                resizable: true,
                // comparator: dateComparator,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.eway_bill_valid_date);
                },
                hide: false
            },
            {
                headerName: "E-Way Bill Status",
                field: "eway_bill_status",
                colId: "eway_bill_status",
                width: 130,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    if (params.data.eway_bill_status == "Cancelled") {
                        return params.data.eway_bill_status;
                    }
                    else {
                        if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
                            var validityDate = params.data.eway_bill_valid_date;
                            var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                            var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                            var d = moment.duration(ms);
                            var difference = parseFloat(d.asDays());
                            if (difference > 0 && difference <= 1) {
                                if (params.data.expiry_date_extension_disabled == 1) {
                                    return "Extention Stopped";

                                } else {
                                    return "Expiring Today";

                                }

                            }
                            else if (difference > 1 && difference <= 2) {
                                return "Expiring Tomorrow";
                            }
                            else if (difference <= 0) {
                                return "Expired";
                            }
                            else {
                                return "Active";
                            }
                        }
                        else {
                            return "N/A";
                        }
                    }
                }
            },
            {
                headerName: "Extend Validity",
                field: "extend",
                width: 130,
                resizable: true,
                hide: this.state.hide_stop,
                // cellRenderer: function (params) {
                //     let basestring = params.data.eway_bill_number;
                //     let encryptedstring = window.btoa(basestring);
                //     var htmloption = '<a href="/tptlogin/extendewaybill/' + encryptedstring + '" class="btn btn-success" style="padding:0 6px 6px">Extend</a>';
                //     if (params.data.eway_bill_status != "Cancelled") {
                //         if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
                //             var validityDate = params.data.eway_bill_valid_date;
                //             var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                //             var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
                //             var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                //             var d = moment.duration(ms);
                //             var difference = parseFloat(d.asHours());
                //             if (difference <= 8 && difference >= -8) {
                //                 return htmloption;
                //             }
                //             else {
                //                 return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                //             }
                //         }
                //         else {
                //             return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                //         }
                //     }
                //     else {
                //         return '<button class="btn btn-success" style="padding:0 6px 6px;color:#ccc;cursor:not-allowed" disabled>Extend</button>';
                //     }
                // }
                cellRendererSelector: function (param) {
                    try {
                        if (param.data._id != null && param.data._id != undefined) {
                            return {
                                component: ExtendValidity
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                },
            },
            {
                headerName: "Automatic Extension",
                width: 140,
                field: "expiry_date_extension_disabled",
                filter: true,
                resizable: true,
                hide: this.state.hide_stop,
                cellRenderer: function (params) {
                    if (![undefined, null, "undefined", ""].includes(params.data.expiry_date_extension_disabled)) {
                        if (params.data.expiry_date_extension_disabled == 0 || params.data.expiry_date_extension_disabled == "0") {
                            return 'YES'
                        } else {
                            return 'NO'
                        }
                    }
                    else {
                        return 'NO'
                    }
                }
            },
            // {
            //     headerName: "Stop",
            //     width: 120,
            //     field: "stop_button",
            //     filter: true,
            //     resizable: true,
            //     hide: this.state.hide_stop,
            //     cellRenderer: function (params) {
            //         try{
            //             if (![undefined, null, "undefined", ""].includes(params.data.expiry_date_extension_disabled)) {
            //                 if (params.data.expiry_date_extension_disabled == 0 || params.data.expiry_date_extension_disabled == "0") {
            //                     var legal_name_of_consignor = this.state.legal_name_of_consignor;
            //                     if(legal_name_of_consignor.includes(params.data.legal_name_of_consignor))
            //                     {
            //                         if (params.data.eway_bill_valid_date != undefined && params.data.eway_bill_valid_date != '') {
            //                             var validityDate = params.data.eway_bill_valid_date;
            //                             var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
            //                             var d2 = moment.parseZone(new Date(validityDate)).utcOffset("+00:00").format("YYYY-MM-DD HH:mm:ss")
            //                             var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
            //                             var d = moment.duration(ms);
            //                             var difference = parseFloat(d.asDays());
            //                             var is_expired = ""
            //                             if (difference > 0 && difference <= 1) {
            //                                 is_expired = "Expiring Today";
            //                             }
            //                             else if (difference > 1 && difference <= 2) {
            //                                 is_expired = "Expiring Tomorrow";
            //                             }
            //                             else if (difference <= 0) {
            //                                 is_expired = "Expired";
            //                             }
            //                             else {
            //                                 is_expired = "Active";
            //                             }
            //                         }
            //                     }
            //                     if (is_expired !== 'Expired') {
            //                         var htmloption = '<button class="btn btn-danger" style="padding-top:0px">stop</button>';

            //                     } else {
            //                         var htmloption = '<button class="btn btn-danger" style="padding-top:0px;cursor:not-allowed" disabled>stop</button>';

            //                     }
            //                 } else {
            //                     var htmloption = '<button class="btn btn-danger" style="padding-top:0px;cursor:not-allowed" disabled>stop</button>';
            //                 }
            //             }
            //             else {
            //                 var htmloption = '<button class="btn btn-danger" style="padding-top:0px;width:100px;cursor:not-allowed;" disabled>stop</button>';
            //             }
            //             return htmloption;
            //         }
            //         catch(e){
            //             console.log(e)
            //         }
            //     }
            // },
            {
                headerName: "Stop",
                width: 120,
                field: "stop_button",
                filter: true,
                resizable: true,
                hide: this.state.hide_stop,
                cellRendererSelector: function (param) {
                    try {
                        if (param.data._id != null && param.data._id != undefined) {
                            return {
                                component: StopButton
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                },
            },
            {
                headerName: "Remarks",
                width: 140,
                field: "remarks",
                filter: true,
                resizable: true,
                hide: this.state.hide_stop,
            },
            {
                headerName: "NO Of Extensions",
                field: 'extended_times',
                colId: 'extended_times',
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Current Location",
                field: 'current_location',
                colId: 'current_location',
                width: 150,
                hide: this.state.hide_stop,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Distance From Dealer",
                field: 'distance_from_dealer',
                colId: 'distance_from_dealer',
                width: 150,
                hide: this.state.hide_stop,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Total Invoice Value",
                field: 'total_invoice_value',
                colId: 'total_invoice_value',
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "GST Of Generator",
                field: "gstin_of_generator",
                colId: "gstin_of_generator",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Eway Bill Type",
                field: "eway_bill_type",
                colId: "eway_bill_type",
                width: 190,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
                    if (params.data.eway_bill_type != undefined && params.data.eway_bill_type != "") {
                        var gen = params.data.eway_bill_type.split('_').join(' ');
                        return gen.toUpperCase();
                    }
                }
            },
            {
                headerName: "Document Number",
                field: "document_number",
                colId: "document_number",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Date",
                field: "document_date",
                colId: "document_date",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Vehicle Details",
                field: "vehicle_details",
                colId: "vehicle_details",
                width: 150,
                hide: false,
                filter: true,
                resizable: true,
                cellRendererSelector: function (param) {
                    try {
                        if (param.data._id != null && param.data._id != undefined) {
                            return {
                                component: ViewButton
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                },
            },
            {
                headerName: "Consignee State",
                field: "state_name_of_delivery",
                colId: "state_name_of_delivery",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignee Place",
                field: "place_of_delivery",
                colId: "place_of_delivery",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignee Pincode",
                field: "pincode_of_delivery",
                colId: "pincode_of_delivery",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignor Name",
                field: "legal_name_of_consignor",
                colId: "legal_name_of_consignor",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignor State",
                field: "state_of_consignor",
                colId: "state_of_consignor",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Consignor Pincode",
                field: "pincode_of_consignor",
                colId: "pincode_of_consignor",
                width: 150,
                hide: false,
                filter: true,
                cellRenderer: '', resizable: true
            },


        ]

        var columnwithDefsForvehicle = [
            {
                headerName: "Vehicle No",
                field: "vehicle_number",
                colId: "vehicle_number",
                width: 110,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Updated On",
                field: "vehicle_number_update_date",
                colId: "vehicle_number_update_date",
                width: 160,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Transportation Mode",
                field: "transportation_mode",
                colId: "transportation_mode",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Number",
                field: "transporter_document_number",
                colId: "transporter_document_number",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Document Date",
                field: "transporter_document_date",
                colId: "transporter_document_date",
                width: 140,
                hide: false,
                cellRenderer: '', resizable: true
            },
        ];


        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    // type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    input
                    required={false}
                    showCancel
                    cancelBtnBsStyle="default"
                    onConfirm={this.OnConfirmStopAutometicExtension}
                    onCancel={this.closeAlert}
                    style={{ height: '175px', width: '250px' }}

                >
                </SweetAlert>
                {this.state.alert}
                <div className="row col-xl-12 col-lg-12">
                    <div class="col-xl-3 col-lg-3 form-group">
                        <label>From Date</label>
                        <DatePicker
                            selected={this.state.eway_from_date}
                            onChange={this.handlerStartDateTime.bind(this)}
                            name="startDate"
                            className="datepicker-custom"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div class="col-xl-3 col-lg-3 form-group">
                        <label>To Date</label>
                        <DatePicker
                            selected={this.state.eway_to_date}
                            onChange={this.handlerEndDateTime.bind(this)}
                            name="endDate"
                            className="datepicker-custom"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div class="col-xl-2 col-lg-2 mr-0 form-group">
                        <label>&nbsp;</label>
                        <br />
                        <button type="button" onClick={this.getEWayBillsAssignedtomeforTransportData} class="btn btn-success">Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                {this.state.org_name != "" && this.state.user?.transportercode.indexOf("T275") < 0 ?
                    <div className={'col-xl-12 col-lg-12'} style={{ marginBottom: "10px" }} >
                        <button className={'btn ' + this.state.hero_tab} style={{ width: "100px" }} onClick={this.onClickTab.bind(this, "hero")}>{this.state.org_name}</button>
                        <button className={'btn ' + this.state.non_hero_tab} style={{ width: "100px" }} onClick={this.onClickTab.bind(this, "non-hero")}>Non-{this.state.org_name}</button>
                    </div>
                    : ""}
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{ paddingLeft: "15px" }}>
                    <div className="card">
                        <div className="card-body n-p-0">
                            <div className="crm-numbers pb-0">
                                <div className="row col-xl-12 col-lg-12 col-sm-12">
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>All</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "5", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "5")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#4099ff" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.orginalRowData.length) ? this.state.orginalRowData.length : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Active</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "1")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#008000" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.activeCnt) ? this.state.activeCnt : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expiring Tomorrow </span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "2")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FFFF00" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.expiringtomorrow) ? this.state.expiringtomorrow : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expiring Today</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "3")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FFA500" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.expiringtoday) ? this.state.expiringtoday : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Expired</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "0", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "4")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "#FF0000" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.expiredCnt) ? this.state.expiredCnt : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                    {/* <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Cancelled</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "5", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "6")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "red" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.cancelledCnt) ? this.state.cancelledCnt : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div> */}
                                    {/* <div className="col cirlce-d">
                                        <span className="f15" style={{ color: "#db3031" }}>Non-Hero</span>
                                        <div className="col-xl-12 col-lg-12 col-sm-12 cpointer" style={{ padding: "5", marginTop: "5%" }} onClick={this.onclickCounter.bind(this, "non-hero")}>
                                            <h5 className={"f25 mt-20p"} style={{ color: "black" }}>
                                                <span className="counter">
                                                    <CountUp end={(this.state.nonHeroCnt) ? this.state.nonHeroCnt : 0} />
                                                </span>
                                            </h5>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Eway Bill Other Party Data </span>

                            </h5>
                        </div>
                        <span className="layoutbtns pull-right">
                            <button className="float-right custom-btn white btn-danger"
                                style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
                                <i className="icofont icofont-save"></i> Save Grid Layout
                            </button>
                            <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                            </button>
                            <button className="float-right custom-btn white btn-success" onClick={this.onClickGetSliderForBulkUpload} style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} >
                                Bulk Upload
                            </button>
                        </span>

                        <div className="card-body pt-15px" >
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{ width: "100%", height: "500px" }} className="ag-theme-balham">
                                        <AgGridReact
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={false}
                                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            stopEditingWhenGridLosesFocus={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            // components={this.state.components}
                                            enableRangeSelection={true}
                                            onCellClicked={this.onRowClicked.bind(this)}
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "auto" }}>
                            <h3 className="subH">
                                {this.state.sidebarSubHeader}
                                <span className="float-right fclose" style={{ marginRight: "12px", padding: "1px 8px", marginTop: "-2px" }} onClick={this.onClickHideAll.bind(this)}>x</span>
                            </h3>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="container-fluid">
                                    {(this.state.sliderType == 2) ?
                                        <EbillWayInfo ebillInfo={this.state.inputDetails} />
                                        : ""}
                                    {(this.state.sliderType == 4) ?
                                        <div className='row col-sm-12'>
                                            <div id="myGrid" style={{ width: "100%", height: "300px" }} className={"ag-theme-balham"}>
                                                <AgGridReact
                                                    columnDefs={columnwithDefsForvehicle}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.VehiclListDetails}
                                                    enableCharts={false}
                                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady1}
                                                    onGridState={this.onGridState1}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={false}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    // components={this.state.components}
                                                    enableRangeSelection={true}
                                                />
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </div>

                        <div className={"slide-r " + (this.state.sliderForBulkUpload)} style={{ overflow: "auto" }}>
                            <div className="slide-r-title">
                                <h4>
                                    Stop EwayBills Bulk Upload
                                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                    <form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload} >
                                        <div className="col-xl-12 col-lg-12 row form-group">
                                            <label className="col-xl-12 col-lg-12">Upload File *</label>
                                            <input
                                                type="file"
                                                id="uploadfile"
                                                className="form-control"
                                                onChange={this.changeFileHandler}
                                            // value={this.state.uploadFile} 
                                            />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <a href={require("../../assets/files/stopEwayBillBulkUploadTemplate.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className={"dataLoadpage " + (this.state.loadshow)}>
                        </div>
                        <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                    </div>
                </div>
            </div>
        )
    }
}

