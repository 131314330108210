const NoGPS=(props)=>{
    const handleClick = (params) => {
        props.onShowNoGPSData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#FF5370"
    }
    else if (props.getValue() != undefined && props.getValue() == 0) {
        color = "#26c6da"
    }
    return(
        <a onClick={handleClick}  title="No GPS Data"><i className="icofont icofont-social-google-map f20" style={{ color: color }}></i> </a>
    )
}
export default NoGPS