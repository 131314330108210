
import React from 'react';

const AttendedButtonRenderer = (props) => {
    const handleClick = () => {
        // Handle button click, you can access props.data here
        console.log('Button clicked for row:', props.data);
        // Add your custom logic here
    };

    return (
        <button
            style={{ padding: '2% 10% 5% 10%',fontSize: '14px' }}
            className="btn btn-warning"
            title="Marks As Attended"
            onClick={handleClick}
        >
            Attended
        </button>
    );
};

export default AttendedButtonRenderer;
