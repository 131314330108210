/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import AgGrid from "./AgGrid"; import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getYYYYMMDDHHMMSS } from '../common/utils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import * as Datetime from 'react-datetime';
// import 'react-datetime/css/react-datetime.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import more from 'material-ui/svg-icons/notification/more';
import redirectURL from "../redirectURL";
var moment = require('moment');

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class DealerDetentionReport extends Component {
    state = {
        show: false,
        basicTitle: '',
        basicType: "default",
        loadshow: "show-n",
        overly: "show-n",
        dept_code: "",
        totalTrucks: [],
        truckscnt: 0,
        rowData: [],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        startDate: new Date(moment.parseZone().subtract(1, "year")),
        endDate: new Date(moment.parseZone()),
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        from_date: moment.parseZone().startOf("day").format("YYYY-MM-DD"),
        to_date: moment.parseZone().endOf("day").format("YYYY-MM-DD"),
        selectedFilter: []
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        // eslint-disable-next-line no-unreachable
        catch (e) { }
    }

    componentDidMount() {
        let from_date = moment.parseZone().subtract(1, "year").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment.parseZone().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        // this.setState({
        //     startDate: from_date,
        //     endDate: to_date
        // })
        let params = {
            from_date, to_date,
            "search_type": "invoice_time"
        }
        if (this.state.selectedFilter == "Invoice Time") {
            params = {
                from_date, to_date,
                "search_type": "invoice_time"
            }
        }
        else if (this.state.selectedFilter == "Left Dealer Time") {
            params = {
                from_date, to_date,
                "search_type": "left_dealer_time"
            }
        }
        console.log('params', params)
        this.setState({
            loadshow: "show-m",
            overlay: "show_m"
        })
        this.getDashboardRowData(params)
    }
    getDashboardRowData = (params) => {
        // let params = {
        //     from_date, to_date,
        // }

        redirectURL.post("/getDealerDetentionReport", params).then(resp => {
            const rowData = JSON.parse(resp.data.apiData)
            console.log("rowData", rowData)
            const rowGridData = []
            if (rowData["response_data"] !== undefined) {
                rowGridData.push(JSON.parse(rowData["response_data"]))
                console.log("rowData", rowData)
                console.log("rowGridData", rowGridData)
                this.setState({
                    rowData: rowGridData[0],
                    loadshow: 'show-n',
                    overly: 'show-n'
                })
            }

        })
    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false
        })
    }

    closeAlert = () => {
        this.setState({
            show: false,
            title: ""
        })
    }
    getDealerDetails = event => {
        event.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        const { startDate, endDate } = this.state

        let sdate = moment.parseZone(new Date(startDate)).utcOffset("+05:30").format("YYYY-MM-DD") + " 00:00:00"
        let edate = moment.parseZone(new Date(endDate)).utcOffset("+05:30").format("YYYY-MM-DD") + " 23:59:59"
        let params = { "search_type": "invoice_time", from_date: sdate, to_date: edate }
        let selectedFilter = this.state.selectedFilter

        if (selectedFilter == "Invoice Time") {
            params = { "search_type": "invoice_time", from_date: sdate, to_date: edate }
        }
        else if (selectedFilter == "Left Dealer Time") {
            params = { "search_type": "left_dealer_time", from_date: sdate, to_date: edate }
        }
        console.log("params", params)
        this.getDashboardRowData(params)
    }


    handleStartDateChange = date => {
        if (this.state.endDate && date > this.state.endDate) {
            this.setState({
                show: true,
                basicTitle: 'To Date should be greater than From Date',
                basicType: "danger",
                loadshow: 'show-n'
            });
        } else {
            this.setState({ startDate: date });
        }
    };

    handleEndDateChange = date => {
        if (this.state.startDate && date < this.state.startDate) {
            this.setState({
                show: true,
                basicTitle: 'To Date should be greater than From Date',
                basicType: "danger",
                loadshow: 'show-n'
            });
        } else {
            this.setState({ endDate: date });
        }
    };




    // handlerStartDateTime = (event, currentDate, selectedDate) => {
    //     var d = new Date(event._d);

    //     var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    //     if (this.state.endDate != undefined && this.state.endDate != "") {
    //         // console.log(startdate,"startdate")
    //         // console.log(this.state.endDate,"endDate")
    //         var startDate = new Date(startdate);
    //         var endDate = new Date(this.state.endDate);
    //         var startTime = startDate.getTime();
    //         var endTime = endDate.getTime();
    //         // console.log(startTime,endTime);
    //         if (startTime > endTime) {
    //             this.setState({
    //                 startDate: ""
    //             });
    //             alert("To Date should be greater than From Date");
    //         }
    //         else {
    //             this.setState({
    //                 startDate: `${startdate} 00:00:00`
    //             });
    //         }
    //     }
    //     else {
    //         this.setState({
    //             startDate: `${startdate} 00:00:00`
    //         });
    //     }
    // }

    // handlerEndDateTime = (event, currentDate, selectedDate) => {
    //     var d = new Date(event._d);
    //     var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
    //     if (this.state.endDate != undefined && this.state.endDate != "") {
    //         // console.log(this.state.startDate,"startdate")
    //         // console.log(edate,"endDate")
    //         var startDate = new Date(this.state.startDate);
    //         var endDate = new Date(edate);
    //         var startTime = startDate.getTime();
    //         var endTime = endDate.getTime();
    //         // console.log(startTime,endTime);
    //         if (startTime > endTime) {
    //             this.setState({
    //                 endDate: ""
    //             });
    //             alert("To Date should be greater than From Date");
    //         }
    //         else {
    //             this.setState({
    //                 endDate: `${edate} 23:59:59`
    //             });
    //         }
    //     }
    //     else {
    //         this.setState({
    //             endDate: `${edate} 23:59:59`
    //         });
    //     }
    // }

    newFilter = (filterType, selOption) => {
        // console.log(filterType, selOption)
        this.setState({ [filterType]: selOption });
    }

    render() {
        const { loadshow, overly } = this.state
        const dealerDetentionColDef = [
            // {
            //     // headerName: "Dealer Detention Report",
            //     // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            //     field: "",
            //     // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            //     // chartDataType: 'series',
            //     width: 300,
            //     chartDataType: "excluded",
            //     children: [
            {
                headerName: "Truck No",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Truck No",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            // {
            //     headerName: "Area Office",
            //     headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            //     field: "Area Office",
            //     width: 150,
            //     // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            //     chartDataType: "series"
            // },

            {
                headerName: "FO Number",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "FO Number",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Invoice Time",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Invoice Time",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data["Invoice Time"] != '' && params.data["Invoice Time"] != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data["Invoice Time"]);
                    }
                    else {
                        return "NA";
                    }
                }
            },


            {
                headerName: "Transporter Name",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Transporter Name",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Transporter Code",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Transporter Code",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Plant Code",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Plant Code",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Dealer Code",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Dealer Code",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Base KM",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Base KM",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "KMS Range",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "KMS Range",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Dealer Name",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Dealer Name",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Dealer City",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Dealer City",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Dealer State",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Dealer State",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Reporting Date",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Reporting Date Time 5 KM",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data["Reporting Date Time 5 KM"] != '' && params.data["Reporting Date Time 5 KM"] != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data["Reporting Date Time 5 KM"]);
                    }
                    else {
                        return "NA";
                    }
                }
            },
            {
                headerName: "Original Reporting Time",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Original Reporting Time",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data["Original Reporting Time"] != '' && params.data["Original Reporting Time"] != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data["Original Reporting Time"]);
                    }
                    else {
                        return "NA";
                    }
                }
            },
            {
                headerName: "Unloading Date Time",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Unloading Date Time",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data["Unloading Date Time"] != '' && params.data["Unloading Date Time"] != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data["Unloading Date Time"]);
                    }
                    else {
                        return "NA";
                    }
                }
            },
            {
                headerName: "Unloading Delay (Hrs)",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Unloading Delay (Hrs)",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data["Unloading Delay (Hrs)"] != '' && params.data["Unloading Delay (Hrs)"] != undefined) {
                        return params.data["Unloading Delay (Hrs)"]
                    }
                    else {
                        return "NA";
                    }
                }
            },

            {
                headerName: "Status",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Status",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            },
            {
                headerName: "Force Closure Time",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "force_closure_time",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series",
                valueGetter: params => {
                    if (params.data.force_closure_time != '' && params.data.force_closure_time != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.force_closure_time);
                    }
                    else {
                        return "NA";
                    }
                }
            },
            {
                headerName: "Remarks",
                // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "Remarks",
                width: 150,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                chartDataType: "series"
            }
        ]
        // },
        // ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        {/* Truck Wise tab button data  */}
                        <div className={"card-body " + this.state.tab1} style={{ padding: '10px' }}>
                            <form className="row" onSubmit={this.getDealerDetails} style={{ margin: '10px' }}>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Select Type </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedFilter"
                                        style={{ borderRadius: "0px" }}
                                        onChange={e => { this.setState({ selectedFilter: e.label }) }}
                                        options={[{ label: "Invoice Time", value: "Invoice Time" }, { label: "Left Dealer Time", value: "Left Dealer Time" }]}
                                    />
                                </div>
                                <div className="col-xl-2 col-lg-2 form-group">
                                    <label>From Date</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleStartDateChange.bind(this)}
                                        name="startDate"
                                        dateFormat="dd/MM/yyyy"
                                        className="datepicker-custom" />
                                </div>

                                <div className="col-xl-2 col-lg-2 form-group">
                                    <label>To Date</label>
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={this.handleEndDateChange.bind(this)}
                                        name="endDate"
                                        dateFormat="dd/MM/yyyy"
                                        className="datepicker-custom" />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2" style={{ textAlign: "left", marginTop: "30px" }}>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            {/* <hr style={{ border: '1px solid #c5bcbc' }} /> */}
                            <div className="col-xl-12 col-lg-12">
                                <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className='row'>
                                            {/* Dealer Detention Grid */}
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="card-header" style={{ padding: '10px' }}>
                                                    <h5>Dealer Detention Report</h5>
                                                </div>
                                                <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                                    <AgGridReact
                                                        columnDefs={dealerDetentionColDef}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.rowData}
                                                        pagination={true}
                                                        paginationPageSize={50}
                                                        enableRangeSelection={true}
                                                        enableCellContextMenu={true}
                                                        sideBar={{
                                                            toolPanels: [
                                                                {
                                                                    id: "columns",
                                                                    labelDefault: "Columns",
                                                                    labelKey: "columns",
                                                                    iconKey: "columns",
                                                                    toolPanel: "agColumnsToolPanel",
                                                                    toolPanelParams: {
                                                                        suppressPivotMode: true,
                                                                        suppressRowGroups: true,
                                                                        suppressValues: true,
                                                                    }
                                                                },
                                                                {
                                                                    id: "filters",
                                                                    labelDefault: "Filters",
                                                                    labelKey: "filters",
                                                                    iconKey: "filter",
                                                                    toolPanel: "agFiltersToolPanel"
                                                                }
                                                            ]
                                                        }}
                                                        statusBar={{
                                                            statusPanels: [
                                                                {
                                                                    statusPanel: "agTotalAndFilteredRowCountComponent",
                                                                    align: "left"
                                                                },
                                                                {
                                                                    statusPanel: "agTotalRowCountComponent",
                                                                    align: "center"
                                                                },
                                                                { statusPanel: "agFilteredRowCountComponent" },
                                                                { statusPanel: "agSelectedRowCountComponent" },
                                                                { statusPanel: "agAggregationComponent" }
                                                            ]
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>
            </div>
        )

    }
}