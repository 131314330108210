const Overspeeding=(props)=>{
    const handleClick = (params) => {
        props.onShowOverspeedingData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#3300c2"
    }
    return(
        <a onClick={handleClick}  title="Overspeeding"><i className="fa fa-tachometer f20" style={{ color: color }}></i> </a>
    )
}
export default Overspeeding