import React, { useEffect, useState } from 'react';
import redirectURL from '../redirectURL';

function MapComponent(props) {
    const [API_KEY, setAPIKey] = useState("");
    const [map, setMap] = useState(null);
    const [line, setLine] = useState(null);
    const [animateMarker, setAnimateMarker] = useState(null);
    const [animationId, setAnimationId] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        redirectURL.post('/details')
            .then((response) => {
                if (response.data.status === 'success') {
                    setAPIKey(response.data.output[0].g_key);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
            renderMap();
    }, [props.mapinfo]);

    useEffect(() => {
        if (API_KEY) {
            renderMap();
        }
    }, [API_KEY]);

    useEffect(() => {
        if (map) {
            renderMap();
        }
        else{
            renderMap();
        }
    }, [props]);

    const renderMap = () => {
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,drawing&callback=initMap`);
        window.initMap = initMap;
    };

    const initMap = () => {
        const jsondata = props.mapinfo;
        const locations = jsondata.breaks;
        const tolls = jsondata.tolls;
        let arr = jsondata.coords;
        // console.log("arr ", jsondata)
        const dealer = jsondata.consignee_coordinates;

        const initialLatLng = arr.length > 0 ? { lat: arr[0].lat, lng: arr[0].lng } : { lat: 17.4578112, lng: 78.366387 };

        const mapOptions = {
            zoom: 11,
            center: new window.google.maps.LatLng(initialLatLng.lat, initialLatLng.lng),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            styles: [
                { featureType: "administrative", elementType: "labels.text.fill", stylers: [{ color: "#444444" }] },
                { featureType: "landscape", elementType: "all", stylers: [{ color: "#EEFEFF" }] },
                { featureType: "poi", elementType: "all", stylers: [{ visibility: "on" }] },
                { featureType: "road", elementType: "all", stylers: [{ saturation: -100 }, { lightness: 45 }] },
                { featureType: "road.highway", elementType: "all", stylers: [{ visibility: "on" }] },
                { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ visibility: "on" }] },
                { featureType: "transit", elementType: "all", stylers: [{ visibility: "on" }] },
            ],
        };

        const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        setMap(map);

        if (arr.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            arr.forEach(coord => {
                bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
            });
            map.fitBounds(bounds);

            const line = new window.google.maps.Polyline({
                map,
                path: arr.map(coord => ({ lat: coord.lat, lng: coord.lng })),
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
            });
            setLine(line);

            // Add animation marker but hide it initially
            const animateMarker = new window.google.maps.Marker({
                map,
                // icon: {
                //     path:'M20 8h-3V4c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2v4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h1a3 3 0 0 0 6 0h2a3 3 0 0 0 6 0h1c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zM8 4h8v4H8V4zm4 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
                //     fillColor: "#066FC1",
                //     fillOpacity: 1,
                //     strokeWeight: 1,
                //     strokeColor: "#0694C1",
                //     scale: 1,
                //     anchor: new window.google.maps.Point(8, 8)
                // },

                icon: {
                    url: require('../../assets/icons/truck-end.png'), // URL of the .png file
                    scaledSize: new window.google.maps.Size(50, 50), // Adjust size as needed
                    anchor: new window.google.maps.Point(15, 15), // Adjust anchor to center the icon
                },
                position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                visible: false
            });
            setAnimateMarker(animateMarker);
        }

        // Handle other markers (breaks, dealer, start, end)
        locations.forEach(location => {
            new window.google.maps.Marker({
                position: new window.google.maps.LatLng(location.lat, location.lng),
                map,
                icon: require('../../assets/icons/cf.png'),
            });
        });

        if (dealer.lat && dealer.lng) {
            new window.google.maps.Marker({
                position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
                map,
                icon: require('../../assets/icons/user-map.png'),
            });
        }

        // Start and end markers
        new window.google.maps.Marker({
            position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
            map,
            icon: require('../../assets/icons/track_start.png'),
        });

        new window.google.maps.Marker({
            position: new window.google.maps.LatLng(arr[arr.length - 1].lat, arr[arr.length - 1].lng),
            map,
            icon: require('../../assets/icons/truck-end.png'),
        });
    };

    const animateRoute = (startStep = 0) => {
        if (!animateMarker || !line) return;
        const path = line.getPath().getArray();
        let step = startStep;
        animateMarker.setVisible(true);
        setIsAnimating(true);

        function moveMarker() {
            if (step >= path.length || isPaused) {
                setIsAnimating(false);
                return;
            }

            const nextStep = step + 10 < path.length ? step + 10 : path.length - 1;
            const bearing = calculateBearing(path[step], path[nextStep]);

            animateMarker.setPosition(path[step]);
            // animateMarker.setIcon({
            //     path:'M20 8h-3V4c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2v4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h1a3 3 0 0 0 6 0h2a3 3 0 0 0 6 0h1c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zM8 4h8v4H8V4zm4 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
            //     fillColor: "#066FC1",
            //     fillOpacity: 1,
            //     strokeWeight: 1,
            //     strokeColor: "#0694C1",
            //     scale: 1,
            //     anchor: new window.google.maps.Point(8, 8),
            //     rotation: bearing // Apply bearing here
            // });

            animateMarker.setIcon({
                url: require('../../assets/icons/truck-end.png'), // URL of the .png file
                scaledSize: new window.google.maps.Size(25, 25), // Adjust size as needed
                anchor: new window.google.maps.Point(15, 15), // Adjust anchor to center the icon
                rotation: bearing // Apply bearing here
            });

            setCurrentStep(step);
            step += 10; // Move every 10th coordinate
            const id = setTimeout(moveMarker, 100); // Adjust speed by changing the timeout value
            setAnimationId(id);
        }

        moveMarker();
    };

    const calculateBearing = (start, end) => {
        const lat1 = start.lat();
        const lng1 = start.lng();
        const lat2 = end.lat();
        const lng2 = end.lng();

        const dLon = (lng2 - lng1);
        const y = Math.sin(dLon) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
        const brng = Math.atan2(y, x) * 180 / Math.PI;
        return (brng + 360) % 360; // Normalize to 0-360
    };

    const pauseAnimation = () => {
        setIsPaused(true);
        clearTimeout(animationId);
    };

    const resumeAnimation = () => {
        setIsPaused(false);
        animateRoute(currentStep);
    };

    return (
        <div className="card theme-height p-0">
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-sm-9" style={{ paddingRight: "0px" }}>
                        <div id="map" style={{ width: '100%' }}></div>
                    </div>
                    <div className="col-sm-3" style={{ paddingLeft: "0px" }}>
                        <h2 className="details-header bg-info">{props.mapTitle}</h2>
                        <ul className="info-list">
                            {
                                props.viewinfo?.length > 0 ?
                                    props.viewinfo.map((itm, index) => (
                                        <li key={index}>
                                            <div className="row">
                                                <div className="col-sm-5">{itm.key}</div>
                                                <div className="col-sm-7">{itm.value}</div>
                                            </div>
                                        </li>
                                    )) : ""
                            }
                        </ul>

                        <button className="btn btn-primary mt-3" onClick={() => { animateRoute(); setIsPaused(false); }}>Animate Route</button>
                        <button className="btn btn-warning mt-3" onClick={pauseAnimation}>Pause</button>
                        <button className="btn btn-success mt-3" onClick={resumeAnimation}>Resume</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function loadScript(url) {
    const index = window.document.getElementsByTagName("script")[0];
    const script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

export default MapComponent;
