import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { functionsIn } from "lodash";


import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
//import Grid from '../layouts/gridnosidebarComponent';
import DrawMap from '../common/drawmap';
import { getDecoder, getHyphenDDMMMYYYYHHMM, getHyphenDDMMYYYYHHMMSS } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import redirectURL from '../redirectURL.js';
import DownloadEwayBill from './downloadEwaybill.js';
$.datetimepicker.setLocale('en');

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class EwayBillRequests extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
				enableCellChangeFlash: false
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Eway Bill Requests',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoice_no: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
			addressColumn: 300,
			pdfRowData: [],
			pdfColumns: [],
			tripDate: "",
			bulkslide: "",
			selectedTruck: { "label": "", value: "" },
			markbtn: "Generate New E-Way Bill",
			cancelparams: "",
			inputDetails: [],
			sliderType: 1,
			lastRunTime: "",
			lastRunRowId: "",
			time: {},
			seconds: 120
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.uploadTafeTrip = this.uploadTafeTrip.bind(this);
		this.onCellClicked = this.onCellClicked.bind(this);
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
		this.timer = 0;
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		// loadDateTimeScript();
		this.onLoadPageData();
	};

	onLoadPageData() {
		const user = getDecoder()
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m',
			user: user
		})
		// console.log(parameters);
		// console.log("urlpath ", urlpath)
		var deviceparams = {
			transporter_code: user?.transportercode
		}
		redirectURL.post("/ewaybill/getEwayBillRequests", deviceparams)
			.then(async (response) => {
				var records=[]
				if (response.data.hasOwnProperty("status") && response.data.status != 400)
					records = response.data;
				// var records = [];
				if (records.length > 0) {
					var processed = records[0].status;
					if (processed == "Pending") {
						var lastRunRowId = records[0]._id;
						var lastRunTime = records[0].requsted_date;
						var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
						var d2 = moment.parseZone(new Date(lastRunTime)).format("YYYY-MM-DD HH:mm:ss");
						var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
						var d = moment.duration(ms);
						var difference = Math.floor(d.asMinutes());
						if (difference <= 30) {
							await this.setState({
								lastRunRowId: lastRunRowId,
								lastRunTime: lastRunTime,
							});
							this.countDownTimer();
						}
					}
					else {
						clearInterval(this.timer);
						this.setState({
							seconds: 0,
						})
					}
				}
				console.log("records-tafe", records);
				this.setState({
					rowData: records,
					loadshow: 'show-n',
					overly: 'show-n'
				});
			});
	}

	countDownTimer = async () => {
		var lastRunRowId = this.state.lastRunRowId;
		var lastRunTime = this.state.lastRunTime;
		var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
		var diffreetime = moment.duration(moment(currentDate).diff(startdate));
		var runseconds = Math.round(diffreetime.asSeconds());
		console.log(runseconds, lastRunTime, currentDate, lastRunRowId, "runseconds");
		if (parseInt(runseconds) < 120) {
			$("#timerDiv").show();
			this.startTimer();
		}
		else {
			$("#timerDiv").hide();
		}
	}

	secondsToTime(secs) {
		let hours = Math.floor(secs / (60 * 60));

		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);

		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);

		let obj = {
			"h": hours,
			"m": minutes,
			"s": seconds
		};
		return obj;
	}

	startTimer() {
		if (this.timer == 0 && this.state.seconds > 0) {
			this.timer = setInterval(this.countDown, 1000);
		}
	}

	countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.state.seconds - 1;
		this.setState({
			time: this.secondsToTime(seconds),
			seconds: seconds,
		});
		if (this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90) {
			//console.log(this.state.seconds,"this.state.seconds")
			this.onLoadPageData();
		}
		// Check if we're at zero.
		if (seconds == 0) {
			clearInterval(this.timer);
			$("#timerDiv").hide();
			this.onLoadPageData();
		}
	}

	onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
	};

	onGridState = () => {
		//console.log(this.gridApi);
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}


	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkslide: "",
			selectedTruck: { "label": "", value: "" },
		});

	}

	showGoogleRouteAlert = () => {
		this.setState({
			show: true,
			basicTitle: "No google routes found",
			basicType: "danger"
		});
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	onClickCounterShowDataTEST(params) {
		console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}


	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			() => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			() => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			() => console.log(`Movement Option selected:`, this.state.movementtype)
		);

	}
	selectConsignerOptionsItems() {
		let items = [];
		//console.log(this.state.trucks);
		var consigners = [];
		if (this.state.deptcode == 'SNDG') {
			var keys = ["B", "F", "G", "I", "M", "N"];

			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri", "Manesar", "TVP Nagpur"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-PRT') {
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN", "RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"];

			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore", "RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-TNP') {
			var keys = ["MSIL-G", "MSIL-M", "MSILMC", "MSILME", "SMG"];

			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting", "Power Train", "SMG Gujarat"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}

		//console.log("consigners ", consigners)
		items.push({ value: 'all', label: 'All' });
		consigners.forEach(item => {
			items.push({ value: item.code, label: item.value });
		});

		return items;
	}

	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			endDate: edate
		});
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onClickShowMapView = async () => {
		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/ewaybill/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};
	changeSegmentHandler = (e) => {
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate: "slider-translate-60p",
			segementConsignmentCode: e.consignment_code,
			overly: "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};




	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0] });
		// console.log(val)
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	onCloseUserManualModal = () => {
		this.setState({ usermanualncrmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualncrmodal: true });
	};

	onCloseUserManualMoveModal = () => {
		this.setState({ usermanualmovemodal: false });
	};

	onClickShowUserMoveManaul = () => {
		this.setState({ usermanualmovemodal: true });
	};

	onShowUploadEWay() {
		this.setState({
			slideuploadeway: "slider-translate",
			overly: 'show-m'
		})
	}

	onClickMarkTafe() {
		this.setState({
			overly: "show-m",
			bulkslide: "slider-translate",
			selectedTruck: { "label": "", value: "" }
		})
	}

	resetUpload = () => {
		this.setState({
			slideuploadeway: '',
			overly: 'show-n',
			file: '',
			bulkslide: '',
		});
	}

	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		console.log("IN onShowRouteDiv, /Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		var gateouttime = moment.parseZone(e.trip_start_date).format('YYYY-MM-DD HH:mm:ss');
		var end_date = moment.parseZone(new Date()).format('YYYY-MM-DD HH:mm:ss');

		var reqData = {
			truck_no: e.truck_no,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment",
			user_email: this.state.user.email
		}
		console.log("reqData", reqData);
		redirectURL.post('/ewaybill/maproutesForTafe', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.status == "failure" || records.coords.length == 0) {
						//console.log("records", records.coords);
						if (records.status != undefined) {
							this.setState({
								show: true,
								basicTitle: 'No Route data available',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}

					}
					else {
						if (records != '') {
							var sdate = e.gate_out_time;
							if (e.invoice_time) {
								console.log("came into invoice time", e.invoice_time)
								//console.log("Step1", e.invoice_time)
								var sdate = e.invoice_time;
							}
							else {
								//console.log("Step2")
								if (e.gate_in_time) {
									console.log("came into gate in time", e.gate_in_time)
									//console.log("Step3")
									var sdate = e.gate_in_time;
								}
								else {
									//console.log("Step4")
									if (e.gate_out_time) {
										//console.log("Step5")
										console.log("came into gate out time", e.gate_out_time)
										var sdate = e.gate_out_time;
									}
								}
							}


							var edate = '';
							if (e.status <= 4) {
								//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
								//var edatesecond = new Date(ms);
								end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
								edate = getHyphenDDMMYYYYHHMMSS(end_date);
							}
							else if (e.status == 5) {
								if (e.left_dealer_marked) {
									edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
								}
								else {
									end_date = moment.parseZone().format('DD-MM-YYYY HH:mm:ss')
									// edate = getHyphenDDMMYYYYHHMMSS(end_date);
								}
							}
							//console.log("End Date ", edate)
							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: e.consignee_code,
								consignment_code: "Truck No : " + e.truck_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
								loadshow: 'show-n',
								sidebarSubHeader: "Consignment Information",
								overly: 'show-m',
								rownode: e,
								leg_no: 0,
								tolls: records.tolls
							});
							this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
							this.renderMap();

						}
					}
				}
				catch (e) {
					// this.setState({
					// 	show: true,
					// 	basicTitle:'No Route data available',
					// 	basicType:"danger",
					// 	loadshow:'show-n'
					// });
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = 'Trips';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: this.state.user?.email,
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/ewaybill/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ", windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: this.state.user?.email,
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/ewaybill/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	setTripDate = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		// console.log(value);
		console.log(value)
		this.setState({ tripDate: value });
	}

	onChangeTruckItem(selectedTruck) {
		this.setState(
			{ selectedTruck },
			() => console.log(`Truck Option selected:`, this.state.selectedTruck)
		);
	}
	uploadTafeTrip(event) {
		event.preventDefault();
		var trip_start_date = $("#trip_start_date").val();
		var trip_no = $("#trip_no").val();
		var dealer_code = $("#dealer_code").val();
		var dealer_name = $("#dealer_name").val();
		var truckNo = this.state.selectedTruck;
		var truck_no = "";
		if (truckNo != undefined && truckNo != '') {
			truck_no = truckNo.value;
		}
		if (trip_start_date != '' && trip_start_date != undefined && truck_no != undefined && truck_no != '' && trip_no != undefined && trip_no != '' && dealer_code != undefined && dealer_code != '' && dealer_name != undefined && dealer_name != '') {
			var reqParams = {
				truck_no: truck_no,
				trip_start_date: trip_start_date,
				trip_no: trip_no,
				dealer_code: dealer_code,
				dealer_name: dealer_name,
				userid: this.state.user?.email,
			}
			reqParams['user_name'] = this.state.user?.email;
			reqParams['email'] = this.state.user?.email;
			reqParams['transporter_code'] = this.state.user?.transportercode;
			console.log("reqParams", reqParams)
			redirectURL.post("/ewaybill/markOlaTrips", reqParams).then((response) => {
				var resp = response.data.message;
				console.log(resp)
				document.getElementById("upform").reset();
				if (resp == "Success") {
					this.setState({
						basicTitle: resp,
						basicType: "success",
						show: true,
						bulkslide: '',
						overly: 'show-n',
						selectedTruck: { "label": "", value: "" },
					})
				}
				else {
					this.setState({
						basicTitle: resp,
						basicType: "warning",
						show: true,
						selectedTruck: { "label": "", value: "" }
					})
				}
				this.onLoadPageData();
			}).catch((e) => {
				console.log(e);
			})
		}
		else {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "warning",
				show: true,
			})
		}
	};



	onCellClicked(e) {
		if (e.colDef.field == "download_ewaybill") {
			var status = e.data.status;
			console.log(status)
			if (status == "Success") {
				console.log(status)
				if (e.data.response_from_api != undefined && e.data.response_from_api != "") {
					console.log(e.data.response_from_api);
					if (e.data.response_from_api.url != undefined && e.data.response_from_api.url != "") {
						console.log(e.data.response_from_api.url);
						let a = document.createElement('a');
						a.target = '_blank';
						a.href = "https://" + e.data.response_from_api.url;
						a.click();
					}
				}
			}
		}
		if (e.colDef.field == "viewdetails") {
			var status = e.data.status;
			console.log(status)
			if (status == "Success") {
				console.log(status)
				if (e.data.input_params != undefined && e.data.input_params != "") {
					var ipData = [];
					var inputDetails = JSON.parse(e.data.input_params);
					ipData.push(inputDetails);
					var content = [];
					ipData.map(function (i) {
						var keys = Object.keys(i);
						keys.map(function (k) {
							var hed = k.split("_").join(" ");
							content.push(hed.toUpperCase() + " : " + i[k]);
						})
					})
					console.log(ipData, "ipData")
					this.setState({
						inputDetails: content,
						bulkslide: "slider-translate-60p",
						sidebarSubHeader: "Eway Bill Details",
						sliderType: 2,
					});
				}
			}
		}
		if (e.colDef.field == "cancel") {
			var ewayBillNo = e.data.ewayBillNo;
			var rowId = e.data._id;
			console.log(e.data._id);
			var qry = e.data;
			this.setState({
				cancelparams: qry,
				bulkslide: "slider-translate-30p",
				sliderType: 1,
				loadshow: 'show-n',
				sidebarSubHeader: "Cancel Eway Bill",
				overly: 'show-m',
			});
			//var confirm = confirm("Are You Sure! Do you want really delete?");

		}
		if (e.colDef.field == "partb") {
			var ewayBillNo = e.data.ewayBillNo;
			var rowId = e.data._id;
			console.log(e.data._id);
			var qry = e.data;
			this.setState({
				cancelparams: qry,
				bulkslide: "slider-translate-30p",
				sliderType: 3,
				loadshow: 'show-n',
				sidebarSubHeader: "Update Part B Details",
				overly: 'show-m',
			});
			//var confirm = confirm("Are You Sure! Do you want really delete?");

		}
	}

	cancelEwayBill = (e) => {
		e.preventDefault();
		var qry = this.state.cancelparams;
		qry.eway_bill_number = this.state.cancelparams.ewayBillNo;
		qry.gstin_of_generator = this.state.cancelparams.userGstin;
		qry.reason_of_cancel = $("#reason_of_cancel").val();
		redirectURL.post("/ewaybill/cancelEwayBill", qry).then((response) => {
			console.log(response.data);
			this.setState({
				basicTitle: response.data.message,
				basicType: "success",
				show: true
			})
			window.location.reload();
			//this.onLoadPageData();
		}).catch((e) => {
			console.log(e);
		})
	}

	updatePartBDetails = () => {
		var qry = this.state.cancelparams;
		qry.reason_of_cancel = $("#reason_of_cancel").val();
		redirectURL.post("/ewaybill/cancelEwayBill", qry).then((response) => {
			console.log(response.data);
			if (response.data.message == "Success") {
				this.setState({
					basicTitle: "Deleted Successfully.",
					basicType: "success",
					show: true
				})
				//window.location.reload();
				this.onLoadPageData();
			}
			else {
				this.setState({
					basicTitle: "Failed to Delete.",
					basicType: "warning",
					show: true
				})
			}
		}).catch((e) => {
			console.log(e);
		})
	}

	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		const columnwithDefs =
			[
				{
					headerName: "",
					field: "download_ewaybill",
					width: 50,
					pinned: "left",
					resizable: true,
					cellRendererSelector: function (param) {
						try {
							if (param.data._id != null && param.data._id != undefined) {
								return {
									component: DownloadEwayBill
								}
							}
						} catch (err) {
							console.log(err);
						}
					},
				},
				{
					headerName: "Request Id",
					field: "request_id",
					width: 180,
					pinned: "left",
					resizable: true
				},
				{
					headerName: "Request Type",
					field: "request_type",
					width: 140,
					pinned: "left",
					resizable: true
				},
				{
					headerName: "Status",
					field: "status",
					width: 140,
					pinned: "left",
					resizable: true,
					valueGetter: function (params) {
						if (params.data.status == "No Content") {
							return "Failed";
						}
						else {
							return params.data.status;
						}
					}
				},
				{
					headerName: "Remarks",
					field: "remarks",
					width: 140,
					pinned: "left",
					resizable: true,
					valueGetter: function (params) {
						if (params.data.status != "Success" && params.data.status != "Pending") {
							return params.data.response_from_api;
						}
						else {
							return params.data.status;
						}
					}
				},
				// {
				//     headerName: "Details",
				//     field: "viewdetails",
				//     width: 140,
				//     pinned : "left",
				//     resizable: true
				// },
				{
					headerName: "Requested Date",
					field: "requsted_date",
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						if (params.data.requsted_date != '' && params.data.requsted_date != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.requsted_date);
						}
						else {
							return "NA";
						}
					},
				},
				{
					headerName: "E Way Bill No",
					field: "ewayBillNo",
					width: 150,
					cellRenderer: '', resizable: true
				},
				{
					headerName: "E Way Bill Date",
					field: "ewayBillDate",
					width: 170,
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Valid Up To",
					field: "validUpto",
					width: 170,
					cellRenderer: '', resizable: true
				},
				// {
				//     headerName: "Part B Details",
				//     field: "partb",
				//     width: 170,                 
				//     resizable: true,
				// 	cellRenderer:function(params){
				// 		var htmloption = '<button class="btn btn-info" style="padding-top:0px">Update</button>';
				// 		if(params.data.request_type != "Cancel")
				// 		{
				// 			if(params.data.status == "Success")
				// 			{
				// 				return htmloption;
				// 			}
				// 			else
				// 			{
				// 				return "N/A";
				// 			}
				// 		}
				// 		else
				// 		{
				// 			return "N/A";
				// 		}
				// 	}
				// },
				// {
				//     headerName: "Cancel",
				//     field: "cancel",
				//     width: 170,                 
				//     resizable: true,
				// 	cellRenderer:function(params){
				// 		var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Cancel</button>';
				// 		if(params.data.request_type != "Cancel")
				// 		{
				// 			if(params.data.status == "Success")
				// 			{
				// 				return htmloption;
				// 			}
				// 			else
				// 			{
				// 				return "N/A";
				// 			}
				// 		}
				// 		else
				// 		{
				// 			return "N/A";
				// 		}
				// 	}
				// }
			];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									<span className="layoutbtns pull-right">
										<a className="btn btn-danger" href="/generatenewewaybill">
											{this.state.markbtn}
										</a>
									</span>
									{(this.state.seconds > 0 && this.state.rowData.length > 0) ?
										<span id="timerDiv" style={{ float: "right", marginRight: "2%", marginTop: "8px" }}>Eway Bill Will Generates in - <span style={{ color: "red" }}>0{this.state.time.m} : {this.state.time.s}</span></span>
										: ""}
								</h5>
							</div>
							<div className="card-body pt-10px" >



								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										//suppressCellFlash={true}
										// rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}

										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										// overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked}
									/>

									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>





				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


				<div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">{this.state.sidebarSubHeader}</h3>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							{(this.state.sliderType == 1) ?
								<form method="POST" id="upform" className="theme-form" onSubmit={this.cancelEwayBill.bind(this)}>

									<div className="form-group mt-20p">
										<label>Reason Of Cancel</label>
										<select name="reason_of_cancel" id="reason_of_cancel" placeholder="" className="form-control" required>
											<option value="">Select Reason</option>
											<option value="Duplicate">Duplicate</option>
											<option value="Order Cancelled">Order Cancelled</option>
											<option value="Data Entry Mistake">Data Entry Mistake</option>
											<option value="Others">Others</option>
										</select>
									</div>

									<div className="form-group">
										<button type="submit" className="btn btn-success">Submit</button>
										<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
									</div>
								</form>
								: ""}
							{(this.state.sliderType == 2) ?
								<div className='row col-sm-12'>
									{(this.state.inputDetails.map(function (i) {
										return <div className='col-sm-6'>
											{i}
										</div>
									}))}
								</div>
								: ""}
							{(this.state.sliderType == 3) ?
								<form method="POST" id="partbform" className="theme-form" onSubmit={this.updatePartBDetails}>
									<div className="form-group mt-20p">
										<label>Mode</label>
										<select class="form-control" name="transportation_mode" onChange={this.changeHandler}  >
											<option value="">-- Select Mode --</option>
											<option value="road">Road</option>
											<option value="rail">Rail</option>
											<option value="air">air</option>
											<option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
										</select>
									</div>
									<div className="form-group mt-20p">
										<label>Vehicle Type</label>
										<select class="form-control" name="vehicle_type" id="vehicle_type">
											<option value="">-- Select Vehicle Type --</option>
											<option value="regular">Regular</option>
											<option value="over dimensional cargo">Over Dimensional Cargo</option>
										</select>
									</div>
									<div className="form-group mt-20p">
										<label>Vehicle No : </label>
										<input type="text" name="vehicle_number" id="vehicle_number" className="form-control" />
									</div>
									<div className="form-group mt-20p">
										<label>Transporter Doc. No : </label>
										<input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields" />
									</div>
									<div className={"form-group mt-20p"}>
										<label>Transporter Doc. Date : </label>
										<input type="text" name="transporter_document_date" id="transporter_document_date" className="datetimepicker_date form-control requestFields" onChange={this.changeHandler} />
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-success">Submit</button>
										<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
									</div>
								</form>
								: ""}
						</div>
					</div>
				</div>
			</div>
		);
	}
}




function loadDateTimeScript() {
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format: 'd/m/Y',
		maxDate: true,
		timepicker: false,
	});

	$('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
		format: 'd/m/Y',
		maxDate: true,
		timepicker: false,
	});

	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script, index)
}

