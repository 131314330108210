import React, { Component } from 'react';
import redirectURL from "../../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDecoder } from '../utils';

const user = getDecoder()
class CustomerSupport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            basicTitle: '',
            basicType: 'default',
            show: false,
            name: '',
            user: {},
            favorites: []
        }
    }
    render() {
        let faves = this.state.favorites?.map(each =>
            <li>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        <i className="icofont icofont-favourite" style={{ fontSize: "14px" }} />&nbsp;&nbsp;&nbsp;
                        <a href={each.path} className='fav-link' >{each.name}
                        </a>
                    </div>
                    <i className='fa fa-trash-o' onClick={(e) => this.deleteItem(each._id)} />
                </div>
            </li>
        )
        return (
            <>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <li className="onhover-dropdown">
                    <a className="txt-dark" title='Control Tower Number' onClick={this.showForm}>
                        <i className='icofont icofont-support' />
                    </a>
                    <ul className="notification-dropdown onhover-show-div">
                        <li>Control Tower Number
                        </li>
                        <li>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <i className='fa fa-phone'></i>&nbsp;&nbsp;+91 7386663920
                            </div>
                        </li>
                    </ul>
                </li>
            </>
        );
    }
}

export default CustomerSupport