module.exports = {
    INSIDE_PLANT: "Inside Plant",
    INTRANSIT: "Intransit",
    INTRANSIT_TRANSPORT_YARD: "Intransit Transporter Yard",
    AT_TRANSPORTER_YARD: "At Transporter Yard",
    NEAR_DESTINATION:"Near Destination",
    REACH_DESTINATION:"Reached Destination",
    MANUAL_CLOSE:"Manual Closure",
    FORCE_CLOSE:"Force Closed",
    PROXIMATE_TRIP_CLOSE:"Proximity Trip Closure",
    

    




    

    




}