const columnwithDefs = [
    {
        headerName:"",
        field:"_id",
        type:"actions",
        actionlist:[
            {
                headerName:"",
                field:"",
                type:"icon",
                isPinned:"left",
                actionfor:"map",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"transit_delay",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_transit",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"no_gps",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_gps",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"overspeed",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_overspeed",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"nightdriving",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_nightdriving",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"stoppages",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_stoppages",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"accident",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_accident",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"trip_deviation",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_trip_deviation",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"lsp_deviation",
                type:'icon',
                isPinned:"left",
                actionfor:"kpi_lsp_deviation",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"break_down",
                type:'icon',
                isPinned:"left",
                actionfor:"info_break_down",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"day_trip_distance",
                type:'icon',
                isPinned:"left",
                actionfor:"info_day_trip_distance",
                viewmode:"show",
                width:50,
                editable:false
            },
            {
                headerName:"",
                field:"tolls",
                type:'icon',
                isPinned:"left",
                actionfor:"info_tolls",
                viewmode:"show",
                width:50,
                editable:false
            }
        ]
    },
    {
        headerName:"Vin Number",
        field:"vin_number",
        type:"string",
        isPinned:"no",
        editable:true
    },
    {
        headerName:"Status",
        field:"status",
        type:"string_status",
        isPinned:"left",
        editable:false
    },
    {
        headerName: "Original ETA",
        field: "expected_trip_end",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Actual ETA",
        field: "recent_dealer_reported",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Truck Type",
        field: "vehicle_mode",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Model",
        field: "model",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Variant Number",
        field: "variant_number",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Description",
        field: "description",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Truck No",
        field: "truck_no",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Vin Number",
        field: "vin_number",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Engine Number",
        field: "consignment_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dealer Name",
        field: "dealer_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Ship To Party Code",
        field: "consignee_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Ship To Party Name",
        field: "consignee_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dealer City",
        field: "consignee_city",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dealer State",
        field: "consignee_state",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Invoice No",
        field: "invoice_no",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Invoice Date",
        field: "invoice_time",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Delivery No",
        field: "delivery_no",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dispatch Date",
        field: "gate_out_time",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transit Delay (Days)",
        field: "consignment_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transit Days Difference",
        field: "difference_transit_time",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Duration from Gate Out",
        field: "duration_from_gate_out",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transporter Name",
        field: "transporter_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transporter Code",
        field: "transporter_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Location",
        field: "address",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Travel Status",
        field: "elaped_time_from_last_moved_seconds",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Speed",
        field: "speed",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Travelled Distance (KM)",
        field: "aprox_dist_travelled_from_start",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Total Google Travelled Distance (KM)",
        field: "total_google_distance_travelled_km",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Packet Time",
        field: "timestamp",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Plant Code",
        field: "consigner_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dist. From Destination (km)",
        field: "distance_from_dealer_location",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Dist. Traveled last 24Hr (KM)",
        field: "distance_travelled_in_24_hrs_kms",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Reached Destination At",
        field: "recent_dealer_reported",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Standard Lead Time Day(s)",
        field: "standard_lead_time",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Actual Lead Time Day(s)",
        field: "actual_lead_time",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Reached Plant Gate In",
        field: "locked_dealer_reported",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "OnTime Delivery",
        field: "ontime_status",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transit Time (Days)",
        field: "transit_time",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "No Of Tolls",
        field: "number_of_tolls",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Driver Name",
        field: "tpt_driver_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Driver Mobile No",
        field: "tpt_driver_mobile",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Convoy Leader Name",
        field: "tpt_convoy_leader_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Convoy Leader Mobile No",
        field: "tpt_convoy_leader_mobile",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Double Driver",
        field: "tpt_double_driver",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Dealer Name",
        field: "near_dealer_name",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Dealer Code",
        field: "near_dealer_code",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Dealer Geofence Entry",
        field: "near_dealer_inside_fence",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Last Dealer Geofence Exit",
        field: "near_dealer_outside_fence",
        type:"datetime",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Transporter Remarks",
        field: "transporter_remarks",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Expected Distance (KM)",
        field: "distance_in_km",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "Device ID",
        field: "device_id",
        type:"string",
        isPinned:"no",
        editable:false
    },
    {
        headerName: "",
        field: "device_id",
        type:"button",
        isPinned:"no",
        width:120,
        buttonTitle:"View Data",
        buttonAction:"viewAction",
        editable:false
    },
    {
        headerName: "",
        field: "device_id",
        type:"button",
        isPinned:"no",
        width:150,
        buttonTitle:"View Information",
        buttonAction:"infoAction",
        editable:false
    }
    
];
export {columnwithDefs};