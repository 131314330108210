import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var criticaldata=[]
export default class SndUnloadingColumnChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
            test:"TEstng",
            chart_title : ""
        }
        this.handleDrilldown = this.handleDrilldown.bind(this)

	}
	componentDidMount = async () =>{
		this.setState({
            chart_title : this.props.title
        });
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
	}
	componentWillReceiveProps (newprops){
        if(newprops.title != this.props.dept_code)
        {
            this.setState({
                chart_title : newprops.title
            });
            // console.log("this.props-componentWillReceiveProps ",newprops);
        }
        
    }
    handleDrilldown = (params) => {
        console.log("Here ,", params)
    }
    render(){
        //console.log("this.props.code) ", this.props.code)
    
  
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
            chart: {
                    type: 'column',
                    events: {
                //     click: function(event) {
                //     alert (
                //     'x: '+ Highcharts.dateFormat('%Y-%m-%d', event.xAxis[0].value) +', ' +
                //     'y: '+ event.yAxis[0].value
                //     );
                //  }
                }
            },
            title: {
				text: this.props.title,
				//align:'left'
            },
            subtitle: {
                text: ''
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: this.props.yaxistitle
                }
        
            },
            legend: {
                enabled: false
            },
            // point: {
            //     events:{
            //         click:function(e)
            //         {
            //             alert("test")
            //            return this.handleDrilldown(e)
            //         }
            //     }
            // },
            plotOptions: {
                series: {
                     cursor: 'pointer',
                        point: {
                            events: {
                                click: (event)=> {
                                    this.props.context.onClickGetGridData(this.props.title,event.point.name);
                                    if(this.state.chart_title == "Route wise Avg. Dead Mile (Other OEM) (KM)"){
                                            $("#redmsd").hide();
                                            $("#redmsdGrid").show();
                                    }
                                    if(this.state.chart_title == "Route wise avg Empty Return Trips (To MSIL) (KM)"){
                                            $("#redmsdet").hide();
                                            $("#redmsdetGrid").show();
                                    }
                                    if(this.state.chart_title == "Route wise Avg. Dead Mile Distance (Summation)"){
                                            $("#redmsds").hide();
                                            $("#redmsdsGrid").show();
                                    }
                                    if(this.state.chart_title == "Cumulated Wt. avg. Empty Running (To MSIL + Other OEM) (KM)"){
                                            $("#redmsds").hide();
                                            $("#redmsdsGrid").show();
                                    }
                                    // console.log(this.props,"this.props")
                                    // console.log(this.props.title,"this.props.context")
                                    // console.log(event.point.name,"event.point.name")
                                    
                                    
                                    
                                }
                            }
                        }
                    },
                column: {
                    dataLabels: {
                        enabled: true
                    },
                    stacked: false,
                    enableMouseTracking: true,
                    cropThreshold: 500
                },
                line:{
					dataLabels:{
                        enabled:true,
                        // format:'{point.y}'
					}
				}
            },
        
            tooltip: {
                // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} {(point.unloadtext)}</b></b><br><span style="color:{point.color}">No of trips</span>: <b>{point.trips}</b>'
				formatter:function(){
                    // console.log(this.series)
                    // if(this.point.showTooltip == 1)
                    // {
                        return "<span style='font-size:11px'>"+this.series.name+"</span><br>\
                            <span style='color:{point.color}'>"+this.point.name+"</span>: <b>"+this.point.y+"</b></b><br>"
                    // }
                    // else
                    // {
                    //     return "<span style='font-size:11px'>"+this.series.name+"</span><br>\
                    //         <span style='color:{point.color}'>"+this.point.name+"</span>: <b>"+this.point.y+"</b></b><br>\
                    //         <span style='color:{point.color}'>No of trips</span>: <b>"+this.point.trips+"</b>"
                    // }
                    
				}
			},
        
            series: [
                {
                    name: this.props.seriesName,
                    colorByPoint: true,
                    data: this.props.series
                }
            ],
            drilldown : this.props.drilldownseries,
			credits:false
        };
        // console.log(highchartoptions.drilldown,"drilldown");
        // console.log(highchartoptions.series,"series")
        return ( 
			<div>

               <HighchartsReact
                    containerProps={{ style: { width: '100%', height: '100%' } }}
                    highcharts={Highcharts} 
                    style={dStyles} 
                    options={highchartoptions} 
                    onClick={this.handleDrilldown.bind(this)}
                />
			</div>
			);
		}
}
