import { React, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columnwithDefs } from "./columnDefs_vehicles";
import Constants from "./Masters_constants";
import redirectURL from "../redirectURL";
import SweetAlert from 'react-bootstrap-sweetalert';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import CSVFileValidator from 'csv-file-validator';

import EditButtonRenderer from './EditButtonRenderer';

function Masters() {
    var [rowData, setRowData] = useState([]);
    const defaultColDef = {
        resizable: true,
        // filter: true,
        sortable: true,
        wrapText: true
    };
    var [columnDefs, setColumnDefs] = useState([

        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width: 240,
            filter: true,
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width: 240,
            filter: true,
        },
        {
            headerName: "Created Date",
            field: "created_date",
            width: 220,
            type: "datetime",
            filter: true,
            valueGetter: (params) => {
                if (!params.data.created_date) return '';
                const date = new Date(params.data.created_date);
                return format(date, 'dd-MM-yyyy');
            }
        },
        {
            headerName: "",
            field: "_id",
            width: 90,
            cellRenderer: EditButtonRenderer,
            cellRendererParams: {
                onClickEdit: (data) => showEditBlock(data),
            },
            cellStyle: { display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }
        },
    ]);

    const [showloader, setshowloader] = useState("show-m");
    var [showAddSlider, setshowAddSlider] = useState("");
    const [showBulkUploadSlider, setShowBulkUploadSlider] = useState("");
    var [overlay, setoverlay] = useState("show-n");
    var [sliderText, setSliderText] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [transporter_name, settransportername] = useState("");
    const [transporter_code, settransportercode] = useState("");
    const [currentTransporterId, setCurrentTransporterId] = useState(null); // To store the current editing transporter id

    const [csvContent, setCsvContent] = useState([]);
    const [file, setFile] = useState('');
    const [show, setShow] = useState(false);
    const [basicType, setBasicType] = useState("");
    const [basicTitle, setBasicTitle] = useState("");

    const getCurrentISODate = () => {
        const date = new Date();
        return date.toISOString();
    };

    useEffect(() => {
        // setTimeout(() => {
        //     setshowloader("show-n");
        // }, 2000);
        loadData();
    }, []);

    const loadData = async () => {
        const parameters = {};
        await redirectURL.post('/transporterlist', parameters)
            .then(response => {
                if (response.data.status === "success") {
                    setRowData(response.data.records);
                }
                setshowloader("show-n");
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const showAddBlock = () => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Add Transporter");
        setIsEditMode(false);
        resetForm();
    };

    const showEditBlock = (data) => {
        setshowAddSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Edit Transporter");
        setIsEditMode(true);
        setCurrentTransporterId(data._id);
        settransportername(data.transporter_name);
        settransportercode(data.transporter_code);
    };

    const showBulkUploadBlock = () => {
        setShowBulkUploadSlider("slider-translate-30p");
        setoverlay("show-m");
        setSliderText("Bulk Upload Transporters");
    };

    const resetForm = () => {
        settransportercode("");
        settransportername("");
        setCurrentTransporterId(null);
    };


    const resetUpload = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }

        // setBulkUploadData(null);
    };

    const hideAll = () => {
        const fileInput = document.getElementById("bulkUploadInput");
        if (fileInput) {
            fileInput.value = "";
        }

        resetForm();
        setoverlay("show-n");
        setshowAddSlider("");
        setShowBulkUploadSlider("");
    };

    const saveMasterForm = async (e) => {
        e.preventDefault();
        const createdDate = getCurrentISODate();
        setshowloader("show-m");
        const params = {
            transporter_name: transporter_name,
            transporter_code: transporter_code,
            created_date: createdDate,
        };
        if (isEditMode) {
            params._id = currentTransporterId;
            await redirectURL.post('/updatetransporterdata', params).then((resp) => {
                if (resp.data.status === "success") {
                    hideAll();
                    resetForm();
                    setShow(true);
                    setBasicTitle("Updated data");
                    setBasicType("success");
                    loadData();
                }
            });
        } else {
            await redirectURL.post('/addtransporter', params).then((resp) => {
                if (resp.data.status === "success") {
                    hideAll();
                    resetForm();
                    setShow(true);
                    setBasicTitle("Added data");
                    setBasicType("success");
                    loadData();
                } else if (resp.data.status === "failure") {
                    setshowloader("show-n");
                    setShow(true);
                    setBasicTitle(resp.data.message);
                    setBasicType("danger");
                }
            }).catch(err => {
                setshowloader("show-n");
                console.error('Error:', err);
            });
        }
    };



    // const handleTransporterCode = (e) => {
    //     if (isEditMode) return;

    //     const inputValue = e.target.value;
    //     const validInput = inputValue.replace(/[^0-9A-Z\-._,&#]/g, ''); // Updated regex for validation

    //     if (validInput !== "") {
    //         if (validInput.length <= 10) {
    //             settransportercode(validInput);
    //         } else {
    //             settransportercode(validInput.substring(0, 10));
    //         }
    //     } else {
    //         settransportercode("");
    //     }
    // };




    const handleBulkUploadSubmit = async (e) => {
        e.preventDefault();

        if (csvContent.length > 0) {
            try {
                // Get the current date in dd-mm-yyyy format
                const createdDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');

                // Add the created_date to each item in the csvContent array
                const updatedCsvContent = csvContent.map(item => ({
                    ...item,
                    created_date: createdDate,
                }));

                const response = await redirectURL.post('/bulkuploadtransporter', updatedCsvContent, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('userid'),
                    },
                });

                if (response.data.message === 'success') {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle("Bulk Upload Successful");
                    setBasicType("success");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
                else if (response.data.status === "partial") {
                    document.getElementById('bulkUploadInput').value = '';
                    hideAll();
                    resetForm();
                    setBasicTitle(`${response.data.uploadedCount} Records uploaded out of ${response.data.totalRecords}`);
                    setBasicType("warning");
                    loadData();
                    setCsvContent([]);
                    setFile('');
                    setShow(true);

                    setTimeout(() => {
                        loadData();
                    }, 1000);
                }
            } catch (error) {
                console.error('Error during bulk upload:', error);
                setShow(true);
                setBasicTitle('Bulk Upload Error');
                setBasicType('danger');
            }
        } else {
            setShow(true);
            setBasicTitle('Cannot enter empty data');
            setBasicType('danger');
        }
    };


    const handleBulkUpload = async (e) => {
        if (e.target.files.length !== 0) {
            const config = {
                headers: [

                    {
                        name: 'transporter_name',
                        inputName: 'transporter_name',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    },
                    {
                        name: 'transporter_code',
                        inputName: 'transporter_code',
                        required: true,
                        requiredError: (headerName, rowNumber, columnNumber) =>
                            `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
                    }

                ],
            };

            const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- _]*$/); // Allowed characters

            try {
                const csvData = await CSVFileValidator(e.target.files[0], config);
                console.log('csvData', csvData);

                let keys = Object.keys(csvData.data[0]);
                let items = ['transporter_name', 'transporter_code'];
                let check = csvData.inValidData.length === 0 && items.every(item => keys.includes(item));

                console.log(check, "check")



                if (!check) {
                    e.target.value = ""; // Clear file input
                    setShow(true);
                    setBasicTitle('Dont upload other fields or special characters');
                    setBasicType('danger');
                } else {
                    setCsvContent(csvData.data);
                }
            } catch (err) {
                console.error('Error validating CSV:', err);
            }

            const reader = new FileReader();
            reader.onload = async (e) => {
                const contents = e.target.result;
                console.log(contents);
            };
            reader.readAsText(e.target.files[0]);

            setFile(e.target.files[0]);

            if (e.target.files[0].type !== 'text/csv' && e.target.files[0].type !== 'application/vnd.ms-excel') {
                e.target.value = null;
                setShow(true);
                setBasicType('danger');
                setBasicTitle('Please upload file having extensions .csv only.');
            }
        }
    };






    // const handleBulkUpload = async (e) => {
    //     const files = e.target.files;

    //     if (files.length !== 0) {
    //         const config = {
    //             headers: [
    //                 {
    //                     name: 'transporter_name',
    //                     inputName: 'transporter_name',
    //                     required: true,
    //                     requiredError: (headerName, rowNumber, columnNumber) =>
    //                         `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
    //                 },
    //                 {
    //                     name: 'transporter_code',
    //                     inputName: 'transporter_code',
    //                     required: true,
    //                     requiredError: (headerName, rowNumber, columnNumber) =>
    //                         `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
    //                 }
    //             ],
    //         };

    //         const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- _]*$/); // Allowed characters

    //         let allFilesValid = true;

    //         for (let file of files) {
    //             try {
    //                 const csvData = await CSVFileValidator(file, config);
    //                 console.log('csvData', csvData);

    //                 let keys = Object.keys(csvData.data[0]);
    //                 let items = ['transporter_name', 'transporter_code'];
    //                 let check = csvData.inValidData.length === 0 && items.every(item => keys.includes(item));

    //                 console.log(check, "check");

    //                 if (!check) {
    //                     allFilesValid = false;
    //                     break;
    //                 }

    //                 const csvaluesarr = Object.values(csvData.data);
    //                 for (let row of csvaluesarr) {
    //                     let csvalues = Object.values(row);
    //                     if (csvalues.some(value => !aplhanumeichypen.test(value))) {
    //                         allFilesValid = false;
    //                         break;
    //                     }
    //                 }

    //                 if (!allFilesValid) {
    //                     break;
    //                 }
    //             } catch (err) {
    //                 console.error('Error validating CSV:', err);
    //                 allFilesValid = false;
    //                 break;
    //             }

    //             const reader = new FileReader();
    //             reader.onload = async (e) => {
    //                 const contents = e.target.result;
    //                 console.log(contents);
    //             };
    //             reader.readAsText(file);

    //             setFile(file);

    //             if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
    //                 allFilesValid = false;
    //                 break;
    //             }
    //         }

    //         if (!allFilesValid) {
    //             e.target.value = ""; // Clear file input
    //             setShow(true);
    //             setBasicTitle('Please upload valid CSV files only.');
    //             setBasicType('danger');
    //         } else {
    //             setCsvContent([...csvContent, ...csvData.data]);
    //         }
    //     }
    // };



    return (
        <div>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={() => {
                    setShow(false);
                }}>
            </SweetAlert>
            <div className="col-lg-12 d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                <div style={{ display: "flex" }}><i className="fa fa-truck cus-i"></i>&nbsp;&nbsp;<span style={{ fontWeight: "600" }}>Transporter Master</span></div>
                <div>
                    {
                        Constants.VEHICLES.BTN_ADD === 1 ? (
                            <button className="btn btn-warning" onClick={showAddBlock}>
                                <i className="icon-plus"></i>&nbsp;Add Transporter
                            </button>
                        ) : ""
                    }
                    <button
                        className="btn btn-success ml-2"
                        onClick={showBulkUploadBlock} // Open the bulk upload slider
                    >
                        <i className="icon-upload"></i>&nbsp;Bulk Upload
                    </button>
                </div>
            </div>
            <div className="container-fluid mt-20p">
                <div className="row">
                    <div className="col-xl-12 col-md-12 xl-60">
                        <div className="card">
                            <div className="card-body">

                                <div className={"ag-theme-balham mt-8"} style={{ height: '500px', width: '100%', paddingTop: "1rem" }}>
                                    <AgGridReact
                                        columnDefs={columnDefs}
                                        rowData={rowData}
                                        // enableAdvancedFilter={true} 
                                        defaultColDef={defaultColDef}
                                        pagination={true}
                                        paginationPageSize={50}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        enableRangeSelection={true}
                                        enableCellContextMenu={true}
                                        sideBar={{
                                            toolPanels: [
                                                {
                                                    id: "columns",
                                                    labelDefault: "Columns",
                                                    labelKey: "columns",
                                                    iconKey: "columns",
                                                    toolPanel: "agColumnsToolPanel",
                                                    toolPanelParams: {
                                                        suppressPivotMode: true,
                                                        suppressRowGroups: true,
                                                        suppressValues: true,
                                                    }
                                                },
                                                {
                                                    id: "filters",
                                                    labelDefault: "Filters",
                                                    labelKey: "filters",
                                                    iconKey: "filter",
                                                    toolPanel: "agFiltersToolPanel"
                                                }
                                            ]
                                        }}
                                        statusBar={{
                                            statusPanels: [
                                                {
                                                    statusPanel: "agTotalAndFilteredRowCountComponent",
                                                    align: "left"
                                                },
                                                {
                                                    statusPanel: "agTotalRowCountComponent",
                                                    align: "center"
                                                },
                                                { statusPanel: "agFilteredRowCountComponent" },
                                                { statusPanel: "agSelectedRowCountComponent" },
                                                { statusPanel: "agAggregationComponent" }
                                            ]
                                        }}
                                    />
                                    <div className={"dataLoadpage " + (showloader)}>
                                    </div>
                                    <div className={"dataLoadpageimg " + (showloader)}>
                                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Overlay Part */}
            <div
                className={"overlay-part " + overlay}
                onClick={hideAll}
            ></div>

            {/* Add/Edit Transporter Slider */}
            <div
                className={"slide-r " + showAddSlider}
                onClick={(e) => e.stopPropagation()} // Prevent click inside slider from closing it
            >
                <div className="slide-r-title">
                    <h4 className="">
                        {sliderText}
                        <span
                            className="float-right closebtn"
                            style={{ marginRight: "25px" }}
                            onClick={hideAll}
                        >
                            X
                        </span>
                    </h4>
                </div>
                <div className="slide-r-body">
                    <form className="" style={{ padding: "20px" }} onSubmit={saveMasterForm}>
                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Name
                            </label>
                            <input
                                type="text"
                                name="transporter_name"
                                autoComplete="off"
                                value={transporter_name}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                    settransportername(capitalizedValue);
                                }}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label style={{ display: "block", marginBottom: "5px" }}>
                                <span style={{ color: "#EB1D54", fontSize: "13px" }}>*</span>&nbsp;Transporter Code
                            </label>
                            <input
                                type="text"
                                autoComplete='off'
                                name="transporter_code"
                                id="transporter_code"
                                value={transporter_code}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const validInput = inputValue.replace(/[^0-9]/g, '');

                                    if (!isEditMode) {
                                        settransportercode(validInput);
                                    }
                                }}
                                required
                                disabled={isEditMode}
                                style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f9f9f9",
                                }}
                            />
                            {isEditMode && (
                                <p style={{ color: "#EB1D54", fontSize: "11px", marginTop: "5px" }}>
                                    *Transporter Code cannot be changed
                                </p>
                            )}
                        </div>


                        <div className="form-group" style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-success"
                                style={{
                                    padding: "10px 20px",
                                    border: "none",
                                    borderRadius: "4px",
                                    backgroundColor: "#28a745",
                                    color: "white",
                                }}
                            >{isEditMode ? 'Update' : 'Add'} Transporter</button>
                        </div>

                    </form>
                </div>
            </div>

            {/* Bulk Upload Slider */}
            <div className={`slide-r ${showBulkUploadSlider}`} onClick={(e) => e.stopPropagation()}>
                <div className="slide-r-title">
                    <h4>
                        {sliderText}
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={hideAll}>X</span>
                    </h4>
                </div>
                <div className="slide-r-body">
                    <div style={{ padding: "20px" }}>

                        <form method="POST" id="upform" className="theme-form" onSubmit={handleBulkUploadSubmit}>
                            <div className="form-group">
                                <label className="">Upload File</label>
                                <input type="file" id="bulkUploadInput" onChange={handleBulkUpload} className="form-control" required />
                                <div style={{ color: "#ff0000", marginTop: "10px" }}>
                                    {/* ** Allowed Space and Special Characters are <span style={{ fontWeight: "700", fontSize: "18px", fontFamily: "arial" }}>-._,&#</span> */}
                                </div>

                            </div>

                            <div className="form-group">
                                <button className="btn btn-success">Submit</button>
                                <button type="button" onClick={resetUpload} className="btn btn-default">Cancel</button>
                            </div>

                        </form>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../assets/json/bulk_master_transporter.csv')} target="_blank">Sample Template</a>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Masters;
