
import redirectURL from "../redirectURL";
import DatePicker from 'react-datepicker'
import Select from 'react-select';
import moment from "moment";
import { useRef, useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

const Filters = (props) => {

    var filterCardStyle1 = { minHeight: "244px" };
    var filterCardStyle2 = { padding: '10px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    var filterClassName = "form-group col-xl-12 col-lg-12";

    const reloadPage = useRef(false);
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState('')
    const [type, setType] = useState('default')
    const [searchCity, setSearchCity] = useState({ label: '', value: '' });
    const [searchState, setSearchStates] = useState({ label: '', value: '' });
    const [searchTransporters, setSearchTransporters] = useState({ label: '', value: '' });
    const [searchConsigner, setSearchConsigner] = useState([{ label: 'All', value: 'all' }]);
    const [searchValue, setSeachValue] = useState('');
    const [searchType, setSearchType] = useState({ label: "Vehicle", value: "truck_no" });
    const [startDate, setStartDate] = useState(new Date(moment.parseZone().subtract(10, "days")));
    const [endDate, setEndDate] = useState(new Date(moment.parseZone()));

    const getTruckConsignments = async (e) => {
        e.preventDefault();
        let params = {
            searchtype: searchType?.value,
            searchnumber: searchValue
        }
        if (props.user.role[0] == "DEALER")
            params["user_code"] = props.user.dealer_code ?? "EMPTY"
        await redirectURL.post("consignments/getConsignments", params)
            .then(resp => {
                props.fragmentData(resp)
            })
            .catch(err => console.log(err))
    }
    const onConfirm = () => {
        setShow(false);
        setTitle('')
        setType('default')
        if (reloadPage.current && reloadPage.current == 4) {
            setTimeout(async () => {
                await props.columnApiConsignments.current.applyColumnState({
                    state: props.gridColumnState,
                    applyOrder: true,
                })
            }, 1000);
        }
        else if (reloadPage.current && reloadPage.current != 4) {
            window.location.reload()
        }
    };

    const handleStartDateChange = (date) => {
        if (endDate && date > endDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setStartDate(date)
        }
    };

    const handleEndDateChange = (date) => {
        if (startDate && date < startDate) {
            setShow(true)
            setTitle("To Date should be greater than From Date")
            setType("danger")
        } else {
            setEndDate(date)
        }
    };

    const resetSearch2 = (e) => {
        setSearchCity({ label: '', value: '' })
        setSearchStates({ label: '', value: '' })
        setSearchTransporters({ label: '', value: '' })
        setSearchConsigner([{ label: 'All', value: 'all' }])
        setStartDate(new Date(moment.parseZone().subtract(10, "days")))
        setEndDate(new Date(moment.parseZone()))
        props.loadConsignmentsData()
    }

    const resetSearch1 = (e) => {
        setSeachValue('')
        setSearchType({ label: "Vehicle", value: "truck_no" })
        props.loadConsignmentsData()
    }

    const onGetConsignments = async (event) => {
        let query = {}
        if (searchCity.value != '')
            query["consignee_city"] = searchCity.value
        if (startDate == '' || startDate == "NaN-NaN-NaN") {
            var sDate = "";
        }
        else {
            query["sdate"] = moment.parseZone(new Date(startDate)).format("YYYY-MM-DD")
        }
        if (endDate == '' || startDate == "NaN-NaN-NaN") {
            var eDate = "";
        }
        else {
            query["edate"] = moment.parseZone(new Date(endDate)).format("YYYY-MM-DD")
        }
        if (searchConsigner == null || searchConsigner[0]?.value == '' || searchConsigner[0]?.value == 'all') {
        }
        else {
            query["consigner_code"] = searchConsigner.map(e => e.value)
        }
        if (searchState.value != '') {
            query["consignee_state"] = searchState.value
        }
        if (searchTransporters.value != "") {
            query["transporter_name"] = searchTransporters.value
        }
        props.setOverlay("show-m")
        props.setLoadShow("show-m")
        if (props.user.role[0] == "DEALER")
            query["user_code"] = props.user.dealer_code ?? "EMPTY"
        await redirectURL.post("consignments/filterConsignments", query)
            .then(resp => {
                props.setOverlay("show-n")
                props.setLoadShow("show-n")
                props.fragmentData(resp)
            })
            .catch(err => console.log(err))
    }

    return (
        <>

            <SweetAlert
                show={show}
                title={<span style={{ fontSize: "1rem" }}>{title}</span>}
                onConfirm={onConfirm}
                confirmBtnBsStyle={type}
                confirmBtnCssClass="sweetButton"
                customClass='sweetWidth'
                type={type}>
            </SweetAlert>
            <div className={props.filterToggle ? "row" : "row"}>
                <div className={"col-xl-4 col-lg-4 "}>
                    <div className="card" style={filterCardStyle1}>
                        <div className="row card-body" style={{ padding: "10px 30px" }}>
                            <form className="row" onSubmit={getTruckConsignments}>
                                <div className={filterClassName}>
                                    <label>Select Type  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedFilter"
                                        value={searchType}
                                        onChange={e => setSearchType(e)}
                                        style={{ borderRadius: "0px" }}
                                        options={[
                                            { label: "Vehicle", value: "truck_no" },
                                            { label: "FO Number", value: "fo_number" },
                                        ]} required />
                                </div>
                                <div className={filterClassName}>
                                    <label className="reason">Vehicle / FO Number</label>
                                    <input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" value={searchValue} onChange={e => setSeachValue(e.target.value)} required />
                                </div>
                                <div className="col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
                                    <button type="submit" className="btn btn-success">Submit</button>&nbsp;
                                    <button type="button" className="btn btn-warning" onClick={resetSearch1}>Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"col-xl-8 col-lg-8"}>
                    <div className="card" style={filterCardStyle1}>
                        <div className="row card-body" style={filterCardStyle2}>
                            <div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
                                <div className="form-group col-xl-4 col-lg-4">
                                    <label>Select Plant / WH </label>
                                    <Select placeholder={"Select "} closeMenuOnSelect={false}
                                        value={searchConsigner}
                                        isMulti={true}
                                        className="border-radius-0"
                                        onChange={e => setSearchConsigner(e)}
                                        style={{ borderRadius: "0px" }}
                                        options={props.consignersList}
                                        required />

                                </div>
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <div className=""><label>Invoice Date From</label></div>
                                    <div className="">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            name="startDate"
                                            autoComplete="off"
                                            dateFormat="dd/MM/yyyy"
                                            className="datepicker-custom"
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <div className=""> <label>Invoice Date To</label></div>
                                    <div className="">
                                        <DatePicker
                                            selected={endDate}
                                            autoComplete="off"
                                            onChange={handleEndDateChange}
                                            name="endDate"
                                            dateFormat="dd/MM/yyyy"
                                            className="datepicker-custom"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
                                {/* <div className="form-group col-xl-4 col-lg-4">
                                    <label>Consignment City   </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedCity"
                                        style={{ borderRadius: "0px" }}
                                        value={searchCity}
                                        onChange={e => setSearchCity(e)}
                                        options={props.filterCities}
                                    />
                                </div>
                                <div className="form-group col-xl-4 col-lg-4">
                                    <label>Consignment State</label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedState"
                                        style={{ borderRadius: "0px" }}
                                        value={searchState}
                                        onChange={e => setSearchStates(e)}
                                        options={props.filterStates}
                                    />
                                </div> */}
                                <div className="form-group col-xl-4 col-lg-4">
                                    <label>Transporter  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={false}
                                        id="selectedTransporter"
                                        style={{ borderRadius: "0px" }}
                                        value={searchTransporters}
                                        onChange={(e) => setSearchTransporters(e)}
                                        options={props.filterTransporters}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
                                <button type="button" className="btn btn-success" onClick={onGetConsignments}>Submit</button>&nbsp;
                                <button type="button" className="btn btn-warning" onClick={resetSearch2}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Filters