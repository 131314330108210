import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';


var criticaldata = []
export default class LineLoadingTimeSlot extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showgrid: 'display:none',
			showtransgrid: 'display:none',
			rowdata: [],
			test: "TEstng"
		}

	}
	componentDidMount = async () => {

	}
	componentWillReceiveProps(newprops) {
		if (newprops.title != this.props.dept_code) {
			//console.log("newprops ",newprops);
		}
	}
	hideGrid(params) {
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params) {
		hideTransGrid(params)
	}

	render() {
		//console.log("this.props.code) ", this.props.code)


		const dStyles = {
			width: '100%',
			height: '100%'
		}
		const highchartoptions = {
			chart: {
				type: 'spline',
				spacingBottom: 20
			},
			title: {
				text: this.props.title
			},

			subtitle: {
				text: ''
			},

			yAxis: {
				title: {
					text: this.props.yaxistitle
				}
			},

			xAxis: {
				categories: this.props.categories,
				title: {
					text: this.props.xaxistitle
				}
				// accessibility: {
				//     rangeDescription: 'Range: 2010 to 2017'
				// }
			},

			legend: {
				//layout: 'vertical',
				align: 'center',
				verticalAlign: 'bottom',
				y: 0
			},

			plotOptions: {
				series: {

					tooltip: {
						headerFormat: '<b>{series.name}</b><br>',
						pointFormat: '{point.value} : {point.y:.2f}',
					},
					// pointStart: 2010
				}
			},
			tooltip: {
				formatter: function () {
					//console.log("this.series.name ", this.series)
					return this.x + ":<br> <b style='color:" + this.series.color + ";font-weight:bold;'>" + this.series.name + " - " + this.y + "</b>";
				}
			},
			// tooltip: {
			// 	formatter: function () {
			// 		var point = this.point;
			// 		console.log("Pints ", point	)
			// 		// return this.point.reduce(function (s, point) {
			// 		// 	return s + '<br/>' + point.series.name + ': ' +
			// 		// 		point.y + ' Hrs';
			// 		// }, '<b>' + this.x + '</b>');
			// 	},
			// 	shared: true
			// },
			series: this.props.series,

			responsive: {
				rules: [{
					condition: {
						maxWidth: 500
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}]
			},
			credits: false
		};

		return (
			<div>
				<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
		);
	}
}


function hideGrid(id) {
	console.log("id ", id)
	//$(".grid-"+id).fadeOut()
}

function hideTransGrid(id) {
	console.log("id ", id)
	//$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}

function groupBy(list, keyGetter) {
	const map = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
		return (p < v ? p : v);
	});
}

function arrayMax(arr) {
	return arr.reduce(function (p, v) {
		return (p > v ? p : v);
	});
}

// function getData(){
// 	alert("cons")
// }
