const TransitDelay=(props)=>{
    const handleClick = (params) => {
        props.onShowTransitData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#FF5370"
    }
    return (
        <a onClick={handleClick}  title="Transit Delay"><i className="icofont icofont-clock-time f20" style={{ color: color }}></i> </a>
);
}
export default TransitDelay