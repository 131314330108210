import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDecoder, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import redirectURL from '../redirectURL';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker'
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
// var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class ConsolidateEwayBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            csvcontent: "",
            file: "",
            loadshow: 'show-n',
            overly: 'show-n',
            uploadYard: "",
            uploadDispatchDate: "",
            rowData: "",
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            bulkslide: "",
            benefitCert: "",
            date: "",
            deptName: "",
            indenterName: "",
            mobile: "",
            indenterEmail: "",
            erpPO: "",
            erpPoNo: [],
            erpPoDate: [],
            ringiNo: "",
            ringiDesc: "",
            cargoDeliveryPlant: "",
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight: "",
            supplierName: "",
            supplierAddr: "",
            supplierCnctPerson: "",
            country: "",
            emailID: "",
            phone: "",
            deliveryTerms: "Ex Works",
            pickUpAddr: "",
            transporter_document_date: "",
            LC: "",
            LCno: "",
            nature: "",
            freeTrade: "",
            benefitCert: "",
            disableERP: "disabled",
            disableLC: "disabled",
            deliveryLabel: "Pick Up Address",
            airportName: "",
            port: "",
            erpPo_date: "",
            cargo_date: "",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest: "activet",
            prepaid: "",
            track: "",
            requestData: "show-m",
            trackData: "show-n",
            trackResData: 'show-n',
            ffsuppliers: [],
            requestFields: "show-m",
            prepaidFields: "show-n",
            addresstxt: "",
            request_type: 1,
            pagetitle: "Consolidate E-Way Bill Form",
            supply_type: "",
            sub_supply_type: "",
            transaction_type: "",
            document_type: "",
            document_number: "",
            document_date: "",
            legal_name_of_consignor: "",
            gstin_of_consignor: "",
            address1_of_consignor: "",
            address2_of_consignor: "",
            pincode_of_consignor: "",
            place_of_consignor: "",
            state_of_consignor: "",
            legal_name_of_consignee: "",
            gstin_of_consignee: "",
            address1_of_consignee: "",
            address2_of_consignee: "",
            pincode_of_consignee: "",
            place_of_consignee: "",
            state_of_supply: "",
            itemList: "",
            taxable_amount: "",
            cgst_amount: "",
            sgst_amount: "",
            igst_amount: "",
            cess_amount: "",
            cess_nonadvol_value: "",
            other_value: "",
            total_invoice_value: "",
            transporter_name: "",
            transporter_id: "",
            transportation_distance: "",
            transportation_mode: "",
            vehicle_type: "",
            vehicle_number: "",
            transporter_document_number: "",
            transporter_document_date: "",
            showOthers: "show-n",
            transporter_gstin: "",
            user: {}
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.fetchEwayBillDetails = this.fetchEwayBillDetails.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    // logPageView = () => {
    //     try {
    //         if (googleAnalytics.page.enableGA) {
    //             googleAnalytics.logPageView();
    //             let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    //             let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
    //             let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
    //             this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
    //         } else {
    //             console.log("GA disabled...");
    //         }
    //     } catch(error) {
    //         console.error("Error occured while logging to GA, error = ", error);
    //     }
    // }
    componentDidMount() {
        // this.logPageView(); // Log page view to GA
        const user = getDecoder()
        var params = {
            transporter_code: user.transportercode
        }
        redirectURL.post("/ewaybill/getTransporterGSTDetails", params).then((response) => {
            var resp = response.data;
            if (resp.length > 0) {
                this.setState({
                    transporter_gstin: resp[0].gstin,
                    user: user
                });
            }
        }).catch((e) => {
            console.log(e);
        })
        // loadDateTimeScript();        
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: ''
        });

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
        //window.location.href="/tptlogin/ewaybillrequests";
    }

    fetchEwayBillDetails = (e) => {
        var fieldName = e.target.name;
        var eway_bill_number = e.target.value;
        console.log(eway_bill_number, "eway_bill_number")
        var gstin_of_generator = this.state.transporter_gstin;
        if (gstin_of_generator != "" && gstin_of_generator != undefined && eway_bill_number != undefined && eway_bill_number != "") {
            var params = {
                eway_bill_number: eway_bill_number,
                gstin_of_generator: gstin_of_generator,
            }
            redirectURL.post("/ewaybill/fecthDetailsByEwayBillNumber", params).then((response) => {
                if (response.data.message == "success") {
                    var ewayBillData = response.data.ewaybilldata;
                    var array = fieldName.split("_");
                    var rowNumber = array[array.length - 1];
                    $("#eway_bill_date_" + rowNumber).val(ewayBillData.eway_bill_date);
                    $("#generated_by_" + rowNumber).val(ewayBillData.userGstin);
                    $("#inv_no_" + rowNumber).val(ewayBillData.document_number + " " + ewayBillData.document_date);
                    $("#total_invoice_value_" + rowNumber).val(ewayBillData.total_invoice_value);
                    $("#eway_bill_valid_date_" + rowNumber).val(ewayBillData.eway_bill_valid_date);
                }
                else {
                    this.setState({
                        basicTitle: response.data.message,
                        basicType: "warning",
                        show: true,
                    });
                }
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    changeHandler = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        console.log(name);
        let value = event.target.value;
        this.setState({ [name]: value });
        if (name == "sub_supply_type") {
            if (value == "Others") {
                this.setState({ showOthers: "show-m" });
            }
            else {
                this.setState({ showOthers: "show-n" });
            }
        }
        if (name == "transportation_mode") {
            if (value == "road") {
                $("#vehicle_type").removeAttr("disabled", true);
                $("#vehicle_number").removeAttr("disabled", true);
                $("#trn_doc_no").html("Transporter Doc. No");
            }
            else if (value == "ship or ship cum road/rail") {
                $("#vehicle_type").attr("disabled", true);
                $("#vehicle_number").removeAttr("disabled", true);
                $("#trn_doc_no").html("Bill of lading No");
            }
            else if (value == "air") {
                $("#vehicle_type").attr("disabled", true);
                $("#vehicle_number").attr("disabled", true);
                $("#trn_doc_no").html("Airway Bill No");
            }
            else {
                $("#vehicle_type").attr("disabled", true);
                $("#vehicle_number").attr("disabled", true);
                $("#trn_doc_no").html("RR No");
            }
        }
    }
    dateValidation = (currentDate) => {
        return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
    };
    changeFileHandler = async (e) => {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.files[0] });
    }
    formHandler(event) {
        event.preventDefault();
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var transportercode = this.state.user?.transportercode;
        var gstin_of_generator = this.state.transporter_gstin
        var params = {
            transporter_code: transportercode[0],
            gstin_of_generator: gstin_of_generator
        };
        params.place_of_consignor = this.state.place_of_consignor;
        //params.state_of_consignor = this.state.state_of_consignor;
        params.state_of_consignor = $("#state_of_consignor option:selected").text();
        var itemsLength = $("#itemsLength").val();
        var itemList = [];
        for (var i = 0; i < itemsLength; i++) {
            var eway_bill_number = $("#eway_bill_number_" + i).val();
            if (eway_bill_number != '') {
                var erptemp = { "eway_bill_number": eway_bill_number }
                itemList.push(erptemp)
            }
        }
        params.list_of_eway_bills = JSON.stringify(itemList);
        params.transportation_mode = this.state.transportation_mode;
        params.vehicle_number = this.state.vehicle_number;
        params.transporter_document_number = this.state.transporter_document_number;
        params.transporter_document_date = moment.parseZone(new Date(this.state.transporter_document_date)).utcOffset("+05:30").format("DD/MM/YYYY");
        params.data_source = "erp";

        redirectURL.post("/ewaybill/consolidateEwayBill", params).then((response) => {
            this.setState({
                basicTitle: response.data.message,
                basicType: "success",
                show: true,
                loadshow: 'show-n',
                overly: 'show-n',
            });
            window.location.href = "/consolidateewaybillrequests";
        }).catch((e) => {
            console.log(e);
        })

    }

    handleAddRow = () => {
        const item = {
            erpPoNo: "",
            erpPoDate: "",
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    };
    handleRemoveRow = (idx) => {
        this.state.rows.splice(idx, 1);
        this.setState({ rows: this.state.rows });
    };

    handleAddRingiRow = () => {
        const item = {
            ringi_file: "",
            ringiDesc: "",
        };
        this.setState({
            ringirows: [...this.state.ringirows, item]
        });
    };
    handleRemoveRingiRow = (idx) => {
        this.state.ringirows.splice(idx, 1);
        this.setState({ ringirows: this.state.ringirows });
    };

    packetsHandler = (e) => {
        var noOfPackets = e.target.value;
        if (parseInt(noOfPackets) >= 1) {
            var lespcks = parseInt(noOfPackets) - 1;
            var bhanu = [{}];
            for (var n = 0; n < lespcks; n++) {
                const item = {
                    itemDesc: "",
                    grossWt: "",
                    dimensions: "",
                    cargo_chargeable_weight: "",
                };
                this.setState({
                    packetrows: [...bhanu, item]
                });
                var bhanu = [...bhanu, item];
            }
        }
        else {
            alert("No of packets should be greater than 0");
            $("#no_of_packets").val(1);
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight: "",
            };
            this.setState({
                packetrows: [item]
            });
        }
    }
    render() {

        const headStyle = {
            marginBottom: "1.5%"
        }
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <h5 className="col-xl-12 col-lg-12">
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span style={{ fontWeight: "600" }}>{this.state.pagetitle}</span>
                </h5>
                <div className="row">
                    <div className={"col-xl-12 col-lg-12 " + this.state.requestData}>
                        <div className="card">
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Place</label>
                                            <input type="text" name="place_of_consignor" placeholder="" className="form-control requestFields" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12"><label style={{ color: "#EB1D54", fontSize: "13px", marginBottom: "0px" }}>*</label>&nbsp;State
                                            </label>
                                            <select name="state_of_consignor" id="state_of_consignor" placeholder="" className="form-control requestFields" onChange={this.changeHandler} required>
                                                <option value="">Select State</option>
                                                <option value="35">ANDAMAN AND NICOBAR</option>
                                                <option value="28">ANDHRA PRADESH</option>
                                                <option value="12">ARUNACHAL PRADESH</option>
                                                <option value="18">ASSAM</option>
                                                <option value="10">BIHAR</option>
                                                <option value="4">CHANDIGARH</option>
                                                <option value="22">CHHATTISGARH</option>
                                                <option value="26">DADAR AND NAGAR HAVELI</option>
                                                <option value="25">DAMAN AND DIU</option>
                                                <option value="7">DELHI</option>
                                                <option value="30">GOA</option>
                                                <option value="24">GUJARAT</option>
                                                <option value="6">HARYANA</option>
                                                <option value="2">HIMACHAL PRADESH</option>
                                                <option value="1">JAMMU AND KASHMIR</option>
                                                <option value="20">JHARKHAND</option>
                                                <option value="29">KARNATAKA</option>
                                                <option value="32">KERALA</option>
                                                <option value="31">LAKSHADWEEP</option>
                                                <option value="23">MADHYA PRADESH</option>
                                                <option value="27">MAHARASHTRA</option>
                                                <option value="14">MANIPUR</option>
                                                <option value="17">MEGHALAYA</option>
                                                <option value="15">MIZORAM</option>
                                                <option value="13">NAGALAND</option>
                                                <option value="21">ORISSA</option>
                                                <option value="34">PONDICHERRY</option>
                                                <option value="3">PUNJAB</option>
                                                <option value="8">RAJASTHAN</option>
                                                <option value="11">SIKKIM</option>
                                                <option value="33">TAMIL NADU</option>
                                                <option value="36">TELANGANA</option>
                                                <option value="16">TRIPURA</option>
                                                <option value="9">UTTAR PRADESH</option>
                                                <option value="5">UTTARAKHAND</option>
                                                <option value="19">WEST BENGAL</option>
                                                <option value="97">OTHER TERRITORY</option>
                                                <option value="99">OTHER COUNTRY</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="">
                                        {this.state.rows.map((item, idx) => (
                                            <div className="row col-xl-12" key={idx}>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Eway Bill Number</label>
                                                    <input type="text" onChange={this.fetchEwayBillDetails} name={"eway_bill_number_" + idx} id={"eway_bill_number_" + idx} className="form-control" required />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Eway Bill Date</label>
                                                    <input type="text" name={"eway_bill_date_" + idx} id={"eway_bill_date_" + idx} className="form-control" disabled />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Generated By</label>
                                                    <input type="text" name={"generated_by_" + idx} id={"generated_by_" + idx} className="form-control" disabled />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Inv Date</label>
                                                    <input type="text" name={"inv_no_" + idx} id={"inv_no_" + idx} className="form-control" disabled />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Inv Amount</label>
                                                    <input type="text" name={"total_invoice_value_" + idx} id={"total_invoice_value_" + idx} className="form-control" disabled />
                                                </div>
                                                <div className="col-xl-2">
                                                    <label className="col-xl-12 col-lg-12">Valid Untill
                                                        {/* <i class="fa fa-asterisk ewayBill-mandatory" style={{color:"red"}} aria-hidden="true"></i> */}
                                                    </label>
                                                    <input type="text" name={"eway_bill_valid_date_" + idx} id={"eway_bill_valid_date_" + idx} className="form-control" disabled />
                                                </div>
                                                <div className="col-xl-4" style={{ display: (idx != 0) ? 'block' : 'none' }}>
                                                    <h6 style={{ marginTop: "1em", cursor: "pointer" }} onClick={() => this.handleRemoveRow(idx)}>Clear</h6>
                                                </div>
                                            </div>
                                        ))}
                                        <input type="hidden" id="itemsLength" value={this.state.rows.length} />
                                        <div class="row col-sm-12" style={headStyle}>
                                            <div className="row col-xl-12 input_fields_wrap m-top-1" id="addMrERp">
                                                <div class="col-xl-12">
                                                    <p style={{ color: "red" }}>*Press tab after entering eway bill no to populate bill details</p>
                                                    <button class="btn btn-primary" type="button" onClick={this.handleAddRow}>Add More +</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Mode : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="transportation_mode" onChange={this.changeHandler}  >
                                                <option value="">-- Select Mode --</option>
                                                <option value="road">Road</option>
                                                <option value="rail">Rail</option>
                                                <option value="air">Air</option>
                                                <option value="ship or ship cum road/rail">Ship or Ship Cum Road/Rail</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Vehicle No : </label>
                                            <input type="text" name="vehicle_number" id="vehicle_number" className="form-control requestFields" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12" id="trn_doc_no">Transporter Doc. No : </label>
                                            <input type="text" name="transporter_document_number" id="transporter_document_number" className="form-control requestFields" onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3"}>
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            {/* <input type="text" name="transporter_document_date" id="transporter_document_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} /> */}
                                            <DatePicker
                                                selected={this.state.transporter_document_date}
                                                onChange={e => this.setState({ transporter_document_date: e })}
                                                name="transporter_document_date"
                                                id="transporter_document_date"
                                                className="datepicker-custom"
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
    }
}



// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-decimal-cls", function (evt) {
    var self = $(this);
    self.val(self.val().replace(/[^0-9\.]/g, ''));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
        evt.preventDefault();
    }
});
$(document).on("input", ".gst", function (evt) {
    if ($(this).val() != "" && $(this).val().length == 15) {
        var statecode = $(this).val().substring(0, 2);
        var pancarno = $(this).val().substring(2, 12);
        var entityNumber = $(this).val().substring(12, 13);
        var defaultZvalue = $(this).val().substring(13, 14);
        var checksumdigit = $(this).val().substring(14, 15);
        if ($(this).val().length != 15) {
            alert('GST Number is invalid');
            $(this).focus();
            return false;
        }
        if (pancarno.length != 10) {
            alert('GST number is invalid ');
            $(this).focus();
            return false;
        }
        if (defaultZvalue !== 'Z') {
            alert('GST Number is invalid Z not in Entered Gst Number');
            $(this).focus();
        }

        if ($.isNumeric(statecode)) {
            $('#gst_state_code').val(statecode).trigger('change');
        } else {
            alert('Please Enter Valid State Code');
            $(this).focus();
        }

        if ($.isNumeric(checksumdigit)) {
            return true;
        } else {
            alert('GST number is invalid last character must be digit');
            $(this).focus();
        }
    }
});