const UnloadingException=(props)=>{
    const handleClick = (params) => {
        props.onShowUnloadingData(props);
    };
    let color = "#cccc"
    if (props.getValue() != undefined && props.getValue() == 1) {
        color = "#7ad337"
    }
    return(
        <a onClick={handleClick}  title="Unloading Exception"><i className="icofont icofont-truck-loaded f20" style={{ color: color }}></i> </a>
    )
}
export default UnloadingException