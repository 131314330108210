
import React, { Component } from 'react';

 
// const MyHeaderComponent = (props) => {
//     //console.log("props ", props)
//     var routeid = props.displayName;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
//     var check = document.getElementsByClassName("checklists");
//     console.log("check here ",check)
//     if(check.length > 0)
//     {
//         for(var c=0;c<check.length;c++)
//         {
//             console.log("check[c] ", check[c])
//             check[c].setAttribute("checked", true)
//             document.getElementById(check[c]).checked = true;
//         }
//     }
//     const handleRouteClick = (e) => {
//         //console.log("Event ",e.target.id)
//         var inputid =e.target.id;
//         var checking = document.getElementById(inputid).checked
//         var checkedlst = []
//         var uncheckedlst = []
//         console.log("checking ", checking)
//         if(checking == true)
//         {
//             checkedlst.push(inputid)
//         }
//         if(checking == false)
//         {
//             uncheckedlst.push(inputid)
//         }
//         console.log("checkedlst ", checkedlst)
//         e.stopPropagation();
//         if(checking == true)
//         {
//             props.context.componentParent.onClickHideRoute(props, checkedlst);
//         }
//         else
//         {
//             props.context.componentParent.onClickShowRoute(props,uncheckedlst);
//         }
        
//     };

//     return (
//         <div className="map-icon-component">
//             <input type="checkbox" onClick={handleRouteClick} id={props.displayName} className="checklists" value={1} /> {props.displayName}
//         </div>
//     );
// };
// window.onload = function() {
//     var check = document.getElementsByClassName("checklists");
//     console.log("check here ",check)
//     if(check.length > 0)
//     {
//         for(var c=0;c<check.length;c++)
//         {
//             console.log("check[c] ", check[c])
//             check[c].setAttribute("checked", true)
//             document.getElementById(check[c]).checked = true;
//         }
//     }
// };
// export default MyHeaderComponent;




export default class MyHeaderComponent extends Component {
    constructor(props) {
      super(props);
      this.state ={
          value:true
      }
      this.checkedHandler = this.checkedHandler.bind(this);
    }
    // checkedHandler = (event) =>{                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 ) {
    //   let checked = event.target.checked;
    //   let colId = this.props.column.colId;
    //     console.log("props ",this.props)
      
    //     this.setState({
    //         value: !this.state.value
    //     });
    // }

    checkedHandler(event){
        var inputid =event.target.id;
        var checking = document.getElementById(inputid).checked
        // console.log("checking ",checking)
        // console.log("props ",this.props)
        var checkedlst = []
        var uncheckedlst = []
        if(checking == true)
        {
            uncheckedlst.push(inputid)
        }
        if(checking == false)
        {
            checkedlst.push(inputid)
        }
       // console.log("checkedlst ", checkedlst)
        event.stopPropagation();
        if(checking == true)
        {
            this.props.context.componentParent.onClickShowRoute(this.props,uncheckedlst);
        }
        else
        {
            this.props.context.componentParent.onClickHideRoute(this.props, checkedlst);
        }

        this.setState({
            value:!this.state.value
        })
    }
    
    render() {
        //console.log("this.props ", this.props)
      return (
        <span>
            <input 
            type="checkbox" 
            id = {this.props.displayName}
            onClick={this.checkedHandler}
            checked={this.state.value}
            className={"h_"+this.props.column.colId}
            /> {this.props.displayName}
        </span>
      )
    }
  }
